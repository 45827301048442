const Regex = {
  validateEmail(val) {
    return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(
      val
    );
  },

  validateEmoji(text) {
    const reg = /[uD83C-uDBFFuDC00-uDFFF]+/g;

    return reg.test(text);
  },

  validateMobile(text) {
    return (/^\+(?:[0-9] ?){6,14}[0-9]$/).test(text);
  },

  validateMobileWithoutCC(val) {
    // return (/^[0]?[3-9]?[1-9][-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/).test(
    //   val
    // );
    return (/^[0][2-9][0-9]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/).test(
      val
    );
  },

  validateString(val) {
    const stringRegex = /^[a-zA-Z\x20]{3,25}$/;
    const emogiRegex = /[uD83C-uDBFFuDC00-uDFFF]+/g;

    if (stringRegex.test(val.trim())) {
      return !emogiRegex.test(val.trim());
    }

    return false;
  },

  validateCityState(val) {
    const stringRegex = /^[a-zA-Z\x20]{2,50}$/;
    const emogiRegex = /[uD83C-uDBFFuDC00-uDFFF]+/g;

    if (stringRegex.test(val.trim())) {
      return !emogiRegex.test(val.trim());
    }

    return false;
  },

  validatePassword(val) {
    return (/^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]\S{8,16}$/).test(val);
  },

  validateNumbers(val) {
    return (/^[0-9]{0,}$/).test(val);
  },

  validateURL(url) {
    return (/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/).test(url);
  },

  validatePrice(val) {
    return (/^(\d*([.,](?=\d{1}))?\d+)?$/).test(val);
  },

  validateAlphaNumberic(val) {
    return (/^[A-Z0-9]+$/i).test(val);
  },

  getNumbericValuesFromString(val) {
    return val.match(/^\d+|\d+\b|\d+(?=\w)/g);
  },

  validateDecimalNumbers(val) {
    return (/^((\d|[1-9]\d+)(\.\d{0,1})?|\.\d{0,1})$/).test(val);
  },

  /**
   * Method used to remove trailing zeros after decimal point.
   */

  removeTrailingZeros(amount) {
    let stringAmount = amount.toString();

    const regEx2 = /[0]+$/; // to check zeros after decimal point
    const regEx3 = /[.]$/; // remove decimal point.

    if (stringAmount.indexOf(".") > -1) {
      stringAmount = stringAmount.replace(regEx2, ""); // Remove trailing 0's
      stringAmount = stringAmount.replace(regEx3, "");
    }

    return parseFloat(stringAmount).toFixed(2);
  },

  /**
   * Regex to validate
   */

  validateGreaterthanZero(number) {
    const regex = /^(0*[1-9][0-9]*)$/;

    return regex.test(number.toUpperCase());
  },

  /**
   * Regex to validate UK postal code without any emoji
   */

  validatePostalCode(val) {
    const stringRegex = /^[0-9]*$/;

    return stringRegex.test(val);
  },

  validateTwoDigitsBeforeAndAfterDecimal(val) {
    const regex = /^\d{0,2}(\.\d{1,2})?$/;

    return !regex.test(val);
  },

  validateTwoDigitsAfterDecimal(val) {
    const regex = /^\d*(\.\d{1,2})?$/;

    return !regex.test(val);
  },

  validateSocialMedia(val) {
    const regex = /http(?:s)?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.com\/([a-zA-Z0-9_]+)/;

    return regex.test(val);
  }
};

module.exports = Regex;
