import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { account } from "./account.reducer";
import { user } from "./user.reducer";
import { alert } from "./alert.reducer";
import { directory } from "./directory.reducer";
import { authorize } from "./authorize.reducer";
import { device } from "./device.reducer";
import { asset } from "./asset.reducer";
import { relation } from "./relation.reducer";
import { report } from "./report.reducer";
import { loading } from "./loading.reducer";
import { accountPageReducer } from "./pages/accountPage.reducer";
import { devicePageReducer } from "./pages/devicePage.reducer";
import { deviceType } from "./deviceType.reducer";
import { data } from "./data.reducer";
import { activity } from "./activity.reducer";
import { activityPageReducer } from "./pages/activityPage.reducer";
import { notification } from "./notification.reducer";

export default combineReducers({
  authentication,
  account,
  alert,
  directory,
  user,
  authorize,
  device,
  asset,
  relation,
  report,
  loading,
  deviceType,
  data,
  activity,
  notification,

  // Page View
  accountPage: accountPageReducer,
  devicePage: devicePageReducer,
  activityPage: activityPageReducer
});
