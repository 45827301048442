import React, { Component, createRef } from "react";
import {
  Button,
  Dropdown,
  Form,
  Image,
  Segment,
  Header,
  Grid,
} from "semantic-ui-react";
import IconCustom from "../../../../../assets/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { authorizeActions, accountPageActions } from "../../../../../_actions/";
import TextField from "../../../../../components/TextField/TextField";
import FormGroupRole from "../../../../../components/FormGroup/FormGroupRole";

import styled from "styled-components";
import { RoleColor } from "../../../../../_helpers";
import SidebarButton from "../../../../../components/Button/SidebarButton";
import handleLoading from "../../../../../components/Loader/Loader";
import DefaultTextArea from "../../../../../components/TextArea/TextArea";
import SearchDropdown from "../../../../../components/Dropdown/SearchDropdown";
import Countries from "../../../../../assets/data/countries";

function gradientColor(props) {
  return (props.roleColors && props.roleColors.length > 1
  ? `linear-gradient(${props.roleColors.map(addColors)})`
  : props.roleColors.length === 1)
    ? `${props.roleColors[0]}`
    : "#589FA5";

  function addColors(item) {
    return `${item}`;
  }
}

const SegmentGradients = styled(Segment)`
  background: ${gradientColor} !important;
`;

const SegmentRole = styled(Segment)`
  background: ${gradientColor} !important;
`;

class CreateUserComponent extends Component {
  constructor(props) {
    super(props);
    const { options } = this.props;

    this.state = {
      roleSelecting: {},
      roleSelected: [],
      options,
      userId: "",
      disableRoleBtn: true,
      disableCreateBtn: true,
      roleColors: [],
      countryOptions: Countries.COUNTRIES_LIST,
    };
  }

  inputEmailRef = createRef();

  checkEligible() {
    const directoryPath = this.props.directoryPath;
    const payload = { action: "*", subject: "*", directoryPath: directoryPath };

    Promise.resolve(this.props.getEligible(payload)).then((response) => {
      this.props.updateEligible(response.isEligible);
      if (!response.isEligible) {
        const arr = this.props.options?.filter((item) => item.text !== "admin");
        this.setState({ options: arr });
      } else {
        this.setState({ options: this.props.options });
      }
    });
  }

  onClickRole = () => {
    const payload = {
      key: this.state.roleSelecting.key,
    };

    Promise.resolve(this.props.decrementRole(payload)).then(() => {
      this.checkEligible();
    });

    this.setState((prevState) => ({
      roleColors:
        prevState.roleColors && prevState.roleColors.length
          ? [...prevState.roleColors, RoleColor(prevState.roleSelecting.value)]
          : [RoleColor(prevState.roleSelecting.value)],
      roleSelected: [prevState.roleSelecting, ...prevState.roleSelected],
      roleSelecting: {},
      disableRoleBtn: true,
    }));
  };

  onClickRemoveRole = (e, { value }) => {
    const roleSelected = this.state.roleSelected.find(
      (item) => item.key === value
    );

    const payload = {
      option: roleSelected,
    };

    Promise.resolve(this.props.incrementRole(payload)).then(() => {
      this.checkEligible();
    });
    this.setState((prevState) => ({
      roleColors: prevState.roleColors.filter(
        (item) => item !== RoleColor(roleSelected.value)
      ),
      roleSelected: prevState.roleSelected.filter((item) => item.key !== value),
    }));
  };

  handleChangeRole = (e, data) => {
    const option = data.options.find((o) => o.value === data.value);

    this.setState({
      roleSelecting: option,
      disableRoleBtn: false,
    });
  };

  componentDidMount() {
    Promise.resolve(this.props.getRoles({ directoryPath: this.props.directoryPath })).then(() => {
      this.checkEligible();
    });
  }

  render() {
    const { roleSelected, countryOptions } = this.state;

    const {
      email,
      fullName,
      firstname,
      lastname,
      telephoneNumber,
      username,
      password,
      address1,
      address2,
      province,
      country,
      postal,
      description,
      errorFullName,
      errorFirstName,
      errorLastName,
      errorTelephoneNumber,
      errorUsername,
      errorPassword,
      errorEmail,
      handleChange,
      handleOnChangeCountry,
      loading,
      userDirectoryStatus,
      firstType,
    } = this.props;

    console.log("userDirectoryStatus", userDirectoryStatus);

    return (
      <React.Fragment>
        {handleLoading(loading)}
        <div>
          {/* <SegmentGradients
            className={"segment--profile"}
            basic
            inverted
            roleColors={this.state.roleColors}
          /> */}
          <div>
            <Header
              className={"header__title1 header__title1--sidebar"}
              textAlign={"center"}
            >
              {userDirectoryStatus === false ? "Update Account Role" : "Create Account"}
            </Header>
          </div>

          <div className={"div__scroll--createUser"}>
            {/* <SegmentRole
              className="image--profile"
              roleColors={this.state.roleColors}
              circular >
              <Image
                src="/images/addUser.svg"
                className="img--addNewUser"
                centered
              />
            </SegmentRole> */}

            <Form autoComplete="off">
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    {/* <TextField
                      text="Full Name"
                      id="fullName"
                      name="fullName"
                      value={fullName}
                      handleInputValue={handleChange}
                      error={errorFullName}
                      isDisable={userDirectoryStatus === false}
                    /> */}

                    <TextField
                      text="First Name"
                      id="firstname"
                      name="firstname"
                      value={firstname}
                      handleInputValue={handleChange}
                      error={errorFirstName}
                      isDisable={userDirectoryStatus === false}
                    />

                    <TextField
                      text="Last Name"
                      id="lastname"
                      name="lastname"
                      value={lastname}
                      handleInputValue={handleChange}
                      error={errorLastName}
                      isDisable={userDirectoryStatus === false}
                    />

                    <TextField
                      type="number"
                      text="Phone Number"
                      id="telephoneNumber"
                      name="telephoneNumber"
                      value={telephoneNumber}
                      handleInputValue={handleChange}
                      error={errorTelephoneNumber}
                      isDisable={userDirectoryStatus === false}
                    />

                    <TextField
                      text={"Address1"}
                      id={"address1"}
                      name="address1"
                      handleInputValue={handleChange}
                      value={address1}
                      isDisable={userDirectoryStatus === false}
                    />
                    <TextField
                      text={"Address2"}
                      id={"address2"}
                      name="address2"
                      handleInputValue={handleChange}
                      value={address2}
                      isDisable={userDirectoryStatus === false}
                    />
                    <TextField
                      text={"Province"}
                      id={"province"}
                      name="province"
                      handleInputValue={handleChange}
                      value={province}
                      isDisable={userDirectoryStatus === false}
                    />

                    <SearchDropdown
                      includeClassName={
                        "dropdown__sidebar dropdown__sidebar--inline dropdown__sidebar--bottom"
                      }
                      options={countryOptions}
                      initialValue={country}
                      text={"Select country"}
                      name={"country"}
                      search={true}
                      handleChangeDropdown={handleOnChangeCountry}
                      isDisable={userDirectoryStatus === false}
                    />

                    <TextField
                      text={"Postal"}
                      id={"postal"}
                      name="postal"
                      handleInputValue={handleChange}
                      value={postal}
                      isDisable={userDirectoryStatus === false}
                    />
                    <br />
                    <div className={"div__header-sidebar--description"}>
                      <Header className={"header__title-sidebar"}>Description</Header>
                    </div>
                    <Form.Field>
                      <DefaultTextArea
                        name="description"
                        text={"Description"}
                        value={description}
                        handleChange={handleChange}
                        placeholder="Description"
                        isDisable={userDirectoryStatus === false}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <TextField
                      text={"Username"}
                      id={"username"}
                      name={"username"}
                      handleInputValue={handleChange}
                      value={username}
                      error={errorUsername}
                      isDisable={
                        userDirectoryStatus === false ||
                        firstType === "username"
                      }
                    />
                    <TextField
                      refTextField={this.inputEmailRef}
                      text="Email"
                      id="email"
                      name="email"
                      value={email}
                      handleInputValue={handleChange}
                      error={errorEmail}
                      isDisable={
                        userDirectoryStatus === false || firstType === "email"
                      }
                    />
                    {userDirectoryStatus !== false && (
                      <TextField
                        type="password"
                        text={"Password"}
                        id={"password"}
                        name={"password"}
                        error={errorPassword}
                        handleInputValue={handleChange}
                        value={password}
                      />
                    )}
                    <br />
                    {roleSelected && roleSelected.length
                      ? roleSelected.map((item) => (
                          <FormGroupRole
                            key={item.key}
                            title={item.value}
                            value={item.key}
                            desc={item.desc}
                            onClickRemove={this.onClickRemoveRole}
                          />
                        ))
                      : null}

                    {this.state.options && this.state.options.length ? (
                      <Form.Group inline>
                        <Form.Field
                          fluid
                          selection
                          className={"form-selection"}
                          width={13}
                          control={Dropdown}
                          options={this.state.options}
                          placeholder="Select Role"
                          name="roles"
                          onChange={this.handleChangeRole}
                        />
                        <Form.Field className={"field--no-right"}>
                          <Button
                            basic
                            fluid
                            className={
                              "button__image button__image--sumbit-role"
                            }
                            onClick={this.onClickRole}
                            disabled={this.state.disableRoleBtn}
                          >
                            <IconCustom
                              name={"check"}
                              className={"svg-icon__check"}
                              fillColor={"ffffff"}
                              width={"40"}
                              height={"40"}
                              viewBox={"0 0 40 40"}
                              reactX={"6"}
                              reactY={"6"}
                            />
                          </Button>
                        </Form.Field>
                      </Form.Group>
                    ) : null}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </div>
        </div>

        <SidebarButton
          disabled={
            //!fullName ||
            !firstname ||
            !lastname ||
            !telephoneNumber ||
            !email ||
            !username ||
            roleSelected.length === 0
          }
          btnSecondary="BACK"
          btnActive={userDirectoryStatus === false ? "ADD" : "CREATE"}
          OnCloseSidebar={() => this.props.displayCheckEmailComponent()}
          handleSubmit={() => this.props.handleSubmit(roleSelected)}
          width={5}
          isUpdateAccount={true}
        />
      </React.Fragment>
    );
  }
}

CreateUserComponent.propTypes = {
  loading: PropTypes.bool,
  roles: PropTypes.array,
  options: PropTypes.array,
};

const mapStateToProps = (state) => ({
  loading: state.user.getting || state.user.creatingUser,
  roles: state.authorize.roles,
  options: state.authorize.options,
});

const mapDispatchToProps = (dispatch) => ({
  getRoles: (payload, objUser) => dispatch(authorizeActions.getRoles(payload, objUser)),
  getAuthority: (payload) => dispatch(authorizeActions.getAuthority(payload)),
  incrementRole: (payload) => dispatch(authorizeActions.incrementRole(payload)),
  decrementRole: (payload) => dispatch(authorizeActions.decrementRole(payload)),
  getEligible: (payload) => dispatch(authorizeActions.getEligible(payload)),
  updateEligible: (eligible) =>
    dispatch(accountPageActions.updateEligible(eligible))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserComponent);
