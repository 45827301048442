import React from "react";

const SVG = ({
  className = "",
  viewBox = "0 0 32 32",
  width = "32",
  height = "32",
  x = "10",
  y = "8"
}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" />

    <path d="M8 9.77778V13.3333H9.77778V9.77778H13.3333V8H9.77778C8.8 8 8 8.8 8 9.77778ZM9.77778 18.6667H8V22.2222C8 23.2 8.8 24 9.77778 24H13.3333V22.2222H9.77778V18.6667ZM22.2222 22.2222H18.6667V24H22.2222C23.2 24 24 23.2 24 22.2222V18.6667H22.2222V22.2222ZM22.2222 8H18.6667V9.77778H22.2222V13.3333H24V9.77778C24 8.8 23.2 8 22.2222 8Z" />
  </svg>
);

export default SVG;
