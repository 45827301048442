import { loadingConstants } from "../_constants/loading.constants";

const initialState = {
  accountLoading: false,
  deviceLoading: false
};

export function loading(state = initialState, action) {
  if (action.type === loadingConstants.SET_LOADING) {
    return {
      ...state,
      [`${action.scope}Loading`]: action.loading
    };
  }

  return state;
}
