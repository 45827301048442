/* eslint-disable complexity */
import { deviceTypeConstants } from "../_constants";
import { DateHelper } from "../_helpers/DateHelper";

export function deviceType(state = [], action) {
  switch (action.type) {

    // get device type list
    case deviceTypeConstants.GET_DEVICE_TYPE_LIST_REQUEST:
      return {
        loading: true,
        deviceTypeList: state.deviceTypeList
      };
    case deviceTypeConstants.GET_DEVICE_TYPE_LIST_SUCCESS:
      return {
        deviceTypeList: action.response.data.map((item) => ({
          _id: item._id,
          deviceType: item.type,
          telemetryKey: item.telemetryKey,
          createDate: DateHelper.convertToDateTime(item.createdAt),
          lastUpdate: DateHelper.convertToDateTime(item.updatedAt),
          delete: true
        }))
      };
    case deviceTypeConstants.GET_DEVICE_TYPE_LIST_FAILURE:
      return {
        error: action.error
      };

       // post device type
    case deviceTypeConstants.POST_DEVICE_TYPE_REQUEST:
      return {
        loading: true,
        deviceTypeList: state.deviceTypeList
      };
    case deviceTypeConstants.POST_DEVICE_TYPE_SUCCESS:
      return {
       deviceTypeList: state.deviceTypeList ? [{
        _id: action.response.data._id,
        deviceType: action.response.data.type,
        telemetryKey: action.response.data.telemetryKey,
        createDate: DateHelper.convertToDateTime(action.response.data.createdAt),
        lastUpdate: DateHelper.convertToDateTime(action.response.data.updatedAt),
        delete: true
       }, ...state.deviceTypeList] : null,
       postDeviceTypeStatus: action.response.status
      };
    case deviceTypeConstants.POST_DEVICE_TYPE_FAILURE:
      return {
        error: action.error,
        deviceTypeList: state.deviceTypeList
      };

        // delete device type
    case deviceTypeConstants.DELETE_DEVICE_TYPE_REQUEST:
      return {
        deleting: true,
        deviceTypeList: state.deviceTypeList
      };
    case deviceTypeConstants.DELETE_DEVICE_TYPE_SUCCESS:
      return {
       deleteDeviceTypeStatus: action.response.status,
       deviceTypeList: state.deviceTypeList
      };
    case deviceTypeConstants.DELETE_DEVICE_TYPE_FAILURE:
      return {
        error: action.error
      };

      // get device type by id
    case deviceTypeConstants.GET_DEVICE_TYPE_BY_ID_REQUEST:
      return {
        get: true,
        deviceTypeList: state.deviceTypeList
      };
    case deviceTypeConstants.GET_DEVICE_TYPE_BY_ID_SUCCESS:
      return {
       deviceTypeInfo: action.response.data,
       deviceTypeList: state.deviceTypeList

      };
    case deviceTypeConstants.GET_DEVICE_TYPE_BY_ID_FAILURE:
      return {
        error: action.error
      };

       // patch device type
    case deviceTypeConstants.PATCH_DEVICE_TYPE_REQUEST:
      return {
        updating: true,
        deviceTypeList: state.deviceTypeList
      };
    case deviceTypeConstants.PATCH_DEVICE_TYPE_SUCCESS:
      return {
       updateDeviceTypeStatus: action.response.status,
       deviceTypeList: state.deviceTypeList
      };
    case deviceTypeConstants.PATCH_DEVICE_TYPE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
