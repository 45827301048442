export const assetConstants = {
  // clear asset
  CLEAR_ASSET: "CLEAR_ASSET",

  // initial state asset
  INITIAL_STATE_ASSET: "ASSET_INITIAL_STATE_ASSET",

  // get asset list
  GET_ASSET_LIST_REQUEST: "ASSET_GET_ASSET_LIST_REQUEST",
  GET_ASSET_LIST_SUCCESS: "ASSET_GET_ASSET_LIST_SUCCESS",
  GET_ASSET_LIST_FAILURE: "ASSET_GET_ASSET_LIST_FAILURE",

  // post asset
  POST_ASSET_REQUEST: "ASSET_POST_ASSET_REQUEST",
  POST_ASSET_SUCCESS: "ASSET_POST_ASSET_SUCCESS",
  POST_ASSET_FAILURE: "ASSET_POST_ASSET_FAILURE",

  // get asset's infomation
  GET_ASSET_INFO_REQUEST: "ASSET_GET_ASSET_INFO_REQUEST",
  GET_ASSET_INFO_SUCCESS: "ASSET_GET_ASSET_INFO_SUCCESS",
  GET_ASSET_INFO_FAILURE: "ASSET_GET_ASSET_INFO_FAILURE",

  // patch asset's infomation
  PATCH_ASSET_REQUEST: "ASSET_PATCH_ASSET_REQUEST",
  PATCH_ASSET_SUCCESS: "ASSET_PATCH_ASSET_SUCCESS",
  PATCH_ASSET_FAILURE: "ASSET_PATCH_ASSET_FAILURE",

  // del asset
  DEL_ASSET_REQUEST: "ASSET_DEL_ASSET_REQUEST",
  DEL_ASSET_SUCCESS: "ASSET_DEL_ASSET_SUCCESS",
  DEL_ASSET_FAILURE: "ASSET_DEL_ASSET_FAILURE"
};
