export const deviceTypeConstants = {
  // get device type
  GET_DEVICE_TYPE_LIST_REQUEST: 'GET_DEVICE_TYPE_LIST_REQUEST',
  GET_DEVICE_TYPE_LIST_SUCCESS: 'GET_DEVICE_TYPE_LIST_SUCCESS',
  GET_DEVICE_TYPE_LIST_FAILURE: 'GET_DEVICE_TYPE_LIST_FAILURE',

   // post device type
   POST_DEVICE_TYPE_REQUEST: 'POST_DEVICE_TYPE_REQUEST',
   POST_DEVICE_TYPE_SUCCESS: 'POST_DEVICE_TYPE_SUCCESS',
   POST_DEVICE_TYPE_FAILURE: 'POST_DEVICE_TYPE_FAILURE',

    // delete device type
    DELETE_DEVICE_TYPE_REQUEST: 'DELETE_DEVICE_TYPE_REQUEST',
    DELETE_DEVICE_TYPE_SUCCESS: 'DELETE_DEVICE_TYPE_SUCCESS',
    DELETE_DEVICE_TYPE_FAILURE: 'DELETE_DEVICE_TYPE_FAILURE',

     // get device type by id
     GET_DEVICE_TYPE_BY_ID_REQUEST: 'GET_DEVICE_TYPE_BY_ID_REQUEST',
     GET_DEVICE_TYPE_BY_ID_SUCCESS: 'GET_DEVICE_TYPE_BY_ID_SUCCESS',
     GET_DEVICE_TYPE_BY_ID_FAILURE: 'GET_DEVICE_TYPE_BY_ID_FAILURE',

     // patch device type
     PATCH_DEVICE_TYPE_REQUEST: 'PATCH_DEVICE_TYPE_REQUEST',
     PATCH_DEVICE_TYPE_SUCCESS: 'PATCH_DEVICE_TYPE_SUCCESS',
     PATCH_DEVICE_TYPE_FAILURE: 'PATCH_DEVICE_TYPE_FAILURE'
};
