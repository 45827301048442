import { accountConstants } from "../_constants";

export function account(state = {}, action) {
  switch (action.type) {

    //  change Pass
    case accountConstants.CHANGE_PASS_REQUEST:
      return {
        loading: true
      };
    case accountConstants.CHANGE_PASS_SUCCESS:
      return {
        loading: false,
        response: action.response
      };
    case accountConstants.CHANGE_USER_FAILURE:
      return {
        loading: false,
        error: action.error
      };

    // get user's info
    case accountConstants.GET_USERINFO_REQUEST:
      return {
        loading: true
      };
    case accountConstants.GET_USERINFO_SUCCESS:
      return {
        loading: false,
        response: action.response,
        directoryStatus: action.response.data.directory
      };
    case accountConstants.GET_USERINFO_FAILURE:
      return {
        loading: false,
        error: action.error,
        errorStatus: action.error.response.data.code
      };

    // // create User
    // case accountConstants.CREATE_USER_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case accountConstants.CREATE_USER_SUCCESS:
    //   return {
    //     loading: false,
    //     response: action.response
    //   };
    // case accountConstants.CREATE_USER_FAILURE:
    //   return {
    //     loading: false,
    //     error: action.error
    //   };

    // forgot passwprd
    case accountConstants.FORGOT_PASSWORD_REQUEST:
      return {
        getting: true
      };
    case accountConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        response: action.response
      };
    case accountConstants.FORGOT_PASSWORD_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
