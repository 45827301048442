import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Card, Feed, Form, Icon } from "semantic-ui-react";
import IconCustom from "../../assets/icons";
import { RoleColor } from "../../_helpers";
import WarningModal from "../Modal/WarningModal"
import { RoleLabel } from "../../_helpers";;


class FormGroupRole extends Component {
  render() {
    return (
      <Form.Group inline>
        <Form.Field width={16} className="role-card">
          <Card>
            <Card.Content>
              <Feed>
                <Feed.Event>
                  <Feed.Label>
                    <IconCustom
                      className={"svg-icon__user"}
                      name={"user"}
                      fillColor={RoleColor(this.props.title)}
                    />
                  </Feed.Label>
                  <Feed.Content>
                    <Feed.Summary className="word-break">{RoleLabel(this.props.title)}</Feed.Summary>
                  </Feed.Content>
                  {
                    !this.props.isDisable ?
                      (this.props.title !== "admin" && this.props.title !== "admin_account" ?
                      <Button
                        icon
                        size="mini"
                        onClick={this.props.onClickRemove}
                        value={this.props.value}
                      >
                        <Icon name='trash alternate' />
                      </Button> :
                      <WarningModal
                        triggerType={"tiny"}
                        descType={"role"}
                        handleDeleteItem={this.props.onClickRemove}
                        item={this.props}
                      />) : null
                  }
                </Feed.Event>
              </Feed>
            </Card.Content>
          </Card>
        </Form.Field>
        {/* {
        this.props.title !== "admin" && this.props.title !== "admin_account" ?
        <Button
          basic
          className={"button__image button__image--inactive"}
          onClick={this.props.onClickRemove}
          value={this.props.value}>
          <IconCustom className={"svg-icon__bin"} name={"bin"} />
        </Button> :
        <WarningModal
          triggerType={"icon--trash"}
          descType={"role"}
          handleDeleteItem={this.props.onClickRemove}
          item={this.props}
        />
      } */}
      </Form.Group>
    );
  }
}

FormGroupRole.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClickRemove: PropTypes.func
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormGroupRole);
