import React from "react";

const SVG = ({
  className = "",
  viewBox = "0 0 32 32",
  stroke = "transparent"
}) => (
  <svg
    width="32"
    height="32"
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    stroke={stroke}
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="8" />
    <mask
      id="add"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="16"
      height="16"
    >
      <path
        d="M23 15H17V9C17 8.4 16.6 8 16 8C15.4 8 15 8.4 15 9V15H9C8.4 15 8 15.4 8 16C8 16.6 8.4 17 9 17H15V23C15 23.6 15.4 24 16 24C16.6 24 17 23.6 17 23V17H23C23.6 17 24 16.6 24 16C24 15.4 23.6 15 23 15Z"
        fill="black"
      />
    </mask>
    <g mask="url(#add)">
      <rect
        id={"icon-add-plus"}
        x="4"
        y="4"
        width="24"
        height="24"
        fill={"none"}
      />
    </g>
  </svg>
);

export default SVG;
