import { Input } from "semantic-ui-react";
import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

const InputSearch = (props) => {
  function handleSearchChange(e, { value }) {
    e.preventDefault();
    setTimeout(() => {
      props.handleTextChange(e, value);
    }, 300);
  }

  return (
    <Input
      fluid
      className={"input__search--withoutBorder"}
      placeholder={"Search"}
      icon={"search"}
      iconPosition={"left"}
      value={props.searchValue}
      onChange={_.debounce(handleSearchChange, 300)}
    />
  );
};

InputSearch.propTypes = {
  handleTextChange: PropTypes.func
};

export default InputSearch;
