/* eslint-disable max-lines */
import { accountConstants } from "../_constants";
import { history } from "../_helpers/";
import { alertActions } from "./";
import axios from "axios";
import { alertFetchServiceError } from "../_helpers";

export const accountActions = {
  login,
  verify,
  logout,
  changePass,
  getUserInfoFromEmail,
  getUserInfoFromUsername,
  deleteUser,
  updateUserInfo,
  sendEmail
};

const accountPath = "/api/account";
const relativePath = "/user";

function login(payload, rememberMe) {
  // let options = {};
  // options.timeout = 3000;
  // options.json = true;
  // options.resolveWithFullResponse = true;
  // options.headers= { 
  //                   'Content-Type': 'application/json',
  //                   'Accept': 'application/json',
  //                   };
  // options.method= 'POST';
  // options.url = `${accountPath}/login`;
  // options.data = payload;

  // const requestOptions = { data: payload };

  return (dispatch) => {
    dispatch(request());
    
      return axios.post(`${accountPath}/login`,payload)
      //.then((response) => response.data)
      .then((response) => {
        dispatch(success(response));
        console.log("resLogin ====== ",response)
        // history.push({
        //   pathname:"/verifyOTP",
        //   state: { phoneNumber: response.telephoneNumber }
        // });

        //TODO response.data.accessToken /////////////
        if(response.data.telephoneNumber){
          return response.data;
        }else{
          if(response.data == "Unauthorized"){
            dispatch(alertActions.error("OTP Verification Failed", "Error!"));
            dispatch(alertActions.clear());
            //history.push("/login");
          }else{
              //get user's info
            axios.get(`${accountPath}/user/me`).then((resUserInfo) => {
              console.log("axios.get(`${accountPath}/user/me`).then((resUserInfo) => ",resUserInfo)
              dispatch(success(resUserInfo.data));
              localStorage.setItem("user", JSON.stringify(resUserInfo.data));
  
              if (resUserInfo.data.isActive === true) {
                history.push("/home");
              } else {
                localStorage.setItem("currentPassword", payload.password);
                //history.push("/changepass");
                history.push("/home");
              }
  
              dispatch(alertActions.success("Login is successful!"));
              dispatch(alertActions.clear());
            }).catch((err) => {
              localStorage.clear();
              dispatch(error(err));
              dispatch(alertActions.error(err.response.data.message, "Error!"));
              dispatch(alertActions.clear());
            });
          }


        }
        
      })
      .catch((err) => {
        localStorage.clear();
        dispatch(error(err));
        dispatch(alertActions.error(err.response.data.message, "Error!"));
        dispatch(alertActions.clear());
      });
    
  };

  function request() {
    return {
      type: accountConstants.LOGIN_REQUEST,
      payload,
      rememberMe
    };
  }

  function success(resUserInfo) {
    return {
      type: accountConstants.LOGIN_SUCCESS,
      resUserInfo
    };
  }

  function error(err) {
    return {
      type: accountConstants.LOGIN_FAILURE,
      err
    };
  }
}


// function login(payload, rememberMe) { //backup
//   return (dispatch) => {
//     dispatch(request(payload));

//     return axios
//       .post(`${accountPath}/login`, payload)
//       .then((resLogin) => {
//         // get user's info
//         axios
//           .get(`${accountPath}/user/me`)
//           .then((resUserInfo) => {
//             dispatch(success(resUserInfo.data));
//             localStorage.setItem("user", JSON.stringify(resUserInfo.data));

//             if (resUserInfo.data.isActive === true) {
//               //history.push("/home");
//               history.push("/verifyOTP");
//             } else {
//               localStorage.setItem("currentPassword", payload.password);
//               history.push("/changepass");
//             }

//             dispatch(alertActions.success("Login is successful!"));
//             dispatch(alertActions.clear());
//           })
//           .catch((err) => {
//             localStorage.clear();
//             dispatch(error(err));
//             dispatch(alertActions.error(err.response.data.message, "Error!"));
//             dispatch(alertActions.clear());
//           });
//       })
//       .catch((err) => {
//         localStorage.clear();
//         dispatch(error(err));
//         dispatch(alertActions.error(err.response.data.message, "Error!"));
//         dispatch(alertActions.clear());
//       });
//   };

  

//   function request() {
//     return {
//       type: accountConstants.LOGIN_REQUEST,
//       payload,
//       rememberMe
//     };
//   }

//   function success(resUserInfo) {
//     return {
//       type: accountConstants.LOGIN_SUCCESS,
//       resUserInfo
//     };
//   }

//   function error(err) {
//     return {
//       type: accountConstants.LOGIN_FAILURE,
//       err
//     };
//   }
// }

function verify(payload) { 
  return (dispatch) => {
    dispatch(request(payload));
    //console.log("account.actions/payload",payload)
    return axios
      .post(`${accountPath}/verify`, payload)
      .then((resVerrify) => {
        console.log("account.actions/resVerrify",resVerrify)
        if(resVerrify.data == "Unauthorized"){
          dispatch(alertActions.error("OTP Verification Failed", "Error!"));
          dispatch(alertActions.clear());
          return resVerrify.data
          //history.push("/login");
        }else{
            //get user's info
          axios.get(`${accountPath}/user/me`).then((resUserInfo) => {
            console.log("axios.get(`${accountPath}/user/me`).then((resUserInfo) => ",resUserInfo)
            dispatch(success(resUserInfo.data));
            localStorage.setItem("user", JSON.stringify(resUserInfo.data));
           
            if (resUserInfo.data.isActive === true) {
              history.push("/home");
            } else {
              localStorage.setItem("currentPassword", payload.password);
              //history.push("/changepass");
              history.push("/home");
            }
            
            dispatch(alertActions.success("Login is successful!"));
            dispatch(alertActions.clear());
          }).catch((err) => {
            localStorage.clear();
            dispatch(error(err));
            dispatch(alertActions.error(err.response.data.message, "Error!"));
            dispatch(alertActions.clear());
          });
        }
        return resVerrify.data
      })
      .catch((err) => {
        localStorage.clear();
        dispatch(error(err));
        dispatch(alertActions.error(err.response.data.message, "Error!"));
        dispatch(alertActions.clear());
      });
  };

  

  function request() {
    return {
      type: accountConstants.LOGIN_REQUEST,
      payload,
    };
  }

  function success(resUserInfo) {
    return {
      type: accountConstants.LOGIN_SUCCESS,
      resUserInfo
    };
  }

  function error(err) {
    return {
      type: accountConstants.LOGIN_FAILURE,
      err
    };
  }
}


function logout(rememberMe) {
  return (dispatch) => {
    dispatch(request());

    return axios.get(`${accountPath}/logout`)
      .then((response) => {
        dispatch(success(response));
        console.log("logout.response ====== ",response)
        if (rememberMe === false) {
          localStorage.clear();
          history.push("/login");
        }

        localStorage.removeItem('user');
        history.push("/login");
      })
      .catch((err) => {
        dispatch(error(err));
        dispatch(alertActions.error(err.response.data.message, "Error!"));
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: accountConstants.LOGOUT_REQUEST
    };
  }

  function success(response) {
    return {
      type: accountConstants.LOGOUT_SUCCESS,
      response
    };
  }

  function error(err) {
    return {
      type: accountConstants.LOGOUT_FAILURE,
      err
    };
  }
}

function changePass(payload, setPassword, token) {
  return (dispatch) => {
    dispatch(request(payload));

    let changePasswordPath;

    if (setPassword) {
      changePasswordPath = axios.post(`/api/account/setPassword`, { ...payload, token });
    } else {
      changePasswordPath = axios.patch(
        `${accountPath}/user/changePassword`,
        payload
      );
    }

    return changePasswordPath
      .then((response) => {
        dispatch(success(response));

        if (setPassword) {
          history.push("/login");
          dispatch(alertActions.success("Set password is successful!"));
        } else {
          localStorage.removeItem("currentPassword");
          history.push("/home");
          dispatch(alertActions.success("Change password is successful!"));
        }

        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));

        if ((error.response >= 502 && error.response <= 504) || error.request) {
          history.push("/login");
        }

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: accountConstants.CHANGE_PASS_REQUEST,
      payload
    };
  }

  function success(response) {
    return {
      type: accountConstants.CHANGE_PASS_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: accountConstants.CHANGE_PASS_FAILURE,
      error
    };
  }
}

function getUserInfoFromEmail(email, directoryPath) {
  return (dispatch) => {
    dispatch(request(email, directoryPath));

    return axios
      .get(
        `${accountPath}/user/email/${email}?directoryPath=${directoryPath}`
      )
      .then((response) => {
        dispatch(success(response));

        if (response.data.directory === true) {
          dispatch(alertActions.error("User exist in the directory", "Error!"));
          dispatch(alertActions.clear());
        }

        return response;
      })
      .catch((error) => {
        dispatch(failure(error));

        if (error.response.status === 403) {
          dispatch(alertActions.error("You do not have permission", "Error!"));
          dispatch(alertActions.clear());
        }

        return error.response;
      });
  };

  function request() {
    return {
      type: accountConstants.GET_USERINFO_REQUEST
    };
  }

  function success(response) {
    return {
      type: accountConstants.GET_USERINFO_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: accountConstants.GET_USERINFO_FAILURE,
      error
    };
  }
}

function getUserInfoFromUsername(username, directoryPath) {
  return (dispatch) => {
    dispatch(request(username, directoryPath));

    return axios
      .get(
        `${accountPath}/user/username/${username}?directoryPath=${directoryPath}`
      )
      .then((response) => {
        dispatch(success(response));

        if (response.data.directory === true) {
          dispatch(alertActions.error("User exist in the directory", "Error!"));
          dispatch(alertActions.clear());
        }

        return response;
      })
      .catch((error) => {
        dispatch(failure(error));

        if (error.response.status === 403) {
          dispatch(alertActions.error("You do not have permission", "Error!"));
          dispatch(alertActions.clear());
        }

        return error.response;
      });
  };

  function request() {
    return {
      type: accountConstants.GET_USERINFO_REQUEST
    };
  }

  function success(response) {
    return {
      type: accountConstants.GET_USERINFO_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: accountConstants.GET_USERINFO_FAILURE,
      error
    };
  }
}

// function createUser(payload) {
//   return (dispatch) => {
//     dispatch(request());
//
//     const requestOptions = {
//       headers: authHeader("withToken")
//     };
//
//     return axios
//       .post(`${accountPath}/user`, payload, requestOptions)
//       .then((response) => {
//         console.log(response);
//         dispatch(success(response));
//       })
//       .catch((error) => {
//         console.log(error);
//         dispatch(failure(error));
//       });
//   };
//
//   function request() {
//     return {
//       type: accountConstants.CREATE_USER_REQUEST
//     };
//   }
//
//   function success(response) {
//     return {
//       type: accountConstants.CREATE_USER_SUCCESS,
//       response
//     };
//   }
//
//   function failure(error) {
//     return {
//       type: accountConstants.CHANGE_USER_FAILURE,
//       error
//     };
//   }
// }

function deleteUser(payload) {
  return (dispatch) => {
    dispatch(request());

    return axios.delete(`/user/${payload.userId}`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: accountConstants.DELETE_USER_REQUEST
    };
  }

  function success(response) {
    return {
      type: accountConstants.DELETE_USER_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: accountConstants.DELETE_USER_FAILURE,
      error
    };
  }
}

function updateUserInfo(payload) {
  return (dispatch) => {
    dispatch(request());

    return axios.patch(`${accountPath}${relativePath}/${payload.userId}`, payload.requestBody)
      .then((response) => {
        dispatch(success(response.data));
        dispatch(alertActions.success("Update your account information Success!!"));
        dispatch(alertActions.clear());
      }).catch((error) => {
        dispatch(alertActions.error(error.response.data.message, "Error! Update User Info Failed"));
        alertFetchServiceError(dispatch, error);
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: accountConstants.UPDATE_USER_REQUEST
    };
  }

  function success(response) {
    return {
      type: accountConstants.UPDATE_USER_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: accountConstants.UPDATE_USER_FAILURE,
      error
    };
  }
}

function sendEmail(email) {
  return (dispatch) => {
    dispatch(request(email));

    return axios.get(`/api/account/user/${email}/forgot/password`)
      .then((response) => {
        dispatch(success(response));

        history.push("/login");
        dispatch(alertActions.success("Send email Success!!"));
        dispatch(alertActions.clear());
      }).catch((error) => {
        dispatch(alertActions.error("Update User Info Failed", "Error!"));
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: accountConstants.FORGOT_PASSWORD_REQUEST,
      email
    };
  }

  function success(response) {
    return {
      type: accountConstants.FORGOT_PASSWORD_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: accountConstants.FORGOT_PASSWORD_FAILURE,
      error
    };
  }
}
