import { paginationConstants } from "../../_constants/pages/pagination.constants";

const initialPaginationState = {
  amountDisplay: 10,
  activePage: 1,
  totalPages: 1
};

export const paginationReducerFor = (prefix) => {
  return (state = initialPaginationState, action) => {
    const { type } = action;

    switch (type) {
      case prefix + paginationConstants.SUFFIX_SET_PAGINATION:
        return {
          ...state,
          activePage: action.activePage,
          totalPages: action.totalPages
        };

      case prefix + paginationConstants.SUFFIX_SET_AMOUNT_DISPLAY:
        return {
          ...state,
          amountDisplay: action.amountDisplay
        };

      case prefix + paginationConstants.SUFFIX_SET_ACTIVE_PAGE:
        return {
          ...state,
          activePage: action.activePage
        };
      default:
        return state;
    }
  };
};
