import React, { Component } from 'react';
import { Button, Container, Form, Grid, Header, Image, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { accountActions } from '../../_actions/account.actions';
import { connect } from 'react-redux';

import InputForm from '../../components/Input/InputForm';
import { NavbarLogo } from '../../components/Menu/NavBarLogo';
import handleLoading from '../../components/Loader/Loader';
import ReCAPTCHA from "react-google-recaptcha";

class ForgotPassWordComponent extends Component {
  state = { email: "", captcha: false };

  handleChangeEmail = (event) => { this.setState({ [event.target.name]: event.target.value }); }

  saveBtnOnclick = () => { this.props.sendEmail(this.state.email); }

  onChangeCaptcha = (value) => {
    this.setState({ captcha: value !== null ? true : false })
  }

  render () {
    const { loading } = this.props;
    const { email, captcha } = this.state;

    return (
      <React.Fragment>
        <NavbarLogo/>
        <Grid className={'grid-form'}>
          <Grid.Column width={1}/>
          <Grid.Column width={14}>
            <Segment className="segment-change-password">

              {handleLoading(loading)}

              <Grid textAlign="center">
                <Grid.Row verticalAlign="middle">
                  <Grid.Column computer={8} only="computer">
                    <Image src="./images/forgot-password.svg"/>
                  </Grid.Column>

                  <Grid.Column computer={8} tablet={16} mobile={16}>
                    <Header textAlign={'left'} className={'header__title'} as="h2">
                      Forgot Password
                    </Header>
                    <Container
                      textAlign={'left'}
                      className={'container__desc container__desc--space-bot'}>
                      Please enter your email or username below to receive a password change confirmation email.
                    </Container>
                    <Form>
                      <InputForm
                        name="email"
                        icon="user"
                        type="text"
                        value={email}
                        placeholder="Please enter your email or username"
                        handleChange={this.handleChangeEmail}
                      />
                    </Form>
                    <br/>

                    <Grid verticalAlign='middle'>
                      <Grid.Column floated='left' width={5}>
                        <ReCAPTCHA
                          sitekey="6LdEScYcAAAAAHJKIs3f1-8OBV3QaUEv8reLZKgy"
                          onChange={this.onChangeCaptcha}
                        />
                      </Grid.Column>
                      <Grid.Column floated='right' width={5}>
                        <Button
                          disabled={!email || !captcha}
                          floated="right"
                          content={'Submit'}
                          className={'btn--primary btn--width144px'}
                          onClick={this.saveBtnOnclick}/>
                      </Grid.Column>
                    </Grid>


                  </Grid.Column>
                </Grid.Row>
              </Grid>

            </Segment>
          </Grid.Column>
          <Grid.Column width={1}/>
        </Grid>
      </React.Fragment>
    );
  }
}

ForgotPassWordComponent.propTypes = {
  loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  loading: state.account.loading
});

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (email) => dispatch(accountActions.sendEmail(email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassWordComponent);

