import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";

import { connect } from "react-redux";
import { assetActions } from "../../../../_actions";

import { InputModal } from "../../../../components/Input/InputModal";
import { SidebarButton } from "../../../../components/Button/SidebarButton";
import { DefaultTextArea } from "../../../../components/TextArea/TextArea";
import handleLoading from "../../../../components/Loader/Loader";
import RelationComponent from "../../Component/RelationComponent";

class AssetSidebar extends Component {
  constructor(props) {
    super(props);

    const { assetInfo } = this.props;

    this.state = {
      id: assetInfo ? assetInfo._id : "",
      name: assetInfo ? assetInfo.name : "",
      label: assetInfo ? assetInfo.label : "",
      description: assetInfo ? assetInfo.description : "",
      isUpdateComp: Boolean(assetInfo)
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmitSidebar = () => {
    const { id, name, label, description, isUpdateComp } = this.state;
    const directory = this.props.objDirectory.path;

    if (isUpdateComp) {
      // update deviec
      const objUpdate = {
        name,
        label,
        description
      };
      const payload = Object.entries(objUpdate).reduce((a, [key, value]) => { return value ? { ...a, [key]: value } : a; }, {});

      if (payload.name && id) {
        this.props.patchAsset(id, payload);
      }
    } else {
      // create deivce
      const objCreate = {
        name,

        // label,
        directory,
        description
      };

      const payload = Object.entries(objCreate).reduce((a, [key, value]) => { return value ? { ...a, [key]: value } : a; }, {});

      if (payload.name && payload.directory) {
        this.props.postAsset(payload);
      }
    }
  };

  render() {
    const { name, label, description, isUpdateComp } = this.state;
    const {
      handleAnimationChangeClose,
      loading,

      assetInfo
    } = this.props;

    return (
      <React.Fragment>
        {handleLoading(loading)}
        <div className="div__without--pd--bt">
          <Header as="h2">
            {isUpdateComp ? "Asset detail" : "Create asset"}
          </Header>
          <div className="div__scrollbar">
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <InputModal
                    isRequired={true}
                    name="name"
                    type="text"
                    value={name}
                    placeholder="Name"
                    handleChange={this.handleChange}
                  />

                  <InputModal
                    name="label"
                    type="text"
                    value={label}
                    placeholder="Label"
                    handleChange={this.handleChange}
                  />

                  <DefaultTextArea
                    name="description"
                    value={description}
                    handleChange={this.handleChange}
                    placeholder="Description"
                  />
                </Grid.Column>
              </Grid.Row>

              {/* relation component */}
              {isUpdateComp ? (
                <React.Fragment>
                  <RelationComponent
                    fromEntityType="ASSET"
                    itemInfo={assetInfo}
                  />
                </React.Fragment>
              ) : null}
            </Grid>
          </div>
        </div>

        <SidebarButton
          disabled={!name}
          btnSecondary={isUpdateComp ? "CLOSE" : "CANCEL"}
          btnActive={isUpdateComp ? "UPDATE" : "CREATE"}
          handleSubmit={this.handleSubmitSidebar} // submit btn
          OnCloseSidebar={handleAnimationChangeClose()}
        />
      </React.Fragment>
    );
  }
}

AssetSidebar.propTypes = {
  handleAnimationChangeClose: PropTypes.func.isRequired,
  gettingType: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
  postAsset: (payload) => dispatch(assetActions.postAsset(payload)),
  patchAsset: (id, payload) => dispatch(assetActions.patchAsset(id, payload))
});

const mapStateToProps = (state) => ({
  loading:
    state.asset.creating || state.asset.updating || state.relation.getting,
  assetInfo: state.asset.assetInfo
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetSidebar);
