import React from "react";
import Bin from "./Bin";
import User from "./User";
import Check from "./Check";
import Key from "./Key";
import Filter from "./Filter";
import Add from "./Add";
import Edit from "./Edit";
import Back from "./Back";
import Setting from "./Setting";
import Save from "./Save";
import FullScreen from "./FullScreen";
import RemoveUser from "./RemoveUser";
import BinNoneBorder from "./BinNoneBorder";
import EditNoneBorder from "./EditNoneBorder";
import Export from "./Export";
import Error from "./Error";

const IconCustom = (props) => {
  switch (props.name) {
    case "bin":
      return <Bin {...props} />;
    case "user":
      return <User {...props} />;
    case "check":
      return <Check {...props} />;
    case "key":
      return <Key {...props} />;
    case "filter":
      return <Filter {...props} />;
    case "add":
      return <Add {...props} />;
    case "edit":
      return <Edit {...props} />;
    case "back":
      return <Back {...props} />;
    case "setting":
      return <Setting {...props} />;
    case "save":
      return <Save {...props} />;
    case "fullScreen":
      return <FullScreen {...props} />;
    case "remove_user":
      return <RemoveUser {...props} />;
    case "binNoneBorder":
      return <BinNoneBorder {...props} />;
    case "editNoneBorder":
      return <EditNoneBorder {...props} />;
    case "export":
      return <Export {...props} />;
      case "error":
        return <Error {...props} />;
    default:
      return null;
  }
};

// IconCustom.PropTypes = {
//
// }

export default IconCustom;
