/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, Grid, Header, Icon } from "semantic-ui-react";

import SingleLineTable from "../../../components/Table/SingleLineTable";
import NormalPagination from "../../../components/Pagination/NormalPagination";
import handleLoading from "../../../components/Loader/Loader";

class ValueComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHeader: ["Time", "Telemetry", "Value"],
      items: {},
      activePage: 1,
      itemsPerPage: 10
    };
  }

  ws = new WebSocket(`${(window.location.protocol === "https:" ? "wss://" : "ws://") + window.location.host}/device/${this.props.id}/telemetry`, "echo-protocol");

  // ws = new WebSocket(`ws://localhost:3001/device/${this.props.id}/telemetry`, "echo-protocol");

  componentDidMount() {
    const { items } = this.state;
    const { telemetryLasted } = this.props;
    if(items && Object.keys(items).length === 0){
      if(telemetryLasted) this.addItem(telemetryLasted);
    }
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      // console.log("connected");
    };

    this.ws.onmessage = (event) => {
      // on receiving a message, add it to the list of messages
      const response = JSON.parse(JSON.parse(event.data));

      this.addItem(response);
    };

    this.ws.onclose = (event) => {
      // console.log("disconnected");
    };

    // ws.on("open", () => {
    //   console.log("connected");
    //   ws.send(Date.now());
    // });

    // ws.on("message", (data) => {
    //   console.log(data);

    //   setTimeout(() => {
    //     ws.send(Date.now());
    //   }, 500);
    // });

    // ws.on("close", function close() {
    //   console.log("disconnected");

    //   //   // automatically try to reconnect on connection loss
    //   this.setState({
    //     ws: new WebSocket(`ws://localhost:3001/device/${id}/telemetry`),
    //   });
    // });
  }

  componentWillUnmount() {
    this.ws.close();
  }

  addItem = (item) => {
    this.setState(
      (state) => ({
        items: item
      })
    );
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({
      activePage
    });
  };

  renderTableOfTelemetryData = () => {
    const { tableHeader, items, activePage, itemsPerPage } = this.state;

    const itemNotEmpty = typeof Object.keys(items) !== 'undefined' && Object.keys(items).length > 0;
    const item = typeof Object.keys(items) === 'object' && Object.keys(items).length === 0;

       if (item) {
          return (
            <Grid.Row>
              <Grid.Column textAlign="center">
                <p style={{"text-align": "center"}}>No value</p>
              </Grid.Column>
            </Grid.Row>
          );
    }

    return (
      <React.Fragment>
      <Grid.Row>
        <Grid.Column>
          <SingleLineTable
            tableHeader={tableHeader}
            items={itemNotEmpty ? Object.keys(items.data) : []}
            telemetryKey={items}
            activePage={activePage}
            itemsPerPage={itemsPerPage}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row textAlign="left" style={{float: "left"}}>
        <NormalPagination
          className="pagination--noneborder"
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          totalItems={itemNotEmpty ? Object.keys(items.data).length : 0}
          handlePaginationChange={this.handlePaginationChange}
        />
      </Grid.Row>
    </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Grid.Row textAlign="left">
          <Grid.Column width={10} textAlign="left" floated="left">
            <Header className="font--body pd--top--bottom" style={{"text-align": "left"}}>Telemetry Value</Header>
          </Grid.Column>
          <Grid.Column width={6}>
            {/* <Button.Group floated="right">
              <Button icon className="btn--icon--border main">
                <Icon name="area graph" />
              </Button>
            </Button.Group> */}
          </Grid.Column>
        </Grid.Row>

        <React.Fragment>
          {this.renderTableOfTelemetryData()}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

ValueComponent.propTypes = {
  id: PropTypes.string.isRequired
};

export default ValueComponent;
