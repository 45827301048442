import React, { Component } from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Image,
  Modal,
  Radio,
  Segment
} from "semantic-ui-react";

import { connect } from "react-redux";
import { relationActions } from "../../_actions";

import handleLoading from "../Loader/Loader";

import { InputModal } from "../Input/InputModal";
import SelectionDropdown from "../Dropdown/SelectionDropdown";

class RelationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemInfo: this.props.itemInfo,

      fromName: this.props.itemInfo.name,
      fromEntityType: this.props.fromEntityType,

      toName: "",
      toEntityType: "",
      toDirectoryPath: "",

      isShowDetailComp: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.relationInfo) {
      this.setState({
        fromName: nextProps.relationInfo.from.name,
        toName: nextProps.relationInfo.to.name,
        toEntityType: nextProps.relationInfo.to.entityType,
        toDirectoryPath: nextProps.relationInfo.to.directoryPathName,
        isShowDetailComp: true
      });
    } else if (nextProps.postStatus === 201) {
      this.handleCloseModal();
    }
  }

  handleChangeRadio = (e, { value }) => {
    this.setState(
      {
        toEntityType: value
      },
      () => this.props.getEntityType(this.state.toEntityType)
    );
  };

  handleChangeDropdown = (value) => {
    const toDirectoryPath = this.props.EntityTypeList.filter(
      (item) => item.key === value
    );

    this.setState({
      toName: value,
      toDirectoryPath: toDirectoryPath[0].directoryPath
    });
  };

  handleSubmitModal = () => {
    const { itemInfo, fromEntityType, toName, toEntityType } = this.state;
    const payload = {
      type: "Contains",
      to: {
        _id: toName,
        entityType: toEntityType
      },
      from: {
        _id: itemInfo._id,
        entityType: fromEntityType
      }
    };

    this.props.handleSubmitModal(payload);
  };

  handleCloseModal = () => {
    this.setState(
      {
        toName: "",
        toEntityType: "",
        toDirectoryPath: "",
        isShowDetailComp: false
      },
      () => {
        this.props.initialStateRelation();
        this.props.handleCloseModal();
      }
    );
  };

  render() {
    const {
      fromName,
      toName,
      toEntityType,
      toDirectoryPath,
      isShowDetailComp
    } = this.state;
    const {
      loading,
      EntityTypeList,

      modalOpen,
      handleCloseModal
    } = this.props;

    return (
      <Modal
        className="modal--width80p--withoutpadding"
        open={modalOpen}
        onClose={handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
      >
        {handleLoading(loading)}

        <Modal.Content>
          <Grid columns="equal">
            <Grid.Column />
            <Grid.Column width={7} textAlign="center">
              <Image
                className="center--vertical"
                src="./images/img-relation-modal.svg"
              />
            </Grid.Column>

            <Grid.Column />

            <Grid.Column width={5}>
              <Segment className="segment--center height75p" basic>
                <Header className="font--title1">
                  {isShowDetailComp ? "RELATION DETAIL" : "CREATE RELATION"}
                </Header>
                <br />
                <p className="font-caption1 mg--bottom">To</p>
                <Form>
                  <Grid columns="equal">
                    <Grid.Column>
                      <Radio
                        className="radio--square"
                        label="Asset"
                        name="checkboxRadioGroup"
                        value="ASSET"
                        checked={toEntityType === "ASSET"}
                        onChange={this.handleChangeRadio}
                        disabled={isShowDetailComp}
                      />
                    </Grid.Column>

                    <Grid.Column>
                      <Radio
                        className="radio--square"
                        label="Device"
                        name="checkboxRadioGroup"
                        value="DEVICE"
                        checked={toEntityType === "DEVICE"}
                        onChange={this.handleChangeRadio}
                        disabled={isShowDetailComp}
                      />
                    </Grid.Column>
                  </Grid>

                  {isShowDetailComp ? (
                    <InputModal
                      padding="pd--bottom"
                      type="text"
                      value={toName}
                      disabled={true}
                    />
                  ) : (
                    <SelectionDropdown
                      includeClassName="margin--top--bottom"
                      placeholder="Entity name"
                      options={EntityTypeList}
                      search={true}
                      handleChangeDropdown={this.handleChangeDropdown}
                      disabled={!toEntityType}
                    />
                  )}
                </Form>

                <p className="font-caption1">Directory path</p>
                <p className="font--body color--darkgray">
                  {toDirectoryPath ? toDirectoryPath : "-"}
                </p>

                <Divider />

                <p className="font-caption1">From</p>
                <p className="font--body color--darkgray">{fromName}</p>
              </Segment>

              <Segment basic>
                <Grid centered>
                  <Grid.Row>
                    <Grid.Column width="8" className="col8__btn--left">
                      <Button
                        fluid
                        className="btn--secondary"
                        onClick={this.handleCloseModal}
                      >
                        CANCEL
                      </Button>
                    </Grid.Column>
                    {!isShowDetailComp && (
                      <Grid.Column width="8" className="col8__btn--right">
                        <Button
                          fluid
                          className="btn--primary"
                          disabled={!toName}
                          onClick={this.handleSubmitModal}
                        >
                          CREATE
                        </Button>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>

            <Grid.Column />
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  postRelation: (payload) => dispatch(relationActions.postRelation(payload)),
  getEntityType: (toEntityType) => dispatch(relationActions.getEntityType(toEntityType)),
  initialStateRelation: () => dispatch(relationActions.initialStateRelation())
});

const mapStateToProps = (state) => ({
  loading: state.relation.creating || state.relation.getting,
  postStatus: state.relation.postRelationStatus,
  relationInfo: state.relation.relationInfo,
  EntityTypeList: state.relation.EntityTypeList
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelationModal);
