import React, { Component } from "react";
import { Button, Grid, Header, Modal, Segment, List, Divider, Popup } from "semantic-ui-react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import IconCustom from "../../assets/icons";
import { DateHelper } from "../../_helpers/DateHelper";

export class DeviceErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
    this.initialState = { ...this.state };

  }

  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
    this.setState(this.initialState);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.delStatus === 204) {
      this.setState({
        modalOpen: false
      });
    }
  }

  handleTriggerType = (className) => {
    return (
      <Popup
        trigger={
          <Button
            icon
            className={className}
            onClick={this.handleOpenModal}
          >
            <IconCustom name="error" fillColor="white" />
          </Button>
        }
        content="View error"
        mouseLeaveDelay={0}
        position='left center'
        basic
      />
    )
  };

  render() {
    const { modalOpen } = this.state;
    const { value, className } = this.props;
    const isDescending = true;
    const sortedDateValue = value.sort((a,b) => isDescending ? new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime() : new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime())

    const showList = sortedDateValue.map((item,index) => (
        <>
          <List key={index} className="font--error ">
            <List.Item>Date: {DateHelper.convertToDateTime(item.lastUpdated)}</List.Item>
            <List.Item>Topic: {item.key}</List.Item>
            <List.Item>Description: {item.value.message}</List.Item>
          </List>
          {index !== sortedDateValue.length -1 && <Divider />}
        </>
    ));

    return (
      <Modal
        size="small"
        trigger={this.handleTriggerType(className)}
        open={modalOpen}
        onClose={this.handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
      >

        <Modal.Content>
          <Grid columns="equal" centered>
              <Grid.Column width={15}>
                <Segment
                  textAlign="center"
                  className="segment--center"
                  basic
                  padded
                >
                  <Header className="font--title1">Device Error</Header>
                </Segment>
                <Segment
                  textAlign="left"
                  className="segment--fix"
                >
                  {showList}

                </Segment>
                <Segment basic textAlign="center">
                  <Grid centered>
                    <Grid.Column width={6}>
                      <Button
                        className="btn--primary btn--width100p"
                        onClick={this.handleCloseModal}
                      >
                        CLOSE
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

DeviceErrorModal.propTypes = {
  triggerType: PropTypes.bool
};

export default connect(
  null
)(DeviceErrorModal);
