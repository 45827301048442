import { devicePageConstants } from "../../_constants";
import { includes } from 'lodash';

export const devicePageActions = {
  setDevicesData,
  setDeletingSelectedDevices,
  clearDeletingSelectedDevices,
  setExportingSelectedDevices,
  setSideBarVisible,
  setFetching,
  setDeleting,
  clearDevice
};

function clearDevice() {
  return { type: devicePageConstants.CLEAR_DEVICE };
}

function setSideBarVisible() {
  return (dispatch) => {
    dispatch(setVisible());
  };

  function setVisible() {
    return {
      type: devicePageConstants.SET_SIDEBAR_VISIBLE
    };
  }
}

function setDevicesData(payload) {
  return (dispatch) => {
    dispatch(setDevices(payload));
  };

  function setDevices(devices) {
    return {
      type: devicePageConstants.SET_DEVICES_DATA,
      devices
    };
  }
}

function setDeletingSelectedDevices(payload) {
  return (dispatch, getState) => {
    const { deletingSelectedId } = getState().devicePage.devicesData;

    if (includes(deletingSelectedId, payload.device)) {
      dispatch(deleteSelectedDevice(payload.device));
    } else {
      dispatch(addSelectedDevice(payload.device));
    }
  };

  function addSelectedDevice(device) {
    return {
      type: devicePageConstants.ADD_SELECTED_ID,
      device
    };
  }

  function deleteSelectedDevice(device) {
    return {
      type: devicePageConstants.DELETE_SELECTED_ID,
      device
    };
  }
}

function clearDeletingSelectedDevices() {
  return (dispatch) => {
    dispatch(clearSelectedDevices());
  };

  function clearSelectedDevices() {
    return {
      type: devicePageConstants.CLEAR_SELECTED_ID
    };
  }
}

function setExportingSelectedDevices(payload) {
  return (dispatch, getState) => {
    const { exportingSelectedId } = getState().devicePage.devicesData;

    if (includes(exportingSelectedId, payload.device)) {
      dispatch(deleteSelectedDevice(payload.device));
    } else {
      dispatch(addSelectedDevice(payload.device));
    }
  };

  function addSelectedDevice(device) {
    return {
      type: devicePageConstants.ADD_SELECTED_EXPORT_ID,
      device
    };
  }

  function deleteSelectedDevice(device) {
    return {
      type: devicePageConstants.DELETE_SELECTED_EXPORT_ID,
      device
    };
  }
}

function setFetching() {
  return (dispatch) => {
    dispatch(setFetch());
  };

  function setFetch() {
    return {
      type: devicePageConstants.SET_IS_FETCHING
    };
  }
}

function setDeleting() {
  return (dispatch) => {
    dispatch(setDelete());
  };

  function setDelete() {
    return {
      type: devicePageConstants.SET_IS_DELETING
    };
  }
}
