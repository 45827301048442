import { accountConstants, authorizeConstants } from "../_constants";

// eslint-disable-next-line complexity
export function authorize(state = {}, action) {
  switch (action.type) {

    // get list of role
    case authorizeConstants.GET_ROLES_REQUEST:
      return {
        // ...state,
        getting: true,
        requestUserObj: action.requestUserObj,
        users: state.users
      };
    case authorizeConstants.GET_ROLES_SUCCESS:
      return {
        // ...state,
        response: action.response.data?.filter((item) => item.name !== 'admin_dashboard'),
        roles: action.response.data?.filter((item) => item.name !== 'admin_dashboard'),
        users: state.users,

        // remove requestUserObj.roles(selected role) from options(role list)
        // new options value is not selected role list
        options: action.isUpdateComponent ?
          action.options.filter(
            (role) => state.requestUserObj.roles.indexOf(role.value) === -1
          ) :
          action.options,

        // recive requestUserObj(from table) and set format same option and setState roleSelected
        roleSelected: action.isUpdateComponent ?
          action.options.filter((role) => state.requestUserObj.roles.includes(role.value)) :
          ""
      };
    // get list of role
    case authorizeConstants.GET_ROLES_NO_STATE_REQUEST:
      return {
        ...state
      };
    case authorizeConstants.GET_ROLES_NO_STATE_SUCCESS:
      return {
        ...state
      };
    case authorizeConstants.INCREMENT_ROLE:
      return {
        // ...state,
        options: action.options
      };
    case authorizeConstants.DECREMENT_ROLE:
      return {
        // ...state,
        users: state.users,
        options: action.options
      };

// Get role information
    case authorizeConstants.GET_ROLE_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case authorizeConstants.GET_ROLE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.response.data
      };

// Get list of users by directory path
    case authorizeConstants.GET_AUTHORITY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        updateUser: state.updateUser
      };
    case authorizeConstants.GET_AUTHORITY_LIST_SUCCESS:
      return {
        ...state,

        // role: action.response.data.map((item) => item.directoryPath.roles),
        loading: false,
        users: action.response,
        updateUser: state.updateUser
      };

    case authorizeConstants.GET_AUTHORITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case authorizeConstants.GET_AUTHORITY_SUCCESS:
      return {
        ...state,
        authority: action.response,
        loading: false
      };

// Assign role to user
    case authorizeConstants.ASSIGN_ROLE_REQUEST:
      return {
        role: state.role,
        loading: true,
        users: state.users

        // shouldUpdateRoleDetail: false
      };
    case authorizeConstants.ASSIGN_ROLE_SUCCESS:
      return {
        role: state.role,
        shouldUpdateRoleDetail: true,
        loading: false,
        assign: action.response.data,
        users: state.users
      };

// UnAssign role to user
    case authorizeConstants.UN_ASSIGN_ROLE_REQUEST:
      return {
        loading: true,
        role: state.role
      };
    case authorizeConstants.UN_ASSIGN_ROLE_SUCCESS:
      return {
        loading: false,
        role: state.role,
        shouldUpdateRoleDetail: true,
        unassign: action.response.data
      };

    // delete authority
    case authorizeConstants.DEL_AUTHORITY_REQUEST:
      return {
        getting: true

      };
    case authorizeConstants.DEL_AUTHORITY_SUCCESS:
      return {
        deleteAuthority: action.response.status,
        response: action.response
      };

    // get eligible
    case authorizeConstants.GET_ELIGIBLE_REQUEST:
      return {
        ...state,
        users: state.users
      };
    case authorizeConstants.GET_ELIGIBLE_SUCCESS:
      return {
        ...state,
        users: state.users,
        isEligible: action.response.isEligible
      };

    // get eligible without directory path
    case authorizeConstants.GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_REQUEST:
      return {
        ...state,
        users: state.users
      };
    case authorizeConstants.GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_SUCCESS:
      return {
        ...state,
        users: state.users,
        isEligibleWithoutDirectoryPath: action.response.isEligible
      };

       // update user infomation
    case accountConstants.UPDATE_USER_REQUEST:
      return {
        loading: true,
        updatingUser: true,
        users: state.users
      };
    case accountConstants.UPDATE_USER_SUCCESS:
      return {
        response: action.response,
        updateUser: action.response,
        patchUserStatus: 200,
        users: state.users
      };
    case accountConstants.UPDATE_USER_FAILURE:
      return {
        users: state.users,
        error: action.error
      };

    // Error
    case authorizeConstants.GET_ELIGIBLE_FAILURE:
    case authorizeConstants.GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_FAILURE:
    case authorizeConstants.DEL_AUTHORITY_FAILURE:
    case authorizeConstants.UN_ASSIGN_ROLE_FAILURE:
    case authorizeConstants.ASSIGN_ROLE_FAILURE:
    case authorizeConstants.GET_AUTHORITY_LIST_FAILURE:
    case authorizeConstants.GET_ROLE_INFO_FAILURE:
    case authorizeConstants.GET_ROLES_FAILURE:
      return {
        loading: false,
        error: action.error.response
      };

    default:
      return state;
  }
}
