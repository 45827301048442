export const accountConstants = {
  // login
  LOGIN_REQUEST: "AUTHEN_LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTHEN_LOGIN_SUCCESS",
  LOGIN_FAILURE: "AUTHEN_LOGIN_FAILURE",

  // logout
  LOGOUT_REQUEST: "AUTHEN_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "AUTHEN_LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "AUTHEN_LOGOUT_FAILURE",

  // change password
  CHANGE_PASS_REQUEST: "CHANGE_PASS_REQUEST",
  CHANGE_PASS_SUCCESS: "CHANGE_PASS_SUCCESS",
  CHANGE_PASS_FAILURE: "CHANGE_PASS_FAILURE",

  // get user's info
  GET_USERINFO_REQUEST: "ACCOUNT_GET_USERINFO_REQUEST",
  GET_USERINFO_SUCCESS: "ACCOUNT_GET_USERINFO_SUCCESS",
  GET_USERINFO_FAILURE: "ACCOUNT_GET_USERINFO_FAILURE",

  // create User
  // CREATE_USER_REQUEST: "CREATE_USER0_REQUEST",
  // CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  // CHANGE_USER_FAILURE: "CHANGE_USER_FAILURE",

  // delete user
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  // update user information
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE"
};
