import React, { Component } from "react";
import { Button, Grid, Header, Icon, Segment, Table, Popup } from "semantic-ui-react";
import IconCustom from "../../../assets/icons";
import styled from "styled-components";
import { RoleColor,RoleLabel } from "../../../_helpers";
import UserRoleTableCell from "./Component/UserRoleTableCell";
import PropTypes from "prop-types";
import { authorizeActions, accountPageActions } from "../../../_actions/";
import { connect } from "react-redux";
import DeleteModal from "../../../components/Modal/DeleteModal";
import axios from 'axios';
import InputSearch from "../../../components/Input/InputSearch";
import _ from "lodash";
import handleLoading from "../../../components/Loader/Loader";
import EmptyLoader from "../../../components/Loader/EmptyLoader";

const ImageUser = styled(IconCustom)`
//fill: ${changeColor} !important;
width: 6rem;
height: 6rem;
`;

const RoleAmountText = styled.p`
color: ${changeColor};
border-color: ${changeColor} !important;
`;

function changeColor(props) {
  return props.roleColor ? props.roleColor : "black";
}

class RoleDetail extends Component {
  state = {
    isHiddenDelBtn: true,
    selectedUsersUnAssignRole: [],
    usersInRole: [],
    resultsUsersInRole: [],
    roleColor: '',
    isAdmin: true
  };

  getUserNameByUserId = (userId) => {
    let str = "";

    if (this.state.usersInRole && this.state.usersInRole.length) {
      const user = this.state.usersInRole.find((item) => item.userId === userId);

      str = str.concat(user.user.firstname.toString().toUpperCase(), ' ', user.user.lastname.toString().toUpperCase());
      return str;
    }

    return str;
  };

  onClickSelectingUser = (value) => {
    const { isHiddenDelBtn } = this.state;

    if (isHiddenDelBtn) {
      this.setState((prevState) => ({
        isHiddenDelBtn: !prevState.isHiddenDelBtn,
        selectedUsersUnAssignRole: selectingUser(prevState.selectedUsersUnAssignRole, value)
      }), () => {
        // console.log("first time delete");
        // console.log(this.state);
      });
    } else {
      this.setState((prevState) => ({
        selectedUsersUnAssignRole: selectingUser(prevState.selectedUsersUnAssignRole, value)
      }), () => {
        // console.log("delete");
        // console.log(this.state);
        if (!(this.state.selectedUsersUnAssignRole && this.state.selectedUsersUnAssignRole.length)) {
          this.setState({
            isHiddenDelBtn: true
          });
        }
      });
    }

    function selectingUser(selectedUser, selectUser) {
      if (selectedUser && selectedUser.length) {
        if (selectedUser.includes(selectUser)) {
          return selectedUser.filter((item) => item !== selectUser);
        }

        return [...selectedUser, selectUser];
      }

      return [selectUser];
    }
  };

  // handleRoleColor: String = (role) => {
  //   if (role && role.name) {
  //     return RoleColor(role.name.toString());
  //   }

  //   return "grey";
  // };

  handleRoleColor = (role) => {
    if (role && role.name) {
      return RoleColor(role.name.toString());
    }

    return "grey";
  };

  handleUnAssignRole = (userId) => {
    const payload = {
      userId,
      roles: [this.props.role._id],
      directoryPath: this.props.directoryPath
    };

    this.props.unAssignRole(payload);
  };

  handleSearchChange = (e, value) => {
    if (value) {
      const re = new RegExp(_.escapeRegExp(value), 'i');

      this.setState((prevState) => ({
        resultsUsersInRole: prevState.usersInRole.filter((item) => re.test(item.user && item.user.firstname) || re.test(item.user && item.user.lastname) || re.test(item.user && item.user.email))
      }));
    } else {
      this.setState((prevState) => ({
        resultsUsersInRole: prevState.usersInRole
      }));
    }
  };

  handleRoleTableCell = (item, index) => {
    return <UserRoleTableCell key={index}
      fullName={item.user && `${item.user.firstname}   ${item.user.lastname}`}
      email={item.user && item.user.email}
      id={item.userId}
      onClickDelBtn={this.onClickSelectingUser}
      isHiddenDelBtn={this.state.isHiddenDelBtn}
      roleColors={item.roles.map((roleName) => RoleColor(roleName))}
      isActive={this.state.selectedUsersUnAssignRole.indexOf(item.userId) > -1}
      isUnassignAccount={this.props.isUnassignAccount} />;
  };

  getUserInRole = (users, roleName) => {
    return users.filter((item) => item.roles.includes(roleName));
  };

  handleAmountMember = (total, item) => {
    return total + item.roles.filter((roleName) => roleName === this.props.role.name).length;
  };

  handleUserList = (directoryPath) => {
    const payloadAuthority = { directoryPath };

    return this.props.getAuthority(payloadAuthority).then(() => {
      this.setState((prevState, prevProps) => ({
        usersInRole: prevProps.users.data && prevProps.users.data.length ? this.getUserInRole(prevProps.users.data, prevProps.role.name) : [],
        resultsUsersInRole: prevProps.users.data && prevProps.users.data.length ? this.getUserInRole(prevProps.users.data, prevProps.role.name) : []
      }));
    });
  };

  handleRoleInfo = () => {
    const payload = { id: this.props.roleId };

    return this.props.getRoleInfo(payload);
  };

  handleGetAllData() {
    const payload = { directoryPath: this.props.directoryPath };

    axios.all([this.handleRoleInfo(), this.props.getAuthority(payload)])
      .then(axios.spread((roleInfo, usersList) => {
        if (roleInfo && roleInfo.name) {
          this.setState({
            usersInRole: usersList.data && usersList.data.length ? usersList.data.filter((item) => item.roles.includes(roleInfo.name)) : [],
            resultsUsersInRole: usersList.data && usersList.data.length ? usersList.data.filter((item) => item.roles.includes(roleInfo.name)) : []
          });
        }
      }));
  }

  checkEligible() {
    const directoryPath = this.props.directoryPath;
    const payload = { action: '*', subject: '*', directoryPath: directoryPath };

    Promise.resolve(this.props.getEligible(payload)).then((response) => {
      this.props.updateEligible(response.isEligible);
      if (!response.isEligible) {
        this.setState({ isAdmin: false })
      }else{
        this.setState({ isAdmin: true })
      }
    });
  }

  componentDidMount() {
    // this.handleGetAllData();
    Promise.resolve(this.handleGetAllData()).then(()=>{this.checkEligible();});
  }

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.directoryPath !== this.props.directoryPath) {
      this.handleUserList(nextProps.directoryPath);
      this.setState({
        isHiddenDelBtn: true,
        selectedUsersUnAssignRole: []
      });
    } else if (nextProps.shouldUpdateRoleDetail !== this.props.shouldUpdateRoleDetail && nextProps.shouldUpdateRoleDetail === true) {
      this.handleUserList(nextProps.directoryPath);
      this.setState({
        isHiddenDelBtn: true,
        selectedUsersUnAssignRole: []
      });
    }

    return true;
  };

  renderComponentOfRoleDetail = () => {
    const { role, error, isAssignAccount, isUnassignAccount } = this.props;
    const { resultsUsersInRole, selectedUsersUnAssignRole } = this.state;

    console.log(error, role);

    if (!error && role && role.name) {
      return (
        <React.Fragment>
          <Grid>
            <Grid.Row className="back--to-role" style={{ background: this.handleRoleColor(role) }}>
              <Icon name="arrow left" className="arrow-left" link onClick={this.props.backToRolePermTab} />
            </Grid.Row>
          </Grid>

          <Grid stackable columns={"equal"} centered className={'grid__detail-page'}>
            <Grid.Row className={"grid-tab__user"}>
              <Grid.Column width={3} textAlign={"center"}>
                <ImageUser className={'svg-icon__user'} fillColor={this.handleRoleColor(role)} name={'user'} />
              </Grid.Column>

              <Grid.Column>
                <Header className={"role-permit"}>{role.name ? RoleLabel(role.name).toString().toUpperCase() : null}</Header>
                <p className={"role-permit role-permit--desc"}>{role.description ? role.description : null}</p>
                <RoleAmountText
                  className={"role-permit role-permit--amount"}
                  roleColor={this.handleRoleColor(role)}
                >
                  {resultsUsersInRole.reduce(this.handleAmountMember, 0)} member
                </RoleAmountText>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className={'grid-tab__user--middle'} verticalAlign={'middle'}>

              <Grid.Column className={'column--header'} floated={"right"} textAlign={"right"}>
                {
                  (role.name.toString() !== "admin" || (role.name.toString() === "admin" && this.state.isAdmin) ) && isAssignAccount &&
                    <Popup
                      trigger={
                        <Button className={"button__image"} basic onClick={this.props.onOpenSidebar('AddMemberInRole')}>
                        <IconCustom className={'svg-icon__add'} name={'add'} />
                      </Button>
                      }
                      content="Add user in role"
                      mouseLeaveDelay={0}
                      basic
                    />
                }

                {
                  this.state.isHiddenDelBtn ? null :
                    <DeleteModal
                      triggerType={"icon--trash"}
                      id={selectedUsersUnAssignRole}
                      descType={"role"}
                      name={selectedUsersUnAssignRole && selectedUsersUnAssignRole.length ? this.getUserNameByUserId(selectedUsersUnAssignRole[0]) : null}
                      tabName={"item"}
                      handleDeleteItem={this.handleUnAssignRole}
                    />
                }
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className={"grid-tab__user--last"}>
              {resultsUsersInRole && resultsUsersInRole.length ?
                <Grid.Column className={"column--body"}>
                  <Table singleLine basic={'very'} textAlign={"center"} className={"table__fixed-header"}>
                    <Table.Header fullWidth className={"table__header-user"}>
                      <Table.Row>
                        <Table.HeaderCell width={2}>
                          <IconCustom
                            className={'svg-icon__user svg-icon__user--header-table'}
                            name={"user"}
                            viewBox={"0 0 24 24"}
                            width={"24"}
                            height={"24"}
                            x={"7.5"}
                            y={"5.5"}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                        <Table.HeaderCell width={6}>Email</Table.HeaderCell>
                        {isUnassignAccount && <Table.HeaderCell width={2} />}
                      </Table.Row>
                    </Table.Header>
                  </Table>

                  <div className={"table__scrolling"}>
                    <Table singleLine basic={'very'} textAlign={"center"}>
                      <Table.Body className={'table__body-user table__body-user-role'}>
                        {resultsUsersInRole.map((item, index) => this.handleRoleTableCell(item, index))}
                      </Table.Body>
                    </Table>
                  </div>

                </Grid.Column> :
                <Segment textAlign="center" basic>
                  <Header className="header--emptyArr">There are no users in this role.</Header>
                </Segment>
              }
            </Grid.Row>
          </Grid>
        </React.Fragment>
      );
    } else if (error && error.data) {
      if (error.data.code === 403) {
        return this.props.renderEmptyContentComponent('not-permission.jpg', 'You do not have permission to access', null, 'grey');
      }

      return (
        <Segment padded={"very"} textAlign={"center"} basic>
          <Header icon>
            <Icon color={"red"} name={"times circle outline"} /> Something went wrong, Please try again.
          </Header> <br/>
          <Button primary onClick={this.handleGetAllData}>Try Again</Button>
        </Segment>
      );
    }

    return (
      handleLoading(true, "tableLoading")
    );
  }

  render() {
    // const { role, error } = this.props;
    // const { resultsUsersInRole, selectedUsersUnAssignRole } = this.state;

    return (
      <React.Fragment>
        {this.renderComponentOfRoleDetail()}
        {/* {
          !error && role && role.name ?
            <Grid stackable columns={"equal"} centered className={'grid__detail-page'}>
              <GridRow className={"grid-tab__user"}>
                <GridColumn width={3} textAlign={"center"}>
                  <ImageUser className={'svg-icon__user'}
                             fillColor={this.handleRoleColor(role)}
                             name={'user'}/>
                </GridColumn>
                <GridColumn>
                  <Header
                    className={"role-permit"}>{role.name ? role.name.toString().toUpperCase() : null}</Header>
                  <p className={"role-permit role-permit--desc"}>{role.description ? role.description : null}</p>
                  <RoleAmountText className={"role-permit role-permit--amount"}
                                  roleColor={this.handleRoleColor(role)}>{resultsUsersInRole.reduce(this.handleAmountMember, 0)} member</RoleAmountText>
                </GridColumn>
              </GridRow>
              <GridRow className={'grid-tab__user--middle'} verticalAlign={'middle'}>
                <GridColumn floated={"left"}>
                  <InputSearch handleTextChange={this.handleSearchChange}/>
                </GridColumn>
                <GridColumn className={'column--header'}>
                  <Button className={"button__image"} basic
                          onClick={this.props.onOpenSidebar('AddMemberInRole')}>
                    <IconCustom className={'svg-icon__add'} name={'add'}/>
                  </Button>
                  <Button className={"button__image"} basic>
                    <IconCustom className={'svg-icon--white'} name={'filter'}/>
                  </Button>
                  {
                    this.state.isHiddenDelBtn ? null :
                      <DeleteModal
                        triggerType={"icon--trash"}
                        id={selectedUsersUnAssignRole}
                        descType={"role"}
                        name={selectedUsersUnAssignRole && selectedUsersUnAssignRole.length ? this.getUserNameByUserId(selectedUsersUnAssignRole[0]) : null}
                        tabName={"item"}
                        handleDeleteItem={this.handleUnAssignRole}/>
                  }
                </GridColumn>
              </GridRow>
              <GridRow className={"grid-tab__user--last"}>
                {
                  resultsUsersInRole && resultsUsersInRole.length ?
                    <GridColumn className={"column--body"}>
                      <Table singleLine fixed basic={'very'} textAlign={"center"} className={"table__fixed-header"}>
                        <TableHeader fullWidth className={"table__header-user"}>
                          <TableRow>
                            <TableHeaderCell width={2}>
                              <IconCustom className={'svg-icon__user svg-icon__user--header-table'}
                                          name={"user"}
                                          viewBox={"0 0 24 24"}
                                          width={"24"}
                                          height={"24"}
                                          x={"7.5"}
                                          y={"5.5"}/>
                            </TableHeaderCell>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Email</TableHeaderCell>
                            <TableHeaderCell width={2}/>
                          </TableRow>
                        </TableHeader>
                      </Table>
                      <div className={"table__scrolling"}>
                        <Table singleLine basic={'very'} textAlign={"center"}>
                          <TableBody className={'table__body-user'}>
                            {resultsUsersInRole.map(this.handleRoleTableCell)}
                          </TableBody>
                        </Table>
                      </div>
                    </GridColumn> :
                    <EmptyLoader desc={'There are no users in this role.'}/>
                }
              </GridRow>
            </Grid> : error && error.data ?
            <Segment padded={"very"} textAlign={"center"} basic>
              <Header icon>
                <Icon color={"red"} name={"times circle outline"}/>
                Something went wrong, Please try again.
              </Header>
              <Button primary onClick={this.handleGetAllData}>Try Again</Button>
            </Segment> : handleLoading(true, "tableLoading")
        } */}
      </React.Fragment>
    );
  }
}

RoleDetail.propTypes = {
  roleId: PropTypes.string.isRequired,
  backToRolPermTab: PropTypes.func.isRequired,
  onOpenSidebar: PropTypes.func,
  role: PropTypes.object,
  users: PropTypes.array,
  directoryPath: PropTypes.string,
  shouldUpdateRoleDetail: PropTypes.bool,
  error: PropTypes.object,
  loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  role: state.authorize.role,
  users: state.authorize.users,
  shouldUpdateRoleDetail: state.authorize.shouldUpdateRoleDetail,
  unassign: state.authorize.unassign,
  error: state.authorize.error,
  loading: state.authorize.loading
});

const mapDispatchToProps = (dispatch) => ({
  getRoleInfo: (payload) => dispatch(authorizeActions.getRoleInfo(payload)),
  getAuthority: (payload) => dispatch(authorizeActions.getAuthority(payload)),
  assignRole: (payload) => dispatch(authorizeActions.assignRole(payload)),
  unAssignRole: (payload) => dispatch(authorizeActions.unAssignRole(payload)),
  getEligible: (payload) => dispatch(authorizeActions.getEligible(payload)),
  updateEligible: (eligible) => dispatch(accountPageActions.updateEligible(eligible))
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleDetail);
