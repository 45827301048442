import React from 'react';
import { Button, Header, Image, Segment } from 'semantic-ui-react';

export const ResErrorComponent = (props) => {
  return (
    <React.Fragment>
      <Segment basic className="createUser--error">
        <Header textAlign="center" className="font--title2 font-weight">
          Hmmmmm.....
        </Header>
        <Header textAlign="center" className="font--title2 font-weight">
          Seem like this user already exists in this directory.
        </Header>

        <Image
          centered
          className="img--addMarginTop"
          src="./images/createError.svg"
        />
      </Segment>

      <Button className="btn--active-green" onClick={props.handleAnimationChangeClose()}>
        Close
      </Button>
    </React.Fragment>
  );
};
