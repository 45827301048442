/* eslint-disable max-lines */
import { userConstants } from "../_constants/user.constants";

// import { authHeader } from "../_helpers/";
import { alertActions } from "./";
import axios from "axios";

export const userActions = {
  clearUser,
  initialStateUser,
  getUserList,
  getUserListNoPage,
  delUserfromDirectory, // delete from system
  getUserInfoFromEmail,
  getUserInfoFromUsername,
  postUser,
  postAssignRole,
  postUnassignRole,
  getUserDetail,
  deleteUserById
};

const authorizePath = "/api/authorize";
const accountPath = "/api/account";

function clearUser() {
  return { type: userConstants.CLEAR_USER };
}

function initialStateUser() {
  return { type: userConstants.INITIAL_STATE_USER };
}

function getUserInfoFromEmail(email, directoryPath) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .get(`${accountPath}/user/email/${email}?directoryPath=${directoryPath}`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(alertActions.error("Failed toget User Information!", "Error!"));
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: userConstants.GET_USERINFO_REQUEST,
      email,
      directoryPath
    };
  }

  function success(response) {
    return {
      type: userConstants.GET_USERINFO_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: userConstants.GET_USERINFO_FAILURE,
      error
    };
  }
}

function getUserInfoFromUsername(username, directoryPath) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .get(`${accountPath}/user/username/${username}?directoryPath=${directoryPath}`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(alertActions.error("Failed toget User Information!", "Error!"));
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: userConstants.GET_USERINFO_REQUEST,
      username,
      directoryPath
    };
  }

  function success(response) {
    return {
      type: userConstants.GET_USERINFO_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: userConstants.GET_USERINFO_FAILURE,
      error
    };
  }
}

function postUser(payload, firstType) {
  return (dispatch) => {
    dispatch(request());

    return new Promise((resolve, reject) => {
    axios
      .post(`${accountPath}/user`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("add account information Success!!"));
        resolve(response);
      })
      .catch((error) => {
        dispatch(failure(error));
        let msg = error.response.data.message ? error.response.data.message : "Something went wrong!";
        if(error.response.data.message && error.response.data.message === "duplicated") {
          //msg = `${type} ${error.response.data.message}`;
          msg = `Username or Email or Phone Number is already in list `;
        }
        dispatch(
          alertActions.error(
            msg,
            "Error!"
          )
        );
        dispatch(alertActions.clear());
        reject();
      });
    });
  };

  function request() {
    return {
      type: userConstants.POST_USER_REQUEST,
      payload
    };
  }

  function success(response) {
    return {
      type: userConstants.POST_USER_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: userConstants.POST_USER_FAILURE,
      error
    };
  }
}

function deleteUserById(payload) {
  return (dispatch) => {
    dispatch(request(payload));

    return new Promise((resolve, reject) => {
      axios
        .delete(`${accountPath}/user/${payload}`)
        .then((response) => {
          dispatch(success(response));
          resolve();
        })
        .catch((error) => {
          dispatch(failure(error));
          dispatch(
            alertActions.error(
              error.response.data.message ? error.response.data.message : "Something went wrong! Delete user failed",
              "Error!"
            )
          );
          dispatch(alertActions.clear());
          reject();
        });
    });
  };

  function request() {
    return {
      type: userConstants.DELETE_USER_REQUEST,
      payload
    };
  }

  function success(response) {
    return {
      type: userConstants.DELETE_USER_SUCCESS,
      response: response.data,
      status: response.status
    };
  }

  function failure(error) {
    return {
      type: userConstants.DELETE_USER_FAILURE,
      error
    };
  }
}

function getUserList(directoryPath) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .get(`${authorizePath}/authority?directoryPath=${directoryPath}`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.data.code === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong! Cannot get userlist",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: userConstants.GET_USER_LIST_REQUEST,
      directoryPath
    };
  }

  function success(response) {
    return {
      type: userConstants.GET_USER_LIST_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: userConstants.GET_USER_LIST_FAILURE,
      error
    };
  }
}

function getUserListNoPage(directoryPath) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .get(`${authorizePath}/authority?directoryPath=${directoryPath}&limit=65535`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.data.code === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong! Cannot get userlist",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: userConstants.GET_USER_LIST_REQUEST,
      directoryPath
    };
  }

  function success(response) {
    return {
      type: userConstants.GET_USER_LIST_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: userConstants.GET_USER_LIST_FAILURE,
      error
    };
  }
}

function delUserfromDirectory(arrayId, userList) {
  const compleateUserList = userList.map((user) => ({
    ...user,
    delStatus: null
  }));
  const selectedUserDetail = userList.filter((item) => arrayId.includes(item._id));

  return (dispatch) => {
    dispatch(request(arrayId));
    return asyncProcess()
    .then((response) => {
        const delUserResponse = selectedUserDetail.map((user, index) => ({
          ...user,
          delResponse: response[index]
        }));

        dispatch(success(delUserResponse, compleateUserList));
        dispatch(alertActions.success(`Delete user from directory`));
        dispatch(alertActions.clear());

        // const delUserSuccessArr = userDetailArr
        //   .filter((user) => user.delStatus === 204)
        //   .map((user) => `${user.email}`).join(", ");

        // const delUserFailduredArr = userDetailArr
        //   .filter((user) => user.delStatus !== 204)
        //   .map((user) => `${user.email}`).join(", ");

        // if (delUserSuccessArr.length > 0) {
        //   dispatch(success(delUserSuccessArr));
        //   dispatch(alertActions.success(`Delete ${delUserSuccessArr} from directory is successful!`));
        //   dispatch(alertActions.clear());
        // }
        // if (delUserFailduredArr.length > 0) {
        //   dispatch(failure(delUserFailduredArr));
        //   dispatch(alertActions.error(`Can't delete ${delUserFailduredArr} from directory, please try again`));
        //   dispatch(alertActions.clear());
        // }
    });
  };

  async function asyncProcessMain (userId) {
    try {
      const result = await axios.delete(`${accountPath}/user/${userId}`);

      compleateUserList.map((user) => {
        return user._id === userId &&
        Object.assign(user, { delStatus: result.status });
      });

      return result;
    } catch (error) {
      compleateUserList.map((user) => {
        return user._id === userId &&
        Object.assign(user, { delStatus: error.response.data.code });
      });

      return error.response.data;
    }
  }

  function asyncProcess() {
    const promises = [];

    arrayId.forEach((userId, index) => {
        promises.push(asyncProcessMain(userId));
    });

    return Promise.all(promises);
  }

  function request() {
    return {
      type: userConstants.DEL_USER_FROM_DIR_REQUEST,
      arrayId,
      userList
    };
  }

  function success(delUserResponse) {
    return {
      type: userConstants.DEL_USER_FROM_DIR_SUCCESS,
      delUserResponse,
      compleateUserList
    };
  }

  // function failure(error) {
  //   return {
  //     type: userConstants.DEL_USER_FROM_DIR_FAILURE,
  //     error
  //   };
  // }
}

// function delUserfromDirectory(userId) {
//   return (dispatch) => {
//     dispatch(request());

//     // delete method normal : axios.delete(url[, config])
//     // but delCardfromUser have bodyRequest, so the format is axios.delete(url, { data(only key), headers })
//     return axios.delete(`${accountPath}/user/${userId}`)
//       .then((response) => {
//         dispatch(success(response));
//         dispatch(
//           alertActions.success("Delete user from directory is successful!")
//         );
//         dispatch(alertActions.clear());
//       })
//       .catch((error) => {
//         dispatch(failure(error));
//         dispatch(
//           alertActions.error(
//             error.response.data.message ? error.response.data.message : "Something went wrong!",
//             "Error!"
//           )
//         );
//         dispatch(alertActions.clear());
//       });
//   };

//   function request() {
//     return {
//       type: userConstants.DEL_USER_FROM_DIR_REQUEST,
//       userId
//     };
//   }

//   function success(response) {
//     return {
//       type: userConstants.DEL_USER_FROM_DIR_SUCCESS,
//       response
//     };
//   }

//   function failure(error) {
//     return {
//       type: userConstants.DEL_USER_FROM_DIR_FAILURE,
//       error
//     };
//   }
// }

// assign role to user
function postAssignRole(payload) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .post(`${authorizePath}/assign`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Assign Role Success!"))
      })
      .catch((error) => {
        //console.log("=======postAssignRole========>>",error.response)
        dispatch(alertActions.error("Assign Role Fail!", "Error!"))
        dispatch(failure(error));
        
      });
  };

  function request() {
    return {
      type: userConstants.POST_ASSIGN_ROLE_REQUEST,
      payload
    };
  }

  function success(response) {
    return {
      type: userConstants.POST_ASSIGN_ROLE_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: userConstants.POST_ASSIGN_ROLE_FAILURE,
      error
    };
  }
}

// unassign role to user
function postUnassignRole(payload) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .post(`${authorizePath}/unassign`, payload)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: userConstants.POST_UNASSIGN_ROLE_REQUEST,
      payload
    };
  }

  function success(response) {
    return {
      type: userConstants.POST_UNASSIGN_ROLE_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: userConstants.POST_UNASSIGN_ROLE_FAILURE,
      error
    };
  }
}

// get user detail
function getUserDetail(id) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .get(`${accountPath}/user/${id}`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: userConstants.GET_USER_DETAIL_REQUEST,
      id
    };
  }

  function success(response) {
    return {
      type: userConstants.GET_USER_DETAIL_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: userConstants.GET_USER_DETAIL_FAILURE,
      error
    };
  }
}
