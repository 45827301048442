import React,{useState} from 'react';
import { Button, Form, Grid, Header, Icon, Input, Label, Modal, Segment, Popup } from 'semantic-ui-react';
import IconCustom from "../../../assets/icons";
import handleLoading from "../../../components/Loader/Loader";
import TextField from "../../../components/TextField/TextField";
import DeleteDeviceTypeModal from "./DeleteDeviceTypeModal";
import AttributeDeviceTypeComponent from "../component/AttributeDeviceTypeComponent";

class CreateAndUpdateDeviceTypeModal extends React.Component {

  constructor(props){
    super(props);
  }

  state = {
    modalOpen: false,
    deviceType: "",
    telemetryKey: "",
    telemetryKeyList: [],
    attribute: "",
    attributeList: [],
    deleteModal: false,

  }

  

  static getDerivedStateFromProps(props, state) {
    if (props.postDeviceTypeStatus === 201 || props.updateDeviceTypeStatus === 200) {
      return {
        modalOpen: false,
        deviceType: "",
        telemetryKey: "",
        telemetryKeyList: [],
        attributeList: [],
      };
    }

    if (props.deleteDeviceTypeStatus === 204) {
      return {
        deleteModal: false
      };
    }

    return null;
  }
  
  handleOpenModal = (id) => {
    if (this.props.type) {
      this.props.getDeviceTypeById(id).then((respp) => {
          this.setState({
            modalOpen: true,
            deviceType: this.props.deviceTypeInfo.type,
            telemetryKeyList: this.props.deviceTypeInfo.telemetryKey,
            attributeList : this.props.deviceTypeInfo.attributesList
          })
      });
      
    } else {

      this.props.deviceTypeInfo = {}
      this.setState({ 
        modalOpen: true,
      });
      this.props.deviceTypeInfo.attributesList = []
    }


  }
  handleCloseModal = () => { this.setState({ modalOpen: false, deviceType: "", telemetryKeyList: [], telemetryKey: "" ,attributeList:[]}); }

  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }); };

  addNewTelemetryKey = (e, newTelemetryKey) => {
    if(!newTelemetryKey){
      return false;
    }
    
    if (e.keyCode === 13) {
    this.setState((prevState) => ({ telemetryKeyList: [...prevState.telemetryKeyList, newTelemetryKey], telemetryKey: "" }));
    }
  };

  removeTelemetryKey = (item) => {
    this.setState({ telemetryKeyList: this.state.telemetryKeyList.filter((key) => key !== item) });
  };

  addNewAttribute = (e, newAttribute) => {
    this.setState((prevState) => ({ attributeList: [...prevState.attributeList, newAttribute]}));
  };

  removeAttribute = (e, attributeKey) => {
    this.setState((prevState) => ({ attributeList: this.state.attributeList.filter((d) => d.attributeKey !== attributeKey) })) 
  };

  componentDidMount() {
    const { item, type, isAdmin, handleDeleteItem, deviceTypeInfo, loading } = this.props
    
    this.setState({
      attributeList : deviceTypeInfo ? deviceTypeInfo.attributesList :[]
    });
  }

  handleSubmit = (type) => {
    const { deviceType, telemetryKeyList ,attributeList} = this.state;

    const payload = {
      type: deviceType,
      directoryPath: this.props.optionDirectory.map((item) => item.value)[0],
      telemetryKey: telemetryKeyList,
      attributesList : attributeList
    };
    if (type) {
      this.props.handleSubmit(payload, type, this.props.deviceTypeInfo._id);
    } else {
      this.props.handleSubmit(payload);
    }
  }

  handleOpenDeleteModal = (obj) => {
    this.props.getDeviceTypeById(obj._id).then(() => this.setState({ deleteModal: true }));
  }

  handleCloseDeleteModal = () => { this.setState({ deleteModal: false }); }

  useEffect() {
    const { item, type, isAdmin, handleDeleteItem, deviceTypeInfo, loading } = this.props;
  }

  render() {
    const { modalOpen, telemetryKey, deviceType, telemetryKeyList, deleteModal,attributeList } = this.state;
    const { item, type, isAdmin, handleDeleteItem, deviceTypeInfo, loading } = this.props;
    return (
      <Modal
      size="tiny"
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={modalOpen}
      onClose={this.handleCloseModal}
      trigger={type ?
      <>
      <td onClick={() => this.handleOpenModal(item._id)}>{item.deviceType}</td>
      <td style={{ width: '50%' }} onClick={() => this.handleOpenModal(item._id)}>{item.telemetryKey && item.telemetryKey ? item.telemetryKey.map((key) => <Label className="label-telemetry"> {key}</Label>) : null}</td>
      <td onClick={() => this.handleOpenModal(item._id)}>{item.lastUpdate}</td>
      <td style={{ textAlign: "center" }} onclick={() => this.handleOpenModal(item._id)}>
        {
          isAdmin ?
          <Popup
            trigger={
              <Button className={"button__icon btn_icon--info pd-left-2"} icon onClick={() => this.handleOpenModal(item._id)}>
                <Icon name='pencil' />
              </Button>
            }
            content="Edit"
            mouseLeaveDelay={0}
            position='left center'
            basic
          /> :
          <Popup
            trigger={
              <Button className={"button__icon btn_icon--primary pd-left-2"} icon onClick={() => this.handleOpenModal(item._id)}>
                <Icon name='eye' />
              </Button>
            }
            content="View"
            mouseLeaveDelay={0}
            position='left center'
            basic
          />
        }
        {
          isAdmin && item.delete && item.delete ?
          <DeleteDeviceTypeModal
            item={item}
            deleteModal={deleteModal}
            handleOpenDeleteModal={this.handleOpenDeleteModal}
            handleCloseDeleteModal={this.handleCloseDeleteModal}
            handleDeleteItem={handleDeleteItem}
            deviceTypeInfo={deviceTypeInfo}
            loading={loading}
          /> : null
        }
      </td>
      </> :
        <Popup
          trigger={
            <span onClick={this.handleOpenModal}><IconCustom className={'svg-icon__add'} name={'add'}/></span>
          }
          content="Create device type"
          mouseLeaveDelay={0}
          position='left center'
          basic
        />}
      >

      {handleLoading(this.props.loading)}

      <Modal.Header>
        <Grid>
          <Grid.Column width={14}>
            {
              isAdmin ?
              (type ? 'Update Device Type' : 'Create Device Type')
              : 'Device Type'
            }
          </Grid.Column>

          <Grid.Column textAlign="right" width={2}>
            <Icon name="close" onClick={this.handleCloseModal} link/>
          </Grid.Column>
        </Grid>
      </Modal.Header>

      <Modal.Content scrolling>
      <Form>
        {/* <Form.Field>
          <Form.Input
          fluid
          label="Device Type"
          placeholder="Device Type"
          name="deviceType"
          onChange={this.handleChange}
          value={deviceType}
          disabled={!isAdmin} />
        </Form.Field> */}
        <Header style={{ fontSize: '13px' }}>Device Type Name</Header>
        <TextField
          className={"--bottom"}
          // text={"Device Type"}
          placeholder={"Device Type"}
          id={"deviceType"}
          name={"deviceType"}
          value={deviceType}
          handleInputValue={this.handleChange}
          isDisable={!isAdmin}
          // error={textError}
        />

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header style={{ fontSize: '13px' }}>Telemetry Key <span style={{ fontSize: '11px' }}>(Actuator or Sensor key name)</span></Header> 
                <Segment textAlign="left" className="segment-createTelemetry">
                    {telemetryKeyList &&
                      telemetryKeyList.map((value, index) =>
                      <Label as="a" className="label-createTelemetry">{value}
                        { isAdmin ? <Icon name="delete" onClick={() => this.removeTelemetryKey(value)}/> : null }
                      </Label>)}

                    { isAdmin ?
                      <Input
                        fluid
                        className="input-telemetryKey"
                        placeholder="Add your telemetry key and press enter"
                        onChange={this.handleChange}
                        name="telemetryKey"
                        value={telemetryKey}
                        onKeyDown={(e) => this.addNewTelemetryKey(e, telemetryKey)}
                      /> : null
                    }
                  </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>


        <Grid>
          <Grid.Row>
            <Grid.Column >
              <AttributeDeviceTypeComponent 
                  isUpdateDevice={true}  
                  addNewAttribute={this.addNewAttribute} 
                  removeAttribute={this.removeAttribute} 
                  deviceTypeInfo={deviceTypeInfo} 
                  attributeList={attributeList}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
           
        

      </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={this.handleCloseModal} className="btn-cancelTelemetry">
          { isAdmin ? 'Cancel' : 'Close' }
        </Button>
        { isAdmin ?
          <Button onClick={() => this.handleSubmit(type)} className="btn-createTelemetry">
            {type ? 'Update' : 'Create'}
          </Button> : null
        }
      </Modal.Actions>
    </Modal>
);
  }
}

export default CreateAndUpdateDeviceTypeModal;
