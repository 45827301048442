import { assetConstants } from "../_constants/";
import { alertActions } from "./";
import axios from "axios";

export const assetActions = {
  initialStateAsset,
  clearAsset,
  getAssetList,
  postAsset,
  getAssetInfo,
  patchAsset,
  delAsset
};

const assetPath = "/api/asset";

function clearAsset() {
  return { type: assetConstants.CLEAR_ASSET };
}

function initialStateAsset() {
  return { type: assetConstants.INITIAL_STATE_ASSET };
}

function getAssetList(directoryPath, value) {
  return (dispatch) => {
    dispatch(request(directoryPath));

    let getAssetPath;

    if (value) {
      getAssetPath = `${assetPath}/asset?name=${value}&directory=${directoryPath}`;
    } else {
      getAssetPath = `${assetPath}/asset?directory=${directoryPath}`;
    }

    return axios
      .get(getAssetPath)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request(dirPath) {
    return {
      type: assetConstants.GET_ASSET_LIST_REQUEST,
      directoryPath: dirPath
    };
  }

  function success(response) {
    return {
      type: assetConstants.GET_ASSET_LIST_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: assetConstants.GET_ASSET_LIST_FAILURE,
      error
    };
  }
}

function postAsset(payload) {
  return (dispatch) => {
    dispatch(request(payload));

    return axios
      .post(`${assetPath}/asset`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Create asset is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: assetConstants.POST_ASSET_REQUEST,
      payload
    };
  }

  function success(response) {
    return {
      type: assetConstants.POST_ASSET_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: assetConstants.POST_ASSET_FAILURE,
      error
    };
  }
}

function getAssetInfo(id) {
  return (dispatch) => {
    dispatch(request(id));

    return axios
      .get(`${assetPath}/asset/${id}`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request(assetId) {
    return {
      type: assetConstants.GET_ASSET_INFO_REQUEST,
      id: assetId
    };
  }

  function success(response) {
    return {
      type: assetConstants.GET_ASSET_INFO_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: assetConstants.GET_ASSET_INFO_FAILURE,
      error
    };
  }
}

function patchAsset(id, payload) {
  return (dispatch) => {
    dispatch(request(id));

    return axios
      .patch(`${assetPath}/asset/${id}`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Update asset is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request(assetId) {
    return {
      type: assetConstants.PATCH_ASSET_REQUEST,
      id: assetId,
      payload
    };
  }

  function success(response) {
    return {
      type: assetConstants.PATCH_ASSET_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: assetConstants.PATCH_ASSET_FAILURE,
      error
    };
  }
}

function delAsset(payload) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .delete(`${assetPath}/asset`, { data: payload })
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Delete asset is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: assetConstants.DEL_ASSET_REQUEST,
      payload
    };
  }

  function success(response) {
    return {
      type: assetConstants.DEL_ASSET_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: assetConstants.DEL_ASSET_FAILURE,
      error
    };
  }
}
