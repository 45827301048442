import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

class SelectionDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initialValue ? this.props.initialValue : ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      this.setState({
        value: ""
      });
    }
  }

  handleChangeDropdown = (e, { value }) => {
    this.setState(
      {
        value
      },
      () => this.props.handleChangeDropdown(value)
    );
  };

  render() {
    const { value } = this.state;
    const {
      includeClassName,
      placeholder,
      options,
      search,
      disabled,
      id,
      name
    } = this.props;

    return (
      <Dropdown
        className={`dropdown__selection ${
          includeClassName ? includeClassName : ""
        }`}
        disabled={disabled}
        options={options}
        selection
        value={value}
        search={search}
        placeholder={placeholder}
        onChange={this.handleChangeDropdown}
        id={id}
        name={name}
      />
    );
  }
}

SelectionDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  handleChangeDropdown: PropTypes.func.isRequired
};

export default SelectionDropdown;
