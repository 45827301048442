import React, { useState } from 'react';
import { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class DateTimePickerComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onChange, value, disabled, minDate } = this.props;
    return (
      <div className="datepicker-wrapper">
        <DatePicker
          selected={Date.parse(value)}
          onChange={(date) => onChange(date)}
          minDate={minDate? minDate : null}
          showMonthDropdown
          showYearDropdown
          showTimeSelect
          timeCaption="Time"
          timeIntervals={15}
          dateFormat="dd/MM/yyyy - HH:mm"
          timeFormat="HH:mm"
          disabled = {disabled ? disabled : false}
        />
      </div>
    );
  }
};
