import React from "react";
import { Header, Image, Segment } from "semantic-ui-react";

// compoentn
import InputModal from "../../../../../components/Input/InputModal";
import SidebarButton from "../../../../../components/Button/SidebarButton";
import TextField from "../../../../../components/TextField/TextField";

export default class CheckEmailComponent extends React.Component {

  render() {
    const { user, errorUser, handleChange, handleSubmit, handleAnimationChangeClose } = this.props;

    return (
      <React.Fragment>

        <Segment basic className="sement--padding-0">
          <div>
            <Header className="header--createUser">Add and Assign user</Header>

            <div className="div--fixedBtn">
              <Image
                centered
                src="./images/create_account.svg"
              />
              <Segment
                basic
                className="segment-nonePending segment-addPadding-left-right"
              >
                <TextField
                  text={"User"}
                  type={"text"}
                  name={"user"}
                  handleInputValue={handleChange}
                  error={errorUser}
                />
                <div className="desc-username div--left"><span>Please input username or email of user</span></div>
              </Segment>
            </div>
          </div>

          <SidebarButton
            disabled={!user}
            btnSecondary="CANCEL"
            btnActive="SUBMIT"
            handleSubmit={handleSubmit}
            OnCloseSidebar={handleAnimationChangeClose()}
            width={5}
            isUpdateAccount={true}
          />
        </Segment>

      </React.Fragment>
    );
  }
}
