import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { history } from "../_helpers";

import LoginPage from "../pages/login/LoginPage";
import ChangeORForgotPassPage from "../pages/changeORforgotPass/ChangeORForgotPassPage";
import SetPasswordPage from "../pages/set password/SetPasswordPage";
import ForgotPassWordComponent from "../pages/changeORforgotPass/ForgotPassWordComponent";
import { PrivateRoute } from "./";
import { ResponsiveContainer } from "../components/Menu/Navbar";
import VerifyOTPPage from "../pages/verifyOTP/VerifyOTPPage";

export default function Routing() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/changepass" component={ChangeORForgotPassPage} />
        <Route exact path="/setpassword" component={SetPasswordPage} />
        <Route exact path="/forgotpassword" component={ChangeORForgotPassPage} />
        <Route exact path="/sendEmail" component={ForgotPassWordComponent} />
        <Route exact path="/verifyOTP" component={VerifyOTPPage} />

        <PrivateRoute path="/" component={ResponsiveContainer} />
      </Switch>
    </Router>
  );
}
