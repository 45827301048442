import React from "react";

const SVG = ({
  className = "",
  viewBox = "0 0 40 40",
  width = "40",
  height = "40",
  x = "10.5",
  y = "8.5",
  borderX = "0",
  borderY = "0",
  borderLine = "0"
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={`svg-icon ${className || ""}`}
  >
    <rect
      id={"bin-border"}
      x={borderX}
      y={borderY}
      width={`${width - 1}`}
      height={`${height - 1}`}
      rx="8"
      stroke={borderLine}
    />
    <svg
      id={"icon-bin"}
      width={width / 2}
      height={width / 2}
      viewBox="0 0 328 328"
      x={x}
      y={y}
      strokeWidth="15"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="7.5" y="48.5" width="272" height="50" rx="25" />
      <path d="M41 128V290C41 306.569 54.4315 320 71 320H217C233.569 320 247 306.569 247 290V128" />
      <path d="M86 143L86 282" />
      <path d="M144 143L144 282" />
      <path d="M201 143L201 282" />
      <path d="M103 42.4997C115.5 -13.0002 183 6.99987 183 42.4998" />
    </svg>
  </svg>
);

export default SVG;
