export const authHeader = (type) => {
  // return authorization header with jwt token

  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjVlNDE0MDFhOGYzMTNjNWUxYzE2N2Y1MSIsImVtYWlsIjoiYWRtaW5AbmV4dGxpdmluZy5jbyIsImZpcnN0bmFtZSI6ImFkbWluIiwibGFzdG5hbWUiOiJpb3RhcHAifSwidHlwZSI6ImFjY2VzcyIsImlhdCI6MTU4NDM1MDI4MiwiZXhwIjoxNTg0MzUzODgyfQ.-M1JqCz_p80l-Vgofgy1lCAD7fYbh0HcHOKVLaZAJCw";

  if (type === "withToken") {
    // const token = localStorage.getItem('accessToken');

    if (token) {
      return {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      };
    }
  }

  return {
    "Content-Type": "application/json"
  };
};
