import { relationConstants } from "../_constants/";
import { alertActions } from "./";
import axios from "axios";

export const relationActions = {
  initialStateRelation,
  getRelationList,
  getEntityType,
  getRelationInfo,
  postRelation,
  delRelation
};

const relationPath = "/api/relation";
const deivcePath = "/api/device";
const assetPath = "/api/asset";

function initialStateRelation() {
  return { type: relationConstants.INITIAL_STATE_RELATION };
}

function getRelationList(id, direction) {
  return (dispatch) => {
    dispatch(request());

    let directionKey;

    if (direction === "from") {
      directionKey = "toId";
    } else {
      directionKey = "fromId";
    }

    return axios
      .get(`${relationPath}/relation?${directionKey}=${id}`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: relationConstants.GET_RELATION_LIST_REQUEST,
      id,
      direction
    };
  }

  function success(response) {
    return {
      type: relationConstants.GET_RELATION_LIST_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: relationConstants.GET_RELATION_LIST_FAILURE,
      error
    };
  }
}

function getEntityType(toEntityType) {
  return (dispatch) => {
    dispatch(request());

    let pathEntityType;

    if (toEntityType === "DEVICE") {
      pathEntityType = `${deivcePath}/device`;
    } else {
      pathEntityType = `${assetPath}/asset`;
    }

    return axios
      .get(pathEntityType)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: relationConstants.GET_ENTITY_TYPE_REQUEST,
      toEntityType
    };
  }

  function success(response) {
    return {
      type: relationConstants.GET_ENTITY_TYPE_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: relationConstants.GET_ENTITY_TYPE_FAILURE,
      error
    };
  }
}

function getRelationInfo(id) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .get(`${relationPath}/relation/${id}`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: relationConstants.GET_RELATION_INFO_REQUEST,
      id
    };
  }

  function success(response) {
    return {
      type: relationConstants.GET_RELATION_INFO_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: relationConstants.GET_RELATION_INFO_FAILURE,
      error
    };
  }
}

function postRelation(payload, direction) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .post(`${relationPath}/relation`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Create relation is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: relationConstants.POST_RELATION_REQUEST,
      payload,
      direction
    };
  }

  function success(response) {
    return {
      type: relationConstants.POST_RELATION_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: relationConstants.POST_RELATION_FAILURE,
      error
    };
  }
}

function delRelation(id) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .delete(`${relationPath}/relation/${id}`)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Delete relation is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));

        dispatch(
          alertActions.error(
            error.response.data.message ?
              error.response.data.message :
              "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: relationConstants.DEL_RELATION_REQUEST,
      id
    };
  }

  function success(response) {
    return {
      type: relationConstants.DEL_RELATION_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: relationConstants.DEL_RELATION_FAILURE,
      error
    };
  }
}
