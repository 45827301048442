import { paginationConstants } from "../../_constants/pages/pagination.constants";

export const paginationActions = {
  setPaginationFor,
  setAmountDisplayFor,
  setActivePageFor
};

function setPaginationFor(prefix) {
  return (payload) => {
    const {
      activePage,
      totalData,
      amountDisplay
    } = payload;

    const totalPages = Math.ceil(totalData / amountDisplay);

    return setPagination(activePage, totalPages);
  };

  function setPagination(activePage, totalPages) {
    return {
      type: prefix + paginationConstants.SUFFIX_SET_PAGINATION,
      activePage,
      totalPages
    };
  }
}

function setAmountDisplayFor(prefix) {
  return (payload) => {
    const {
      amountDisplay
    } = payload;

    return setAmountDisplay(amountDisplay);
  };

  function setAmountDisplay(amountDisplay) {
    return {
      type: prefix + paginationConstants.SUFFIX_SET_AMOUNT_DISPLAY,
      amountDisplay
    };
  }
}

function setActivePageFor(prefix) {
  return (payload) => {
    const {
      activePage
    } = payload;

    return setActivePage(activePage);
  };

  function setActivePage(activePage) {
    return {
      type: prefix + paginationConstants.SUFFIX_SET_ACTIVE_PAGE,
      activePage
    };
  }
}
