export const directoryConstants = {
  // clear child directory
  CLEAR_CHILD_DIR: "CLEAR_CHILD_DIR",

  // get directory
  GET_DIRECTORY_LIST_REQUEST: 'GET_DIRECTORY_LIST_REQUEST',
  GET_DIRECTORY_LIST_SUCCESS: 'GET_DIRECTORY_LIST_SUCCESS',
  GET_DIRECTORY_LIST_FAILURE: 'GET_DIRECTORY_LIST_FAILURE',

  // get root/parent directory
  GET_DIRECTORY_REQUEST: "DIR_GET_DIRECTORY_REQUEST",
  GET_DIRECTORY_SUCCESS: "DIR_GET_DIRECTORY_SUCCESS",
  GET_DIRECTORY_FAILURE: "DIR_GET_DIRECTORY_FAILURE",

  // get child directory
  GET_CHILD_DIRECTORY_REQUEST: "DIR_GET_CHILD_DIRECTORY_REQUEST",
  GET_CHILD_DIRECTORY_SUCCESS: "DIR_GET_CHILD_DIRECTORY_SUCCESS",
  GET_CHILD_DIRECTORY_FAILURE: "DIR_GET_CHILD_DIRECTORY_FAILURE",

  // get directory dropdown
  GET_DIRECTORY_DROPDOWN_REQUEST: 'GET_DIRECTORY_DROPDOWN_REQUEST',
  GET_DIRECTORY_DROPDOWN_SUCCESS: 'GET_DIRECTORY_DROPDOWN_SUCCESS',
  GET_DIRECTORY_DROPDOWN_FAILURE: 'GET_DIRECTORY_DROPDOWN_FAILURE',

  // create directory
  POST_DIRECTORY_REQUEST: "DIR_POST_DIRECTORY_REQUEST",
  POST_DIRECTORY_SUCCESS: "DIR_POST_DIRECTORY_SUCCESS",
  POST_DIRECTORY_FAILURE: "DIR_POST_DIRECTORY_FAILURE",

  // update directory
  PATCH_DIRECTORY_REQUEST: "DIR_PATCH_DIRECTORY_REQUEST",
  PATCH_DIRECTORY_SUCCESS: "DIR_PATCH_DIRECTORY_SUCCESS",
  PATCH_DIRECTORY_FAILURE: "DIR_PATCH_DIRECTORY_FAILURE",

  // delete directory
  DEL_DIRECTORY_REQUEST: "DIR_DEL_DIRECTORY_REQUEST",
  DEL_DIRECTORY_SUCCESS: "DIR_DEL_DIRECTORY_SUCCESS",
  DEL_DIRECTORY_FAILURE: "DIR_DEL_DIRECTORY_FAILURE",

  GET_DIRECTORY_ELIGIBLE_REQUEST: "GET_DIRECTORY_ELIGIBLE_REQUEST",
  GET_DIRECTORY_ELIGIBLE_SUCCESS: "GET_DIRECTORY_ELIGIBLE_SUCCESS",
  GET_DIRECTORY_ELIGIBLE_FAILURE: "GET_DIRECTORY_ELIGIBLE_FAILURE"
};
