export const deviceConstants = {
  // clear device
  CLEAR_DEVICE: "CLEAR_DEVICE",

  // get device list
  GET_DEVICE_LIST_REQUEST: "DEVICE_GET_DEVICE_LIST_REQUEST",
  GET_DEVICE_LIST_SUCCESS: "DEVICE_GET_DEVICE_LIST_SUCCESS",
  GET_DEVICE_LIST_FAILURE: "DEVICE_GET_DEVICE_LIST_FAILURE",

  // get device all list
  GET_ALL_DEVICE_LIST_REQUEST: "GET_DEVICE_ALL_LIST_REQUEST",
  GET_ALL_DEVICE_LIST_SUCCESS: "GET_DEVICE_ALL_LIST_SUCCESS",
  GET_ALL_DEVICE_LIST_FAILURE: "GET_DEVICE_ALL_LIST_FAILURE",

  // create device
  POST_DEVICE_REQUEST: "DEVICE_POST_DEVICE_REQUEST",
  POST_DEVICE_SUCCESS: "DEVICE_POST_DEVICE_SUCCESS",
  POST_DEVICE_FAILURE: "DEVICE_POST_DEVICE_FAILURE",

  // get device info
  GET_DEVICE_INFO_REQUEST: "DEVICE_GET_DEVICE_INFO_REQUEST",
  GET_DEVICE_INFO_SUCCESS: "DEVICE_GET_DEVICE_INFO_SUCCESS",
  GET_DEVICE_INFO_FAILURE: "DEVICE_GET_DEVICE_INFO_FAILURE",

  // del device
  PATCH_DEVICE_REQUEST: "DEVICE_PATCH_DEVICE_REQUEST",
  PATCH_DEVICE_SUCCESS: "DEVICE_PATCH_DEVICE_SUCCESS",
  PATCH_DEVICE_FAILURE: "DEVICE_PATCH_DEVICE_FAILURE",

  // del device
  DEL_DEVICE_REQUEST: "DEVICE_DEL_DEVICE_REQUEST",
  DEL_DEVICE_SUCCESS: "DEVICE_DEL_DEVICE_SUCCESS",
  DEL_DEVICE_FAILURE: "DEVICE_DEL_DEVICE_FAILURE",

  // get device token
  GET_DEVICE_TOKEN_REQUEST: "DEVICE_GET_DEVICE_TOKEN_REQUEST",
  GET_DEVICE_TOKEN_SUCCESS: "DEVICE_GET_DEVICE_TOKEN_SUCCESS",
  GET_DEVICE_TOKEN_FAILURE: "DEVICE_GET_DEVICE_TOKEN_FAILURE",

  // get device type
  GET_DEVICE_TYPE_REQUEST: "DEVICE_GET_DEVICE_TYPE_REQUEST",
  GET_DEVICE_TYPE_SUCCESS: "DEVICE_GET_DEVICE_TYPE_SUCCESS",
  GET_DEVICE_TYPE_FAILURE: "DEVICE_GET_DEVICE_TYPE_FAILURE",

  // get keys's telemetry data from device
  GET_KEYS_TELEMETRY_REQUEST: "DEVICE_GET_KEYS_TELEMETRY_REQUEST",
  GET_KEYS_TELEMETRY_SUCCESS: "DEVICE_GET_KEYS_TELEMETRY_SUCCESS",
  GET_KEYS_TELEMETRY_FAILURE: "DEVICE_GET_KEYS_TELEMETRY_FAILURE",

  // get device's attribute
  GET_DEVICE_ATTRIBUTE_REQUEST: "DEVICE_GET_DEVICE_ATTRIBUTE_REQUEST",
  GET_DEVICE_ATTRIBUTE_SUCCESS: "DEVICE_GET_DEVICE_ATTRIBUTE_SUCCESS",
  GET_DEVICE_ATTRIBUTE_FAILURE: "DEVICE_GET_DEVICE_ATTRIBUTE_FAILURE",

  // post device's attribute
  POST_DEVICE_ATTRIBUTE_REQUEST: "DEVICE_POST_DEVICE_ATTRIBUTE_REQUEST",
  POST_DEVICE_ATTRIBUTE_SUCCESS: "DEVICE_POST_DEVICE_ATTRIBUTE_SUCCESS",
  POST_DEVICE_ATTRIBUTE_FAILURE: "DEVICE_POST_DEVICE_ATTRIBUTE_FAILURE",

  // del device's attribute
  DEL_DEVICE_ATTRIBUTE_REQUEST: "DEL_POST_DEVICE_ATTRIBUTE_REQUEST",
  DEL_DEVICE_ATTRIBUTE_SUCCESS: "DEL_POST_DEVICE_ATTRIBUTE_SUCCESS",
  DEL_DEVICE_ATTRIBUTE_FAILURE: "DEL_POST_DEVICE_ATTRIBUTE_FAILURE",

  // get device by search
  GET_DEVICE_SEARCH_LIST_REQUEST: "GET_DEVICE_SEARCH_LIST_REQUEST",
  GET_DEVICE_SEARCH_LIST_SUCCESS: "GET_DEVICE_SEARCH_LIST_SUCCESS",
  GET_DEVICE_SEARCH_LIST_FAILURE: "GET_DEVICE_SEARCH_LIST_FAILURE"
};
