import React from 'react';
import { Header, Segment, Table, Dropdown, Grid } from 'semantic-ui-react';
import DeleteDeviceTypeModal from "./DeleteDeviceTypeModal";
import CreateAndUpdateDeviceTypeModal from "./CreateAndUpdateDeviceTypeModal";
import { VehicleTableHeader } from '../../../components/Table/VehicleTableHeader';
import handleLoading from "../../../components/Loader/Loader";

class DeviceTypeTable extends React.Component {
  renderEmptyPage = (directoryEligibleStatus, deviceTypeList, loading) => {
    if (loading) {
      return null;
    } else if (directoryEligibleStatus === 403) {
      return <Segment textAlign="center" className="segment--center" basic ><Header color="grey">You do not have permission to access</Header></Segment>;
    } else if (deviceTypeList && deviceTypeList.length < 1) {
      return <Segment textAlign="center" className="segment--center" basic ><Header color="grey">No data</Header></Segment>;
    }

    return null;
  }

  render () {
    // const { deleteModal } = this.state;
    const { tableHeader, deviceTypeList, optionDirectory, handleSubmit, handleDeleteItem, getDeviceTypeById, deviceTypeInfo,
      updateDeviceTypeStatus, loading, directoryEligibleStatus, colSpanFooter, paginationComponent, sizeOptions, handleChangeSize, isAdmin,
      deleteDeviceTypeStatus } = this.props;

    return (
    <>
    {handleLoading(loading)}

      <div className="fixed-table-container" style={{ marginTop: 0 }}>
      <div className="header-background"> </div>
      <div className="fixed-table-container-inner fixed-table-container-inner--deviceType">

        <table cellSpacing="0">

        <VehicleTableHeader vehiclesHeader={tableHeader} />

        {directoryEligibleStatus === 403 ? null :
        <tbody>
         {deviceTypeList.map((item) => <tr>
              <CreateAndUpdateDeviceTypeModal
                type="update"
                item={item}
                handleSubmit={handleSubmit}
                getDeviceTypeById={getDeviceTypeById}
                deviceTypeInfo={deviceTypeInfo}
                optionDirectory={optionDirectory}
                updateDeviceTypeStatus={updateDeviceTypeStatus}
                loading={loading}
                isAdmin={isAdmin}
                handleDeleteItem={handleDeleteItem}
                deleteDeviceTypeStatus={deleteDeviceTypeStatus}
              />
          </tr>)}
          </tbody>
          }
          </table>

          {this.renderEmptyPage(directoryEligibleStatus, deviceTypeList, loading)}
        </div>
        {deviceTypeList && deviceTypeList.length > 0 ?
            // <Table.Footer>
            //   <Table.Row>
            //     <Table.HeaderCell colSpan={colSpanFooter ? colSpanFooter : "1"} className="table-th__footer">
            //       {
            //         paginationComponent ?
            //           <div style={{"display":"inline-block"}}>
            //             {paginationComponent}
            //             <div className="dropdown-pagesize-wrapper">
            //             <Dropdown
            //                 inline
            //                 selection
            //                 options={sizeOptions}
            //                 defaultValue={sizeOptions[0].value}
            //                 onChange={handleChangeSize}
            //               />
            //             Per page
            //             </div>
            //           </div>
            //         : null
            //       }
            //     </Table.HeaderCell>
            //   </Table.Row>
            // </Table.Footer>
            <Grid textAlign={"left"} className="table-th__footer_grid">
              <Grid.Row>
                {paginationComponent && paginationComponent}
                <div className="dropdown-pagesize-wrapper">
                  <Dropdown
                    inline
                    selection
                    options={sizeOptions}
                    defaultValue={sizeOptions[0].value}
                    onChange={handleChangeSize}
                  />
                  Per page
                </div>
              </Grid.Row>

            </Grid>
            : null
          }
        </div>
        </>
    );
  }
}

export default DeviceTypeTable;
