import React, { Component } from "react";
import { Accordion, Dropdown, Grid, Icon } from "semantic-ui-react";
import ChildAccordion from "./ChildAccordion";
import CreateAndEditModal from "../Modal/CreateAndEditModal";
import DeleteModal from "../Modal/DeleteModal";

export default class ParentAccordion extends Component {
  render() {
    const {
      activeIndex,
      handleClick,
      parentDirectory,
      getParentDirectory,
      childDirectory,
      handleCreateItem,
      handleUpdateItem,
      handleDeleteItem,
      status,
      directoryEligibleStatus,
      directoryPerm,
      isAdminRoot,
      activeDirectory
    } = this.props;

    return (
      <>
        {parentDirectory && (
          <Accordion>
            {parentDirectory.map((parentDir, index) => (
              <div
                key={index}
                className={
                  activeIndex.includes(index) ?
                    "accordion__menu--active" :
                    "accordion__menu--inactive"
                }
              >
                <Accordion.Title
                  active={activeIndex.includes(index)}
                  index={index}
                  onClick={ (e, titleProps) => handleClick(e, titleProps, parentDir)
                  }
                >
                  <Grid>
                    {" "}
                    {/* onClick={(e) => getChildDirectory(parentDir._id, parentDir.name, parentDir.path)} */}
                    <Grid.Column width={13} className={`word-wrap ${(activeIndex.includes(index) && activeDirectory === parentDir.name) ?"font-primary":""}`}>
                      <Icon
                        name={
                          activeIndex.includes(index) ? "caret down" : "caret right"
                        }
                      />
                      {/* {parentDir.name} */}

                      {activeIndex.includes(index) ? <b>{parentDir.name}</b> : parentDir.name}
                    </Grid.Column>
                    {activeIndex.includes(index) || directoryEligibleStatus === 403 ? null : (
                      isAdminRoot || directoryPerm.some(dir => dir.path === parentDir.path) ?
                        (<Grid.Column width={3}>
                          <Dropdown
                            icon="ellipsis horizontal"
                            className="dropdown__menu--etc"
                          >
                            <Dropdown.Menu>
                              {/* dropdown-item edit */}
                              <CreateAndEditModal
                                type="dropdown__item--edit"
                                role="parent"
                                id={parentDir._id}
                                name={parentDir.name}
                                properties={parentDir ? parentDir.properties : ""}
                                header="UPDATE DIRECTORY"
                                btnLeft="CLOSE"
                                btnRight="UPDATE"
                                status={status} // componentWillReceiveProps
                                handleSubmitModal={handleUpdateItem}
                              />
                              {/* dropdown-item delete */}
                              <DeleteModal
                                triggerType="dropdown__item--delete"
                                id={parentDir._id}
                                name={parentDir.name}
                                descType={"directory"}
                                role="parent"
                                dir={parentDir.path}
                                handleDeleteItem={handleDeleteItem}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Grid.Column>)
                      : null
                    ) /* column width={3} */}
                  </Grid>
                </Accordion.Title>

                <Accordion.Content active={activeIndex.includes(index)}>
                  <ChildAccordion
                    activeIndex={index}
                    // get parent directory
                    parentDir={parentDir}
                    getParentDirectory={getParentDirectory} // for get parent by click child
                    childDirectory={childDirectory} // for render child dir.
                    status={status} // componentWillReceiveProps
                    // create/update/delete modaL
                    handleCreateItem={handleCreateItem}
                    handleUpdateItem={handleUpdateItem}
                    handleDeleteItem={handleDeleteItem}
                    directoryEligibleStatus={directoryEligibleStatus}
                    directoryPerm={directoryPerm}
                    isAdminRoot={isAdminRoot}
                    activeDirectory={activeDirectory}
                  />

                  {/* btn-icon plus */}
                  {directoryEligibleStatus === 403 ? null : (
                    isAdminRoot || directoryPerm.some(dir => dir.path === parentDir.path) ?
                      <CreateAndEditModal
                        id={parentDir._id}
                        role="child"
                        type="btnlong__icon--plus"
                        header="CREATE DIRECTORY"
                        btnLeft="CANCEL"
                        btnRight="CREATE"
                        status={status} // componentWillReceiveProps
                        handleSubmitModal={handleCreateItem}
                      />
                    : null)
                  }
                </Accordion.Content>
              </div>
            ))}
          </Accordion>
        )}
      </>
    );
  }
}
