import React from "react";

const SVG = ({ className = "", viewBox = "0 0 553 553" }) => (
  <svg
    width="18.5"
    height="18.5"
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="188.356"
      cy="276.5"
      r="61.3795"
      stroke="white"
      strokeWidth="21"
    />
    <path
      d="M251.842 275.975H430.229"
      stroke="white"
      strokeWidth="28"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M381.958 305.357H437.573V337.886H397.958C389.121 337.886 381.958 330.723 381.958 321.886V305.357Z"
      fill="white"
    />
    <circle cx="276.5" cy="276.5" r="266.5" stroke="white" strokeWidth="20" />
  </svg>
);

export default SVG;
