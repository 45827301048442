import { accountPageConstants } from "../../_constants/pages/accountPage.constants";

export const accountPageActions = {
  updatingTable,
  updateTableFinish,
  loadingTable,
  updateEligible
};

function loadingTable() {
  return (dispatch) => {
    dispatch(loading());
  };

  function loading() {
    return {
      type: accountPageConstants.LOADING_TABLE
    };
  }
}

function updatingTable() {
  return (dispatch) => {
    dispatch(update());
  };

  function update() {
    return {
      type: accountPageConstants.UPDATING_TABLE
    };
  }
}

function updateTableFinish() {
  return (dispatch) => {
    dispatch(updateFinish());
  };

  function updateFinish() {
    return {
      type: accountPageConstants.UPDATE_TABLE_COMPLETED
    };
  }
}

function updateEligible(isEligible) {
  return (dispatch) => {
    dispatch(setEligible(isEligible));
  };

  function setEligible(eligible) {
    return {
      type: accountPageConstants.SET_ELIGIBLE,
      eligible
    };
  }
}
