import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Modal,
  Segment,
  Popup
} from "semantic-ui-react";
import { connect } from "react-redux";

import handleLoading from "../Loader/Loader";
import DeviceAttributeComponent from "../../pages/directory/Component/ManageAttributeComponent";

class AttributeModal extends Component {
  constructor(props) {
    super(props);
    // check json type string or object
    let valueVal;
    if (props.typeEdit === "json") {
      if (typeof props.valueEdit !== "string") {
        valueVal = JSON.stringify(props.valueEdit);
      } else {
        valueVal = JSON.stringify(JSON.parse(props.valueEdit));
      }
    } else {
      valueVal = props.valueEdit
    }
    this.state = {
      modalOpen: false,
      attribute: [{ key: "", value: "" }],
      keyVal: props.triggerType !== "add" ? props.keyEdit : "",
      valueVal: props.triggerType !== "add" ? valueVal : "",
      lastTextVal: props.triggerType !== "add" && props.typeEdit !== "date" ? valueVal : "",
      lastDateVal: props.triggerType !== "add" && props.typeEdit === "date" ? props.valueEdit : new Date(),
      scope: props.triggerType !== "add" ? props.scope : "server",
      scopeOption: [
        { key: "server", text: "server", value: "server" },
        { key: "shared", text: "shared", value: "shared" }
      ],
      type: props.triggerType !== "add" ? props.typeEdit : "text",
      typeOption: [
        { key: "text", text: "text", value: "text" },
        { key: "json", text: "json", value: "json" },
        { key: "date", text: "date", value: "date" }
      ]
    };
    this.initialState = { ...this.state }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.postAttributeStatus === 200) {
        this.setState({
            modalOpen: false,
            attribute: [{ key: "", value: "" }]
        });
    }
}

  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      attribute: [{ key: "", value: "" }]
    });
    this.setState(this.initialState);
  };

  handleChangeScope = (e, { value }) => {
    this.setState({
      scope: value
    });
  };

  handleChangeType = (e, { value }) => {
    this.setState({
      type: value,
      valueVal: value !== "date"? (value === "json" ? this.returnIfJsonValue(this.state.lastTextVal) : this.state.lastTextVal) : this.state.lastDateVal
    });
  };

  returnIfJsonValue = (value) => {
    if(this.hasJsonStructure(value) || typeof value === "object") return value;
    else return JSON.stringify(value)
  }

  hasJsonStructure = (str) => {
    if (typeof str !== 'string') return false;
    try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]'
            || type === '[object Array]';
    } catch (err) {
        return false;
    }
}

  handleChangeInput = (e) => {
    const { type } = this.state;
    let input = "";
    if(this.state.type === "text") {
      input = e.target.value;
    } else if(this.state.type === "json") {
      // input = JSON.stringify(JSON.parse(e));
      input = e;
    } else{
      input = e;
    }
    this.setState({
      valueVal: input
    });
    if(type !== "date") {
      this.setState({ lastTextVal: this.state.type === "json"? JSON.stringify(JSON.parse(input)) : input });
    } else{
      this.setState({ lastDateVal: input });
    }
    // this.setState((prevState) => ({
    //   attribute: prevState.attribute.map((item, i) => {
    //     return index === i ? { ...item, [name]: value } : item;
    //   })
    // }));
  };
  handleChangeKey = (e, { value }) => {
    this.setState({
      keyVal: value
    });
  }

  getInput = () => {
    let { keyVal, valueVal, type } = this.state;
    const val = type !== "json"? valueVal : JSON.parse(valueVal)
    return [{ key: keyVal, value: val }];
  }

  // increaseAttribute = () => {
  //   const newAttribute = { key: "", value: "" };

  //   this.setState((prevState) => ({
  //     attribute: [...prevState.attribute, newAttribute]
  //   }));
  // };

  // decreaseAttribute = (index) => {
  //   this.setState((prevState) => ({
  //     attribute: prevState.attribute.filter((item, key) => key !== index)
  //   }));
  // };

  handleTriggerType = (triggerType) => {
    switch (triggerType) {
      case "add":
        return (
          <Popup
            trigger={
              <Button
                icon
                className="btn--icon--border main"
                //onClick={this.handleOpenModal}
                onMouseDown={this.handleOpenModal}
              >
                <Icon name="plus"/>
              </Button>
            }
            content="Add attribute"
            mouseLeaveDelay={0}
            basic
          />
        );
      case "edit":
        return (
          <Dropdown.Item onClick={this.handleOpenModal}>EDIT</Dropdown.Item>
        );

      case "view":
        return (
          <Dropdown.Item onClick={this.handleOpenModal}>VIEW</Dropdown.Item>
        );

      default:
        return null;
    }
  }

  render() {
    const {
      handleCreateAttribute,
      triggerType,
      loading
    } = this.props;
    const { modalOpen, scope, scopeOption, attribute, type, typeOption, keyVal, valueVal } = this.state;

    return (
      <Modal
        className="modal--width80p--withoutpadding"
        trigger={this.handleTriggerType(triggerType)}
        open={modalOpen}
        onClose={this.handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
        onClick={e => e.stopPropagation()}
      >

        {handleLoading(loading)}

        <Modal.Content>
          <Grid columns="equal">
            <Grid.Column width={5} textAlign="center">
              <Image
                className="center--vertical"
                src="./images/img-relation-modal.svg"
              />
            </Grid.Column>

            <Grid.Column width={11}>
              <Segment className="segment--center height75p" basic>
                <Header className="font--title1">{ triggerType === "add"? "CREATE" : triggerType === "edit" ? "EDIT" : "VIEW" } ATTRIBUTE</Header>
                <br />
                <Form>
                  <Grid columns="equal">
                    <Grid.Row>
                      <DeviceAttributeComponent
                          attribute={attribute}
                          decreaseAttribute={this.decreaseAttribute}
                          handleChangeInput={this.handleChangeInput}
                          handleChangeKey={this.handleChangeKey}
                          scopeOption={scopeOption}
                          handleChangeScope={this.handleChangeScope}
                          scope={scope}
                          typeOption={typeOption}
                          handleChangeType={this.handleChangeType}
                          type={type}
                          keyVal={keyVal}
                          valueVal={valueVal}
                          triggerType={triggerType}
                        />
                    </Grid.Row>
                    {/* <div className="scrollbar--attribute">
                      <DeviceAttributeComponent
                        attribute={attribute}
                        decreaseAttribute={this.decreaseAttribute}
                        handleChangeInput={this.handleChangeInput}
                      />

                      <Grid.Row>
                        <Grid.Column>
                          <Button className="btn--add" onClick={this.increaseAttribute}>
                            ADD
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </div> */}
                  </Grid>
                </Form>
              </Segment>

              <Segment basic floated="right" style={{width: "100%"}}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="4" className="col8__btn--left">
                      <Button
                        fluid
                        className="btn--secondary"
                        onClick={this.handleCloseModal}
                      >
                        { triggerType !== "view"? "CANCEL" : "CLOSE" }
                      </Button>
                    </Grid.Column>

                    <Grid.Column width="4" className="col8__btn--left">
                    {triggerType !== "view" && <Button
                        fluid
                        className="btn--primary"
                        onClick={() => {handleCreateAttribute(scope, this.getInput() , triggerType); this.handleCloseModal()}}
                        >
                        { triggerType === "add"? "CREATE" : "UPDATE" }
                      </Button>
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

AttributeModal.propTypes = {
  handleCreateAttribute: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
    loading: state.device.creating,
    postAttributeStatus: state.device.postAttributeStatus
});

export default connect(
  mapStateToProps,
  null
)(AttributeModal);
