import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import "semantic-ui-css/semantic.min.css";
import "./assets/scss/style.scss";

// import { store } from './_helpers';
import configureStore from "./_helpers/store";
import { Provider } from "react-redux";

const MyApp = () => (
  <Provider store={configureStore()}>
    <App />
  </Provider>
);

ReactDOM.render(<MyApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
