import { directoryConstants } from "../_constants";
import { alertActions } from "./";
import axios from "axios";

export const directoryActions = {
  clearChildDirectory,
  getDirectory,
  getRootDirectory,
  getParentDirectory,
  getChildDirectory,
  postDirectory,
  patchDirectory,
  delDirectory,
  getDirectoryEligible,
  getDirectoryDropdown
};

const directoryPath = "/api/directory";

function clearChildDirectory() {
  return { type: directoryConstants.CLEAR_CHILD_DIR };
}

function getDirectory(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { params: payload };

    return axios.get(`${directoryPath}/directory`, requestOptions)
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        dispatch(failure(error));

        // const message = error.response.data.code === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: directoryConstants.GET_DIRECTORY_LIST_REQUEST };
  }

  function success(response) {
    return { type: directoryConstants.GET_DIRECTORY_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: directoryConstants.GET_DIRECTORY_LIST_FAILURE, error };
  }
}

function getRootDirectory() {
  return (dispatch) => {
    dispatch(request());

    return axios.get(`${directoryPath}/directory?path=/`)
      .then((rootDirectory) => {
        localStorage.setItem("rootDirectory", JSON.stringify(rootDirectory.data.data[0]));

        const parentDirectory = axios.get(`${directoryPath}/directory`);

        parentDirectory
          .then((response) => {
            response.data.data = response.data.data.filter((dir) => dir.path.split('/').length <= 2);
            dispatch(success(response))
          })
          .catch((error) => {
            dispatch(alertActions.error(error.response.data.message ? error.response.data.message : "Something went wrong!", "Error!"));
            dispatch(alertActions.clear());
          });
      })
      .catch((error) => {
        dispatch(failure(error));

        // const message = error.response.data.code === 403 ? null : error.response.data.message;

        dispatch(alertActions.error(error.response.data ? error.response.data.message : "Something went wrong!", "Error!"));
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: directoryConstants.GET_DIRECTORY_REQUEST };
  }

  function success(response) {
    return { type: directoryConstants.GET_DIRECTORY_SUCCESS, response };
  }

  function failure(error) {
    return { type: directoryConstants.GET_DIRECTORY_FAILURE, error };
  }
}

function getParentDirectory(id, name, activeDirPath) {
  return (dispatch) => {
    dispatch(request());

    const getParentDirectoryPath = name ? `${directoryPath}/directory?name=${name}` : `${directoryPath}/directory`;

    return axios.get(getParentDirectoryPath)
      .then((response) => {
        response.data.data = response.data.data.filter((dir) => dir.path.split('/').length <= 2);
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.response.data.message ? error.response.data.message : "Something went wrong!", "Error!"));
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: directoryConstants.GET_DIRECTORY_REQUEST,
      id,
      name,
      activeDirPath
    };
  }

  function success(response) {
    return { type: directoryConstants.GET_DIRECTORY_SUCCESS, response };
  }

  function failure(error) {
    return { type: directoryConstants.GET_DIRECTORY_FAILURE, error };
  }
}

function getChildDirectory(id, name, activeDirPath) {
  return (dispatch) => {
    dispatch(request());

    const getParentDirectoryPath = name ? `${directoryPath}/directory?name=${name}` : `${directoryPath}/directory`;

    return axios.get(getParentDirectoryPath)
      .then((response) => {
        response.data.data = response.data.data.filter((dir) => dir.path.split('/').length === 3 && dir.path.includes(activeDirPath[0].path));
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.response.data.message ? error.response.data.message : "Something went wrong!", "Error!"));
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: directoryConstants.GET_CHILD_DIRECTORY_REQUEST, id, activeDirPath };
  }

  function success(response) {
    return { type: directoryConstants.GET_CHILD_DIRECTORY_SUCCESS, response };
  }

  function failure(error) {
    return { type: directoryConstants.GET_CHILD_DIRECTORY_FAILURE, error };
  }
}

function postDirectory(payload, role) {
  return (dispatch) => {
    dispatch(request());

    return axios.post(`${directoryPath}/directory`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Create directory is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.response.data.message ? error.response.data.message : "Something went wrong!", "Error!"));
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: directoryConstants.POST_DIRECTORY_REQUEST, role };
  }

  function success(response) {
    return { type: directoryConstants.POST_DIRECTORY_SUCCESS, response };
  }

  function failure(error) {
    return { type: directoryConstants.POST_DIRECTORY_FAILURE, error };
  }
}

function patchDirectory(id, payload, role) {
  return (dispatch) => {
    dispatch(request());

    return axios.patch(`${directoryPath}/directory/${id}`, payload)
      .then((response) => {
        const rootDirectory = JSON.parse(localStorage.getItem("rootDirectory"));

        if (rootDirectory._id === id) { localStorage.setItem("rootDirectory", JSON.stringify(response.data)); }

        dispatch(success(response));
        dispatch(alertActions.success("Update directory is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.response.data.message ? error.response.data.message : "Something went wrong!", "Error!"));
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: directoryConstants.PATCH_DIRECTORY_REQUEST,
      id,
      payload,
      role
    };
  }

  function success(response) {
    return { type: directoryConstants.PATCH_DIRECTORY_SUCCESS, response };
  }

  function failure(error) {
    return { type: directoryConstants.PATCH_DIRECTORY_FAILURE, error };
  }
}

function delDirectory(id, role) {
  return (dispatch) => {
    dispatch(request());

    return axios.delete(`${directoryPath}/directory/${id}`)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Delete directory is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error ? error.response.data.message : "Something went wrong!", "Error!"));
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: directoryConstants.DEL_DIRECTORY_REQUEST,
      id,
      role
    };
  }

  function success(response) {
    return { type: directoryConstants.DEL_DIRECTORY_SUCCESS, response };
  }

  function failure(error) {
    return { type: directoryConstants.DEL_DIRECTORY_FAILURE, error };
  }
}

function getDirectoryEligible(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { params: payload };

    return axios.get(`${directoryPath}/directory/eligible`, requestOptions)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.status === 403 ? null : error.response.data.message;

        dispatch(alertActions.error(error ? message : "Something went wrong!", "Error!"));
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: directoryConstants.GET_DIRECTORY_ELIGIBLE_REQUEST, payload
    };
  }

  function success(response) {
    return { type: directoryConstants.GET_DIRECTORY_ELIGIBLE_SUCCESS, response };
  }

  function failure(error) {
    return { type: directoryConstants.GET_DIRECTORY_ELIGIBLE_FAILURE, error };
  }
}

function getDirectoryDropdown() {
  return (dispatch) => {
    dispatch(request());

    // const requestOptions = { params: payload };

    return axios.get(`${directoryPath}/directory/dropdown`)
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        dispatch(failure(error));

        // const message = error.response.data.code === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: directoryConstants.GET_DIRECTORY_DROPDOWN_REQUEST };
  }

  function success(response) {
    return { type: directoryConstants.GET_DIRECTORY_DROPDOWN_SUCCESS, response };
  }

  function failure(error) {
    return { type: directoryConstants.GET_DIRECTORY_DROPDOWN_FAILURE, error };
  }
}
