import React from "react";

const SVG = ({
  className = "",
  viewBox = "0 0 32 32",
  width = "32",
  height = "32",
  x = "10",
  y = "8"
}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" />
    <path d="M22.4481 16.8812C22.4856 16.6 22.5044 16.3094 22.5044 16C22.5044 15.7 22.4856 15.4 22.4387 15.1187L24.3419 13.6375C24.5106 13.5062 24.5575 13.2531 24.4544 13.0656L22.6544 9.95312C22.5419 9.74687 22.3075 9.68125 22.1013 9.74687L19.8606 10.6469C19.3919 10.2906 18.895 9.99062 18.3419 9.76562L18.0044 7.38437C17.9669 7.15937 17.7794 7 17.5544 7H13.9544C13.7294 7 13.5512 7.15937 13.5137 7.38437L13.1762 9.76562C12.6231 9.99062 12.1169 10.3 11.6575 10.6469L9.41687 9.74687C9.21062 9.67187 8.97625 9.74687 8.86375 9.95312L7.07313 13.0656C6.96063 13.2625 6.99813 13.5062 7.18563 13.6375L9.08875 15.1187C9.04187 15.4 9.00438 15.7094 9.00438 16C9.00438 16.2906 9.02313 16.6 9.07 16.8812L7.16688 18.3625C6.99813 18.4937 6.95125 18.7469 7.05438 18.9344L8.85438 22.0469C8.96688 22.2531 9.20125 22.3187 9.4075 22.2531L11.6481 21.3531C12.1169 21.7094 12.6137 22.0094 13.1669 22.2344L13.5044 24.6156C13.5512 24.8406 13.7294 25 13.9544 25H17.5544C17.7794 25 17.9669 24.8406 17.995 24.6156L18.3325 22.2344C18.8856 22.0094 19.3919 21.7094 19.8513 21.3531L22.0919 22.2531C22.2981 22.3281 22.5325 22.2531 22.645 22.0469L24.445 18.9344C24.5575 18.7281 24.5106 18.4937 24.3325 18.3625L22.4481 16.8812ZM15.7544 19.375C13.8981 19.375 12.3794 17.8563 12.3794 16C12.3794 14.1438 13.8981 12.625 15.7544 12.625C17.6106 12.625 19.1294 14.1438 19.1294 16C19.1294 17.8563 17.6106 19.375 15.7544 19.375Z" />
  </svg>
);

export default SVG;
