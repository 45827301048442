import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Feed, Form, IconGroup, Icon } from "semantic-ui-react";
import IconCustom from "../../assets/icons";
import { RoleColor } from "../../_helpers";
import WarningModal from "../Modal/WarningModal"
import { RoleLabel } from "../../_helpers";

const FormGroupRole = (props) => {
  return (
    <Form.Group className={`${props.renderIsNotForm ? 'div__inline-warp div__inline-warp--top-bottom' : ''}`} inline>
      <Form.Field width={16} className="role-card">
        <Card className={props.renderIsNotForm ? 'card__acc-role' : ''}>
          <Card.Content>
            <Feed>
              <Feed.Event>
                <IconGroup>
                  <IconCustom
                    className={"svg-icon__user"}
                    name={"user"}
                    fillColor={RoleColor(props.title)} />
                </IconGroup>
                <Feed.Content>
                  <Feed.Summary>{RoleLabel(props.title)}</Feed.Summary>
                  {/* <Feed.Extra>{props.desc}</Feed.Extra> */}
                </Feed.Content>
                {
                  !props.isDisable ?
                  (props.title !== "admin" && props.title !== "admin_account" ?
                  <Button
                    icon
                    size="mini"
                    onClick={props.onClickRemove}
                    value={props.value}
                  >
                    <Icon name='trash alternate' />
                  </Button> :
                  <WarningModal
                    triggerType={"tiny"}
                    descType={"role"}
                    handleDeleteItem={props.onClickRemove}
                    item={props}
                  />) : null
                }
              </Feed.Event>
            </Feed>
          </Card.Content>
        </Card>
      </Form.Field>
    </Form.Group>
  );
};

FormGroupRole.propTypes = {
  renderIsNotForm: PropTypes.bool,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClickRemove: PropTypes.func
};

export default FormGroupRole;
