import React from "react";
import { Header, Segment } from "semantic-ui-react";

import { VehicleRow } from "./VehicleRow.jsx";
import { VehicleTableHeader } from "./VehicleTableHeader.jsx";

export const VehicleTable = (props) => {
  const renderEmptyTable = (content) => {
    return (
      <Segment textAlign="center" className="segment--center" basic >
      <Header className="header--emptyArr">{content}</Header>
    </Segment>
    );
  };

  if (!props.vehicles) {
    if (props.deviceStatus === 403) {
      return props.renderEmptyContentComponent('not-permission.jpg', 'You do not have permission to access', null, 'grey');
    }

    return null;
  }

  const vehicleRows = props.vehicles.map((vehicle, index) => {
    return (
      <VehicleRow
        key={index}
        rowIndex={index + 1}
        vehicle={vehicle}
        checkedListAll={props.checkedListAll}
        isShowCheckbox={props.isShowCheckbox}
        handleSwitchCheckbox={props.handleSwitchCheckbox}
        handleCheckboxClick={props.handleCheckboxClick}
        checkedListExportAll={props.checkedListExportAll}
        isShowExportCheckbox={props.isShowExportCheckbox}
        handleSwitchExportCheckbox={props.handleSwitchExportCheckbox}
        handleExportCheckboxClick={props.handleExportCheckboxClick}
        handleSelectedRow={props.handleSelectedRow}
        user={props.user}
        type={props.tabName? props.tabName : ""}
        isUpdateAccount={props.isUpdateAccount}
        isUpdateCustomAccount={props.isUpdateCustomAccount}
        isDeleteAccount={props.isDeleteAccount}
        isUpdateDevice={props.isUpdateDevice}
        isDeleteDevice={props.isDeleteDevice}
        isUpdateCustomDevice={props.isUpdateCustomDevice}
        isAdminRoot={props.isAdminRoot}
      />
    );
  });

  return (
    <React.Fragment>
      <div className="fixed-table-container">
        <div className="header-background"> </div>
        <div className="fixed-table-container-inner">
          <table cellSpacing="0">

            <VehicleTableHeader vehiclesHeader={props.vehiclesHeader} />

            {props.vehicles.length > 0 ? (
              <tbody>
                {props.user === true ?
                  props.vehicles
                    .filter((item) => {
                      const name = item.name.toLowerCase();

                      return (
                        name.indexOf(props.filterText) >= 0 ||
                        item.email.indexOf(props.filterText) >= 0
                      );
                    })
                    .map((vehicle, index) => {
                      return (
                        <VehicleRow
                          key={index}
                          rowIndex={index + 1}
                          vehicle={vehicle}
                          checkedListAll={props.checkedListAll}
                          isShowCheckbox={props.isShowCheckbox}
                          checkedListExportAll={props.checkedListExportAll}
                          isShowExportCheckbox={props.isShowExportCheckbox}
                          handleSwitchCheckbox={props.handleSwitchCheckbox}
                          handleCheckboxClick={props.handleCheckboxClick}
                          handleSwitchExportCheckbox={props.handleSwitchExportCheckbox}
                          handleExportCheckboxClick={props.handleExportCheckboxClick}
                          handleSelectedRow={props.handleSelectedRow}
                          user={props.user}
                          type={props.tabName? props.tabName : ""}
                          isUpdateAccount={props.isUpdateAccount}
                          isUpdateCustomAccount={props.isUpdateCustomAccount}
                          isDeleteAccount={props.isDeleteAccount}
                          isUpdateDevice={props.isUpdateDevice}
                          isDeleteDevice={props.isDeleteDevice}
                          isUpdateCustomDevice={props.isUpdateCustomDevice}
                          isAdminRoot={props.isAdminRoot}
                        />
                      );
                    }) :
                  props.tabName === "device" ?
                    props.vehicles
                    .filter((item) => {
                      return (
                        item.name.toLowerCase().indexOf(props.filterText) >= 0 ||
                        item.type.toLowerCase().indexOf(props.filterText) >= 0
                      );
                    })
                    .map((vehicle, index) => {
                      return (
                        <VehicleRow
                          key={index}
                          rowIndex={index + 1}
                          vehicle={vehicle}
                          checkedListAll={props.checkedListAll}
                          isShowCheckbox={props.isShowCheckbox}
                          handleSwitchCheckbox={props.handleSwitchCheckbox}
                          handleCheckboxClick={props.handleCheckboxClick}
                          checkedListExportAll={props.checkedListExportAll}
                          isShowExportCheckbox={props.isShowExportCheckbox}
                          handleSwitchExportCheckbox={props.handleSwitchExportCheckbox}
                          handleExportCheckboxClick={props.handleExportCheckboxClick}
                          handleSelectedRow={props.handleSelectedRow}
                          handleDateTime={props.handleDateTime}
                          user={props.user}
                          type={props.tabName? props.tabName : ""}
                          isUpdateAccount={props.isUpdateAccount}
                          isUpdateCustomAccount={props.isUpdateCustomAccount}
                          isDeleteAccount={props.isDeleteAccount}
                          isUpdateDevice={props.isUpdateDevice}
                          isDeleteDevice={props.isDeleteDevice}
                          isUpdateCustomDevice={props.isUpdateCustomDevice}
                          isAdminRoot={props.isAdminRoot}
                        />
                      );
                    }) :
                    (
                      <React.Fragment>{vehicleRows}</React.Fragment>
                    )}
              </tbody>
            ) : null}
          </table>

          {props.vehicles.length > 0 ? null : renderEmptyTable(`No data, please create ${props.tabName} before.`)}

        </div>
      </div>
    </React.Fragment>
  );
};
