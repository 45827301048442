import React from "react";
import IconCustom from "../../assets/icons";
import { Button } from "semantic-ui-react";

export default class ExportButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
    const { id } = this.props;
        return (
            <>
            <Button
                icon
                className="btn--icon--row btn--icon--row--top col--export"
                onClick={() => this.props.handleExportItem(id)}
                >
                <IconCustom name={"export"} />
            </Button>
            </>
        )
    }
};
