/* eslint-disable complexity */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown, Grid, Header, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { assetActions, deviceActions, directoryActions, userActions, alertActions, authorizeActions } from "../../_actions/";
import ParentAccordion from "../../components/Accordion/ParentAccordion";
import SidebarPushable from "./Pushable/SidebarPushable";
import CreateAndEditModal from "../../components/Modal/CreateAndEditModal";
import UpdateModal from "../../components/Modal/UpdateModal";
import handleLoading from "../../components/Loader/Loader";
import { Notification } from "../../components/Notification/Notification";
import { accountPageActions } from "../../_actions/pages/accountPage.action";
import { InputSearchTable } from "../../components/Input/InputSearchTable";

// import { InputSearchTable } from "../../components/Input/InputSearchTable";
import DirectoryPath from "./DirectoryPath";

class DirectoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directoryPath: [],
      objDirectory: "",
      modalOpen: false,
      activeTabIndex: 0,
      searchValue: "",
      activeIndex: [0],
      alert: {
        type: "",
        message: "",
        title: ""
      },
      directoryPerm: [],
      directoryIsClicked: false,
      isAdminRoot: false,
      childDirectoryArray: [],
      isCreateDirectory: false,
      isUpdateDirectory: false,
      activeDirectory: "",
      isCreateAccount: false,
      isUpdateAccount: false,
      isDeleteAccount: false,
      isAssignAccount: false,
      isUnassignAccount: false,
      isCreateDevice: false,
      isUpdateDevice: false,
      isDeleteDevice: false,
      isUpdateCustomDevice: false,
      isUpdateCustomAccount: false
    };
  }

  componentDidMount() {
    const payload = { admin: "directory" };

    this.props.getDirectoryEligible(payload).then(() => {
      this.setState({ directoryPerm: this.props.directory.data.data });
      this.getRootDirectory();
    });

    const payload2 = { action: '*', subject: '*', directoryPath: '/' };
    this.props.getEligible(payload2).then((response) => {
      this.props.updateEligible(response.isEligible);
      if (response.isEligible) {
        this.setState({ isAdminRoot: true });
      }
    });
  }

  componentDidUpdate(prevState) {
    if (this.state.activeIndex !== prevState.activeIndex) {
      console.log("this.state.activeIndex");
      console.log(this.state.activeIndex);
    }
  }

  // handle change tab menu
  handleTabChange = (e, { activeIndex }) => {
    const directoryPath = this.state.objDirectory ? this.state.objDirectory.path : "/";

    this.setState({ activeTabIndex: activeIndex }, () => this.getTabListbyDirectoryPath(directoryPath));
  };

  // search directory name
  handleSearchChange = (e, { value }) => {
    const rootDirectory = JSON.parse(localStorage.getItem("rootDirectory"));
    const { objDirectory, searchValue } = this.state;
    const parentDirId = objDirectory ? objDirectory._id : rootDirectory._id;
    const parentDir = objDirectory ? objDirectory : rootDirectory;

    this.setState({ searchValue: value }, () => {
      this.setState({ activeIndex: [0], childDirectoryArray: [] },
      () => { this.props.getSearchDirectory(parentDirId, this.state.searchValue).then(() => {
        this.getChildDirectory(this.props.parentDirectory[0], this.state.searchValue, 0);
      })});
    });
  };

  // open the sub directory
  handleClick = (e, titleProps, parentDir) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    let newActiveIndex;
    const newIndex = index;
    this.setState({ directoryIsClicked: true });
    let tempDirectoryPath;

    if (activeIndex.includes(index)) {
      // inactive accordion
      const { directoryPath } = this.state;

      // for inactive accordition to remove lasted of elements
      // render in breadcrumb
      directoryPath.pop();

      // if (directoryPath.length > 0) {
      //   this.setState(
      //     { objDirectory: directoryPath.slice(-1)[0], activeTabIndex: 0, directoryIsClicked: false } // lasted obj in array[directoryPath]
      //   );
      // } else {
      //   this.setState({ objDirectory: JSON.parse(localStorage.getItem("rootDirectory")), activeTabIndex: 0, directoryIsClicked: false }, () => this.getRootDirectory());
      // }
      this.setState({ objDirectory: JSON.parse(localStorage.getItem("rootDirectory")), activeTabIndex: 0, directoryIsClicked: false, activeDirectory: "" });
      newActiveIndex = activeIndex.filter((acIndex) => acIndex !== index);
      tempDirectoryPath = JSON.parse(localStorage.getItem("rootDirectory")).path;
    } else {
      // active accordion
      // when accordion is active this fuc get childdirectory by parent id
      this.setState({ objDirectory: parentDir, activeTabIndex: 0, directoryIsClicked: false, activeDirectory: parentDir.name });
      this.getChildDirectory(parentDir, this.state.searchValue, index);
      newActiveIndex = activeIndex;
      newActiveIndex.push(index);
      tempDirectoryPath = parentDir.path;
    }

    this.setState({ activeIndex: newActiveIndex }, () => this.getTabListbyDirectoryPath(this.state.objDirectory.path));

    this.getPermAll(tempDirectoryPath);
  };

  getPermAll = (tempDirectoryPath) => {
    const payloadCreateDirectory = { action: 'create', subject: 'directory', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadCreateDirectory).then((response) => {
      this.setState({ isCreateDirectory: response.isEligible });
    });

    const payloadUpdateDirectory = { action: 'update', subject: 'directory', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadUpdateDirectory).then((response) => {
      this.setState({ isUpdateDirectory: response.isEligible });
    });

    const payloadCreateAccount = { action: 'create', subject: 'account', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadCreateAccount).then((response) => {
      console.log(response);
      this.setState({ isCreateAccount: response.isEligible });
    });

    const payloadUpdateAccount = { action: 'update', subject: 'account', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadUpdateAccount).then((response) => {
      console.log(response);
      this.setState({ isUpdateAccount: response.isEligible });
    });

    const payloadUpdateCustomAccount = { action: 'updateCustom', subject: 'account', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadUpdateCustomAccount).then((response) => {
      console.log(response);
      this.setState({ isUpdateCustomAccount: response.isEligible });
    });

    const payloadDeleteAccount = { action: 'delete', subject: 'account', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadDeleteAccount).then((response) => {
      console.log(response);
      this.setState({ isDeleteAccount: response.isEligible });
    });

    const payloadAssignAuthorize = { action: 'assign', subject: 'authorize', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadAssignAuthorize).then((response) => {
      console.log(response);
      this.setState({ isAssignAccount: response.isEligible });
    });

    const payloadUnassignAuthorize = { action: 'unassign', subject: 'authorize', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadUnassignAuthorize).then((response) => {
      console.log(response);
      this.setState({ isUnassignAccount: response.isEligible });
    });

    const payloadCreateDevice = { action: 'create', subject: 'device', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadCreateDevice).then((response) => {
      console.log(response);
      this.setState({ isCreateDevice: response.isEligible });
    });

    const payloadUpdateDevice = { action: 'update', subject: 'device', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadUpdateDevice).then((response) => {
      console.log(response);
      this.setState({ isUpdateDevice: response.isEligible });
    });

    const payloadUpdateCustomDevice = { action: 'updateCustom', subject: 'device', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadUpdateCustomDevice).then((response) => {
      console.log(response);
      this.setState({ isUpdateCustomDevice: response.isEligible });
    });

    const payloadDeleteDevice = { action: 'delete', subject: 'device', directoryPath: tempDirectoryPath };
    this.props.getEligible(payloadDeleteDevice).then((response) => {
      console.log(response);
      this.setState({ isDeleteDevice: response.isEligible });
    });
  };

  // back to directory path with icon back
  handleBackDirectory = () => {
    const { directoryPath, activeIndex } = this.state;

    directoryPath.pop();

    console.log("handleBackDirectory > directoryPath")
    console.log(directoryPath)
    if (directoryPath.length > 0) {
      this.setState({ objDirectory: directoryPath.slice(-1)[0], activeTabIndex: 0 }, () => this.getParentDirectory(this.state.objDirectory));
      this.getChildDirectory(directoryPath[0], this.state.searchValue, activeIndex[activeIndex.length - 1]);
    } else {
      this.setState({ activeIndex: [0], objDirectory: JSON.parse(localStorage.getItem("rootDirectory")), activeTabIndex: 0 }, () => this.getRootDirectory());
    }
  };

  // back to root path (in directory path)
  getRootDirectory = () => {
    this.props.getRootDirectory();
    this.getTabListbyDirectoryPath("/");
    this.getPermAll("/");
    this.setState({ activeIndex: [], childDirectoryArray: [], directoryPath: [], objDirectory: "", searchValue: "", activeTabIndex: 0, activeDirectory: "" });
  };

  // back to parent path (in directory path)
  getParentDirectory = (selfDir, childDir, index) => {
    const thisDir = childDir? childDir : selfDir;
    const { directoryPath } = this.state;
    const activeDirIndex = directoryPath.findIndex((dir) => dir._id === selfDir._id);
    const directoryPathFilter = directoryPath.filter((dir) => {
      return dir.parent !== thisDir.parent;
    });

    // to remove all elements after index+1(activeDirIndex)
    // directoryPath.splice(activeDirIndex + 1);
    // this.setState({ objDirectory: selfDir, searchValue: "", activeIndex: index, activeTabIndex: 0 });
    // this.props.getParentDirectory(selfDir._id, "", directoryPath).then(() => {
    //   if (typeof childDir === 'undefined') {
    //     return this.getTabListbyDirectoryPath(selfDir.path);
    //   }
    //   return this.getChildDirectory(childDir, index);
    // });

    // this.setState({ objDirectory: thisDir, directoryPath: [...directoryPathFilter, thisDir], activeTabIndex: 0 },
    const tempDirectoryPath = childDir ? [selfDir, childDir] : [selfDir];
    this.setState({ objDirectory: thisDir, directoryPath: tempDirectoryPath, activeTabIndex: 0, activeDirectory: thisDir.name },
       () => this.getTabListbyDirectoryPath(thisDir.path)
    );

    this.getPermAll(thisDir.path);

  };

  getChildDirectory = (parentDir, name, index) => {
    const { directoryPath, activeIndex, childDirectoryArray } = this.state;
    const directoryPathFilter = directoryPath.filter((dir) => {
         return dir.parent !== parentDir.parent;
    });

    this.setState({ activeIndex: [...activeIndex, index], objDirectory: parentDir, directoryPath: [parentDir], activeTabIndex: 0, activeDirectory: parentDir.name }, () => {
      const payload = { admin: "directory" };

      this.props.getChildDirectory(parentDir._id, name, this.state.directoryPath).then(() => {
        this.props.getDirectoryEligible(payload);
        let tempChildDirectoryArray = childDirectoryArray;
        tempChildDirectoryArray[index] = this.props.childDirectory;
        this.setState({ childDirectoryArray: tempChildDirectoryArray });
      });
    });

    this.getPermAll(parentDir.path);

    this.getTabListbyDirectoryPath(parentDir.path);
  };

  // create directory
  handleCreateItem = (parent, name, properties, role) => {
    const { directoryPath, childDirectoryArray } = this.state;
    const activeIndex = this.props.parentDirectory.map(function(o) { return o._id; }).indexOf(parent);
    const childList =  childDirectoryArray[activeIndex];

    const obj = { parent, name, properties };
    const payload = Object.entries(obj).reduce((a, [k, v]) => { return v ? { ...a, [k]: v } : a; }, {});

    if (payload.properties) { payload.properties = { description: payload.properties }; }

    this.props.postDirectory(payload, role).then(() => {
      const payloadGet = { admin: "directory" };
      this.props.getDirectoryEligible(payloadGet).then(() => {
        this.setState({ directoryPerm: this.props.directory.data.data });
        if (directoryPath.length > 0) {
          let tempChildDirectoryArray = childDirectoryArray;
          tempChildDirectoryArray[activeIndex] = [...childList, this.props.childDirectory.pop()];
          this.setState({ childDirectoryArray: tempChildDirectoryArray });
        }
      });
    });
  };

  // update directoty
  handleUpdateItem = (id, name, properties, role) => {
    const { objDirectory, directoryPath, activeIndex, childDirectoryArray } = this.state;
    const obj = { name, properties };
    const payload = Object.entries(obj).reduce((a, [k, v]) => { return v ? { ...a, [k]: v } : a; }, {});

    if (payload.properties) { payload.properties = { description: payload.properties }; }
    if (objDirectory._id === id) { this.setState({ objDirectory: { ...payload, _id: id } }); }

    this.props.patchDirectory(id, payload, role).then(() => {
      if (directoryPath.length > 0) {
        let tempChildDirectoryArray = childDirectoryArray;
        tempChildDirectoryArray[activeIndex[activeIndex.length - 1]] = this.props.childDirectory;
        this.setState({ childDirectoryArray: tempChildDirectoryArray });
      }
    });
  };

  // delete directory
  handleDeleteItem = (id, role, dir) => {
    const { directoryPath, activeIndex, childDirectoryArray } = this.state;
    this.props.getDeviceListNoPage(dir).then(() => {
      if(this.props.deviceList?.length > 0) {
        this.setState({
          alert: {
            type: "error",
            message: "There are some devices in this directory",
            title: "Error!"
          }
        },() => {this.clearAlert()})
      } else {
        this.props.delDirectory(id, role).then(() => {
          if (directoryPath.length > 0) {
            let tempChildDirectoryArray = childDirectoryArray;
            tempChildDirectoryArray[activeIndex[activeIndex.length - 1]] = this.props.childDirectory;
            this.setState({ childDirectoryArray: tempChildDirectoryArray });
          }
        });
      }
    });
  };

  clearAlert = () => {
    this.setState({
      alert: {
        type: "",
        message: "",
        title: ""
      }
    })
  };

  // control render content in the tab menu content
  getTabListbyDirectoryPath = (directoryPath) => {
    const { activeTabIndex } = this.state;
    const dirPath = directoryPath ? directoryPath : "/";

    switch (activeTabIndex) {
      case 0: return this.props.getUserListNoPage(dirPath);
      case 1: return null;
      case 2: return this.props.getDeviceListNoPage(dirPath);
      case 3: return this.props.getAssetList(dirPath);
      default: return null;
    }
  };

  // render name of root directory
  renderNameOfActiveAccordion = (size) => {
    const rootDirectory = JSON.parse(localStorage.getItem("rootDirectory"));
    const { objDirectory } = this.state;
    if(size) {
      if (objDirectory) {
        return objDirectory.name.length > size ? `${objDirectory.name.substring(0, size)}...` : objDirectory.name;
      } else if (rootDirectory) {
        return rootDirectory.name.length > size ? `${rootDirectory.name.substring(0, size)}...` : rootDirectory.name;
      }
    } else {
      if (objDirectory) {
        return objDirectory.name;
      } else if (rootDirectory) {
        return rootDirectory.name;
      }
    }

    return "";
  }

  renderDescOfActiveAccordion = (size) => {
    const rootDirectory = JSON.parse(localStorage.getItem("rootDirectory"));
    const { objDirectory } = this.state;
    if(size) {
      if (objDirectory) {
        if(objDirectory.properties) {
          return objDirectory.properties.description.length > size ? `${objDirectory.properties.description.substring(0, size)}...` : objDirectory.properties.description;
        } else {
          return ""
        }
      } else if (rootDirectory && rootDirectory.properties) {
        return rootDirectory.properties.description.length > size ? `${rootDirectory.properties.description.substring(0, size)}...` : rootDirectory.properties.description;
      }
    } else {
      if (objDirectory) {
        return objDirectory.properties? objDirectory.properties.description : "";
      } else if (rootDirectory) {
        return rootDirectory.properties? rootDirectory.properties.description : "";
      }
    }

    return "";
  }

  render() {
    const { parentDirectory, childDirectory, status, loading, error, directoryEligibleStatus } = this.props;
    const { directoryPath, objDirectory, activeIndex, activeTabIndex, searchValue, directoryPerm, isAdminRoot, childDirectoryArray, activeDirectory,
            isCreateDirectory, isUpdateDirectory, isCreateAccount, isUpdateAccount, isDeleteAccount, isAssignAccount, isUnassignAccount,
            isCreateDevice, isUpdateDevice, isDeleteDevice, isUpdateCustomDevice, isUpdateCustomAccount } = this.state;
    const rootDirectory = JSON.parse(localStorage.getItem("rootDirectory"));
    console.log(directoryPath)
    console.log(objDirectory)

    return (
      <React.Fragment>
        <Header id="layout__row2" className="Header-page">Directory</Header>

        <DirectoryPath
          error={error}
          directoryPath={directoryPath}
          handleBackDirectory={this.handleBackDirectory}
          getRootDirectory={this.getRootDirectory}
          rootDirectory={rootDirectory}
          getParentDirectory={this.getParentDirectory}
          getChildDirectory={this.getChildDirectory}
          searchValue={searchValue}
          handleSearchChange={this.handleSearchChange}
          activeIndex={activeIndex}
        />

        <Grid>
          <Grid.Row>
            {/* ------------------------------- Directory -------------------------------*/}
            <Grid.Column width={4} className="dirCol__menu">

            {error && error.response.status === 403 ? null :
              <Menu borderless className="menu--noneborder">
                <Menu.Item position="left" className="menu--withoutpadding word-wrap">
                  {/* <Header as="h2" title={this.renderNameOfActiveAccordion()}>{this.renderNameOfActiveAccordion(27)}</Header> */}
                  <Header as="h2" title={this.renderNameOfActiveAccordion()}>{this.renderNameOfActiveAccordion()}</Header>
                </Menu.Item>

                {directoryEligibleStatus === 403 ? null :
                  (directoryPath.length === 0 && directoryPerm.some(dir => dir.path === '/')) || (isAdminRoot || (directoryPath.length !== 0 && directoryPerm.some(dir => dir.path === directoryPath[directoryPath.length - 1].path))) || isCreateDirectory || isUpdateDirectory ?
                    (<Menu.Item position="right" className="menu--withoutpadding">
                      <Dropdown
                        closeOnBlur
                        icon="ellipsis horizontal"
                        className="dropdown__menu--etc"
                      >
                        <Dropdown.Menu>
                          {directoryPath.length < 2 && isCreateDirectory &&
                            <CreateAndEditModal
                            type="dropdown__item--create"
                            id={objDirectory ? objDirectory._id : ""}
                            role={directoryPath.length === 0 ? "parent" : "child"}
                            header="CREATE DIRECTORY"
                            btnLeft="CANCEL"
                            btnRight="CREATE"
                            status={status}
                            handleSubmitModal={this.handleCreateItem}
                          />}

                          {objDirectory ? (
                            <React.Fragment>
                              <UpdateModal
                                type="dropdown__item--edit"
                                id={objDirectory ? objDirectory._id : ""}
                                role="parent"
                                name={objDirectory ? objDirectory.name : ""}
                                properties={objDirectory ? objDirectory.properties : ""}
                                header="UPDATE DIRECTORY" btnLeft="CLOSE" btnRight="UPDATE" status={status}
                                handleSubmitModal={this.handleUpdateItem}
                              />
                            </React.Fragment>
                          ) : (
                              <CreateAndEditModal
                                type="dropdown__item--edit"
                                id={rootDirectory ? rootDirectory._id : ""}
                                role="parent"
                                name={rootDirectory ? rootDirectory.name : ""}
                                properties={rootDirectory ? rootDirectory.properties : ""}
                                header="UPDATE DIRECTORY"
                                btnLeft="CLOSE"
                                btnRight="UPDATE"
                                status={status}
                                handleSubmitModal={this.handleUpdateItem}
                              />
                            )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Menu.Item>) : null
                }
              </Menu>
            }

              {<div>
                {parentDirectory && parentDirectory.length > 0 ? (
                  <div className="div__accordion">
                    <ParentAccordion
                      activeIndex={activeIndex}
                      handleClick={this.handleClick}
                      parentDirectory={parentDirectory}
                      getParentDirectory={this.getParentDirectory}
                      childDirectory={childDirectoryArray}
                      getChildDirectory={this.getChildDirectory}
                      handleCreateItem={this.handleCreateItem}
                      handleUpdateItem={this.handleUpdateItem}
                      handleDeleteItem={this.handleDeleteItem}
                      status={status}
                      loading={loading}
                      directoryEligibleStatus={directoryEligibleStatus}
                      directoryPerm={directoryPerm}
                      isAdminRoot={isAdminRoot}
                      activeDirectory={activeDirectory}
                    />
                  </div>
                ) : (
                    directoryEligibleStatus === 403 || directoryPath.length > 1 ? null :
                    <CreateAndEditModal
                      type="btnlong__icon--plus"
                      id={objDirectory ? objDirectory._id : ""}
                      role="parent"
                      header="CREATE DIRECTORY"
                      btnLeft="CANCEL"
                      btnRight="CREATE"
                      status={status}
                      handleSubmitModal={this.handleCreateItem}
                    />
                  )}
              </div>}
            </Grid.Column>

            {/* ------------------------------- content -------------------------------*/}
            <Grid.Column width={12}>
              <Grid>
              <Grid.Column width={16} verticalAlign='bottom' className="grid__description">
                    <Menu width={16} borderless className="menu--noneborder">
                      <Menu.Item position="left" className="menu--withoutpadding word-wrap word-wrap-long">
                        <span className="text-description" title={this.renderDescOfActiveAccordion()}>{this.renderDescOfActiveAccordion()}</span>
                      </Menu.Item>
                      <Menu.Item position="right">
                        <InputSearchTable searchValue={searchValue} handleSearchChange={this.handleSearchChange} />
                      </Menu.Item>
                    </Menu>
                  </Grid.Column>
                <Grid.Row>
                  <Grid.Column width={16} className="grid__directory-content">
                    <SidebarPushable
                      objDirectory={this.state.objDirectory ? this.state.objDirectory : rootDirectory}
                      activeTabIndex={activeTabIndex}
                      handleTabChange={this.handleTabChange}
                      directoryIsClicked={this.state.directoryIsClicked}
                      directoryEligibleStatus={directoryEligibleStatus}
                      isCreateAccount={isCreateAccount}
                      isUpdateAccount={isUpdateAccount}
                      isUpdateCustomAccount={isUpdateCustomAccount}
                      isDeleteAccount={isDeleteAccount}
                      isAssignAccount={isAssignAccount}
                      isUnassignAccount={isUnassignAccount}
                      isCreateDevice={isCreateDevice}
                      isUpdateDevice={isUpdateDevice}
                      isDeleteDevice={isDeleteDevice}
                      isUpdateCustomDevice={isUpdateCustomDevice}
                      isAdminRoot={isAdminRoot}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.state.alert.message && (
          <Notification
            type={this.state.alert.type}
            msg={this.state.alert.message}
            title={this.state.alert.title}
            callback={this.clearAlert}
            timeout={3000}
          />
        )}
      </React.Fragment>
    );
  }
}

DirectoryPage.propTypes = {
  parentDirectory: PropTypes.arrayOf(PropTypes.object),
  childDirectory: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  status: PropTypes.bool,
  deviceList: PropTypes.arrayOf(PropTypes.object),
  directory: PropTypes.arrayOf(PropTypes.object)
};

const mapDispatchToProps = (dispatch) => ({
  getRootDirectory: () => dispatch(directoryActions.getRootDirectory()),
  getParentDirectory: (id, name, activeDirPath) => dispatch(directoryActions.getParentDirectory(id, name, activeDirPath)),
  getChildDirectory: (id, name, activeDirPath) => dispatch(directoryActions.getChildDirectory(id, name, activeDirPath)),
  postDirectory: (payload, role) => dispatch(directoryActions.postDirectory(payload, role)),
  patchDirectory: (id, payload, role) => dispatch(directoryActions.patchDirectory(id, payload, role)),
  delDirectory: (id, role) => dispatch(directoryActions.delDirectory(id, role)),
  getDirectoryEligible: (payload) => dispatch(directoryActions.getDirectoryEligible(payload)),
  getDirectory: (payload) => dispatch(directoryActions.getDirectory(payload)),

  // search directory by name
  getSearchDirectory: (id, name) => dispatch(directoryActions.getParentDirectory(id, name)),
  clearChildDirectory: () => dispatch(directoryActions.clearChildDirectory()),

  // get item list
  getUserListNoPage: (directoryPath) => dispatch(userActions.getUserListNoPage(directoryPath)),
  getDeviceListNoPage: (directoryPath) => dispatch(deviceActions.getDeviceListNoPage(directoryPath)),
  getAssetList: (directoryPath) => dispatch(assetActions.getAssetList(directoryPath)),

  getEligible: (payload) => dispatch(authorizeActions.getEligible(payload)),
  updateEligible: (eligible) => dispatch(accountPageActions.updateEligible(eligible))
});

const mapStateToProps = (state) => ({
  parentDirectory: state.directory.parentDirectory,
  childDirectory: state.directory.childDirectory,
  status: state.directory.status,
  loading: state.directory.loading,
  error: state.directory.error,
  directoryEligibleStatus: state.directory.directoryEligibleStatus,
  deviceList: state.device.deviceList,
  directory: state.directory.directory
});

export default connect(mapStateToProps, mapDispatchToProps)(DirectoryPage);
