import React, { Component } from "react";
import { connect } from "react-redux";
import RoleAndPermission from "./RoleAndPermission";
import RoleDetail from "./RoleDetail";
import { authorizeActions } from "../../../_actions";
import PropTypes from "prop-types";

class SwitchingRolePermission extends Component {
  state = {
    isRoleDetail: false,
    roleId: null
  };

  onClickGotoDetail = (event, { value }) => {
    // this.props.isToggleTab();
    this.setState({
      roleId: value,
      isRoleDetail: true
    });
  };

  backToRolePermTab = (event, data) => {
    this.setState({
      roleId: null,
      isRoleDetail: false
    }, () => this.handleGetRoles());
  };

  handleGetRoles = () => {
    this.props.getRoles({ directoryPath: (this.props.objDirectory && this.props.objDirectory.path ? this.props.objDirectory.path : "/") });
  };

  componentDidMount() {
    this.handleGetRoles();
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   console.log(prevProps);
  //   console.log(this.props);
  //   console.log(prevState);
  //   console.log(this.state);
  //
  //   if (prevProps.objDirectory.path !== this.props.objDirectory.path) {
  //    console.log("update");
  //   }
  // }

  render() {
    const { isRoleDetail } = this.state;
    const { objDirectory, isAssignAccount, isUnassignAccount } = this.props;

    return (
      <div>
        {isRoleDetail ?
          <RoleDetail
            roleId={this.state.roleId}
            directoryPath={
              objDirectory && objDirectory.path ? objDirectory.path : "/"
            }
            backToRolePermTab={this.backToRolePermTab}
            onOpenSidebar={this.props.onOpenSidebar}
            renderEmptyContentComponent={this.props.renderEmptyContentComponent}
            isAssignAccount={isAssignAccount}
            isUnassignAccount={isUnassignAccount}
          /> :

          <RoleAndPermission
            roles={this.props.roles}
            directoryPath={
              objDirectory && objDirectory.path ? objDirectory.path : "/"
            }
            onClickGotoDetail={this.onClickGotoDetail}
            handleGetRoles={this.handleGetRoles}
          />
        }
      </div>
    );
  }
}

SwitchingRolePermission.propTypes = {
  objDirectory: PropTypes.object.isRequired,
  isToggleTab: PropTypes.func,
  onOpenSidebar: PropTypes.func,
  roles: PropTypes.array
};

const mapStateToProps = (state) => ({
  roles: state.authorize.roles
});

const mapDispatchToProps = (dispatch) => ({
  getRoles: (payload) => dispatch(authorizeActions.getRoles(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchingRolePermission);
