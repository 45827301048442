import { alertConstants } from '../_constants';

export const alertActions = {
  success,
  warning,
  error,
  clear
};

function success (message, title) {
  return { type: alertConstants.SUCCESS, message, title };
}

function warning (message, title) {
  return { type: alertConstants.WARNING, message, title };
}

function error (message, title) {
  return { type: alertConstants.ERROR, message, title };
}

function clear () {
  return { type: alertConstants.CLEAR };
}
