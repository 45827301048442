import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, TableCell, TableRow, Icon, Popup } from "semantic-ui-react";
import IconCustom from "../../../../assets/icons";
import PropTypes from "prop-types";
import styled from 'styled-components';

const gradientColor = (props) => {
  if (props.roleColors && props.roleColors.length > 1) {
    return `linear-gradient(${props.roleColors.map(addColors)})`;
  } else if (props.roleColors.length === 1) {
    return `${props.roleColors[0]}`;
  }

  return "#589FA5";

  function addColors(item) {
    return `${item}`;
  }
};

const IconCustomGradient = styled(IconCustom)`
background: ${(props) => gradientColor(props)};
border-radius: 50% !important;
`;

class UserRoleTableCell extends Component {

  onClickDelBtn = (event, { value }) => {
    event.preventDefault();
    this.props.onClickDelBtn(value);
  };

  render() {
    const { roleColors, fullName, email, id, isHiddenDelBtn, isActive, isUnassignAccount } = this.props;

    return (
      <TableRow>
        <TableCell width={2}>
          <IconCustomGradient className={'svg-icon__user'}
            name={"user"}
            viewBox={"0 0 32 32"}
            width={"32"}
            height={"32"}
            x={"9.5"}
            y={"8"}
            roleColors={roleColors} />
        </TableCell>
        <TableCell width={6}>{fullName}</TableCell>
        <TableCell width={6}>{email}</TableCell>
        {isUnassignAccount ?
          <TableCell width={2}>
            <Popup
              trigger={
                <Button
                  className={`button__icon ${!isHiddenDelBtn? "btn_icon--danger-outline pd-left": "btn_icon--danger pd-left"}`}
                  icon
                  onClick={this.onClickDelBtn}
                  value={id}
                >
                  {!isHiddenDelBtn ? (
                    isActive?
                  <Icon name='checkmark' /> :
                  <Icon name='' />
                  ): (<Icon name='remove user' />)}
                </Button>
              }
              content="Unassign role"
              mouseLeaveDelay={0}
              position='left center'
              basic
            />
          </TableCell> : null
        }
      </TableRow>
    );
  }
}

UserRoleTableCell.propTypes = {
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClickDelBtn: PropTypes.func.isRequired,
  isHiddenDelBtn: PropTypes.bool.isRequired,
  roleColors: PropTypes.array,
  isActive: PropTypes.bool
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(UserRoleTableCell);
