/* eslint-disable max-classes-per-file */
import React, { Component ,useEffect} from "react";
import { Button, Grid, Icon, Image, Menu, Popup, Responsive, Segment, Sidebar } from "semantic-ui-react";
import { NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { accountActions, directoryActions,authorizeActions } from "../../_actions";
import { devicePageActions } from "../../_actions/pages/devicePage.action";
import Routing from "../../routes/HomeRoutes";
import handleLoading from "../../components/Loader/Loader";
import Loadable from 'react-loadable';

const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};


const LoadableAccountDetailSideBar = Loadable({
  loader: () => import("./../../pages/Account/Sidebar/AccountDetailSideBar"),
  loading: () => handleLoading(true)
});


class Navbar extends Component {

  InfoUser = "";
  
  state = {
    activeItem: window.location.pathname,
    isOpenPopup: false,
    isAdmin: false,
    userInfo:{},
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  handleOpenPopup = () => {
    this.setState({ isOpenPopup: true });
  }

  handleClosePopup = () => {
    this.setState({ isOpenPopup: false });
  }

  renderPopupContent = (userObj, rememberMe) => {
    return (
      <Grid className="profile-popup">
        <Grid.Row className="remove-paddingBottom">
          <Grid.Column textAlign="right" >
            <Icon
              name="close"
              link
              color="grey"
              onClick={this.handleClosePopup}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="remove-padding">
          <Grid.Column textAlign="center">
            <Segment basic>
              {/* <Icon
                circular
                inverted
                color="teal"
                name="image"
                size="big"
              /> */}
              <Image
                  src="./images/profile.svg"
                  avatar
                  size="tiny"
                />
            </Segment>

            {/* profile */}
            <p className="font--body mg0">
              <b>{userObj ? userObj.firstname : 'Firstname'} {userObj ? userObj.lastname : 'Lastname'}</b>
            </p>
            <p className="font--body mg0 username_logout">{userObj ? userObj.email : 'example@email.com'}</p>

            <br />

            {/* logout btn */}
            <Button onClick={() => this.redirectMyAccount2()} className="btn--curve mg--bottom">
              <Grid columns="equal">
                <Grid.Column textAlign="right">
                  <Icon name="edit outline" className="icon--font16" />
                </Grid.Column>

                <Grid.Column width={8}>
                  <p className="font--caption1">Edit Profile</p>
                </Grid.Column>
                <Grid.Column />
              </Grid>
            </Button>
            
            <br />

            <Button onClick={() => this.logout(rememberMe)} className="btn--curve">
              <Grid columns="equal">
                <Grid.Column textAlign="right">
                  <Icon name="log out" className="icon--font16" />
                </Grid.Column>

                <Grid.Column width={8}>
                  <p className="font--caption1">Logout</p>
                </Grid.Column>
                <Grid.Column />
              </Grid>
            </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
    );
  }

  logout = (rememberMe) => {
    this.props.handleLogout(rememberMe).then(() => { this.props.clearDirectory(); this.props.clearDevice(); });
  }

  redirectMyAccount2 = () => {
     this.props.redirectMyAccount();
     this.handleClosePopup()
   
  }
  
    componentDidMount(){
    let userInfo ;
      this.props.getAuthority().then((res)=>{
        this.props.authorityData = res;
        userInfo=res;
        this.InfoUser=res
        //console.log("userInfo1",userInfo.data[0])

        if (userInfo.data[0]['directoryPath'] != undefined) {
          let xxx = userInfo.data[0]['directoryPath']['/'].roles.includes("6180b4409a6a9f7808004d3b");
          //console.log("xxx=========== >>>",xxx)
          this.setState({ isAdmin: xxx,userInfo:userInfo.data[0]});
        }

      })
  }

  
  
  render() {
    const { activeItem} = this.state;
    const { userObj, rememberMe, redirectHome ,authorityData} = this.props;
    
    // console.log("stateIsAdmin",this.state.isAdmin)
    // console.log("stateuserInfo",this.state.userInfo)
    // console.log("this.state.userInfo.userInfo['directoryPath']",this.state.userInfo['directoryPath'])
    // console.log("userObj +++>>>> ",userObj)
    
    return (
      <Menu id="layout__row1" secondary className="layout-menu">
        <Menu.Item className="pd-left0" onClick={redirectHome}>
          <Image src="./images/logo-kubota.png" alt="logo" className="logo"/>
        </Menu.Item>

        <Menu.Item
          name="home"
          as={NavLink}
          to="/home"
          active={activeItem === "home"}
          onClick={this.handleItemClick}
        />

        <Menu.Item
          label
          name="directory"
          to="/directory"
          as={NavLink}
          active={activeItem === "directory"}
          onClick={this.handleItemClick}
        />

        <Menu.Item
          name="Account"
          to="/account"
          as={NavLink}
          active={activeItem === "account"}
          onClick={this.handleItemClick}
        />

        <Menu.Item
          name="Device"
          to="/device"
          as={NavLink}
          active={activeItem === "device"}
          onClick={this.handleItemClick}
        />
       {this.state.isAdmin &&        
          <Menu.Item
            name="Device Type"
            to="/deviceType"
            as={NavLink}
            active={activeItem === "deviceType"}
            onClick={this.handleItemClick}
            
          />
       }
       

        <Menu.Item
          name="Activity Log"
          to="/activityLog"
          as={NavLink}
          active={activeItem === "activityLog"}
          onClick={this.handleItemClick}
        />

        <Menu.Item
          name="Notification Log"
          to="/notificationLog"
          as={NavLink}
          active={activeItem === "activityLog"}
          onClick={this.handleItemClick}
        />

        {/* <Menu.Item
          name="report"
          to="/report"
          as={NavLink}
          active={activeItem === "report"}
          onClick={this.handleItemClick}
        /> */}

        <Menu.Menu position="right">
          <Menu.Item className="pd-right0 align-self-End">
            {/* <Input icon="search" iconPosition="left" placeholder="Search..." /> */}
          </Menu.Item>

          <Menu.Item className="pd-right0 pd-left0">
            <Popup
              basic
              trigger={
                <div className="cursor-pointer">
                  {/* <span className="user-nameProfile color--empty">
                    {userObj ? `${`${userObj.firstname.charAt(0).toUpperCase() + userObj.firstname.slice(1)}
                    ${userObj.lastname.charAt(0).toUpperCase()}`}.` : null}
                  </span> */}
                  <span className="user-nameProfile color--empty">
                    {userObj ? userObj.username : null}
                  </span>

                 <Image
                  src="./images/profile.svg"
                  avatar
                />
                </div>
              }
              content={() => this.renderPopupContent(userObj, rememberMe)}
              on="click"
              open={this.state.isOpenPopup}
              onOpen={this.handleOpenPopup}
              pinned
              position="bottom right"
            >
            </Popup>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}

class DesktopContainer extends Component {

  hideFixedMenu = () => this.setState({ fixed: false });

  showFixedMenu = () => this.setState({ fixed: true });
  
  render() {
    
    const { children, userObj, handleLogout, rememberMe, clearDirectory, clearDevice, redirectHome ,getAuthority,redirectMyAccount} = this.props;   
    
    return (
      <Responsive
        getWidth={getWidth}
        // minWidth={Responsive.onlyComputer.minWidth}
      >
        <React.Fragment>
          <Grid centered columns="equal">
            <Grid.Row>
              <Grid.Column width="15">
                <Navbar  getAuthority={getAuthority} userObj={userObj} handleLogout={handleLogout} clearDirectory={clearDirectory} clearDevice={clearDevice} rememberMe={rememberMe} redirectHome={redirectHome} redirectMyAccount={redirectMyAccount}/>

                <Routing />

                {children}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      </Responsive>
    );
    console.log("DesktopContainer props",this.props.authorityData)
    var roleArr = this.props.authorityData;
    console.log("DesktopContainer roleArr",roleArr)

    
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = { activeItem: window.location.pathname };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  logout = (rememberMe) => {
    this.props.handleLogout(rememberMe).then(() => {
      this.props.clearDirectory();
      this.props.clearDevice();
    });
  }

  render() {
    const { activeItem, sidebarOpened } = this.state;
    const { children, userObj, rememberMe, redirectHome } = this.props;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyTablet.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
          className="sidebar-mobile"
        >
          <Segment className="cover-mobile">
            <Segment basic className="bg-logo" onClick={redirectHome}>
              <Image src="../images/logo-kubota.png" alt="logo" centered />
            </Segment>

            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              {/* <Image
                src="./images/profile.svg"
                className="cursor-pointer"
                centered
              /> */}
              <p className="font--body mg0">
                <b>{userObj ? userObj.firstname : 'firstname'} {userObj ? userObj.lastname : 'lastname'}</b>
              </p>
              <p className="font--body mg0">{userObj ? userObj.email : 'example@email.com'}</p>

              <Button onClick={() => this.logout(rememberMe)} content="Log out" />

            </div>

            <div>
              {/* <ProfilePage/> */}

              <Menu.Item
                name="home"
                as={NavLink}
                to="/home"
                active={activeItem === "home"}
                onClick={this.handleItemClick}
              />

              <Menu.Item
                name="directory"
                to="/directory"
                as={NavLink}
                active={activeItem === "directory"}
                onClick={this.handleItemClick}
              />

              <Menu.Item
                name="Account"
                to="/account"
                as={NavLink}
                active={activeItem === "account"}
                onClick={this.handleItemClick}
              />

              <Menu.Item
                name="Device"
                to="/device"
                as={NavLink}
                active={activeItem === "device"}
                onClick={this.handleItemClick}
              />

              <Menu.Item
                name="Device Type"
                to="/deviceType"
                as={NavLink}
                active={activeItem === "deviceType"}
                onClick={this.handleItemClick}
              />

              {/* <Menu.Item
                className="announcementPage"
                name="relationship"
                to="/relationship"
                as={NavLink}
                active={activeItem === "relationship"}
                onClick={this.handleItemClick}
              /> */}

              {/* <Menu.Item
                className="feedbackPage"
                name="report"
                to="/report"
                as={NavLink}
                active={activeItem === "report"}
                onClick={this.handleItemClick}
              /> */}
            </div>
          </Segment>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment className="segment-content-mobile">
            <Menu inverted pointing secondary size="large" className="menu-responsive">
              <Menu.Item onClick={this.handleToggle} style={{ padding: '0' }}>
                <div className="top fixed">
                  <Icon name="sidebar" size="big" color="teal"/>
                </div>
              </Menu.Item>
            </Menu>

            {/* Routing page */}
            <Routing />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

export const ResponsiveContainer = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = (rememberMe) => dispatch(accountActions.logout(rememberMe));
  const clearDirectory = () => dispatch(directoryActions.clearChildDirectory());
  const clearDevice = () => dispatch(devicePageActions.clearDevice());

  const userObj = useSelector((state) => state.authentication.user);
  const rememberMe = useSelector((state) => state.authentication.rememberMe);

  const getAuthority =() => dispatch(authorizeActions.getAuthority({ limit: 10, search:userObj.username }));
  
  const redirectHome = () => {
    //console.log("redirectHome called")
    history.push("/home")
  }

  const redirectMyAccount = () => {
    //console.log("redirectMyAccount called")
    history.push({
      pathname: "/account",
      state: { editMe: true, id: userObj._id, username: userObj.username}
    });
  }

  
  return (
    <div>
      <DesktopContainer getAuthority={getAuthority} children={children} userObj={userObj ? userObj : ''} handleLogout={handleLogout} clearDirectory={clearDirectory} clearDevice={clearDevice} redirectHome={redirectHome} redirectMyAccount={redirectMyAccount}/>
      {/* <MobileContainer children={children} userObj={userObj ? userObj : ''} handleLogout={handleLogout} clearDirectory={clearDirectory} clearDevice={clearDevice} redirectHome={redirectHome}/> */}
    </div>
  );
};

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};
