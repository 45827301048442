import { relationConstants } from "../_constants";

export function relation(state = {}, action) {
  switch (action.type) {

    // set initial state relation
    case relationConstants.INITIAL_STATE_RELATION:
      return {
        relationList: state.relationList
      };

    // get relation list
    case relationConstants.GET_RELATION_LIST_REQUEST:
      return {
        getting: true,
        direction: action.direction
      };
    case relationConstants.GET_RELATION_LIST_SUCCESS:
      return {
        relationList: action.response.data.data.map((item) => ({
          _id: item._id,
          type: item.type,
          from: item.from,
          to: item.to,
          directionDetail: item[state.direction],
          direction: state.direction
        }))
      };
    case relationConstants.GET_RELATION_LIST_FAILURE:
      return {
        error: action.error
      };

    // get EntityType (device or asset list)
    case relationConstants.GET_ENTITY_TYPE_REQUEST:
      return {
        relationList: state.relationList,
        relationInfo: state.relationInfo,
        getting: true
      };
    case relationConstants.GET_ENTITY_TYPE_SUCCESS:
      return {
        relationList: state.relationList,
        relationInfo: state.relationInfo,
        EntityTypeList: action.response.data.data.map((item) => ({
          key: item._id,
          value: item._id,
          text: item.name,
          directoryPath: item.directoryPathName
        }))
      };
    case relationConstants.GET_ENTITY_TYPE_FAILURE:
      return {
        relationList: state.relationList,
        relationInfo: state.relationInfo,
        error: action.error
      };

    // get relation info
    case relationConstants.GET_RELATION_INFO_REQUEST:
      return {
        relationList: state.relationList,
        getting: true,
        id: action.id
      };
    case relationConstants.GET_RELATION_INFO_SUCCESS:
      return {
        relationList: state.relationList,
        relationInfo: state.relationList.filter(
          (item) => item._id === state.id
        )[0]
      };
    case relationConstants.GET_RELATION_INFO_FAILURE:
      return {
        relationList: state.relationList,
        error: action.error
      };

    // post relation
    case relationConstants.POST_RELATION_REQUEST:
      return {
        relationList: state.relationList,
        creating: true,
        direction: action.direction
      };
    case relationConstants.POST_RELATION_SUCCESS:
      return {
        relationList:
          state.direction === "from" ?
            state.relationList :
            [
                {
                  _id: action.response.data._id,
                  type: action.response.data.type,
                  from: action.response.data.from,
                  to: action.response.data.to,
                  directionDetail: action.response.data.to,
                  direction: state.direction
                },
                ...state.relationList
              ],
        postRelation: action.response.data,
        postRelationStatus: action.response.status
      };
    case relationConstants.POST_RELATION_FAILURE:
      return {
        relationList: state.relationList,
        error: action.error
      };

    // del relation
    case relationConstants.DEL_RELATION_REQUEST:
      return {
        relationList: state.relationList,
        deleting: true,
        id: action.id
      };
    case relationConstants.DEL_RELATION_SUCCESS:
      return {
        relationList: state.relationList.filter(
          (item) => item._id !== state.id
        ),
        delRelationStatus: action.response.status
      };
    case relationConstants.DEL_RELATION_FAILURE:
      return {
        relationList: state.relationList,
        error: action.error
      };

    default:
      return state;
  }
}
