/* eslint-disable max-lines */
import { dataConstants } from "../_constants/";
// import { history } from "../_helpers/";
import { alertActions } from "./";
import axios from "axios";
// import { loadingConstants } from "../_constants/loading.constants";

export const dataActions = {
  exportTelemetry,
  getStatusExportTelemetry,
  sendSuccessExportTelemetry,
  exportTelemetryDB
};

const deivcePath = "/api/data";

function exportTelemetry(payload) {
  return (dispatch) => {
    dispatch(request());

    let url;
    const requestOptions = { params: payload };

    // if (deviceIds) {
    //   url = `${deivcePath}/data/query?directoryPath=${directoryPath}&deviceIds=${deviceIds}&telemetryKey=${telemetryKey}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    // } else {
    //   url = `${deivcePath}/data/query?directoryPath=${directoryPath}&telemetryKey=${telemetryKey}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    // }

    return axios
    .get(`${deivcePath}/data/query`, requestOptions)
      .then((response) => {
        dispatch(success(response));
        localStorage.setItem("taskId", response.data.taskId);
      })
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.status === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: dataConstants.GET_TELEMETRY_EXPORT_REQUEST,
      payload
    };
  }

  function success(response) {
    return { type: dataConstants.GET_TELEMETRY_EXPORT_SUCCESS, response };
  }

  function failure(error) {
    return { type: dataConstants.GET_TELEMETRY_EXPORT_FAILURE, error };
  }
}

function getStatusExportTelemetry(taskId) {
  return (dispatch) => {
    dispatch(request());

    let url = `${deivcePath}/data/query/${taskId}/status`;

    return axios
      .get(url)
      .then((response) => {
        dispatch(success(response));
        return response;
      })
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.status === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: dataConstants.GET_TELEMETRY_EXPORT_STATUS_REQUEST,
      taskId
    };
  }

  function success(response) {
    return { type: dataConstants.GET_TELEMETRY_EXPORT_STATUS_SUCCESS, response };
  }

  function failure(error) {
    return { type: dataConstants.GET_TELEMETRY_EXPORT_STATUS_FAILURE, error };
  }
}

function sendSuccessExportTelemetry(taskId) {
  return (dispatch) => {
    dispatch(request());

    let url = `${deivcePath}/data/query/${taskId}/success`;

    return axios
      .get(url)
      .then((response) => {
        dispatch(success(response));
        localStorage.removeItem('taskId');
      })
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.status === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: dataConstants.SEND_TELEMETRY_EXPORT_SUCCESS_REQUEST,
      taskId
    };
  }

  function success(response) {
    return { type: dataConstants.SEND_TELEMETRY_EXPORT_SUCCESS_SUCCESS, response };
  }

  function failure(error) {
    return { type: dataConstants.SEND_TELEMETRY_EXPORT_SUCCESS_FAILURE, error };
  }
}

function exportTelemetryDB(payload) {
  return (dispatch) => {
    dispatch(request());

    let url;
    payload.filterLv = "raw";
    payload.resType = "csv";

    const requestOptions = { params: payload };

    return axios
    .get(`${deivcePath}/data/query/db`, requestOptions)
      .then((response) => {
        dispatch(success(response));
        localStorage.setItem("urlExport", response.data.url);
      })
      .catch((error) => {
        localStorage.removeItem("urlExport");
        dispatch(failure(error));

        let message = error.response.status === 403 ? null : error.response.data.message;
        message = error.response.status === 500 ? error.message : message;

        dispatch(
          alertActions.error(
            error.response.data ? `Export Telemetry ${message}` : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: dataConstants.GET_TELEMETRY_DB_EXPORT_REQUEST,
      payload
    };
  }

  function success(response) {
    return { type: dataConstants.GET_TELEMETRY_DB_EXPORT_SUCCESS, response };
  }

  function failure(error) {
    return { type: dataConstants.GET_TELEMETRY_DB_EXPORT_FAILURE, error };
  }
}