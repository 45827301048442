export const relationConstants = {
  // set initial state relation
  INITIAL_STATE_RELATION: "RELATION_INITIAL_STATE_RELATION",

  // get relation list
  GET_RELATION_LIST_REQUEST: "RELATION_GET_RELATION_LIST_REQUEST",
  GET_RELATION_LIST_SUCCESS: "RELATION_GET_RELATION_LIST_SUCCESS",
  GET_RELATION_LIST_FAILURE: "RELATION_GET_RELATION_LIST_FAILURE",

  // get EntityType (device or asset list)
  GET_ENTITY_TYPE_REQUEST: "RELATION_GET_ENTITY_TYPE_REQUEST",
  GET_ENTITY_TYPE_SUCCESS: "RELATION_GET_ENTITY_TYPE_SUCCESS",
  GET_ENTITY_TYPE_FAILURE: "RELATION_GET_ENTITY_TYPE_FAILURE",

  // get relation info
  GET_RELATION_INFO_REQUEST: "RELATION_GET_RELATION_INFO_REQUEST",
  GET_RELATION_INFO_SUCCESS: "RELATION_GET_RELATION_INFO_SUCCESS",
  GET_RELATION_INFO_FAILURE: "RELATION_GET_RELATION_INFO_FAILURE",

  // post relation
  POST_RELATION_REQUEST: "RELATION_POST_RELATION_REQUEST",
  POST_RELATION_SUCCESS: "RELATION_POST_RELATION_SUCCESS",
  POST_RELATION_FAILURE: "RELATION_POST_RELATION_FAILURE",

  // patch relation
  PATCH_RELATION_REQUEST: "RELATION_PATCH_RELATION_REQUEST",
  PATCH_RELATION_SUCCESS: "RELATION_PATCH_RELATION_SUCCESS",
  PATCH_RELATION_FAILURE: "RELATION_PATCH_RELATION_FAILURE",

  // delete relation
  DEL_RELATION_REQUEST: "RELATION_DEL_RELATION_REQUEST",
  DEL_RELATION_SUCCESS: "RELATION_DEL_RELATION_SUCCESS",
  DEL_RELATION_FAILURE: "RELATION_DEL_RELATION_FAILURE"
};
