import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Form, Grid, Header, Icon, Image, Modal, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { InputModal } from "../Input/InputModal";
import { DefaultTextArea } from "../TextArea/TextArea";
import handleLoading from "../Loader/Loader";
import IconCustom from "../../assets/icons";
import TextField from "../../components/TextField/TextField";

class CreateAndEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      name: this.props.name ? this.props.name : "",
      description: this.props.properties ?
        this.props.properties.description :
        ""
    };

    this.handleOpenModal = () => this.setState({ modalOpen: true });
    this.handleCloseModal = () => {
      this.setState({
        modalOpen: false,
        name: this.props.name ? this.props.name : "",
        description: this.props.properties ?
          this.props.properties.description :
          ""
      });
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.postStatus === 201 || nextProps.patchStatus === 200) {
      this.setState({
        modalOpen: false,
        name: this.props.name ? this.props.name : "",
        description: this.props.properties ?
          this.props.properties.description :
          ""
      });
    }
  }

  handleChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { modalOpen, name, description } = this.state;
    const {
      id,
      role,
      type,
      header,
      btnLeft,
      btnRight,
      handleSubmitModal,
      loading
    } = this.props;

    let trigger;

    if (type === "btn__icon--plus") {
      trigger = (
        <Button
          className="btn--icon-border"
          icon="plus"
          onClick={this.handleOpenModal}
        />
      );
    } else if (type === "btnlong__icon--plus") {
      trigger = (
        <Segment className="segment-addCard" onClick={this.handleOpenModal}>
          <Icon link name="plus" />
        </Segment>
      );
    } else if (type === "dropdown__item--edit") {
      trigger = <Dropdown.Item text="EDIT" onClick={this.handleOpenModal} />;
    } else if (type === "dropdown__item--create") {
      trigger = <Dropdown.Item text="CREATE" onClick={this.handleOpenModal} />;
    } else if (type === "dropdown__item--edit-icon") {
      trigger = (
        <span onClick={this.handleOpenModal}>
          <IconCustom
            className={"svg-icon__edit marginTop--30"}
            name={"edit"}
          />
        </span>
      );
    }

    return (
      <Modal
        className="modal--width80p"
        trigger={trigger}
        open={modalOpen}
        onClose={this.handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
        onClick={e => e.stopPropagation()}
      >
        {handleLoading(loading)}

        <Modal.Content>
          <Grid columns="equal">
            <Grid.Column />

            <Grid.Column width="14">
              <Grid columns="equal">
                <Grid.Column width="9">
                  { type === "dropdown__item--edit" ?
                    <Image src="./images/create-edit-modal.svg" className="img-createDirectory" /> :
                    <Image src="./images/img_createDirectory.svg" className="img-createDirectory" />
                  }
                </Grid.Column>

                <Grid.Column width="1" />

                <Grid.Column width="6">
                  <Header className="header__title">{header}</Header>

                  <br />

                  <Form>
                    {/* <InputModal
                      name="name"
                      type="text"
                      value={name}
                      handleChange={this.handleChangeInput}
                      placeholder="Directory Name"
                    /> */}
                    <TextField
                      className={"--bottom"}
                      name={"name"}
                      text={"Directory Name"}
                      placeholder={"Directory Name"}
                      value={name}
                      handleInputValue={this.handleChangeInput}
                    />

                    <DefaultTextArea
                      name="description"
                      text={"Description"}
                      value={description}
                      handleChange={this.handleChangeInput}
                      placeholder="Description"
                    />
                  </Form>

                  <br />
                  <br />

                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="8" className="col8__btn--left">
                        <Button
                          fluid
                          className="btn--secondary"
                          onClick={this.handleCloseModal}
                        >
                          {btnLeft}
                        </Button>
                      </Grid.Column>
                      <Grid.Column width="8" className="col8__btn--right">
                        <Button
                          fluid
                          className="btn--primary"
                          onClick={(e) => handleSubmitModal(id, name, description, role)
                          }
                          disabled={!name}
                        >
                          {btnRight}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column />
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

CreateAndEditModal.propTypes = {
  id: PropTypes.string.isRequired,
  parent: PropTypes.string,
  type: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  btnLeft: PropTypes.string.isRequired,
  btnRight: PropTypes.string.isRequired,
  handleSubmitModal: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  loading: state.directory.loading || state.directory.updating,
  postStatus: state.directory.postDirStatus,
  patchStatus: state.directory.patchDirStatus
});

export default connect(
  mapStateToProps,
  null
)(CreateAndEditModal);
