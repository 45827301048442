/* eslint-disable max-lines */
import { deviceConstants } from "../_constants/";
import { history } from "../_helpers/";
import { alertActions } from "./";
import axios from "axios";
import { loadingConstants } from "../_constants/loading.constants";

export const deviceActions = {
  clearDevice,
  getAllDeviceList,
  getDeviceList,
  getDeviceListNoPage,
  postDevice,
  getDeviceInfo,
  patchDevice,
  delDevice,
  getDeviceToken,
  getDeviceType,
  getKeysTelemetry,
  getDeviceAttribute,
  getDeviceAttributeQuery,
  postDeviceAttribute,
  delDeviceAttribute,
  getDeviceListBySearch
};

const deivcePath = "/api/device";
const scopeLoading = "device";

function clearDevice() {
  return { type: deviceConstants.CLEAR_DEVICE };
}

function getDeviceList(directoryPath, value) {
  return (dispatch) => {
    dispatch(request());

    let getDeviePath;

    if (value) {
      getDeviePath = `${deivcePath}/device?name=${value}&directoryPath=${directoryPath}`;
    } else {
      getDeviePath = `${deivcePath}/device?directoryPath=${directoryPath}`;
    }

    return axios
      .get(getDeviePath)
      .then((response) => dispatch(success(response)))
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.status === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: deviceConstants.GET_DEVICE_LIST_REQUEST,
      directoryPath,
      value
    };
  }

  function success(response) {
    return { type: deviceConstants.GET_DEVICE_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.GET_DEVICE_LIST_FAILURE, error };
  }
}

function getDeviceListNoPage(directoryPath, value) {
  return (dispatch) => {
    dispatch(request());

    let getDeviePath;

    if (value) {
      getDeviePath = `${deivcePath}/device?name=${value}&directoryPath=${directoryPath}&limit=65535`;
    } else {
      getDeviePath = `${deivcePath}/device?directoryPath=${directoryPath}&limit=65535`;
    }

    return axios
      .get(getDeviePath)
      .then((response) => dispatch(success(response)))
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.status === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: deviceConstants.GET_DEVICE_LIST_REQUEST,
      directoryPath,
      value
    };
  }

  function success(response) {
    return { type: deviceConstants.GET_DEVICE_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.GET_DEVICE_LIST_FAILURE, error };
  }
}

function getAllDeviceList(payload) {
  return (dispatch) => {
    dispatch(setLoading(scopeLoading, true));
    dispatch(fetch(payload));

    const requestOptions = { params: payload };

    return new Promise((resolve, reject) => {
      axios
        .get(`${deivcePath}/device`, requestOptions)
        .then((response) => {
          dispatch(setLoading(scopeLoading, false));
          dispatch(success(response.data));
          resolve(response.data.total);
        })
        .catch((error) => {
          dispatch(setLoading(scopeLoading, false));
          dispatch(failure(error));
          reject();
        });
    });
  };

  function setLoading(scope, loading) {
    return {
      type: loadingConstants.SET_LOADING,
      scope,
      loading
    };
  }

  function fetch(request) {
    return { type: deviceConstants.GET_ALL_DEVICE_LIST_REQUEST, request, payload };
  }

  function success(response) {
    return { type: deviceConstants.GET_ALL_DEVICE_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.GET_ALL_DEVICE_LIST_FAILURE, error };
  }
}

function postDevice(payload) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .post(`${deivcePath}/device`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Create device is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response?.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceConstants.POST_DEVICE_REQUEST, payload };
  }

  function success(response) {
    return { type: deviceConstants.POST_DEVICE_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.POST_DEVICE_FAILURE, error };
  }
}

function getDeviceInfo(id) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .get(`${deivcePath}/device/${id}`)
      .then((response) => dispatch(success(response)))
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceConstants.GET_DEVICE_INFO_REQUEST, id };
  }

  function success(response) {
    return { type: deviceConstants.GET_DEVICE_INFO_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.GET_DEVICE_INFO_FAILURE, error };
  }
}

function patchDevice(id, payload) {
  return (dispatch) => {
    dispatch(request());
    return axios
      .patch(`${deivcePath}/device/${id}`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Update device is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceConstants.PATCH_DEVICE_REQUEST, id, payload };
  }

  function success(response) {
    return { type: deviceConstants.PATCH_DEVICE_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.PATCH_DEVICE_FAILURE, error };
  }
}

function delDevice(payload, isDeletingOneByOne = false) {
  return (dispatch) => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      axios
        .delete(`${deivcePath}/device/${payload}`)
        .then((response) => {
          dispatch(success(response, isDeletingOneByOne));

          if (!isDeletingOneByOne) {
            dispatch(alertActions.success("Delete device is successful!"));
            dispatch(alertActions.clear());
          }

          resolve();
        })
        .catch((error) => {
          dispatch(failure(error));
          dispatch(
            alertActions.error(
              error.response.data.message ? error.response.data.message : "Something went wrong!",
              "Error!"
            )
          );
          dispatch(alertActions.clear());
          reject();
        });
    });
  };

  function request() {
    return { type: deviceConstants.DEL_DEVICE_REQUEST, payload };
  }

  function success(response, isDeleting) {
    return {
      type: deviceConstants.DEL_DEVICE_SUCCESS,
      response,
      isDeletingOneByOne: isDeleting
    };
  }

  function failure(error) {
    return { type: deviceConstants.DEL_DEVICE_FAILURE, error };
  }
}

function getDeviceToken(id) {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(`${deivcePath}/device/${id}/token`)
      .then((response) => dispatch(success(response)))
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceConstants.GET_DEVICE_TOKEN_REQUEST, id };
  }

  function success(response) {
    return { type: deviceConstants.GET_DEVICE_TOKEN_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.GET_DEVICE_TOKEN_FAILURE, error };
  }
}

function getDeviceType(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { params: payload };

    return axios
      .get(`${deivcePath}/deviceType`, requestOptions)
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        dispatch(failure(error));

        if ((error.response >= 502 && error.response <= 504) || error.request) {
          history.push("/login");
        }

        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceConstants.GET_DEVICE_TYPE_REQUEST, payload };
  }

  function success(response) {
    return { type: deviceConstants.GET_DEVICE_TYPE_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.GET_DEVICE_TYPE_FAILURE, error };
  }
}

function getKeysTelemetry(deviceId, index) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .get(`${deivcePath}/device/${deviceId}/telemetry/keys`)
      .then((response) => dispatch(success(response)))
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: deviceConstants.GET_KEYS_TELEMETRY_REQUEST,
      index
    };
  }

  function success(response) {
    return {
      type: deviceConstants.GET_KEYS_TELEMETRY_SUCCESS,
      response,
      index
    };
  }

  function failure(error) {
    return {
      type: deviceConstants.GET_KEYS_TELEMETRY_FAILURE,
      error
    };
  }
}

// get device's attribute
function getDeviceAttribute(id, scope) {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(`${deivcePath}/device/${id}/attribute/${scope}`)
      .then((response) => dispatch(success(response)))
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceConstants.GET_DEVICE_ATTRIBUTE_REQUEST, id, scope };
  }

  function success(response) {
    return { type: deviceConstants.GET_DEVICE_ATTRIBUTE_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.GET_DEVICE_ATTRIBUTE_FAILURE, error };
  }
}

// get device's attribute
function getDeviceAttributeQuery(id, scope,keywords) {
  return (dispatch) => {
    dispatch(request());
    let getDevicePath;
    if (keywords) {
      getDevicePath = `${deivcePath}/device/${id}/attribute/${scope}/query?keywords=${keywords}`;
    } else {
      getDevicePath = `${deivcePath}/device/${id}/attribute/${scope}/query`;
    }

    return axios
      .get(getDevicePath)
      .then((response) => {
        response.data = response.data[keywords]
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceConstants.GET_DEVICE_ATTRIBUTE_REQUEST, id, scope };
  }

  function success(response) {
    return { type: deviceConstants.GET_DEVICE_ATTRIBUTE_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.GET_DEVICE_ATTRIBUTE_FAILURE, error };
  }
}

// post device's attribute
function postDeviceAttribute(id, scope, payload, type) {
  return (dispatch) => {
    dispatch(request());
    return axios
      .post(`${deivcePath}/device/${id}/attribute/${scope}`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success(type === "add" ? "Create device's attribute is successful!" : "Edit device's attribute is successful!"));
        dispatch(alertActions.clear());
      }).catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: deviceConstants.POST_DEVICE_ATTRIBUTE_REQUEST,
      id,
      scope,
      payload
    };
  }

  function success(response) {
    return {
      type: deviceConstants.POST_DEVICE_ATTRIBUTE_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: deviceConstants.POST_DEVICE_ATTRIBUTE_FAILURE,
      error
    };
  }
}

// del device's attribute
function delDeviceAttribute(id, scope, key) {
  return (dispatch) => {
    dispatch(request());
    return axios
      .delete(`${deivcePath}/device/${id}/attribute/${scope}/${key}`)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Delete device's attribute is successful!"));
        dispatch(alertActions.clear());
      }).catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: deviceConstants.DEL_DEVICE_ATTRIBUTE_REQUEST,
      id,
      scope,
      key
    };
  }

  function success(response) {
    return {
      type: deviceConstants.DEL_DEVICE_ATTRIBUTE_SUCCESS,
      response
    };
  }

  function failure(error) {
    return {
      type: deviceConstants.DEL_DEVICE_ATTRIBUTE_FAILURE,
      error
    };
  }
}

function getDeviceListBySearch(payload) {
  return (dispatch) => {
    dispatch(request());

    let getDevicePath;

    if (payload.search) {
      getDevicePath = `${deivcePath}/device/query?search=${payload.search}`;
    } else {
      getDevicePath = `${deivcePath}/device/query`;
    }

    return axios
      .get(getDevicePath)
      .then((response) => {
        dispatch(success(response.data));
        return response.data;
      })
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.data.code === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceConstants.GET_DEVICE_SEARCH_LIST_REQUEST, payload };
  }

  function success(response) {
    return { type: deviceConstants.GET_DEVICE_SEARCH_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceConstants.GET_DEVICE_SEARCH_LIST_FAILURE, error };
  }
}

