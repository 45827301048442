import React, { Component } from "react";
import { NotificationContainer } from "react-notifications";
import { history } from "../_helpers";
import "./App.css";

import { connect } from "react-redux";
import { alertActions } from "../_actions/";

import Routing from "../routes/AppRoutes";
import { Notification } from "../components/Notification/Notification";
import handleLoading from "../components/Loader/Loader";

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;

    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  render() {
    const { alert, logout } = this.props;

    return (
      <div style={{ padding: "1rem" }}>

        {handleLoading(logout)}

        {alert.message && (
          <Notification
            type={alert.type}
            msg={alert.message}
            title={alert.title}
            callback={null}
          />
        )}

        <Routing />

        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  alert: state.alert,
  logout: state.authentication.logout
});

export default connect(
  mapStateToProps,
  null
)(App);
