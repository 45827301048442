import React from 'react';
import { Button, Grid, Icon, Modal, Popup } from 'semantic-ui-react';
import IconCustom from "../../../assets/icons/";
import handleLoading from "../../../components/Loader/Loader";

const DeleteDeviceTypeModal = (props) => {
  return (
    <>
      <Modal
      size="tiny"
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={props.deleteModal}
      onClose={props.handleCloseDeleteModal}
      trigger={
      //   <span onClick={() => props.handleOpenDeleteModal(props.item)}>
      //     <IconCustom
      //       className={"svg-icon__bin"}
      //       name={"bin"}
      //       viewBox={"0 0 33 33"}
      //       width={"33"}
      //       height={"33"}
      //       x={"9"}
      //       y={"7.5"}
      //     />
      // </span>

        <Popup
          trigger={
            <Button
              className={"button__icon btn_icon--danger mg-left"}
              icon
              onClick={() => props.handleOpenDeleteModal(props.item)}
            >
              <Icon name='trash alternate outline' />
              </Button>
          }
          content="Delete"
          mouseLeaveDelay={0}
          position='left center'
          basic
        />
      }
      >

    {handleLoading(props.loading)}

      <Modal.Header>
        <Grid>
          <Grid.Column textAlign="left" width={14}>
            Are you sure?
          </Grid.Column>

          <Grid.Column textAlign="right" width={2}>
            <Icon name="close" onClick={props.handleCloseDeleteModal} link/>
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete <b>{props.deviceTypeInfo && props.deviceTypeInfo.type}</b>.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.handleCloseDeleteModal} className="btn-cancelTelemetry">
          Cancel
        </Button>
        <Button onClick={() => props.handleDeleteItem(props.deviceTypeInfo && props.deviceTypeInfo._id)} className="btn-deleteTelemetry">
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
    </>
);
};

export default DeleteDeviceTypeModal;
