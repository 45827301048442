import React from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export const Notification = (props) => {
  const timeout = props.timeout? props.timeout : 10000
  switch (props.type) {
    case 'info':
      NotificationManager.info(props.msg, props.title, timeout, props.callback);
      break;
    case 'success':
      NotificationManager.success(props.msg, props.title, timeout, props.callback);
      break;
    case 'warning':
      NotificationManager.warning(props.msg, props.title, timeout, props.callback);
      break;
    case 'error':
      NotificationManager.error(props.msg, props.title, timeout, props.callback);
      break;
    default:
      return null;
    }

  return (
    <NotificationContainer/>
  );
};

