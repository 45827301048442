/* eslint-disable max-lines */
import { activityConstants } from "../_constants";
// import { history } from "../_helpers/";
import { alertActions } from ".";
import axios from "axios";
// import { loadingConstants } from "../_constants/loading.constants";

export const activityActions = {
  getAudit,
  getAuditDetail,
  getAuditExport
};

const activityPath = "/api/activity";

function getAudit(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { params: payload };

    return new Promise((resolve, reject) => {
      axios
        .get(`${activityPath}/audit`, requestOptions)
          .then((response) => {
            dispatch(success(response));
            resolve(response.data.total);
          })
          .catch((error) => {
            dispatch(failure(error));

            const message = error.response.status === 403 ? null : error.response.data.message;

            dispatch(
              alertActions.error(
                error.response.data ? message : "Something went wrong!",
                "Error!"
              )
            );
            dispatch(alertActions.clear());
            reject();
          });
      });
  };

  function request() {
    return {
      type: activityConstants.GET_AUDIT_REQUEST,
      payload
    };
  }

  function success(response) {
    return { type: activityConstants.GET_AUDIT_SUCCESS, response };
  }

  function failure(error) {
    return { type: activityConstants.GET_AUDIT_FAILURE, error };
  }
}

function getAuditDetail(id) {
  return (dispatch) => {
    dispatch(request());

    let url = `${activityPath}/audit/${id}`;

    return axios
      .get(url)
      .then((response) => {
        dispatch(success(response));
        return response;
      })
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.status === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: activityConstants.GET_AUDIT_DETAIL_REQUEST
    };
  }

  function success(response) {
    return { type: activityConstants.GET_AUDIT_DETAIL_SUCCESS, response };
  }

  function failure(error) {
    return { type: activityConstants.GET_AUDIT_DETAIL_FAILURE, error };
  }
}

function getAuditExport(payload) {
  return (dispatch) => {
    dispatch(request());
    const requestOptions = { params: payload };

    return axios
      .get(`${activityPath}/audit/query`, requestOptions)
      .then((response) => {
        dispatch(success(response));
        return response.data;
      })
      .catch((error) => {
        dispatch(failure(error));

        const message = error.response.data.code === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return {
      type: activityConstants.GET_AUDIT_EXPORT_REQUEST,
      payload
    };
  }

  function success(response) {
    return { type: activityConstants.GET_AUDIT_EXPORT_SUCCESS, response };
  }

  function failure(error) {
    return { type: activityConstants.GET_AUDIT_EXPORT_FAILURE, error };
  }
}