import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Input } from 'semantic-ui-react';

// const InputFormStyle = styled(Input)`
// color: ${props => props.labelErr ? console.log(props) : console.log(props)};
// `

class InputForm extends Component {

  static propTypes = {
    iconPosition: PropTypes.string,
    icon: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    handleChange: PropTypes.func,
    maxlength: PropTypes.string,
    minlength: PropTypes.string,
  };

  render() {
    return (
      <div className="div__input">
        <Input
          fluid
          className={"input--withicon"}
          icon={<Icon name={this.props.icon} className="icon--font24"/>}
          iconPosition="left"
          name={this.props.name}
          type={this.props.type}
          value={this.props.value}
          onChange={this.props.handleChange}
          placeholder={this.props.placeholder}
          maxlength={this.props.maxlength}
          minlength={this.props.minlength}/>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {};
// };

// const mapDispatchToProps = (dispatch) => {
//   return {};
// };

export default connect(null, null)(InputForm);
