export const reportConstants = {
        // get dashboard template
        GET_DASHBOARD_TEMP_REQUEST: 'REPORT_GET_DASHBOARD_TEMP_REQUEST',
        GET_DASHBOARD_TEMP_SUCCESS: 'REPORT_GET_DASHBOARD_TEMP_SUCCESS',
        GET_DASHBOARD_TEMP_FAILURE: 'REPORT_GET_DASHBOARD_TEMP_FAILURE',

         // get dashboard of direcotry
         GET_DASHBOARD_OF_DIR_REQUEST:
           "REPORT_GET_DASHBOARD_OF_DIR_REQUEST",
         GET_DASHBOARD_OF_DIR_SUCCESS:
           "REPORT_GET_DASHBOARD_OF_DIR_SUCCESS",
         GET_DASHBOARD_OF_DIR_FAILURE:
           "REPORT_GET_DASHBOARD_OF_DIR_FAILURE",

         // get report list
         GET_DASHBOARD_LIST_REQUEST: "REPORT_GET_DASHBOARD_LIST_REQUEST",
         GET_DASHBOARD_LIST_SUCCESS: "REPORT_GET_DASHBOARD_LIST_SUCCESS",
         GET_DASHBOARD_LIST_FAILURE: "REPORT_GET_DASHBOARD_LIST_FAILURE",

         // get dashboard bt id
         GET_DASHBOARD_BY_ID_REQUEST: "REPORT_GET_DASHBOARD_BY_ID_REQUEST",
         GET_DASHBOARD_BY_ID_SUCCESS: "REPORT_GET_DASHBOARD_BY_ID_SUCCESS",
         GET_DASHBOARD_BY_ID_FAILURE: "REPORT_GET_DASHBOARD_BY_ID_FAILURE",

         // post report
         POST_DASHBOARD_REQUEST: "REPORT_POST_DASHBOARD_REQUEST",
         POST_DASHBOARD_SUCCESS: "REPORT_POST_DASHBOARD_SUCCESS",
         POST_DASHBOARD_FAILURE: "REPORT_POST_DASHBOARD_FAILURE",

         // patch report
         PATCH_DASHBOARD_REQUEST: "REPORT_PATCH_DASHBOARD_REQUEST",
         PATCH_DASHBOARD_SUCCESS: "REPORT_PATCH_DASHBOARD_SUCCESS",
         PATCH_DASHBOARD_FAILURE: "REPORT_PATCH_DASHBOARD_FAILURE",

         // delete report
         DELETE_DASHBOARD_REQUEST: "REPORT_DELETE_DASHBOARD_REQUEST",
         DELETE_DASHBOARD_SUCCESS: "REPORT_DELETE_DASHBOARD_SUCCESS",
         DELETE_DASHBOARD_FAILURE: "REPORT_DELETE_DASHBOARD_FAILURE",

         // get widgettemplate
         GET_WIDGET_TEMP_REQUEST: "REPORT_GET_WIDGET_TEMP_REQUEST",
         GET_WIDGET_TEMP_SUCCESS: "REPORT_GET_WIDGET_TEMP_SUCCESS",
         GET_WIDGET_TEMP_FAILURE: "REPORT_GET_WIDGET_TEMP_FAILURE"
       };
