import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Form, Grid, Header, Image, Modal } from 'semantic-ui-react';

import { connect } from 'react-redux';

import { InputModal } from '../Input/InputModal';
import { DefaultTextArea } from '../TextArea/TextArea';
import handleLoading from '../Loader/Loader';
import TextField from "../../components/TextField/TextField";

class UpdateModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      modalOpen: false,
      name: this.props.name ? this.props.name : '',
      description: this.props.properties ? this.props.properties.description : ''
    };

    this.handleOpenModal = () => this.setState({ modalOpen: true });
    this.handleCloseModal = () => {
      this.setState({
        modalOpen: false,
        name: this.props.name ? this.props.name : '',
        description: this.props.properties ? this.props.properties.description : ''
      });
    };
  }

  componentWillReceiveProps (nextProps) {
    // DrectoryPage is render modal when componentDidMount
    // this fuc will set new state by new props

    if (nextProps.name !== this.props.name && nextProps.properties !== this.props.properties) {
      this.setState({
        name: nextProps.name,
        description: nextProps.properties ? nextProps.properties.description : ''
      });
    }
    if (nextProps.status === 200 || nextProps.patchStatus === 200) {
      this.setState({
        modalOpen: false
      });
    }
  }

  handleChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render () {
    const { modalOpen, name, description } = this.state;
    const { id, role, header, btnLeft, btnRight, handleSubmitModal, loading } = this.props;

    return (
      <Modal
        className="modal--width80p"
        trigger={<Dropdown.Item text="EDIT" onClick={this.handleOpenModal}/>}
        open={modalOpen}
        onClose={this.handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
        onClick={e => e.stopPropagation()}
      >
        { handleLoading(loading) }

        <Modal.Content>

          <Grid columns="equal">
            <Grid.Column/>

            <Grid.Column width="14">
              <Grid columns="equal">

                <Grid.Column width="8">
                  <Image src="./images/create-edit-modal.svg" />
                </Grid.Column>

                <Grid.Column width="2"/>

                <Grid.Column width="6">
                  <Header className="header__title">
                    {header}
                  </Header>

                  <br/>

                  <Form>
                    <TextField
                      className={"--bottom"}
                      name={"name"}
                      text={"Directory Name"}
                      placeholder={"Directory Name"}
                      value={name}
                      handleInputValue={this.handleChangeInput}
                    />

                    <DefaultTextArea
                      name="description"
                      text={"Description"}
                      value={description}
                      handleChange={this.handleChangeInput}
                      placeholder="Description"
                    />
                  </Form>

                  <br/><br/>

                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="8" className="col8__btn--left">
                        <Button
                          fluid
                          className="btn--secondary"
                          onClick={this.handleCloseModal}
                        >
                          {btnLeft}
                        </Button>
                      </Grid.Column>
                      <Grid.Column width="8" className="col8__btn--right">
                        <Button
                          fluid
                          className="btn--primary"
                          onClick={ (e) => handleSubmitModal(id, name, description, role)}
                          disabled={!name}
                        >
                          {btnRight}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                </Grid.Column>
              </Grid>

            </Grid.Column>
            <Grid.Column/>

          </Grid>

        </Modal.Content>
      </Modal>
    );
  }
}

UpdateModal.propTypes = {
  id: PropTypes.string.isRequired,
  parent: PropTypes.string,
  type: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  btnLeft: PropTypes.string.isRequired,
  btnRight: PropTypes.string.isRequired,
  handleSubmitModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  loading: state.directory.loading,
  patchStatus: state.directory.patchDirStatus
});

export default connect(
  mapStateToProps,
  null
)(UpdateModal);
