/* eslint-disable complexity */
import { activityConstants } from "../_constants";
import { isEmpty } from 'lodash';

export function activity(state = {}, action) {
  switch (action.type) {

    // get audit list
    case activityConstants.GET_AUDIT_REQUEST:
      return {
        loading: true
      };
    case activityConstants.GET_AUDIT_SUCCESS:
      return {
        activityList: action.response.data.data,
        loading: false
      };
    case activityConstants.GET_AUDIT_FAILURE:
      return {
        error: action.error,
        loading: false
      };

    // get audit detail
    case activityConstants.GET_AUDIT_DETAIL_REQUEST:
      return {
        // loading: true
      };
    case activityConstants.GET_AUDIT_DETAIL_SUCCESS:
      return {
        activityDetail: action.response.data.data,
        // loading: false
      };
    case activityConstants.GET_AUDIT_DETAIL_FAILURE:
      return {
        error: action.error
        // loading: false
      };
    // get audit list
    case activityConstants.GET_AUDIT_EXPORT_REQUEST:
      return {
        loading: true
      };
    case activityConstants.GET_AUDIT_EXPORT_SUCCESS:
      return {
        activityExportList: action.response.data.data,
        loading: false
      };
    case activityConstants.GET_AUDIT_EXPORT_FAILURE:
      return {
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}
