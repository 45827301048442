import React from "react";

const SVG = ({
  className = "",
  viewBox = "0 0 32 32",
  width = "32",
  height = "32"
}) => (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink" className={`svg-icon ${className || ""}`}>
      <rect x="0.5" y="0.5" width={width - 1} height={height - 1} rx="8" />
      <svg x="8" y="8.5" width={width / 2} height={height / 2} viewBox="0 0 296 296">
        <path d="M13 36L283 36" strokeWidth="25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 133L283 133" strokeWidth="25" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="197" cy="133" r="26" fill="white" strokeWidth="20" />
        <circle cx="74" cy="36" r="26" fill="white" strokeWidth="20" />
        <path d="M13 230L283 230" strokeWidth="25" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="74" cy="230" r="26" fill="white" strokeWidth="20" />
      </svg>
    </svg>
  );

export default SVG;
