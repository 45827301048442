import { Image, Segment } from "semantic-ui-react";
import React, { useState } from "react";
import styled from "styled-components";

const gradientColor = (props) => {
  if (props.roleColors && props.roleColors.length > 1) {
    return `linear-gradient(${props.roleColors.map(addColors)})`;
  } else if (props.roleColors.length === 1) {
    return `${props.roleColors[0]}`;
  }

  return "#589FA5";

  function addColors(item) {
    return `${item}`;
  }
};

const SegmentCustom = styled(Segment)`
background: ${gradientColor} !important;
`;

const SegmentGradients = (props) => {
  const [roleColors] = useState([]);

  return (
    <SegmentCustom className={'segment--profile'} basic inverted roleColors={roleColors}>
      {/* <Image className={'image--profile'} src={'/images/image-default.svg'} centered circular /> */}
    </SegmentCustom>
  );
};

SegmentGradients.propTypes = {

};

export default SegmentGradients;
