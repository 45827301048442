import React,{useState,useMemo,useEffect} from "react";
import { Grid, Header, Image, Segment } from "semantic-ui-react";
import { authorizeActions } from "../../_actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const HomePage = (props) => {
  

  const [isAdmin, setIsAdmin] = useState(false);

  let renderSegmentCard = [
    { header: 'Directory', href: '/directory', img: 'directory-homepage.jpg' },
    { header: 'Account', href: '/account', img: 'account.jpg' },
    { header: 'Device', href: '/device', img: 'account-homepage.jpg' },
    { header: 'Device Type', href: '/deviceType', img: 'device-type.jpg' }
    // { header: 'Report', href: '/report', img: 'report-homepage.svg' }
  ];

  useMemo(() => {
   
    const payload2 = { action: '*', subject: '*', directoryPath: '/' };
    props.getEligible(payload2).then((response) => {
      //this.props.updateEligible(response.isEligible);
      if (response.isEligible) {
        //this.setState({ isAdminRoot: true });
        setIsAdmin(response.isEligible)
        renderSegmentCard = renderSegmentCard.filter((o)=>o.header != "Device Type")
      }
    });

  }, []);


  return (
    <React.Fragment>
      <div className="marginTop--30">
        <Grid className="marginTop--30">
          <Grid.Row className="marginTop--30">
            <Grid.Column computer={8} tablet={12} mobile={16}>
              <Header as="h1">K-iFarm account & Controller platform</Header>
              <p className="text--description">Platform for create user account and config contoller.</p>
            </Grid.Column>

            <Grid.Column width="8" />
          </Grid.Row>

          <Grid.Row>
            {console.log("Grid.Row",renderSegmentCard)}
            {renderSegmentCard.map((item) => <Grid.Column computer={3} tablet={8} mobile={16} style={{ marginBottom: "10px" }}>
              {item.header != "Device Type" &&
                <a href={item.href}>
                <Segment className="segment__homepage--card">
                  <Header>{item.header}</Header>
                  <div className="textAlign--right">
                    <Image src={`./images/${item.img}`} className="img--homepage" />
                    </div>
                </Segment>
              </a>
              }
              {item.header == "Device Type" && isAdmin &&
                <a href={item.href}>
                <Segment className="segment__homepage--card">
                  <Header>{item.header}</Header>
                  <div className="textAlign--right">
                    <Image src={`./images/${item.img}`} className="img--homepage" />
                    </div>
                </Segment>
              </a>
              }
              
            </Grid.Column>)}
          </Grid.Row>
        </Grid>
      </div>
      <div className="marginTop--30">
        <Grid className="marginTop--30">
        <hr className="text--description" style={{width:"100%",border: "2px solid #d9d9d9"}} color="#999" />
          <Grid.Row className="marginTop--30">
            <Grid.Column computer={8} tablet={12} mobile={16}>
              <Header as="h1">K-iFarm Application Platform</Header>
              <p className="text--description">Platform for launch information and crop recipe in K-iFarm Application.</p>
            </Grid.Column>

            <Grid.Column width="8" />
          </Grid.Row>

          <Grid.Row>
          <Grid.Column computer={3} tablet={8} mobile={16} style={{ marginBottom: "10px" }}>
            <a href="https://backoffice.kubota-ifarm.com" target="_blank" rel="noreferrer noopener">
              <Segment className="segment__homepage--card">
                  <Header>Backoffice</Header>
                  <div className="textAlign--right">
                    <Image src={`./images/img-backoffice.png`} className="img--homepage" style={{width:"80px",paddingRight: "5px",paddingBottom: "5px"}}/>
                    </div>
              </Segment>
            </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </React.Fragment>
  );
};



HomePage.propTypes = {
  //directoryOptions: PropTypes.object
  isAdmin:PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    isEligible: state.authorize.isEligible
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    getEligible: (payload) => authorizeActions.getEligible(payload),
  };

  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
