/* eslint-disable max-lines */
import { deviceTypeConstants } from "../_constants/";
import { alertActions } from "./";
import axios from "axios";

export const deviceTypeActions = {
 getDeviceTypeList,
 postDeviceType,
 deleteDeviceType,
 getDeviceTypeById,
 patchDeviceType
};

const deivceTypePath = "/api/device";

function getDeviceTypeList(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { params: payload };

    return new Promise((resolve, reject) => {
      axios
        .get(`${deivceTypePath}/deviceType`, requestOptions)
        .then((response) => {
          dispatch(success(response.data));
          resolve(response.data.total);
        })
        .catch((error) => {
          dispatch(failure(error));
          dispatch(
            alertActions.error(
              error.response.data.message ? error.response.data.message : "Something went wrong! Get Device list failed",
              "Error!"
            )
          );
          dispatch(alertActions.clear());
          reject();
        });
    });
  };

  function request() {
    return { type: deviceTypeConstants.GET_DEVICE_TYPE_LIST_REQUEST, payload };
  }

  function success(response) {
    return { type: deviceTypeConstants.GET_DEVICE_TYPE_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceTypeConstants.GET_DEVICE_TYPE_LIST_FAILURE, error };
  }
}

function postDeviceType(payload) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .post(`${deivceTypePath}/deviceType`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Create device type is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong! Post Device Failed",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceTypeConstants.POST_DEVICE_TYPE_REQUEST, payload };
  }

  function success(response) {
    return { type: deviceTypeConstants.POST_DEVICE_TYPE_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceTypeConstants.POST_DEVICE_TYPE_FAILURE, error };
  }
}

function deleteDeviceType(id, payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { data: payload };

    return axios
      .delete(`${deivceTypePath}/deviceType/${id}`, requestOptions)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Delete device type is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong! Delete Device failed",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceTypeConstants.DELETE_DEVICE_TYPE_REQUEST, id, payload };
  }

  function success(response) {
    return { type: deviceTypeConstants.DELETE_DEVICE_TYPE_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceTypeConstants.DELETE_DEVICE_TYPE_FAILURE, error };
  }
}

function getDeviceTypeById(id) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .get(`${deivceTypePath}/deviceType/${id}`)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong! Get Device by Id failed",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceTypeConstants.GET_DEVICE_TYPE_BY_ID_REQUEST, id };
  }

  function success(response) {
    return { type: deviceTypeConstants.GET_DEVICE_TYPE_BY_ID_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceTypeConstants.GET_DEVICE_TYPE_BY_ID_FAILURE, error };
  }
}

function patchDeviceType(id, payload) {
  return (dispatch) => {
    dispatch(request());

    return axios
      .patch(`${deivceTypePath}/deviceType/${id}`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Update device type is successful!"));
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error.response.data.message ? error.response.data.message : "Something went wrong! Update Device failed",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: deviceTypeConstants.PATCH_DEVICE_TYPE_REQUEST, id, payload };
  }

  function success(response) {
    return { type: deviceTypeConstants.PATCH_DEVICE_TYPE_SUCCESS, response };
  }

  function failure(error) {
    return { type: deviceTypeConstants.PATCH_DEVICE_TYPE_FAILURE, error };
  }
}
