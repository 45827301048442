import React from "react";

const SVG = ({
  className = "",
  viewBox = "0 0 32 32",
  width = "32",
  height = "32",
  x = "10",
  y = "8",
  fillColor = "transparent"
}) => (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink" className={`svg-icon ${className || ""}`}>
      <circle id={"circle-user"} cx={height / 2} cy={width / 2} r={height / 2} fill={fillColor} />
      <svg id={"icon-user"} x={x} y={y} width={width / 2} height={height / 2} viewBox="0 0 392 392" strokeWidth="25">
        <circle cx="159.635" cy="113" r="99.5" />
        <path
          d="M104.635 198C75.1349 220 9.63489 225.5 13.1349 314.5C58.6349 372 200.135 424.5 306.635 315.5C310.135 263.5 294.135 228.5 215.135 201.5" />
      </svg>
    </svg>
  );

export default SVG;
