import React, { Component } from "react";
import { Button, Dropdown, Grid, GridRow, Header, Image, Modal, Segment, TableCell, Popup } from "semantic-ui-react";

import { connect } from "react-redux";

import handleLoading from "../Loader/Loader";
import PropTypes from "prop-types";
import IconCustom from "../../assets/icons";
import DateTimePickerComponent from "../DateTimePicker/DateTimePickerComponent"
import { DateHelper } from "../../_helpers/DateHelper";

export class EditExpireDateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      valueDate: props.value,
      valueChange: ""
    };

  }

  handleOpenModal = () => {
    if (this.props.isUpdateDevice) {
      this.setState({ modalOpen: true });
    } else {
      this.props.handleSelectedRow(this.props.name);
    }
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false, valueChange: "" });
  };

  onChange = (e) => {
    this.setState({ valueDate: e, valueChange: e });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.postAttributeStatus) {
      this.handleCloseModal();
    }
    if(nextProps.value) {
      if (this.state.valueChange === "") {
        this.setState({ valueDate: nextProps.value });
      }
    }
  }

  isExpired = (dateVal) => {
    const today = new Date();
    const date = new Date(dateVal);
    if(today.getTime() >= date.getTime()) {
      return "font--error";
    }else if(DateHelper.convertMiliseconds(date.getTime() - today.getTime(), "d") <= 7 ){
      return "font--warning";
    }
    return "";
  }

  handleTriggerType = (triggerType, value, index, width) => {
    switch (triggerType) {
      case "table":
        return (
          <td key={index} onClick={this.handleOpenModal}>
            <span className={value? this.isExpired(value) : ""}>{value? DateHelper.convertToDateTime(value) : ""}</span>
            {
              this.props.isUpdateDevice ?
              <Popup
                trigger={
                  <span>
                    <IconCustom
                      className={"svg-icon__edit--noneborder"}
                      stroke={"gray"}
                      name={"editNoneBorder"}
                      viewBox={"0 0 32 32"}
                      width={"24"}
                      height={"24"}
                    />
                  </span>
                }
                content="Edit expire date"
                mouseLeaveDelay={0}
                position='left center'
                basic
              /> : null
            }
          </td>
        );
      case "tableDevice":
        return (
          <TableCell onClick={this.handleOpenModal} width={width}>
            <span className={value? this.isExpired(value) : ""}>{value? DateHelper.convertToDateTime(value) : ""}</span>
            {
              this.props.isUpdateDevice ?
              <Popup
                trigger={
                  <span>
                    <IconCustom
                      className={"svg-icon__edit--noneborder"}
                      stroke={"gray"}
                      name={"editNoneBorder"}
                      viewBox={"0 0 32 32"}
                      width={"24"}
                      height={"24"}
                    />
                  </span>
                }
                content="Edit expire date"
                mouseLeaveDelay={0}
                basic
              /> : null
            }
          </TableCell>
        );
      default:
        return null;
    }
  };

  render() {
    const { modalOpen, valueDate } = this.state;
    const {
      triggerType,
      value,
      index,
      width,
      id
    } = this.props;

    return (
      <Modal
        size="tiny"
        // className="modal--width80p--withoutpadding"
        trigger={this.handleTriggerType(triggerType, value, index, width)}
        open={modalOpen}
        onClose={this.handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
      >

        <Modal.Content>
          <Grid columns="equal">
            <Grid.Column/>
              <Grid.Column width={12}>
                <Segment
                  textAlign="center"
                  className="segment--center"
                  basic
                >
                  <Header className="font--title1">Edit Expire Date</Header>
                </Segment>
                <Segment
                  textAlign="center"
                  className="segment--center"
                  basic
                >
                  <DateTimePickerComponent
                    onChange={this.onChange}
                    value={valueDate}
                  />
                </Segment>
                <Segment basic textAlign="center">
                  <Grid columns="equal">
                    <Grid.Column>
                      <Button
                        className="btn--secondary btn--width100p"
                        onClick={this.handleCloseModal}
                      >
                        CLOSE
                      </Button>
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        className="btn--primary btn--width100p"
                        onClick={() => {this.props.handleDateTime(id, valueDate)}}
                      >
                        UPDATE
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column/>
              <Grid.Row></Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

EditExpireDateModal.propTypes = {
  triggerType: PropTypes.bool
};

const mapStateToProps = (state) => ({
  postAttributeStatus: state.device.postAttributeStatus
});

export default connect(
  mapStateToProps,
  null
)(EditExpireDateModal);
