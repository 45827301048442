/* eslint-disable complexity */
import { dataConstants } from "../_constants";
import { isEmpty } from 'lodash';

export function data(state = {}, action) {
  switch (action.type) {

    // get telemetry list export
    case dataConstants.GET_TELEMETRY_EXPORT_REQUEST:
      return {};
    case dataConstants.GET_TELEMETRY_EXPORT_SUCCESS:
      return {
        taskId: action.response.data.taskId
      };
    case dataConstants.GET_TELEMETRY_EXPORT_FAILURE:
      return {
        error: action.error,
        deviceStatus: action.error.response.status
      };

    // get status telemetry list export
    case dataConstants.GET_TELEMETRY_EXPORT_STATUS_REQUEST:
      return {};
    case dataConstants.GET_TELEMETRY_EXPORT_STATUS_SUCCESS:
      return {
        status: action.response.data.status,
        url: action.response.data.url
      };
    case dataConstants.GET_TELEMETRY_EXPORT_STATUS_FAILURE:
      return {
        error: action.error,
        deviceStatus: action.error.response.status
      };

    // send status success telemetry list export
    case dataConstants.SEND_TELEMETRY_EXPORT_SUCCESS_REQUEST:
      return {};
    case dataConstants.SEND_TELEMETRY_EXPORT_SUCCESS_SUCCESS:
      return {};
    case dataConstants.SEND_TELEMETRY_EXPORT_SUCCESS_FAILURE:
      return {
        error: action.error,
        deviceStatus: action.error.response.status
      };

    default:
      return state;
  }
}
