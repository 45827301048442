export const authorizeConstants = {

  // get roles
  GET_ROLES_REQUEST: 'AUTHORIZE_GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS: 'AUTHORIZE_GET_ROLES_SUCCESS',
  GET_ROLES_FAILURE: 'AUTHORIZE_GET_ROLES_FAILURE',

  // get roles
  GET_ROLES_NO_STATE_REQUEST: 'AUTHORIZE_GET_ROLES_NO_STATE_REQUEST',
  GET_ROLES_NO_STATE_SUCCESS: 'AUTHORIZE_GET_ROLES_NO_STATE_SUCCESS',
  GET_ROLES_NO_STATE_FAILURE: 'AUTHORIZE_GET_ROLES_NO_STATE_FAILURE',

  // get Authority
  GET_AUTHORITY_REQUEST: 'GET_AUTHORITY_REQUEST',
  GET_AUTHORITY_SUCCESS: 'GET_AUTHORITY_SUCCESS',
  GET_AUTHORITY_FAILURE: 'GET_AUTHORITY_FAILURE',

  // delete Authority
  DEL_AUTHORITY_REQUEST: 'DEL_AUTHORITY_REQUEST',
  DEL_AUTHORITY_SUCCESS: 'DEL_AUTHORITY_SUCCESS',
  DEL_AUTHORITY_FAILURE: 'DEL_AUTHORITY_FAILURE',

  // Assign Role to user
  ASSIGN_ROLE_REQUEST: 'ASSIGN_ROLE_REQUEST',
  ASSIGN_ROLE_SUCCESS: 'ASSIGN_ROLE_SUCCESS',
  ASSIGN_ROLE_FAILURE: 'ASSIGN_ROLE_FAILURE',

  // Assign Role to user
  UN_ASSIGN_ROLE_REQUEST: 'UN_ASSIGN_ROLE_REQUEST',
  UN_ASSIGN_ROLE_SUCCESS: 'UN_ASSIGN_ROLE_SUCCESS',
  UN_ASSIGN_ROLE_FAILURE: 'UN_ASSIGN_ROLE_FAILURE',

  // Get list of users by directory path
  GET_AUTHORITY_LIST_REQUEST: 'GET_AUTHORITY_LIST_REQUEST',
  GET_AUTHORITY_LIST_SUCCESS: 'GET_AUTHORITY_LIST_SUCCESS',
  GET_AUTHORITY_LIST_FAILURE: 'GET_AUTHORITY_LIST_FAILURE',

  // get role information
  GET_ROLE_INFO_REQUEST: 'GET_ROLE_INFO_REQUEST',
  GET_ROLE_INFO_SUCCESS: 'GET_ROLE_INFO_SUCCESS',
  GET_ROLE_INFO_FAILURE: 'GET_ROLE_INFO_FAILURE',

  // get permission list
  GET_ELIGIBLE_REQUEST: 'GET_ELIGIBLE_REQUEST',
  GET_ELIGIBLE_SUCCESS: 'GET_ELIGIBLE_SUCCESS',
  GET_ELIGIBLE_FAILURE: 'GET_ELIGIBLE_FAILURE',

  // get permission list
  GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_REQUEST: 'GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_REQUEST',
  GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_SUCCESS: 'GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_SUCCESS',
  GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_FAILURE: 'GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_FAILURE',

  // get users list by search
  GET_USER_SEARCH_LIST_REQUEST: 'GET_USER_SEARCH_LIST_REQUEST',
  GET_USER_SEARCH_LIST_SUCCESS: 'GET_USER_SEARCH_LIST_SUCCESS',
  GET_USER_SEARCH_LIST_FAILURE: 'GET_USER_SEARCH_LIST_FAILURE',

  //  --------------------------------------------- Non Api ---------------------------------------- //

  INCREMENT_ROLE_REQUEST: 'INCREMENT_ROLE_REQUEST',
  INCREMENT_ROLE: 'INCREMENT_ROLE',

  DECREMENT_ROLE_REQUEST: 'DECREMENT_ROLE_REQUEST',
  DECREMENT_ROLE: 'DECREMENT_ROLE',

  REMOVE_ROLE_REQUEST: 'REMOVE_ROLE_REQUEST',
  REMOVE_ROLE: 'REMOVE_ROLE'
};

