import React from "react";
import { Checkbox, Icon, Label, Popup, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import IconCustom from "../../assets/icons";
import GetTokenModal from "../Modal/GetTokenModal";
import { RoleColor } from "../../_helpers";
import EditExpireDateModal from "../Modal/EditExpireDateModal";
import { DeviceErrorModal } from "../Modal/DeviceErrorModal";

export const VehicleRow = (props) => {
  const roleColor = (item) => {
    switch (item) {
      case "admin":
      case "5e786223925a581eccd8e577":
        return (
          <IconCustom
            name={"user"}
            className={"svg-icon__user svg-icon__user--wider"}
            fillColor={RoleColor("admin")}
          />
        );
      case "admin_account":
      case "5e786223925a581eccd8e578":
        return (
          <IconCustom
            name={"user"}
            className={"svg-icon__user svg-icon__user--wider"}
            fillColor={RoleColor("admin_account")}
          />
        );
      case "admin_directory":
      case "5e786223925a581eccd8e579":
        return (
          <IconCustom
            name={"user"}
            className={"svg-icon__user svg-icon__user--wider"}
            fillColor={RoleColor("admin_directory")}
          />
        );
      case "admin_asset_and_device":
      case "5e786223925a581eccd8e57c":
        return (
          <IconCustom
            name={"user"}
            className={"svg-icon__user svg-icon__user--wider"}
            fillColor={RoleColor("admin_asset_and_device")}
          />
        );
      case "admin_authorize":
      case "5e786223925a581eccd8e57b":
        return (
          <IconCustom
            name={"user"}
            className={"svg-icon__user svg-icon__user--wider"}
            fillColor={RoleColor("admin_authorize")}
          />
        );
      case "user":
      case "5e786223925a581eccd8e57d":
        return (
          <IconCustom
            name={"user"}
            className={"svg-icon__user svg-icon__user--wider"}
            fillColor={RoleColor("user")}
          />
        );
      case "admin_dashboard":
      case "5e786223925a581eccd8e57a":
        return (
          <IconCustom
            name={"user"}
            className={"svg-icon__user svg-icon__user--wider"}
            fillColor={RoleColor("admin_dashboard")}
          />
        );

      default:
        return (
          <IconCustom
            name={"user"}
            className={"svg-icon__user svg-icon__user--wider"}
            fillColor={"gray"}
          />
        );
    }
  };

  const tableCell = (key, index) => {
    switch (key) {
      case "_id":
      case "telephoneNumber":
      case "username":
      case "address1":
      case "address2":
      case "province":
      case "country":
      case "postal":
      case "description":
      case "deleting":
        return null;
      case "roles":
        return (
          <td key={index}>
            {props.vehicle.roles.map((item) => (
              <Popup
                content={item}
                mouseLeaveDelay={0}
                position='left center'
                key={item}

                // header={item.name}
                // trigger={roleColor(item)}
                trigger={
                  <IconCustom
                    name={"user"}
                    className={"svg-icon__user svg-icon__user--wider"}
                    fillColor={RoleColor(item)}
                  />
                }
              />
            ))}
          </td>
        );
      case "token":
        return null;
      case "delStatus":
        return (
          <td key={index}>
            {
              props.vehicle[key] &&
              <Label color="red">
                Failed
              </Label>
            }
          </td>
        );
      case "expiredOn":
        return (
          <EditExpireDateModal
            triggerType="table"
            id={props.vehicle._id}
            name={props.vehicle}
            descType={"device"}
            handleDateTime={props.handleDateTime}
            value={props.vehicle[key]}
            index={index}
            isUpdateDevice={!props.isAdminRoot && props.isUpdateCustomDevice ? false : props.isUpdateDevice}
            handleSelectedRow={props.handleSelectedRow}
          />
        )
      case "error":
        return (
          <td key={index} style={{ textAlign: 'left' }} >
            { props.vehicle[key] && props.vehicle[key].length > 0 ?
              <DeviceErrorModal
                id={props.vehicle._id}
                name={props.vehicle}
                value={props.vehicle[key]}
                className={"btn--icon--row col--error-left"}
              /> : ""
            }
          </td>
        )
      default:
        return (
          <td key={index} onClick={() => props.handleSelectedRow(props.vehicle)}>
            <Link>
              {props.vehicle[key]}
            </Link>
          </td>
        );
    }
  };

  const currentUser = JSON.parse(localStorage.getItem("user")) || { _id: "" };
  return (
    <tr>
      {Object.keys(props.vehicle).map((key, index) => {
        return tableCell(key, index);
      })}

      {
        props.vehicle.deleting ?
        <td className="table__cell--loading">
          <Icon loading name="spinner" />
        </td> :
        <td style={{ textAlign: 'center' }} >
          { !props.user && <GetTokenModal id={props.vehicle._id} />}
          {
            props.isUpdateDevice || (props.isAdminRoot || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.vehicle._id)) ?

            <Popup
              trigger={
                <Button
                  // className={`button__icon btn_icon--info ${!props.user || (props.user && currentUser._id !== props.vehicle._id) ? "mg-right" : ""}`}
                  className={`button__icon btn_icon--info mg-right`}
                  icon
                  onClick={() => props.handleSelectedRow(props.vehicle)}
                >
                  <Icon name='pencil' />
                </Button>
              }
              content="Edit"
              mouseLeaveDelay={0}
              position='left center'
              basic
            /> :
            <Popup
              trigger={
                <Button className={`button__icon btn_icon--primary pd-left-2 mg-right`} icon onClick={() => props.handleSelectedRow(props.vehicle)}>
                  <Icon name='eye' />
                </Button>
              }
              content="View"
              mouseLeaveDelay={0}
              position='left center'
              basic
            />
          }
         { !props.user || (props.user && currentUser._id !== props.vehicle._id) ?
            props.isShowCheckbox === true ? (
              <Checkbox
                className="checkbox--border-red"
                checked={props.checkedListAll.includes(props.vehicle._id)}
                id={props.vehicle._id}
                onChange={(e) => props.handleCheckboxClick(e)}
              />
            ) : (
                // <span onClick={() => props.handleSwitchCheckbox(props.vehicle._id)}>
                //   <IconCustom
                //     className={"svg-icon__bin"}
                //     name={"bin"}
                //     viewBox={"0 0 33 33"}
                //     width={"33"}
                //     height={"33"}
                //     x={"9"}
                //     y={"7.5"}
                //   />
                // </span>
                <Popup
                  basic
                  mouseLeaveDelay={0}
                  position='left center'
                  content={props.user? 'Remove from directory' : 'Delete'}
                  trigger={
                    ( props.user && props.isDeleteAccount) || (props.user === undefined && props.isDeleteDevice) ?
                    <Button
                      className={"button__icon btn_icon--danger pd-left"}
                      icon
                      onClick={() => props.handleSwitchCheckbox(props.vehicle._id)}
                    >
                      {props.user? <Icon name='remove user' /> : <Icon name='trash alternate outline' />}
                    </Button> : null
                    } />

              ) : null
         }

       </td>
      }
    </tr>
  );
};
