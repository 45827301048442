import React from "react";
import { Dimmer, Image, Loader, Segment } from "semantic-ui-react";

function handleLoading(loading, type) {
  if (loading) {
    if (type === "onImage") {
      return (
        <React.Fragment>
          <Segment basic>
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Image size="massive" src="../images/paragraph.svg" />
          </Segment>
        </React.Fragment>
      );
    } else if (type === "paragraph") {
      return (
        <React.Fragment>
          <Segment basic className="segment--loading">
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Image size="massive" src="../images/paragraph.svg" />
            <br />
            <Image size="massive" src="../images/paragraph.svg" />
          </Segment>
        </React.Fragment>
      );
    } else if (type === "tableLoading") {
      return (
        <Segment textAlign="center" className="segment--center height17em" basic>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      );
    }

    return (
      <React.Fragment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </React.Fragment>
    );
  }

  return null;
}

export default handleLoading;
