import React from "react";
import PropTypes from "prop-types";
import { Header, Segment } from "semantic-ui-react";

const EmptyLoader = (props) => {
  return (
    <React.Fragment>
      <Segment textAlign="center"
               className="segment--center height17em"
               basic>
        <Header className="header--emptyArr">
          {props.desc ? props.desc : null}
        </Header>
      </Segment>
    </React.Fragment>
  );
};

EmptyLoader.propsType = {
  desc: PropTypes.string
};

export default EmptyLoader;
