import React from "react";
import { Button, Grid, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

export const SidebarButton = (props) => {
  return (
    <Segment textAlign="center" basic className={props.className ? props.className : ""}>
      <Grid columns="equal" centered>
        <Grid.Column width={props.width? props.width : 8}>
          <Button
            className={props.activeSecondaryBtn ? "btn--primary btn--width100p" : "btn--secondary btn--width100p"}
            onClick={props.OnCloseSidebar}
          >
            {props.btnSecondary}
          </Button>
        </Grid.Column>
        <Grid.Column width={props.width? props.width : 8}>
          {
            props.isUpdateAccount || props.isUpdateDevice ?
            <Button
              className="btn--primary btn--width100p"
              disabled={props.disabled}
              onClick={props.handleSubmit}
            >
              {props.btnActive}
            </Button> :
            null
          }
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

SidebarButton.propTypes = {
  activeSecondaryBtn: PropTypes.bool
};

export default SidebarButton;
