import React from 'react';
import PropTypes from 'prop-types';

export function VehicleTableHeader(props) {
  return (
    <thead>
      <tr className="header">
        {
          props.vehiclesHeader.map((header, index) => <th
            key={index}
            className="first"
          >
            <div className="th-inner">
              {header}
            </div>

          </th>)
        }
      </tr>
    </thead>
  );
}

VehicleTableHeader.propTypes = {
  vehiclesHeader: PropTypes.array
};
