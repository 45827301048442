import React, { Component } from "react";
import { Button, Container, Form, Grid, Header, Image, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

import { accountActions } from "../../_actions/account.actions";
import { connect } from "react-redux";

import InputForm from "../../components/Input/InputForm";
import { NavbarLogo } from "../../components/Menu/NavBarLogo";
import handleLoading from "../../components/Loader/Loader";
import AlertsModal from "../../components/Modal/AlertsModal";
import { alertActions } from "../../_actions/alert.actions";

class VerifyOTPPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpCode: "",
      phoneNumber:""
    };
    console.log("props=====",props)
    console.log("this.props.location.state.phoneNumber",this.props.location.state.telephoneNumber)
  }

  handleChange = (data) => {
    this.setState({ otpCode: data.value });
  };

  validate = () => {
    let errorOTP = "";
    const { otpCode } = this.state;

    // if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email) === false) {
    //   errorEmail = "Please enter your email address.";
    // }

    if (otpCode === "") {
      errorOTP = "Please enter your OTP code from your mobile phone";
    }

    if (errorOTP) {
      this.setState({ errorOTP });
      return false;
    }

      this.setState({
        errorOTP: ""
      });

    return true;
  };

  handleSubmit = () => {
    const isValid = this.validate();
    const payload = {
      telephonenumber: this.props.location.state.telephoneNumber,
      token: this.state.otpCode
    };

    if (isValid) {
      // call login function in actions file by props
      this.props.verify(payload).then((res) => {
        console.log("handleSubmit,this.props.verify(payload).then((res)=>",res)
        // if(localStorage.getItem('remember')){

        // }
        if (localStorage.getItem('remember') === true) {
          if(this.state.email.includes("@")) {
            localStorage.setItem('email', this.state.email);
            if(localStorage.getItem('username')) localStorage.removeItem('username');
          }
          else {
            localStorage.setItem('username', this.state.email);
            if(localStorage.getItem('email')) localStorage.removeItem('email');
          }
        }else {
          if(localStorage.getItem('email')) localStorage.removeItem('email');
          if(localStorage.getItem('username')) localStorage.removeItem('username');
        }
      });
    }
  };
  render() {
    
    const { otpCode } = this.state.otpCode;
    const { loading } = this.props;

    return (
      <React.Fragment>
        <NavbarLogo />
        <Grid className={"grid-form"}>
          <Grid.Column width={1} />
          <Grid.Column width={14}>
            <Segment className="segment-change-password">
              {handleLoading(loading)}

              <Grid textAlign="center">
                <Grid.Row verticalAlign="middle">
                  <Grid.Column width={8}>
                    <Image src="./images/forgot-password.svg" />
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <Header
                      textAlign={"left"}
                      className={"header__title"}
                      as="h2"
                    >
                      OTP Verification
                    </Header>

                    <Container
                      textAlign={"left"}
                      className={"container__desc container__desc--space-bot"}
                    >
                      <p>Authentication already send to your mobile phone</p>
                      <label className="verifyOTPNumber-label">{this.props.location.state.telephoneNumber}</label>
                      <p>Please check SMS in your mobile device</p>
                    </Container>

                    <Form>
                      <InputForm
                        icon={"key"}
                        name={"otpCode"}
                        type={"password"}
                        placeholder="OTP Code"
                        minlength={6}
                        maxlength={6}
                        handleChange={(event, data) => this.handleChange(data)}
                      />
                      <p className="font--error">{this.state.errorOTP}</p>
                      <br />

                      <Button
                        type="submit"
                        floated="right"
                        content={"Verify"}
                        className={"btn--primary btn--width144px"}
                        disabled={this.state.otpCode.length!=6}
                        onClick={this.handleSubmit}
                      />

                      {/* <label className="verifyOTPRequestCode-label" >
                        Request Code
                      </label> */}
                    </Form>


                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={1} />
        </Grid>

        {/* <AlertsModal /> */}
      </React.Fragment>
    );
  }
}

VerifyOTPPage.propTypes = {
  changePass: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  loading: state.account.loading
});

const mapDispatchToProps = (dispatch) => ({
  verify: (payload) => dispatch(accountActions.verify(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyOTPPage);
