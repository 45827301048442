import React, {Component} from 'react';

import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

export default class JSONEditorDemo extends Component {
  componentDidMount () {
    const { mode, onChangeJSON, onChange, onChangeText, json } = this.props;
    const options = {
      mode: mode,
      onChangeJSON: onChangeJSON,
      onChange: onChange,
      onChangeText: onChangeText,
      history: false
    };

    this.jsoneditor = new JSONEditor(this.container, options);

    if (mode !== "tree") {
      console.log("componentDidMount setText")
      this.jsoneditor.setText(json);
    } else {
      console.log("componentDidMount set")
      this.jsoneditor.set(json);
    }
  }

  componentWillUnmount () {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  componentDidUpdate() {
    const { mode, json } = this.props;
    // this.jsoneditor.update(this.props.json);
    if (mode !== "tree") {
      console.log("componentDidUpdate setText")
      this.jsoneditor.updateText(json);
    } else {
      console.log("componentDidUpdate set")
      this.jsoneditor.update(json);
    }
  }

  render() {
    return (
        <div style={{width: '100%', height: '100%'}} className="jsoneditor-react-container" ref={elem => this.container = elem} />
    );
  }
}