import React, { Component } from "react";
import { Button, Container, Form, Grid, Header, Image, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

import { accountActions } from "../../_actions/account.actions";
import { connect } from "react-redux";

import InputForm from "../../components/Input/InputForm";
import { NavbarLogo } from "../../components/Menu/NavBarLogo";
import handleLoading from "../../components/Loader/Loader";
import AlertsModal from "../../components/Modal/AlertsModal";

class SetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: ""
    };
  }

  handleChange = (data) => {
    this.setState({ password: data.value });
  };

  handleSubmit = () => {
    const { password } = this.state;

    const payload = {
      password
    };

    const token = new URLSearchParams(this.props.location.search).get("token");
    this.props.changePass(payload, "setPassword", token);
  };

  render() {
    const { password } = this.state;
    const { loading } = this.props;

    return (
      <React.Fragment>
        <NavbarLogo />
        <Grid className={"grid-form"}>
          <Grid.Column width={1} />
          <Grid.Column width={14}>
            <Segment className="segment-change-password">
              {handleLoading(loading)}

              <Grid textAlign="center">
                <Grid.Row verticalAlign="middle">
                  <Grid.Column width={8}>
                    <Image src="./images/forgot-password.svg" />
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <Header
                      textAlign={"left"}
                      className={"header__title"}
                      as="h2"
                    >
                      SET YOUR PASSWORD
                    </Header>

                    <Container
                      textAlign={"left"}
                      className={"container__desc container__desc--space-bot"}
                    >
                      <p>Please enter your password below.</p>
                    </Container>

                    <Form>
                      <InputForm
                        icon={"lock"}
                        name={"password"}
                        type={"password"}
                        placeholder="Password"
                        handleChange={(event, data) => this.handleChange(data)}
                      />
                      <br />

                      <Button
                        type="submit"
                        floated="right"
                        content={"SAVE"}
                        className={"btn--primary btn--width144px"}
                        disabled={!password}
                        onClick={this.handleSubmit}
                      />
                    </Form>


                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={1} />
        </Grid>

        <AlertsModal />
      </React.Fragment>
    );
  }
}

SetPasswordPage.propTypes = {
  changePass: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  loading: state.account.loading
});

const mapDispatchToProps = (dispatch) => ({
  changePass: (payload, setPassword, token) => dispatch(accountActions.changePass(payload, setPassword, token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetPasswordPage);
