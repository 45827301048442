import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { relationActions } from "../../../_actions/";
import RelationModal from "../../../components/Modal/RelationModal";
import { RelationRow } from "./RelationRow";
import SelectionDropdown from "../../../components/Dropdown/SelectionDropdown";

class RelationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemInfo: this.props.itemInfo,
      direction: "from",
      options: [
        { key: "from", text: "From", value: "from" },
        { key: "to", text: "To", value: "to" }
      ],

      // modal
      modalOpen: false
    };

    this.handleOpenModal = () => {
      this.setState({ modalOpen: true });
    };

    this.handleCloseModal = () => {
      this.setState({ modalOpen: false });
    };
  }

  // componentWillMount = () => {
  //   const { itemInfo, direction } = this.state;

  //   this.props.getRelationList(itemInfo._id, direction);
  // };

  handleChangeDropdown = (value) => {
    const { itemInfo } = this.state;

    this.setState(
      {
        direction: value
      },
      () => this.props.getRelationList(itemInfo._id, value)
    );
  };

  handleShowDetail = (id) => {
    this.props.getRelationInfo(id).then(() => this.handleOpenModal());
  };

  handleSubmitModal = (payload) => {
    const { direction } = this.state;

    this.props.postRelation(payload, direction);
  };

  handleDeleteItem = (id) => {
    this.props.delRelation(id);
  };

  render() {
    const { itemInfo, direction, options, modalOpen } = this.state;
    const { fromEntityType, relationList } = this.props;

    return (
      <React.Fragment>
        <Grid.Row textAlign="left">
          <Grid.Column width={7}>
            <Header className="font--body pd--top--bottom">RELATIONS</Header>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              icon
              className="btn--icon--border main"
              onClick={this.handleOpenModal}
            >
              <Icon name="plus" />
            </Button>
          </Grid.Column>
          <Grid.Column width={6}>
            <SelectionDropdown
              initialValue={direction}
              options={options}
              handleChangeDropdown={this.handleChangeDropdown}
            />
          </Grid.Column>
        </Grid.Row>

        {/* attach label to a content segment */}
        {relationList &&
          (relationList.length > 0 ? (
            <React.Fragment>
              {relationList.map((relation, index) => (
                <RelationRow
                  key={index}
                  relation={relation}
                  handleShowDetail={this.handleShowDetail}
                  handleDeleteItem={this.handleDeleteItem}
                />
              ))}
            </React.Fragment>
          ) : (
            <Grid.Row>
              <Grid.Column textAlign="center">
                <p>No relationship</p>
              </Grid.Column>
            </Grid.Row>
          ))}

        {/* relation modalOpen */}
        <RelationModal
          fromEntityType={fromEntityType}
          itemInfo={itemInfo}

          // modal
          modalOpen={modalOpen}
          handleCloseModal={this.handleCloseModal}
          handleSubmitModal={this.handleSubmitModal}
        />
      </React.Fragment>
    );
  }
}

RelationComponent.propTypes = {
  fromEntityType: PropTypes.string.isRequired,
  relationList: PropTypes.arrayOf(PropTypes.object),
  itemInfo: PropTypes.object.isRequired,
  getRelationList: PropTypes.func.isRequired,
  getRelationInfo: PropTypes.func.isRequired,
  postRelation: PropTypes.func.isRequired,
  delRelation: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  getRelationList: (id, direction) => dispatch(relationActions.getRelationList(id, direction)),
  getRelationInfo: (id) => dispatch(relationActions.getRelationInfo(id)),
  postRelation: (payload, direction) => dispatch(relationActions.postRelation(payload, direction)),
  delRelation: (id) => dispatch(relationActions.delRelation(id)),
  getEntityType: (toEntityType) => dispatch(relationActions.getEntityType(toEntityType))
});

const mapStateToProps = (state) => ({
  relationList: state.relation.relationList
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelationComponent);
