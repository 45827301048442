/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import SegmentGradients from "../../../components/Segment/SegmentGradients";
import { Button, Dropdown, Header, Grid, Form } from "semantic-ui-react";
import TextField from "../../../components/TextField/TextField";
import { authorizeActions } from "../../../_actions/authorize.actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormGroupRole from "../../../components/Form/FormGroupRole";
import SidebarButton from "../../../components/Button/SidebarButton";
import IconCustom from "../../../assets/icons";
import { accountActions, directoryActions, userActions } from "../../../_actions";
import * as Regex from "../../../utils/Validator";
import AccountDirectoryRole from "../Component/AccountDirectoryRole";
import { accountPageActions } from "../../../_actions/pages/accountPage.action";
import { makeGetDirectoryToDropDown } from "../../../_selectors";
import DefaultTextArea from "../../../components/TextArea/TextArea";
import SearchDropdown from "../../../components/Dropdown/SearchDropdown";
import Countries from '../../../assets/data/countries';

const AccountDetailSideBar = (props) => {
  const [disableRoleBtn, setDisableRoleBtn] = useState(true);
  const [disablePrimaryBtn, setDisablePrimaryBtn] = useState(true);
  const [roleSelected, setRoleSelected] = useState([]);
  const [roleSelecting, setRoleSelecting] = useState({});
  const [inputValue, setInputValue] = useState({});
  const [disableInput, setDisableInput] = useState(false);
  const [textError, setTextError] = useState('');
  const [numError, setNumError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);
  const [directoryOptions, setDirectoryOptions] = useState([]);
  const [directorySelecting, setDirectorySelecting] = useState('');
  const [roleRemove, setRoleRemove] = useState([]);
  const [roleAdd, setRoleAdd] = useState([])
  const [roleValue, setRoleValue] = useState('');
  const [directoryValue, setDirectoryValue] = useState('');
  const [roleOriginal, setRoleOriginal] = useState([]);

  const countryOptions = Countries.COUNTRIES_LIST;

  const currentUser = JSON.parse(localStorage.getItem("user"))

  // const inputNameRef = createRef();

  const handleOnChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
    // if(e.target.name === "fullNameValue") setTextError('')
    // else 
    if(e.target.name === "firstnameValue") setTextError('')
    else if(e.target.name === "lastnameValue") setTextError('')
    else if(e.target.name === "phoneNumberValue") setNumError('')
    else if(e.target.name === "usernameValue") setUsernameError('')
    else if(e.target.name === "passwordValue") setPasswordError('')
    else if(e.target.name === "emailValue") setEmailError('')
  };

  const handleOnChangeCountry = (value) => {
    setInputValue({ ...inputValue, ["countryValue"]: value });
  };

  const handleChangeDirectory = (e, data) => {
    setDirectoryValue(data.value);
    const directoryPath = props.accountInfo.directoryPath;
    const directory = directoryPath.find((o) => o.key === data.value);
    const payload = { action: '*', subject: '*', directoryPath: '/' };
    Promise.resolve(props.getEligible(payload)).then((response) => {
      props.updateEligible(response.isEligible);
      const directoryPath = props.accountInfo.directoryPath;
      const getDirectoryToDropdown = function(directory) {
        return directory.map((value) => ({
          key: value._id,
          value: value.path,
          text: value.name
        }))
      };

      props.getRolesNoState({ directoryPath: data.value }).then((roleOption) => {
        // setup dropdown directory
        // role other
        if (!response.isEligible) {
          if (directory !== undefined) {
            setRoleOptions(roleOption.filter((item) => { return !directory.roles.includes(item.key) }).filter((item) => { return item.text !== 'admin' }));
          } else {
            setRoleOptions(roleOption);
          }
        } else {
          if (directory !== undefined) {
            setRoleOptions(roleOption.filter((item) => { return !directory.roles.includes(item.key) }));
          } else {
            setRoleOptions(roleOption);
          }
        }
      });

      setDirectorySelecting(data.value);
    });
  };

  const handleChangeRole = (e, data) => {
    setRoleValue(data.value);
    const option = roleOptions.find((o) => o.value === data.value);

    if (disableRoleBtn) {
      setDisableRoleBtn((prevState) => !prevState);
    }

    setRoleSelecting(option);
  };

  const onClickRole = () => {
    const payload = {
      key: roleSelecting.key
    };

    if (!props.isEditingAcc) {
      props.decrementRole(payload);
    } else {
      const directoryOriginal = roleOriginal.find((o) => o.directoryPath === directorySelecting);
      if (directoryOriginal === undefined) {
        roleAdd.push({
          role: roleSelecting.key,
          directoryPath: directorySelecting
        });
        setRoleAdd(roleAdd);
      } else {
        if (!directoryOriginal.roles.includes(roleSelecting.key)) {
          roleAdd.push({
            role: roleSelecting.key,
            directoryPath: directorySelecting
          });
          setRoleAdd(roleAdd);
        } else {
          const role = roleRemove.filter((o) => o.role !== roleSelecting.key && o.directoryPath !== directorySelecting);
          setRoleRemove(role);
        }
      }

      const directory = props.accountInfo.directoryPath.find((o) => o.key === directorySelecting);
      if (directory === undefined) {
        props.accountInfo.directoryPath = [{ key: directorySelecting, roles: [roleSelecting.key]}, ...props.accountInfo.directoryPath];
      } else {
        directory.roles.push(roleSelecting.key);
      }
    }

    setDirectorySelecting('');
    setRoleSelecting({});
    setRoleSelected((prevState) => [roleSelecting, ...prevState]);
    setDisableRoleBtn((prevState) => !prevState);
    handleDisablePrimaryBtn(false);
    setRoleValue('');
    setDirectoryValue('');
    setRoleOptions([]);
  };

  const onClickRemoveRole = (e, { value }) => {
    const roleSelectedTmp = roleSelected.find((item) => item.key === value);

    const payload = {
      option: roleSelectedTmp
    };

    props.incrementRole(payload);
    setRoleSelected((prevState) => prevState.filter((item) => item.key !== value));
  };

  const handleDisablePrimaryBtn = (status) => {
    if (roleAdd.length > 0 || roleRemove.length > 0) {
      setDisablePrimaryBtn(false);
    } else {
      setDisablePrimaryBtn(status);
    }
  };

  const onClickPrimary = () => {
    if (props.isEditingAcc) {
      //const splitFullName = inputValue.fullNameValue.split(" ", 2);
      let someError = false;

      if (inputValue.firstnameValue && inputValue.lastnameValue) {
        let firstname = inputValue.firstnameValue;
        let lastname = inputValue.lastnameValue;
        let telephoneNumber = inputValue.phoneNumberValue;
        let email = inputValue.emailValue;
        let address1 = inputValue.address1Value;
        let address2 = inputValue.address2Value;
        let province = inputValue.provinceValue;
        let country = inputValue.countryValue;
        let postal = inputValue.postalValue;
        let description = inputValue.descriptionValue;

        // splitFullName.forEach((value, index) => {
        //   if (_.isEqual(index, 0)) {
        //     firstname = value;
        //   } else if (_.isEqual(index, 1)) {
        //     lastname = value;
        //   }
        // });

        if(!Regex.validateMobileWithoutCC(telephoneNumber)) {
          setNumError('Please enter valid phone number');
          someError = true;
        }

        if(!someError) {
          const payload = {
            userId: props.accountInfo.userId,
            requestBody: { email, firstname, lastname, telephoneNumber, address1, address2, province, country, postal, description }
          };

          let roleAddPayload = [];
          roleAdd.forEach((role, index) => {
            if (roleAddPayload.length !== 0) {
              const rolePayload = roleAddPayload.find((o) => o.directoryPath === role.directoryPath);
              if (rolePayload === undefined) {
                roleAddPayload.push({
                  userId: [props.accountInfo.user._id],
                  directoryPath: role.directoryPath,
                  roles: [role.role]
                });
              } else {
                rolePayload.roles.push(role.role);
              }
            } else {
              roleAddPayload.push({
                userId: [props.accountInfo.user._id],
                directoryPath: role.directoryPath,
                roles: [role.role]
              });
            }
          });

          let roleRemovePayload = [];
          roleRemove.forEach((role, index) => {
            if (roleRemovePayload.length !== 0) {
              const rolePayload = roleRemovePayload.find((o) => o.directoryPath === role.directoryPath);
              if (rolePayload === undefined) {
                roleRemovePayload.push({
                  userId: [props.accountInfo.user._id],
                  directoryPath: role.directoryPath,
                  roles: [role.role]
                });
              } else {
                rolePayload.roles.push(role.role);
              }
            } else {
              roleRemovePayload.push({
                userId: [props.accountInfo.user._id],
                directoryPath: role.directoryPath,
                roles: [role.role]
              });
            }
          });

          if (roleAddPayload.length !== 0 && roleRemovePayload.length !== 0) {
            const addRole = roleAddPayload.map((roleAdd) => props.postAssignRole(roleAdd));
            const deleteRole = roleRemovePayload.map((roleRemove) => props.postUnassignRole(roleRemove));

            Promise.all([addRole, deleteRole]).then((values) => {
              props.updateUserInfo(payload)
                .then(() => { props.getRoles({}); props.updatingTable(); });
            });
          } else if (roleAddPayload.length !== 0 && roleRemovePayload.length === 0) {
            const addRole = roleAddPayload.map((roleAdd) => props.postAssignRole(roleAdd));

            Promise.all(addRole).then((values) => {
              props.updateUserInfo(payload)
                .then(() => { props.getRoles({}); props.updatingTable(); });
            });
          } else if (roleAddPayload.length === 0 && roleRemovePayload.length !== 0) {
            const deleteRole = roleRemovePayload.map((roleRemove) => props.postUnassignRole(roleRemove));

            Promise.all(deleteRole).then((values) => {
              props.updateUserInfo(payload)
                .then(() => { props.getRoles({}); props.updatingTable(); });
            });
          } else {
            props.updateUserInfo(payload)
              .then(() => { props.getRoles({}); props.updatingTable(); });
          }
        }


      }
    } else {
      //const splitFullName = inputValue.fullNameValue.split(" ", 2);
      const telephoneNumber = inputValue.phoneNumberValue;
      const email = inputValue.emailValue;
      const username = inputValue.usernameValue;
      const password = inputValue.passwordValue;
      const address1 = inputValue.address1Value;
      const address2 = inputValue.address2Value;
      const province = inputValue.provinceValue;
      const country = inputValue.countryValue;
      const postal = inputValue.postalValue;
      const description = inputValue.descriptionValue;
      let someError = false;

      let firstname = inputValue.firstnameValue;
      let lastname = inputValue.firstnameValue;
      // if (splitFullName && splitFullName.length) {
      //   splitFullName.forEach((value, index) => {
      //     if (index === 0) {
      //       firstname = value;
      //     } else if (index === 1) {
      //       lastname = value;
      //     }
      //   });
      // }

      const roles = roleSelected.map((value) => value.key);

      if (props.accountInfo.directory === false) {
        const payload = {
          userId: [props.accountInfo.user._id],
          roles,
          directoryPath: props.accountInfo.directoryPath
        };

        props.assignRole(payload).then(() => {
          props.onClickPrimary(true);
        });
      } else {
        if (lastname === null || lastname === "") {
          setTextError('Please enter your lastname');
          someError = true;
        }
        if(inputValue.usernameValue === ""){
          setUsernameError('Please enter username');
          someError = true;
        }
        if(inputValue.emailValue === ""){
          setEmailError('Please enter email');
          someError = true;
        }
        if(inputValue.passwordValue === ""){
          setPasswordError('Please enter password');
          someError = true;
        }
        if (!Regex.validateMobileWithoutCC(telephoneNumber)) {
          setNumError('Please enter valid phone number');
          someError = true;
        }
        if(!someError) {
          const payload = {
            email,
            firstname,
            lastname,
            telephoneNumber,
            username,
            password,
            address1,
            address2,
            province,
            country,
            postal,
            description,
            roles,
            directoryPath: props.accountInfo.directoryPath
          };

          // const fields = ["email", "firstname", "lastname", "username", "password", "telephoneNumber", "address1", "address2", "province", "postal", "description"];

          // const deleteField = (field, value) => {
          //   if (_.isEmpty(value)) {
          //     delete payload[field];
          //   }
          // }

          // fields.forEach((field)=>{
          //   deleteField(field, payload[field]);
          // });

          props.postUser(payload, props.firstType).then(() => {
            props.onClickPrimary(true);
            props.updatingTable();
            props.getRoles({ directoryPath: props.accountInfo.directoryPath });
          });
        }
      }
    }
  };

  // check permission admin root
  const checkEligible = () => {
    const payload = { action: '*', subject: '*', directoryPath: '/' };
    Promise.resolve(props.getEligible(payload)).then((response) => {
      props.updateEligible(response.isEligible);
      const directoryPath = props.accountInfo.directoryPath;
      const getDirectoryToDropdown = function(directory) {
        return directory.map((value) => ({
          key: value._id,
          value: value.path,
          text: value.name
        }))
      };

      // setup dropdown directory
      // role other
      if (!response.isEligible) {
        const directoryList = props.directory.data.data.filter((item) => { return directoryPath.filter((dir) => { return item.path.includes(dir.key) }).length > 0 && (item.path !== '/' || item.parent !== null) });
        setDirectoryOptions(getDirectoryToDropdown(directoryList));
      }else{
        setDirectoryOptions(getDirectoryToDropdown(props.directory.data.data));
      }
    });
  };

  const checkEligibleRole = () => {
    const directoryPath = props.accountInfo.directoryPath;
    const payload = { action: '*', subject: '*', directoryPath: directoryPath };

    Promise.resolve(props.getEligible(payload)).then((response) => {
      props.updateEligible(response.isEligible);
      if (!response.isEligible) {
        setRoleOptions(props.options.filter((item)=> item.text !== "admin"));
      } else {
        setRoleOptions(props.options);
      }
    });
  }

  const onClickUnAssignRole = (roleId, dirPath) => {
    const directory = props.accountInfo.directoryPath.find((o) => o.key === dirPath);
    const dirRole = directory.roles.filter((o) => o !== roleId);
    props.accountInfo.directoryPath.find((o) => o.key === dirPath).roles = dirRole;
    const roleNew = roleAdd.find((o) => o.role === roleId && o.directoryPath === dirPath);
    if (roleNew === undefined) {
      roleRemove.push({
        role: roleId,
        directoryPath: dirPath
      });
      setRoleRemove(roleRemove);
    } else {
      const role = roleAdd.filter((o) => o.role !== roleId && o.directoryPath !== dirPath);
      setRoleAdd(role);
    }

    handleDisablePrimaryBtn(false);
    setDirectoryOptions([...directoryOptions]);
  };

  const handleSecondaryBtn = () => {
    props.onClickSecondary();
  };

  // create user
  // useEffect(() => {
  //   // inputNameRef.current.focus();

  //   if (!props.isEditingAcc) {
  //     props.getRoles();
  //   }

  //   return () => {
  //     setDisableInput(false);
  //   };
  // });

  useEffect(() => {
    if (!props.isEditingAcc) {
      Promise.resolve(props.getRoles({ directoryPath: props.accountInfo.directoryPath })).then(() => {
        checkEligibleRole();
      })
    }

    if (!_.isEmpty(props.isEditingAcc) && !_.isEmpty(props.accountInfo) && props.isEditingAcc) {
      setRoleOriginal(props.accountInfo.directoryPath.map(info => ({ directoryPath: info.key, roles: info.roles })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update info
  useEffect(() => {
    if (props.isEditingAcc) {
      const account = props.accountInfo;

      if (inputValue && inputValue.firstnameValue && inputValue.lastnameValue && inputValue.phoneNumberValue) {
        const fieldModify = _.isEqual(inputValue.firstnameValue, account.user.firstname.toString())
        && _.isEqual(inputValue.lastnameValue, account.user.lastname.toString())
        && _.isEqual(inputValue.phoneNumberValue, account.user.telephoneNumber.toString())
        && _.isEqual(inputValue.emailValue, account.user.email? account.user.email.toString(): "")
        && _.isEqual(inputValue.address1Value, account.user.address1? account.user.address1.toString(): "")
        && _.isEqual(inputValue.address2Value, account.user.address2? account.user.address2.toString(): "")
        && _.isEqual(inputValue.provinceValue, account.user.province? account.user.province.toString(): "")
        && _.isEqual(inputValue.countryValue, account.user.country? account.user.country.toString(): "")
        && _.isEqual(inputValue.postalValue, account.user.postal? account.user.postal.toString(): "")
        && _.isEqual(inputValue.descriptionValue, account.user.description? account.user.description.toString(): "");
        const validation = Regex.validateMobileWithoutCC(
          inputValue.phoneNumberValue
        );

        if (!fieldModify && validation) {
          if (disablePrimaryBtn) { handleDisablePrimaryBtn(false); }
        } else {
          if (!disablePrimaryBtn) { handleDisablePrimaryBtn(true); }
        }
      } else {
        if (!disablePrimaryBtn) { handleDisablePrimaryBtn(true); }
      }
    } else {
      if (inputValue && inputValue.firstnameValue && inputValue.lastnameValue && inputValue.emailValue && Regex.validateEmail(inputValue.emailValue) &&
        inputValue.phoneNumberValue && Regex.validateMobileWithoutCC(inputValue.phoneNumberValue) && roleSelected && roleSelected.length) {
        if (disablePrimaryBtn) {
          handleDisablePrimaryBtn(false);
        }
      } else {
        if (!disablePrimaryBtn) {
          handleDisablePrimaryBtn(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, roleSelected]);

  useEffect(() => {
    if (!_.isEmpty(props.accountInfo)) {
      console.log(props.accountInfo);
      setInputValue({
        // fullNameValue:
        //   props.accountInfo.user.firstname && props.accountInfo.user.lastname ?
        //     `${props.accountInfo.user.firstname} ${props.accountInfo.user.lastname}` : "",
        firstnameValue: props.accountInfo.user.firstname ? props.accountInfo.user.firstname : "",
        lastnameValue: props.accountInfo.user.lastname ? props.accountInfo.user.lastname : "",
        emailValue: props.accountInfo.user.email,
        phoneNumberValue: props.accountInfo.user.telephoneNumber ? props.accountInfo.user.telephoneNumber : "",
        usernameValue: props.accountInfo.user.username ? props.accountInfo.user.username : "",
        passwordValue: props.accountInfo.user.password ? props.accountInfo.user.password : "",
        address1Value: props.accountInfo.user.address1 ? props.accountInfo.user.address1 : "",
        address2Value: props.accountInfo.user.address2 ? props.accountInfo.user.address2 : "",
        provinceValue: props.accountInfo.user.province ? props.accountInfo.user.province : "",
        countryValue: props.accountInfo.user.country ? props.accountInfo.user.country : "",
        postalValue: props.accountInfo.user.postal ? props.accountInfo.user.postal : "",
        descriptionValue: props.accountInfo.user.description ? props.accountInfo.user.description : ""
      });

      if (
        _.isBoolean(props.accountInfo.directory) && props.accountInfo.directory === false
      ) {
        setDisableInput(true);
      }

      if (props.isEditingAcc) {
        checkEligible();
      }
    }
  }, [props.accountInfo]);

  useEffect(() => {
    if (!props.isEditingAcc) {
      checkEligibleRole();
    }
  }, [props.options]);

  return (
    <React.Fragment>
      {/* <div>
        <SegmentGradients />
      </div> */}
      <div>
        <Header
          className={"header__title1 header__title1--sidebar"}
          textAlign={"center"}
        >
          {
            props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId) ?
              (props.isEditingAcc ? "Edit Account": !disableInput? "Create Account" : "Update Account Role") : "Account Detail"
          }
        </Header>
      </div>
      <div className={"add-sidebar__body add-sidebar__body--editing"}>
        <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                {/* <TextField
                  // refTextField={inputNameRef}
                  className={"--bottom"}
                  text={"Name"}
                  placeholder={"Full Name"}
                  id={"name"}
                  name={"fullNameValue"}
                  value={inputValue.fullNameValue}
                  handleInputValue={handleOnChange}
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                  error={textError}
                /> */}

                <TextField
                  // refTextField={inputNameRef}
                  className={"--bottom"}
                  text={"First Name"}
                  placeholder={"First Name"}
                  id={"firstname"}
                  name={"firstnameValue"}
                  value={inputValue.firstnameValue}
                  handleInputValue={handleOnChange}
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                  error={textError}
                />

                <TextField
                  // refTextField={inputNameRef}
                  className={"--bottom"}
                  text={"Last Name"}
                  placeholder={"Last Name"}
                  id={"lastname"}
                  name={"lastnameValue"}
                  value={inputValue.lastnameValue}
                  handleInputValue={handleOnChange}
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                  error={textError}
                />

                <TextField
                  type={"number"}
                  className={"--bottom"}
                  text={"Phone"}
                  placeholder={"Phone number"}
                  value={inputValue.phoneNumberValue}
                  id={"phone-number"}
                  name={"phoneNumberValue"}
                  handleInputValue={handleOnChange}
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                  error={numError}
                />
                <TextField
                  className={"--bottom"}
                  text={"Address1"}
                  placeholder={"Address1"}
                  value={inputValue.address1Value}
                  id={"address1"}
                  name={"address1Value"}
                  handleInputValue={handleOnChange}
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                />
                <TextField
                  className={"--bottom"}
                  text={"Address2"}
                  placeholder={"Address2"}
                  value={inputValue.address2Value}
                  id={"address2"}
                  name={"address2Value"}
                  handleInputValue={handleOnChange}
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                />
                <TextField
                  className={"--bottom"}
                  text={"Province"}
                  placeholder={"Province"}
                  value={inputValue.provinceValue}
                  id={"province"}
                  name={"provinceValue"}
                  handleInputValue={handleOnChange}
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                />
                <SearchDropdown
                  includeClassName={"dropdown__sidebar dropdown__sidebar--inline dropdown__sidebar--bottom"}
                  options={countryOptions}
                  initialValue={inputValue.countryValue}
                  text={"Select country"}
                  name={"countryValue"}
                  search={true}
                  handleChangeDropdown={handleOnChangeCountry}
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                />
                <TextField
                  className={"--last"}
                  text={"Postal"}
                  placeholder={"Postal"}
                  value={inputValue.postalValue}
                  id={"postal"}
                  name={"postalValue"}
                  handleInputValue={handleOnChange}
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                />
                <div className={"div__header-sidebar--description"}>
                  <Header className={"header__title-sidebar field__sidebar-last"}>Description</Header>
                </div>
                <DefaultTextArea
                  name="descriptionValue"
                  text={"Description"}
                  value={inputValue.descriptionValue}
                  handleChange={handleOnChange}
                  placeholder="Description"
                  isDisable={!(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
                  padding={"pd--bottom"}
                />
              </Grid.Column>
              <Grid.Column>
                <TextField
                  className={"--bottom"}
                  text={"Username"}
                  placeholder={"Username"}
                  value={inputValue.usernameValue}
                  id={"username"}
                  name={"usernameValue"}
                  handleInputValue={handleOnChange}
                  isDisable={props.isEditingAcc || disableInput || props.firstType === "username"}
                  error={usernameError}
                />
                <TextField
                  type={"email"}
                  className={"--bottom"}
                  text={"Email"}
                  placeholder={"Email address"}
                  id={"email"}
                  name={"emailValue"}
                  value={inputValue.emailValue}
                  handleInputValue={handleOnChange}
                  isDisable={props.isEditingAcc || disableInput || props.firstType === "email"}
                  error={emailError}
                />
                {(!props.isEditingAcc && !disableInput) && <TextField
                  type={"password"}
                  className={"--bottom"}
                  text={"Password"}
                  placeholder={"Password"}
                  value={inputValue.passwordValue}
                  id={"password"}
                  name={"passwordValue"}
                  handleInputValue={handleOnChange}
                  isDisable={props.isEditingAcc || disableInput}
                  error={passwordError}
                />}
                <br/>
                { props.isEditingAcc ?
                  <React.Fragment>
                    {
                      props.accountInfo && props.accountInfo.directoryPath && props.accountInfo.directoryPath.length ?
                        props.accountInfo.directoryPath.map((item, index) => <AccountDirectoryRole
                          name={item.key}
                          directoryList={props.directory.data.data}
                          key={index}
                          dirId={item}
                          indexDirectory={index + 1}
                          amountDirectory={props.accountInfo.directoryPath.length}
                          roles={item.roles}
                          onClickUnAssign={onClickUnAssignRole}
                          roleList={props.roles}
                          loading={props.loading}
                          getDirectory={props.getDirectory}
                          isDisable={!props.isCreateAccount}
                        />) :
                        null

                    }
                    {
                      props.isCreateAccount ?
                      <>
                      <div className={`div__inline-warp div__inline-warp--w100`}>
                        <Dropdown
                          className={"dropdown__sidebar dropdown__sidebar--inline dropdown__sidebar--bottom"}
                          selection
                          options={directoryOptions}
                          value={directoryValue}
                          placeholder="Select Directory Name"
                          noResultsMessage={"No results found."}
                          onChange={handleChangeDirectory}
                        />
                      </div>
                      <div className={`div__inline-warp`}>
                        <Dropdown
                          className={"dropdown__sidebar dropdown__sidebar--inline"}
                          selection
                          options={roleOptions}
                          value={roleValue}
                          placeholder="Select Role"
                          noResultsMessage={"No results found."}
                          onChange={handleChangeRole}
                        />
                        <Button
                          basic
                          fluid
                          className={"button__image button__image--sumbit-role"}
                          onClick={onClickRole}
                          disabled={disableRoleBtn}
                        >
                          <IconCustom
                            name={"check"}
                            className={"svg-icon__check"}
                            fillColor={"ffffff"}
                            width={"40"}
                            height={"40"}
                            viewBox={"0 0 40 40"}
                            reactX={"6"}
                            reactY={"6"}
                          />
                        </Button>
                      </div></> : null
                    }

                  </React.Fragment> : (
                <React.Fragment>
                  {roleSelected && roleSelected.length ?
                    roleSelected.map((item) => (
                      <Form>
                        <Form.Field>
                          <FormGroupRole
                            key={item.key}
                            title={item.value}
                            desc={item.desc}
                            value={item.key}
                            onClickRemove={onClickRemoveRole}
                            isDisable={!props.isCreateAccount}
                          />
                        </Form.Field>
                      </Form>
                    )) :
                    null}

                  { props.isCreateAccount && roleOptions && roleOptions.length ? (
                    <div className={`div__inline-warp`}>
                      <Dropdown
                        className={"dropdown__sidebar dropdown__sidebar--inline"}
                        selection
                        options={roleOptions}
                        placeholder="Select Role"
                        noResultsMessage={"No results found."}
                        onChange={handleChangeRole}
                      />
                      <Button
                        basic
                        fluid
                        className={"button__image button__image--sumbit-role"}
                        onClick={onClickRole}
                        disabled={disableRoleBtn}
                      >
                        <IconCustom
                          name={"check"}
                          className={"svg-icon__check"}
                          fillColor={"ffffff"}
                          width={"40"}
                          height={"40"}
                          viewBox={"0 0 40 40"}
                          reactX={"6"}
                          reactY={"6"}
                        />
                      </Button>
                    </div>) : null
                  }
                </React.Fragment>
              )}
              </Grid.Column>
            </Grid.Row>
        </Grid>
      </div>
      <SidebarButton
        disabled={disablePrimaryBtn}
        btnSecondary={props.secondaryText}
        btnActive={props.primaryText}
        handleSubmit={onClickPrimary}
        OnCloseSidebar={handleSecondaryBtn}
        width={5}
        isUpdateAccount={(props.isAdmin || (!props.isUpdateCustomAccount && props.isUpdateAccount) || (props.isUpdateCustomAccount && currentUser._id === props.accountInfo.userId))}
      />
    </React.Fragment>

  );
};

AccountDetailSideBar.propTypes = {
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
  secondaryText: PropTypes.string,
  primaryText: PropTypes.string,
  roles: PropTypes.array,
  isEditingAcc: PropTypes.bool,
  isMailChecked: PropTypes.bool,
  options: PropTypes.array,
  accountInfo: PropTypes.object,
  directory: PropTypes.object,
  directoryOptions: PropTypes.object
};

const mapStateToProps = () => {
  const getDirectoryToDropdown = makeGetDirectoryToDropDown();

  return (state) => {
    return {
      roles: state.authorize.roles,
      options: state.authorize.options,
      directory: state.directory.directory,
      directoryOptions: getDirectoryToDropdown(state.directory),
      loading: state.authorize.loading
    }
  }
};

const mapDispatchToProps = (dispatch) => ({
  postUser: (payload, firstType) => dispatch(userActions.postUser(payload, firstType)),
  postAssignRole: (payload) => dispatch(userActions.postAssignRole(payload)),
  postUnassignRole: (payload) => dispatch(userActions.postUnassignRole(payload)),
  getAuthorityById: (payload) => dispatch(authorizeActions.getAuthorityById(payload)),
  updateUserInfo: (payload) => dispatch(accountActions.updateUserInfo(payload)),
  getRoles: (payload) => dispatch(authorizeActions.getRoles(payload)),
  getRolesNoState: (payload) => dispatch(authorizeActions.getRolesNoState(payload)),
  incrementRole: (payload) => dispatch(authorizeActions.incrementRole(payload)),
  decrementRole: (payload) => dispatch(authorizeActions.decrementRole(payload)),
  assignRole: (payload) => dispatch(authorizeActions.assignRole(payload)),
  unAssignRole: (payload) => dispatch(authorizeActions.unAssignRole(payload)),
  getAuthority: (payload) => dispatch(authorizeActions.getAuthority(payload)),
  updatingTable: () => dispatch(accountPageActions.updatingTable()),
  getDirectory: () => dispatch(directoryActions.getDirectory()),
  getEligible: (payload) => dispatch(authorizeActions.getEligible(payload)),
  updateEligible: (eligible) => dispatch(accountPageActions.updateEligible(eligible))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailSideBar);
