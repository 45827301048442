import React from 'react';
import { Grid, Image, Menu } from 'semantic-ui-react';

export const NavbarLogo = () => {
  return (
    <div style={{ padding: '1rem' }}>
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column></Grid.Column>
          <Grid.Column width={14}>
            <Menu secondary className="layout-menu">
              <Menu.Item className="pd-left0">
                <Image src="./images/logo-kubota.png" alt="logo" className="logo" />
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

