import React, { Component } from "react";
import { Button, Dropdown, Grid, Header, Image, Modal, Segment, Icon, Popup } from "semantic-ui-react";

import { connect } from "react-redux";

import handleLoading from "../Loader/Loader";
import PropTypes from "prop-types";
import IconCustom from "../../assets/icons";

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      removeList: ["user", "role", "userdirectory"]
    };

    this.handleOpenModal = () => {
      this.setState({ modalOpen: true });
    };

    this.handleCloseModal = () => {
      this.setState({ modalOpen: false });
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.delStatus === 204) {
      this.setState({
        modalOpen: false
      });
    }
  }

  handleDirNameType = (descType, descText) => {
    switch (descType.toLowerCase()) {
      case "userdirectory":
        return "role on this directory from the";
      case "user":
        return "user from this directory";
      case "role":
        return "user from this role";
      case "device":
        return "device name";
      case "asset":
        return "asset name";
      case "directory":
        return "directory name";
      case "related":
        return "relation with";
      default:
        return `${descText && descText.length ? descText : ''}`;
    }
  };

  handleDeleteItem = (id, role, dir) => {
    const { closeModal } = this.props;

    //if (closeModal) {
      this.setState({
        modalOpen: false
      }, () => this.props.handleDeleteItem(id, role, dir));
    //} else {
    //   this.props.handleDeleteItem(id, role, dir);
    // }
  }

  handleTriggerType = (triggerType) => {
    switch (triggerType) {
      case "dropdown__item--delete":
        return (
          <Dropdown.Item onClick={this.handleOpenModal}>DELETE</Dropdown.Item>
        );
      case "icon--trash":
        return (
          <Popup
            trigger={
              <Button
                floated={"right"}
                disabled={this.props.id.length < 1}
                icon
                className="button__icon btn_icon--danger mg-left"
                onClick={this.handleOpenModal}>
                <Icon name={`${this.props.icon && this.props.icon.length ? this.props.icon : 'trash alternate outline'}`}/>
              </Button>
            }
            content="Delete"
            mouseLeaveDelay={0}
            basic
          />
        );

      case "icon--bin":
        return (
          <span onClick={this.handleOpenModal}>
            <IconCustom
              className={"svg-icon__bin marginTop--30"}
              name={"bin"}
              viewBox={"0 0 32 32"}
              width={"32"}
              height={"32"}
              x={"8.5"}
              y={"7.5"}
            />
          </span>
        );
      default:
        return null;
    }
  };

  render() {
    const { modalOpen } = this.state;
    const {
      id,
      name,
      descType,
      triggerType,
      role,
      deleting,
      descText,
      dir
    } = this.props;

    return (
      <Modal
        className="modal--width80p--withoutpadding"
        trigger={this.handleTriggerType(triggerType)}
        open={modalOpen}
        onClose={this.handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
        onClick={e => e.stopPropagation()}
      >
        {handleLoading(deleting)}

        <Modal.Content>
          <Grid columns="equal">
            <Grid.Column />
            <Grid.Column width={8}>
              <Image src="./images/img_del_modal.svg" />
            </Grid.Column>

            <Grid.Column width={4}>
              <Segment
                textAlign="center"
                className="segment--center height75p"
                basic
              >
                <Header className="font--title1">Are you sure?</Header>
                <Header className="font--title2 color--gray weight--normal without-margin">
                  You want to {descType && descType.length ? (descType === 'userdirectory' ? "unassign" : (this.state.removeList.includes(descType)? "remove": "delete")) : "delete"}
                  {descType && descType.length && ` ${this.handleDirNameType(descType, descText)}`}
                  <br />
                  {name && typeof name === "object" ?
                    ` "${name.name}" ` :
                    ` "${name}"`}
                  <br />
                  {id && Array.isArray(id) && id.length > 1 ?
                    ` and ${id.length - 1} item(s)` :
                    null}
                  {this.state.removeList.includes(descType)?
                  <p className="font--error mg--top">Any admin roles of user(s) will be removed and cannot access administrator permissions.</p> : <></>}
                </Header>
              </Segment>

              <Segment basic textAlign="center">
                <Grid columns="equal">
                  <Grid.Column>
                    <Button
                      className="btn--secondary btn--width100p"
                      onClick={() => this.handleDeleteItem(id, role, dir)}
                    >
                      {descType && descType.length && this.state.removeList.includes(descType)? "REMOVE": "DELETE"}
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      className="btn--primary btn--width100p"
                      onClick={this.handleCloseModal}
                    >
                      CANCEL
                    </Button>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column />
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  name: PropTypes.string,
  iconName: PropTypes.string,
  handleDeleteItem: PropTypes.func,
  descType: PropTypes.string,
  descText: PropTypes.string,
  triggerType: PropTypes.bool
};

const mapStateToProps = (state) => ({
  deleting:
    state.directory.deleting ||
    state.user.deleting ||
    state.device.deleting ||
    state.asset.deleting ||
    state.relation.deleting ||
    state.report.deleting,
  delStatus:
    state.directory.delDirectoryStatus ||

    // state.user.delUserStatus ||
    state.device.delDeviceStatus ||
    state.device.delAttributeStatus ||
    state.asset.delAssetStatus ||
    state.relation.delRelationStatus ||
    state.report.delDashboardStatus
});

export default connect(
  mapStateToProps,
  null
)(DeleteModal);
