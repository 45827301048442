import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Input, Dropdown, TextArea } from "semantic-ui-react";
import { connect } from "react-redux";
import JSONEditorDemo from '../../../components/JsonEditor/JSONEditorDemo';
import DateTimePickerComponent from "../../../components/DateTimePicker/DateTimePickerComponent"

class ManageAttributeComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleChangeInput,
            handleChangeKey,
            scopeOption,
            scope,
            handleChangeScope,
            typeOption,
            type,
            handleChangeType,
            keyVal,
            valueVal,
            triggerType
        } = this.props;

        return (
            <React.Fragment>
                <>
                    <Grid.Column width="5">
                        <Grid.Row>Key</Grid.Row>
                        <Input
                            fluid
                            type="text"
                            placeholder="Enter key"
                            value={keyVal}
                            name="key"
                            onChange={handleChangeKey}
                            disabled={triggerType!=="add"}
                        />
                        <br/>
                        <Grid.Row>Scope</Grid.Row>
                        {scope !== "client" ? <Dropdown
                            className="dropdown__selection"
                            options={scopeOption}
                            selection
                            placeholder="Scope"
                            value={scope}
                            onChange={handleChangeScope}
                            disabled={triggerType!=="add"}
                        /> :
                        <Input
                            fluid
                            type="text"
                            placeholder="Enter key"
                            value={scope}
                            name="key"
                            onChange={handleChangeKey}
                            disabled={true}
                        />
                        }
                        <br/>
                        <br/>
                        <Grid.Row>Type</Grid.Row>
                        <Dropdown
                            className="dropdown__selection"
                            options={typeOption}
                            selection
                            placeholder="Type"
                            value={type}
                            onChange={handleChangeType}
                            disabled={triggerType==="view"}
                        />
                    </Grid.Column>
                    {
                        type === "text" &&
                        (<Grid.Column width="11">
                            <Grid.Row>Value</Grid.Row>
                            <Input
                                fluid
                                type="text"
                                placeholder="Enter value"
                                value={valueVal}
                                name="value"
                                onChange={handleChangeInput}
                                disabled={triggerType==="view"}
                            />
                        </Grid.Column>)
                    }
                    {
                        type === "json" &&
                        (<Grid.Column width="11">
                            <Grid.Row>Value</Grid.Row>
                            <JSONEditorDemo
                                mode={triggerType !== "view" ? "code" : "preview"}
                                json={valueVal}
                                onChangeText={handleChangeInput}
                            />
                        </Grid.Column>)
                    }
                    {
                        type === "date" &&
                        (<Grid.Column width="11">
                            <Grid.Row>Value</Grid.Row>
                            <DateTimePickerComponent
                                onChange={handleChangeInput}
                                value={valueVal !== "" ? valueVal : new Date()}
                                disabled={triggerType === "view"}
                            />
                        </Grid.Column>)
                    }

                    {/* <Grid.Row className="removePadding">
                        <Grid.Column width="7">
                            <Grid.Row>Key</Grid.Row>
                            <Input
                                type="text"
                                placeholder="Enter key"
                                value={item.key}
                                name="key"
                                onChange={handleChangeInput.bind(this, index)}
                            />
                        </Grid.Column>
                        <Grid.Column width="9">
                            <Grid.Row>Value</Grid.Row>
                            <JSONEditorDemo
                                json={this.state.json}
                                onChangeJSON={this.onChangeJSON,handleChangeInput.bind(this, index)}
                            />
                        </Grid.Column>

                        <Grid.Column width="7">
                            <Input
                                type="text"
                                placeholder="Enter value"
                                value={item.value}
                                name="value"
                                onChange={handleChangeInput.bind(this, index)}
                            />
                        </Grid.Column>

                        <Grid.Column width="2">
                            <span onClick={ attribute.length === 1 ? null : () => decreaseAttribute(index)}>
                                <IconCustom
                                    name="bin"
                                    className={"svg-icon__bin"}
                                    viewBox={"0 0 32 32"}
                                    width={"32"}
                                    height={"32"}
                                    x={"8.5"}
                                    y={"7.5"} />
                            </span>
                        </Grid.Column>
                    </Grid.Row> */}
                </>
            </React.Fragment>
        );
    }
}

ManageAttributeComponent.propTypes = {
    attribute: PropTypes.object,
    removeAttribute: PropTypes.func,
    handleChangeInput: PropTypes.func
};

const mapStateToProps = (state) => ({});

export default connect(
    mapStateToProps,
    null
)(ManageAttributeComponent);
