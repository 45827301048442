import { alertActions } from "../_actions";
import { has } from "lodash";

export const alertFetchServiceError = (dispatch, error) => {
  const message = "Something went wrong!";
  const title = "Error!";

  dispatch(alertActions.error(
    has(error, "response") && error.response.data.message ?
      error.response.data.message : message,
    title
  ));
  dispatch(alertActions.clear());
};
