import React, { Component } from "react";
import { Button, Grid, Header, Image, Modal, Segment } from "semantic-ui-react";

import { connect } from "react-redux";

class AlertsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: true,
      pathCode: parseInt(window.location.search.split("=")[1])
    };

    this.handleOpenModal = () => this.setState({ modalOpen: true });
    this.handleCloseModal = () => this.setState({ modalOpen: false });
  }

  render() {
    const { modalOpen, pathCode } = this.state;

    return (
      <Modal
        size="tiny"
        open={modalOpen}
        onClose={this.handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
      >
        <Modal.Header
          className={`modal__header--alert ${
            pathCode === 200 ? "success" : "failed"
          }`}
        >
          <Grid centered>
            <Grid.Row>
              {pathCode === 200 ? (
                <Image src="./images/verify_success.svg" />
              ) : (
                <Image src="./images/verify_failed.svg" />
              )}
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <Segment basic>
            <Grid centered>
              <Grid.Row>
                {pathCode === 200 ? (
                  <Header className="font--title2">
                    Email Address Verified!
                  </Header>
                ) : (
                  <Header className="font--title2">Verified Failed!</Header>
                )}

                {pathCode === 200 ? (
                  <p className="font--body">
                    Your email address has been successfully verified, please
                    click "Continue" button to follow the last step for create
                    your account.
                  </p>
                ) : (
                  <p className="font--body">
                    Your email address has been verified failed, please click
                    verify link for try again.
                  </p>
                )}
              </Grid.Row>

              {pathCode === 200 ? (
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Button
                      fluid
                      className="btn--primary"
                      onClick={this.handleCloseModal}
                    >
                      CONTINUE
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              ) : null}
            </Grid>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.directory.loading
});

export default connect(
  mapStateToProps,
  null
)(AlertsModal);
