import React from "react";
import PropTypes from "prop-types";
import { Form, Header } from "semantic-ui-react";
import _ from "lodash";
import FormGroupRole from "../../../components/Form/FormGroupRole";
import handleLoading from "../../../components/Loader/Loader";

const AccountDirectoryRole = (props) => {
  const handleOnClickUnAssignRole = (e, { value }) => {
    props.onClickUnAssign(value, props.dirId.key);
  };

  const roleList = props.roleList && props.roleList.map((i) => ({
    id: i._id,
    name: i.name,
    desc: i.description
  }));

  const roles = roleList && roleList.filter(({ id }) => {
    return props.roles.includes(id);
  });

  const findByPath = props.directoryList.find((item) => _.isEqual(item.path, props.name));
  const path = findByPath && findByPath.name;

  return (
    <React.Fragment>
      {findByPath &&
        <div
          className={`div__header-sidebar--role ${
            props.indexDirectory > 1 ? `div__header-sidebar--role-not-first` : ""
            }`}
        >
          <Header className={"header__title-sidebar"} floated="left">{findByPath && findByPath ? path : null}</Header>
          {/* <Header className={"header__title-sidebar"} floated={"right"}>{ `${
            props.indexDirectory
            }/${props.amountDirectory}`}</Header> */}
        </div>
      }

      {handleLoading(props.loading)}

      {findByPath && findByPath ? roles && roles.map((item) => (
        <Form>
          <Form.Field>
            <FormGroupRole
              key={item.id}
              title={item.name}
              desc={item.desc}
              value={item.id}
              onClickRemove={handleOnClickUnAssignRole}
              isDisable={props.isDisable}
            />
          </Form.Field>
        </Form>
      )) : null}

    </React.Fragment>
  );
};

AccountDirectoryRole.propsType = {
  dirId: PropTypes.string,
  name: PropTypes.string,
  indexDirectory: PropTypes.number,
  amountDirectory: PropTypes.number,
  roles: PropTypes.array,
  onClickUnAssign: PropTypes.func
};

export default AccountDirectoryRole;
