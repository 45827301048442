import React, { Component } from "react";
import { Button, Dropdown, Grid, Header, Icon, Label, Segment } from "semantic-ui-react";

import { connect } from "react-redux";
import { deviceActions } from "../../../_actions";
import { InputSearchTable } from "../../../components/Input/InputSearchTable";
import DeleteModal from "../../../components/Modal/DeleteModal";
import AttributeModal from "../../../components/Modal/AttributeModal";
import { DateHelper } from "../../../_helpers/DateHelper";
import moment from "moment";
import "moment-timezone";

class AttributeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scope: "all",
            keywords:"",
            options: [
                { key: "all", text: "All", value: "all" },
                { key: "client", text: "Client", value: "client" },
                { key: "server", text: "Server", value: "server" },
                { key: "shared", text: "Shared", value: "shared" }
            ]
        };
    }

    componentDidMount() {
        const { id } = this.props;
        const { scope } = this.state;

        this.props.getDeviceAttribute(id, scope);
    }

    componentWillReceiveProps(nextProps) {
        const { id } = this.props;
        const { scope } = this.state;

        if (nextProps.postAttributeStatus === 200 || nextProps.delAttributeStatus === 204) {
            this.props.getDeviceAttribute(id, scope);
        }
        
    }

    handleChangeDropdown = (e, { value }) => {
        const { id } = this.props;

        this.setState({
            scope: value
        }, () => this.props.getDeviceAttribute(id, this.state.scope));
    }

    handleSearchChange = (e, { value }) => {
        const { id } = this.props;
        const { scope } = this.state;

        this.setState({
            keywords:value
        }, () => this.props.getDeviceAttributeQuery(id, scope,this.state.keywords) ? this.props.getDeviceAttributeQuery(id, scope,this.state.keywords):this.props.getDeviceAttribute(id, scope));
      };

    handleCreateAttribute = (scope, attribute, type) => {
        attribute = attribute.filter(x => x.key !== "" && x.value !== "");
        if(attribute.length > 0) {
            this.setState({
                scope
            }, () => {
                const { id } = this.props;
                const payload = attribute.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
                this.props.postDeviceAttribute(id, scope, payload, type);
            });
        }
    }

    handleDeleteItem = (id, scope, key) => {
        this.props.delDeviceAttribute(id, scope, key);
    };

    getType = (value) => {
        if(this.isDate(value)) return "date"
        else if(this.hasJsonStructure(value) || typeof value === "object") return "json"
        else return "text"
    }

    handleShowValue = (type, value) => {
        if(type === "json") return "JSON"
        else if(type === "date") return moment.tz(value, "Asia/Bangkok").format("DD/MM/YYYY - HH:mm"); // DateHelper.convertToDateTime(value)
        return value.toString();
    }

    hasJsonStructure = (str) => {
        if (typeof str !== 'string') return false;
        try {
            const result = JSON.parse(str);
            const type = Object.prototype.toString.call(result);
            return type === '[object Object]'
                || type === '[object Array]';
        } catch (err) {
            return false;
        }
    }

    isDate = (str) => {
        if (new Date(str) !== "Invalid Date" && !isNaN(new Date(str))) {
            if(str == new Date(str).toISOString()) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    

    render() {
        const { options, scope } = this.state;
        const { id, attributeList, isUpdateDevice, isUpdateExpiredOn } = this.props;
        let attributeListArr = attributeList;
        // let metadata = [];

        // if(attributeList) {
        //     for (const property in attributeList) {
        //         if(property !== "" && property !== "$metadata" && property !== "$version") attributeListArr.push({ key: property, value: attributeList[property], type: this.getType(attributeList[property])})
        //     }
        //     if(scope === "shared") {
        //         for (const property in attributeList.$metadata) {
        //             if(property !== "" && property !== "$lastUpdated" && property !== "$lastUpdatedVersion") metadata.push({ key: property, value: attributeList.$metadata[property]})
        //         }
        //         if(metadata.length > 0) {
        //             let merged = [];
        //             attributeListArr.forEach(item => {
        //                 merged.push({
        //                     ...item,
        //                     ...(metadata.find(x => x.key === item.key)?.value)}
        //                 );
        //             })
        //             attributeListArr = merged
        //         }
        //     }
        // }

        return (
            <React.Fragment>
                <Grid style={{"margin-top": "0rem"}}>
                    {/* <Grid.Row /> */}
                    <Grid.Row textAlign="left">
                        <Grid.Column width={7}>
                            <Header className="font--body pd--top--bottom">ATTRIBUTE</Header>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {
                                isUpdateDevice ?
                                <AttributeModal
                                    handleCreateAttribute={this.handleCreateAttribute}
                                    triggerType={"add"}
                                /> : null
                            }
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Dropdown
                                className="dropdown__selection"
                                options={options}
                                selection
                                onChange={this.handleChangeDropdown}
                                value={scope}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row >
                        <Grid.Column width={16}>
                        <InputSearchTable handleSearchChange={this.handleSearchChange}  />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                            {
                                attributeListArr && attributeListArr.length >= 1 ?
                                <Grid.Column>
                                    <div className="scrollbar--segment">
                                        {attributeListArr && attributeListArr.map((item, index) =>
                                        <Segment textAlign="left" padded className="segment--relation" key={index}>
                                            <Label horizontal className="label--key">key: {item.key}</Label>
                                                <Dropdown icon="ellipsis horizontal" className="dropdown__menu--etc">
                                                    <Dropdown.Menu>
                                                        <AttributeModal
                                                            handleCreateAttribute={this.handleCreateAttribute}
                                                            triggerType={"view"}
                                                            keyEdit={item.key}
                                                            valueEdit={item.value}
                                                            typeEdit={this.getType(item.value)}
                                                            scope={item.type}
                                                        />
                                                        { (item.key !== "expiredOn" && item.type !== "client" && isUpdateDevice) || (item.key === "expiredOn" && isUpdateExpiredOn && isUpdateDevice) ?
                                                            <><AttributeModal
                                                                handleCreateAttribute={this.handleCreateAttribute}
                                                                triggerType={"edit"}
                                                                keyEdit={item.key}
                                                                valueEdit={item.value}
                                                                typeEdit={this.getType(item.value)}
                                                                scope={item.type}
                                                            />
                                                            <DeleteModal
                                                                triggerType="dropdown__item--delete"
                                                                id={id}
                                                                name={`${item.key}:${item.value}`}
                                                                handleDeleteItem={() => this.handleDeleteItem(id, item.type, item.key)}
                                                            /></> : null
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            <Header className="font--body weight--bold attribute--header" size="huge">{this.handleShowValue(this.getType(item.value), item.value)}</Header>
                                            <div className="footer-wrapper">
                                                <div>
                                                    {item.type==="shared" &&
                                                        <span className="font-caption1 color--empty">Last update: {DateHelper.convertToDateTime(item.$lastUpdated)}</span>
                                                    }
                                                </div>
                                                <div className="text-type">{item.type}</div>
                                            </div>

                                        </Segment>)}
                                    </div>
                                </Grid.Column> :
                                <Grid.Column textAlign="center">
                                    <p>No attribute</p>
                                </Grid.Column>
                            }
                    </Grid.Row>
                    {/* <Grid.Row /> */}
                </Grid>
            </React.Fragment>
        );
    }
}

AttributeComponent.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
    getDeviceAttribute: (id, scope) => dispatch(deviceActions.getDeviceAttribute(id, scope)),
    getDeviceAttributeQuery: (id, scope,keywords) => dispatch(deviceActions.getDeviceAttributeQuery(id, scope,keywords)),
    postDeviceAttribute: (id, scope, payload, type) => dispatch(deviceActions.postDeviceAttribute(id, scope, payload, type)),
    delDeviceAttribute: (id, scope, key) => dispatch(deviceActions.delDeviceAttribute(id, scope, key))
});

const mapStateToProps = (state) => ({
    attributeList: state.device.attributeList,
    attributeList: state.device.attributeList,
    postAttributeStatus: state.device.postAttributeStatus,
    delAttributeStatus: state.device.delAttributeStatus
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttributeComponent);
