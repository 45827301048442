import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown, Grid, Header, Icon, Segment } from "semantic-ui-react";
import CreateAndEditModal from "../Modal/CreateAndEditModal";
import DeleteModal from "../Modal/DeleteModal";

export default class ChildAccordion extends Component {
  render() {
    const {
      activeIndex,
      childDirectory,
      parentDir,
      getParentDirectory,
      handleUpdateItem,
      handleDeleteItem,
      status,
      directoryEligibleStatus,
      directoryPerm,
      isAdminRoot,
      activeDirectory
    } = this.props;

    return (
      <>
        {childDirectory[activeIndex] && childDirectory[activeIndex].length === 0 && directoryEligibleStatus === 403 ?
        <Segment basic textAlign="center">
          <Header as="h4" color="grey">No Data</Header>
        </Segment> :
        (
          <>
            {childDirectory[activeIndex] && childDirectory[activeIndex].map((childDir, index) => (
              <Segment
                key={index}
                onClick={(e) => getParentDirectory(parentDir, childDir, index)}
                className={`segment__menu--withborder ${(activeDirectory === childDir.name) ?"segment-selected":""}`}
              >
                <Grid>
                  <Grid.Column width={13} className="word-wrap">
                  {/* <Icon name="caret right" /> */}
                      {childDir.name}
                  </Grid.Column>

                  {this.props.directoryEligibleStatus === 403 ? null : (
                    isAdminRoot || directoryPerm.some(dir => dir.path === childDir.path) ?
                      (<Grid.Column width={3}>
                        <Dropdown
                          icon="ellipsis horizontal"
                          className="dropdown__menu--etc">
                          <Dropdown.Menu>
                            {/* dropdown-item edit */}
                            <CreateAndEditModal
                              type="dropdown__item--edit"
                              role="child"
                              id={childDir._id}
                              name={childDir ? childDir.name : ""}
                              properties={childDir ? childDir.properties : ""}
                              header="UPDATE DIRECTORY"
                              btnLeft="CLOSE"
                              btnRight="UPDATE"
                              status={status} // componentWillReceiveProps
                              handleSubmitModal={handleUpdateItem}
                            />
                            {/* dropdown-item delete */}
                            <DeleteModal
                              triggerType="dropdown__item--delete"
                              id={childDir._id}
                              name={childDir.name}
                              descType={"directory"}
                              role="child"
                              dir={childDir.path}
                              handleDeleteItem={handleDeleteItem}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Grid.Column>)
                    : null)
                  }
                </Grid>
              </Segment>
            ))}
          </>
        )}

      </>
    );
  }
}

ChildAccordion.propTypes = {
  childDirectory: PropTypes.arrayOf(PropTypes.object),
  handleUpdateItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired
};
