import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, ListContent, ListItem } from "semantic-ui-react";
import IconCustom from "../../../../assets/icons";
import PropTypes from "prop-types";

class UserNotInRoleListCell extends Component {

  onClickSelect = (event, { value }) => {
    // this.setState((prevState) => ({
    //   _isActive: !prevState._isActive
    // }), () => {
    //   this.props.handleUserSelected(value);
    // });
    event.preventDefault();
    this.props.handleUserSelected(value);
  };

  render() {
    return (
      <ListItem className={"item__add-role"}>
        <ListContent floated={'right'}>
          <Button className={"button__image"} basic onClick={this.onClickSelect} value={this.props.valueBtn}>
            <IconCustom name={'check'}
                        active={this.props.isActive}
                        className={'svg-icon__check-assign'}
                        activeClassName={'svg-icon__check-assign--checked'}/>
          </Button>
        </ListContent>
        <ListContent floated={"left"} verticalAlign={"middle"}>
          <p>{this.props.fullName}</p>
        </ListContent>
      </ListItem>
    );
  }
}

UserNotInRoleListCell.propTypes = {
  fullName: PropTypes.string.isRequired,
  handleUserSelected: PropTypes.func,
  valueBtn: PropTypes.string,
  isActive: PropTypes.bool
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(UserNotInRoleListCell);
