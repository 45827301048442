import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { DateHelper } from "../../_helpers";

const SingleLineTable = ({
  tableHeader,
  items,
  activePage,
  itemsPerPage,
  renderCellByKey,
  telemetryKey
}) => {
  const indexOfHeader = (index) => {
    if (index === 0) {
      return "HeaderCell--first";
    } else if (index === tableHeader.length - 1) {
      return "HeaderCell--last";
    }

    return null;
  };

  const renderBody = (item, activeItems) => {
    if (typeof Object.keys(item) !== 'undefined' && Object.keys(item).length > 0) {
        return <Table.Body>
        <React.Fragment>
          {Object.entries(activeItems).map((value, index) =>
          <Table.Row title={`${DateHelper.convertToDateTimeToSec(item.ts)} | ${value[0]} : ${value[1]}`}>
            <Table.Cell width={6}>
              {DateHelper.convertToDateTimeToSec(item.ts)}
            </Table.Cell>
            <Table.Cell>{value[0]}</Table.Cell>
            <Table.Cell>{value[1]}</Table.Cell>
          </Table.Row>)}
        </React.Fragment>
        </Table.Body>;
    }

    return null;
  };

  // get current items
  const indexOfLastItem = activePage * itemsPerPage; // last index of item in activePage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage; // first index of item in activePage
  const activetItems = items.slice(indexOfFirstItem, indexOfLastItem); // slice of first and last index item of activePage
  const activetItemsTelemetry = Object.keys(telemetryKey.data).slice(indexOfFirstItem, indexOfLastItem).reduce((result, key) => {
        result[key] = telemetryKey.data[key];

        return result;
    }, {});
  return (
    <Table fixed textAlign="center" className="table__header--graybg">
      <Table.Header>
        <Table.Row>
          {tableHeader.map((header, index) => (
            <Table.HeaderCell className={indexOfHeader(index)} key={index}>
              {header}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      {renderCellByKey ? (
        <Table.Body>
          {activetItems &&
            activetItems.map((item, index) => (
              <React.Fragment key={index}>
                <Table.Row>
                  {Object.entries(item).map(([key, value]) => {
                    return <Table.Cell>{value}</Table.Cell>;
                  })}
                </Table.Row>
              </React.Fragment>
            ))}
        </Table.Body>
      ) : renderBody(telemetryKey, activetItemsTelemetry)
    }

    </Table>
  );
};

SingleLineTable.propTypes = {
  renderCellByKey: PropTypes.bool,
  tableHeader: PropTypes.array.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  activePage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired
};

export default SingleLineTable;
