import React from "react";
import PropTypes from "prop-types";
import { Pagination } from "semantic-ui-react";

const PaginationBuilder = ({ activePage, totalPages, siblingRange, handleOnPageChange }) => {
  const handlePaginationChange = (e, data) => {
    handleOnPageChange(data.activePage);
  };

  return (
    <Pagination
      text
      floated={"left"}
      activePage={activePage}
      onPageChange={handlePaginationChange}
      totalPages={totalPages}
      siblingRange={siblingRange}
      firstItem={null}
      lastItem={null}
    />
  );
};

PaginationBuilder.propsType = {
  activePage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  siblingRange: PropTypes.number,
  handleOnPageChange: PropTypes.func
};

export default PaginationBuilder;