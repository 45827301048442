import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tab } from "semantic-ui-react";

// component
import Users from "../User/Users";
import SwitchingRolePermission from "../RoleAndPermission/SwitchingRolePermission";
import DeviceTab from "../Device/DeviceTab";

// import AssetTab from "../Asset/AssetTab";

class SwicthRenderTabPane extends Component {
  render() {
    const {
      activeTabIndex,
      handleTabChange,
      objDirectory,

      // open create sidebar
      handleAnimationChange,
      isSidebarOpen,

      // muliple checkbox
      isShowCheckbox,
      checkedListAll,
      isShowExportCheckbox,
      checkedListExportAll,
      handleSwitchCheckbox,
      handleCheckboxClick,
      handleSwitchExportCheckbox,
      handleExportCheckboxClick,

      // search
      searchValue,
      handleSearchChange,

      // del and select row
      handleDeleteItem,
      handleSelectedRow,

      // export
      handleExportItem,

      isAdminRoot,
      isCreateAccount,
      isUpdateAccount,
      isUpdateCustomAccount,
      isDeleteAccount,
      isAssignAccount,
      isUnassignAccount,
      isCreateDevice,
      isUpdateDevice,
      isDeleteDevice,
      isUpdateCustomDevice
    } = this.props;

    const panes = [
      {
        menuItem: {
          content: "Users",
          key: "Users"
        },
        render: () => (
          <Tab.Pane attached={false}>
            <Users

              // open create sidebar
              onOpenSidebar={handleAnimationChange}

              // directoryPath
              objDirectory={objDirectory}

              // muliple checkbox
              isShowCheckbox={isShowCheckbox}
              checkedListAll={checkedListAll}
              handleSwitchCheckbox={handleSwitchCheckbox}
              handleCheckboxClick={handleCheckboxClick}

              // del and select row for open sidebar detail of device
              handleDeleteItem={handleDeleteItem}
              handleSelectedRow={handleSelectedRow}

              getUserListStatus={this.props.getUserListStatus}
              renderEmptyContentComponent={this.props.renderEmptyContentComponent}

              isCreateAccount={isCreateAccount}
              isUpdateAccount={isUpdateAccount}
              isUpdateCustomAccount={isUpdateCustomAccount}
              isDeleteAccount={isDeleteAccount}
              isAdminRoot={isAdminRoot}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: {
          content: "Role & Permission",
          key: "RolePermission"
        },
        render: () => (
          <Tab.Pane attached={false}>
            {
              this.props.getUserListStatus ? this.props.renderEmptyContentComponent('not-permission.jpg', 'You do not have permission to access', null, 'grey') :
              (<SwitchingRolePermission
                onOpenSidebar={handleAnimationChange}
                objDirectory={objDirectory}

                renderEmptyContentComponent={this.props.renderEmptyContentComponent}

                isAssignAccount={isAssignAccount}
                isUnassignAccount={isUnassignAccount}
              />)
            }
          </Tab.Pane>
        )
      },
      {
        menuItem: {
          content: "Devices",
          key: "Devices"
        },
        render: () => (
          <Tab.Pane attached={false}>
            <DeviceTab

              // open create sidebar
              onOpenSidebar={handleAnimationChange}
              isSidebarOpen={isSidebarOpen}

              // directoryPath
              objDirectory={objDirectory}

              // muliple checkbox
              isShowCheckbox={isShowCheckbox}
              checkedListAll={checkedListAll}
              isShowExportCheckbox={isShowExportCheckbox}
              checkedListExportAll={checkedListExportAll}
              handleSwitchCheckbox={handleSwitchCheckbox}
              handleCheckboxClick={handleCheckboxClick}
              handleSwitchExportCheckbox={handleSwitchExportCheckbox}
              handleExportCheckboxClick={handleExportCheckboxClick}

              // del and select rpw for open sidebar detail of device
              handleDeleteItem={handleDeleteItem}
              handleSelectedRow={handleSelectedRow}

              // export
              handleExportItem={handleExportItem}

              // search
              searchValue={searchValue}
              handleSearchChange={handleSearchChange}

              renderEmptyContentComponent={this.props.renderEmptyContentComponent}
              
              isCreateDevice={isCreateDevice}
              isUpdateDevice={isUpdateDevice}
              isDeleteDevice={isDeleteDevice}
              isUpdateCustomDevice={isUpdateCustomDevice}
              isAdminRoot={isAdminRoot}
            />
          </Tab.Pane>
        )
      }

      // {
      //   menuItem: {
      //     content: "Assets",
      //     key: "Assets"
      //   },
      //   render: () => (
      //     <Tab.Pane attached={false}>
      //       <AssetTab

      //         // open create sidebar
      //         onOpenSidebar={handleAnimationChange}

      //         // directoryPath
      //         objDirectory={objDirectory}

      //         // muliple checkbox
      //         isShowCheckbox={isShowCheckbox}
      //         checkedListAll={checkedListAll}
      //         handleSwitchCheckbox={handleSwitchCheckbox}
      //         handleCheckboxClick={handleCheckboxClick}

      //         // del and select rpw for open sidebar detail of asset
      //         handleDeleteItem={handleDeleteItem}
      //         handleSelectedRow={handleSelectedRow}

      //         // search
      //         searchValue={searchValue}
      //         handleSearchChange={handleSearchChange}
      //       />
      //     </Tab.Pane>
      //   )
      // }

    ];

    return (
      <React.Fragment>
        <Tab
          className="tab-width-height"
          menu={{
            borderless: true,
            attached: false,
            tabular: false
          }}
          panes={panes}
          activeIndex={activeTabIndex}
          onTabChange={handleTabChange}
        />
      </React.Fragment>
    );
  }
}

SwicthRenderTabPane.propsType = {
  objDirectory: PropTypes.object.isRequired,
  handleAnimationChange: PropTypes.func.isRequired,
  isShowCheckbox: PropTypes.bool.isRequired,
  checkedListAll: PropTypes.array.isRequired,
  isShowExportCheckbox: PropTypes.bool.isRequired,
  checkedListExportAll: PropTypes.array.isRequired,
  handleSwitchCheckbox: PropTypes.func.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  handleSwitchExportCheckbox: PropTypes.func.isRequired,
  handleExportCheckboxClick: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  handleExportItem: PropTypes.func.isRequired,
  handleSelectedRow: PropTypes.func.isRequired,
  activeTabIndex: PropTypes.string,
  handleTabChange: PropTypes.func.isRequired
};

export default SwicthRenderTabPane;
