/* eslint-disable complexity */
import { reportConstants } from "../_constants";

export function report(state = [0], action) {
  switch (action.type) {

    // get dashboard template
    case reportConstants.GET_DASHBOARD_TEMP_REQUEST:
      return {
        dashboardList: state.dashboardList,
        directoryList: state.directoryList,
        getting: true
      };
    case reportConstants.GET_DASHBOARD_TEMP_SUCCESS:
      return {
        dashboardList: state.dashboardList,
        directoryList: state.directoryList,
        dashboardTemplate: action.response.data.data
      };
    case reportConstants.GET_DASHBOARD_TEMP_FAILURE:
      return {
        dashboardList: state.dashboardList,
        directoryList: state.directoryList,
        error: action.error
      };

    // get dashboard of direcotry
    case reportConstants.GET_DASHBOARD_OF_DIR_REQUEST:
      return {
        gettingDirectory: true
      };
    case reportConstants.GET_DASHBOARD_OF_DIR_SUCCESS:
      return {
        directoryList: action.response.filter(
          (dir, index) => dir.dashboardTotal !== 0
        )
      };
    case reportConstants.GET_DASHBOARD_OF_DIR_FAILURE:
      return {
        error: action.error
      };

    // get dashboard list
    case reportConstants.GET_DASHBOARD_LIST_REQUEST:
      return {
        activeDirectoryObj: state.activeDirectoryObj,

        // createDirectoryObj: state.createDirectoryObj,
        directoryList: state.directoryList,
        getting: true
      };
    case reportConstants.GET_DASHBOARD_LIST_SUCCESS:
      return {
        activeDirectoryObj: action.activeDirectoryObj ? action.activeDirectoryObj : state.activeDirectoryObj,

        // createDirectoryObj: state.createDirectoryObj,
        directoryList: state.directoryList,
        dashboardList: action.response.data.data
      };
    case reportConstants.GET_DASHBOARD_LIST_FAILURE:
      return {
        // createDirectoryObj: state.createDirectoryObj,
        error: action.error
      };

    // get dashboard by id
    case reportConstants.GET_DASHBOARD_BY_ID_REQUEST:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,
        getting: true
      };
    case reportConstants.GET_DASHBOARD_BY_ID_SUCCESS:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,
        activeDashboardObj: action.response.data,
        getDashboardStatus: action.response.status
      };
    case reportConstants.GET_DASHBOARD_BY_ID_FAILURE:
      return {
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,
        error: action.error
      };

    // post dashboard
    case reportConstants.POST_DASHBOARD_REQUEST:
      return {
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,
        dashboardTemplate: state.dashboardTemplate,
        creating: true,
        activeDirectoryObj: action.createDirectoryObj
      };
    case reportConstants.POST_DASHBOARD_SUCCESS:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,

        // response
        activeDashboardObj: action.response.data,
        postDashboardResponse: action.response.data,
        postDashboardStatus: action.response.status,
        dashboardTemplate: state.dashboardTemplate
      };
    case reportConstants.POST_DASHBOARD_FAILURE:
      return {
        createDirectoryObj: state.createDirectoryObj,
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,
        dashboardTemplate: state.dashboardTemplate,
        error: action.error
      };

    // delete dashboard
    case reportConstants.DELETE_DASHBOARD_REQUEST:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        dashboardList: state.dashboardList,
        directoryList: state.directoryList,
        deleting: true,
        dashboardId: action.id
      };
    case reportConstants.DELETE_DASHBOARD_SUCCESS:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        directoryList: state.directoryList,
        dashboardList: state.dashboardList.filter((item) => item._id !== state.dashboardId),
        delDashboardResponse: action.response,
        delDashboardStatus: action.response.status
      };
    case reportConstants.DELETE_DASHBOARD_FAILURE:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        dashboardList: state.dashboardList,
        directoryList: state.directoryList,
        error: action.error
      };

    // patch dashboard
    case reportConstants.PATCH_DASHBOARD_REQUEST:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        activeDashboardObj: state.activeDashboardObj,
        dashboardList: state.dashboardList,
        directoryList: state.directoryList,
        updating: true
      };
    case reportConstants.PATCH_DASHBOARD_SUCCESS:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,

        // response
        activeDashboardObj: action.response.data,
        updateDashboardResponse: action.response.data,
        updateDashboardStatus: action.response.status

      };
    case reportConstants.PATCH_DASHBOARD_FAILURE:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        activeDashboardObj: state.activeDashboardObj,
        dashboardList: state.dashboardList,
        directoryList: state.directoryList,
        error: action.error
      };

    // get widget template
    case reportConstants.GET_WIDGET_TEMP_REQUEST:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        activeDashboardObj: state.activeDashboardObj,
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,
        getting: true
      };
    case reportConstants.GET_WIDGET_TEMP_SUCCESS:
      return {
        activeDirectoryObj: state.activeDirectoryObj,
        activeDashboardObj: state.activeDashboardObj,
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,
        widgetTemplate: action.response.data.data
      };
    case reportConstants.GET_WIDGET_TEMP_FAILURE:
      return {
        directoryList: state.directoryList,
        dashboardList: state.dashboardList,
        error: action.error
      };

    default:
      return state;
  }
}
