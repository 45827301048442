import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Header, Icon, Input, Label, Segment } from "semantic-ui-react";

import { connect } from "react-redux";
import { deviceActions, deviceTypeActions } from "../../../../_actions";

import { InputModal } from "../../../../components/Input/InputModal";
import { SidebarButton } from "../../../../components/Button/SidebarButton";
import { DefaultTextArea } from "../../../../components/TextArea/TextArea";
import MultipleSelectionDropdown from "../../../../components/Dropdown/MultipleSelectionDropdown";
import handleLoading from "../../../../components/Loader/Loader";
import RelationComponent from "../../Component/RelationComponent";
import ValueComponent from "../../Component/ValueComponent";
// import AttributeComponent from "../../Component/AttributeComponent";
import AttributeComponent from "../../../Device/Component/AttributeComponent";
import TextField from "../../../../components/TextField/TextField";

class DeviceSidebar extends Component {
  constructor(props) {
    super(props);

    const { deviceInfo } = this.props;

    this.state = {
      id: deviceInfo ? deviceInfo._id : "",
      name: deviceInfo ? deviceInfo.name : "",
      type: deviceInfo ? deviceInfo.type : "",
      label: deviceInfo ? deviceInfo.label : "",
      description: deviceInfo ? deviceInfo.description : "",
      isUpdateComp: Boolean(deviceInfo),
      telemetryKey: "",
      telemetryKeyList: deviceInfo ? deviceInfo.telemetryKey : [],
      telemetryLasted: deviceInfo ? deviceInfo.telemetryLasted : {}
    };
  }

  componentDidMount () {
    this.props.getDeviceTypeList({ page: 1, limit: 65535 });
    this.props.getDeviceType({ page: 1, limit: 65535 });
  }

  // componentWillMount = () => {
  //   this.props.getDeviceType();
  // };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  addNewTelemetryKey = (e, newTelemetryKey) => {
    if (e.keyCode === 13) {
    this.setState((prevState) => ({ telemetryKeyList: [...prevState.telemetryKeyList, newTelemetryKey], telemetryKey: "" }));
    }
  };

  removeTelemetryKey = (item) => {
    this.setState({ telemetryKeyList: this.state.telemetryKeyList.filter((key) => key !== item) });
  };

  handleSubmitSidebar = () => {
    const { id, name, type, label, description, isUpdateComp, telemetryKeyList } = this.state;
    const directoryPath = this.props.objDirectory.path;

    if (isUpdateComp) {
      // update deviec
      const objUpdate = {
        name,
        type,
        label,
        description,
        telemetryKey: telemetryKeyList
      };

      // const payload = Object.entries(objUpdate).reduce((a, [key, value]) => { return value ? { ...a, [key]: value } : a; }, {});

      if (objUpdate.name && objUpdate.type && id) {
        this.props.patchDevice(id, objUpdate);
      }
    } else {
      // create deivce
      const objCreate = {
        name,
        type,
        label,
        directoryPath,
        description,
        telemetryKey: telemetryKeyList
      };

      // const payload = Object.entries(objCreate)
      //   .reduce((a, [key, value]) => { return value ? { ...a, [key]: value } : a; }, {});

      if (objCreate.name && objCreate.type && objCreate.directoryPath) {
        this.props.postDevice(objCreate);
      }
    }
  };

  handleChangeDropdown = (type) => {
    this.setState({ type });

    const test = this.props.deviceTypeList.filter((item) => item.deviceType === type);

    if (type && test[0]) {
      this.setState({ telemetryKeyList: test[0].telemetryKey });
    }
  }

  render() {
    const { id, name, type, label, description, isUpdateComp, telemetryKeyList, telemetryKey, telemetryLasted } = this.state;
    const {
      handleAnimationChangeClose,
      deviceInfo,
      deviceType,
      loading,
      isUpdateDevice,
      isUpdateCustomDevice,
      isAdminRoot
    } = this.props;

    return (
      <React.Fragment>
        {handleLoading(loading)}
          <div>
            <Header
              className={"header__title1 header__title1--sidebar"}
              textAlign={"center"}
            >
              {isUpdateComp ? "Device detail" : "Create device"}
            </Header>
          </div>

          <div className="div__scrollbar div__scrollbar--add-device">
            <Grid centered={!isUpdateComp}>
              <Grid.Row>
                <Grid.Column width={isUpdateComp ? 8 : 15}>
                  <TextField
                    name={"name"}
                    text={"Device Name"}
                    placeholder={"Device Name"}
                    value={name}
                    placeholder="Name"
                    handleInputValue={this.handleChange}
                    isDisable={!isUpdateComp ? false : !isUpdateDevice}
                  />

                  <MultipleSelectionDropdown
                    classList={"mg--top1-half"}
                    type={type}
                    options={deviceType}
                    placeholder={'Device type'}
                    handleChangeDropdown={this.handleChangeDropdown}
                    disabled={!isUpdateComp ? false : (isAdminRoot || (!isUpdateCustomDevice && isUpdateDevice) ? false : true)}
                  />

                {type === "" ? null :
                  <Segment textAlign="left" className="segment-telemetryKey">
                    { 
                      telemetryKeyList &&
                      telemetryKeyList.map((item, index) => <Label as="a" style={{ margin: '3px' }}>{item}
                      { isAdminRoot || (!isUpdateCustomDevice && isUpdateDevice) ? <Icon name="delete" onClick={() => this.removeTelemetryKey(item)}/> : null }
                      </Label>)
                    }

                    { 
                      isAdminRoot || (!isUpdateCustomDevice && isUpdateDevice) ?
                      <Input
                        className="input-telemetryKey"
                        type="text"
                        placeholder="New telemetry key"
                        onChange={this.handleChange}
                        name="telemetryKey"
                        value={telemetryKey}
                        onKeyDown={(e) => this.addNewTelemetryKey(e, telemetryKey)}
                      /> : null
                    }
                  </Segment>
                }
                  <TextField
                    className={"--top-bottom"}
                    name={"label"}
                    text={"Label"}
                    placeholder={"Label"}
                    value={label}
                    handleInputValue={this.handleChange}
                    placeholder="Label"
                    isDisable={!isUpdateDevice}
                  />

                  <DefaultTextArea
                    name="description"
                    text={"Description"}
                    value={description}
                    handleChange={this.handleChange}
                    placeholder="Description"
                    isDisable={!isUpdateDevice}
                  />
                </Grid.Column>

                  {/* attribute component */}
                  {isUpdateComp && (
                    <Grid.Column width={8}>
                      <AttributeComponent id={id} isUpdateDevice={isUpdateDevice} isUpdateExpiredOn={!(!isAdminRoot && isUpdateCustomDevice)} />
                    </Grid.Column>
                  )}
              </Grid.Row>
              {/* value component */}
              {isUpdateComp && (
                <Grid.Row>
                    <Grid.Column width={15}>
                      <ValueComponent id={id} telemetryLasted={telemetryLasted} />
                    </Grid.Column>
                </Grid.Row>
              )}

            </Grid>
          </div>

        <SidebarButton
          disabled={!name || !type}
          btnSecondary={isUpdateComp ? "CLOSE" : "CANCEL"}
          btnActive={isUpdateComp ? "UPDATE" : "CREATE"}
          handleSubmit={this.handleSubmitSidebar} // submit btn
          OnCloseSidebar={handleAnimationChangeClose()}
          width={5}
          isUpdateDevice={isUpdateDevice}
        />
      </React.Fragment>
    );
  }
}

DeviceSidebar.propTypes = {
  deviceType: PropTypes.arrayOf(PropTypes.object),
  handleAnimationChangeClose: PropTypes.func.isRequired,
  gettingType: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
  getDeviceType: (payload) => dispatch(deviceActions.getDeviceType(payload)),
  postDevice: (payload) => dispatch(deviceActions.postDevice(payload)),
  patchDevice: (id, payload) => dispatch(deviceActions.patchDevice(id, payload)),
  getDeviceTypeList: (payload) => dispatch(deviceTypeActions.getDeviceTypeList(payload))
});

const mapStateToProps = (state) => ({
  loading:
    state.device.gettingType ||
    state.device.creating ||
    state.device.updating ||
    state.device.gettingAttribute ||
    state.relation.getting,
  deviceType: state.device.deviceType,
  deviceInfo: state.device.deviceInfo,
  deviceTypeList: state.deviceType.deviceTypeList
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceSidebar);
