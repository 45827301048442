export const dataConstants = {
  // get telemetry list export
  GET_TELEMETRY_EXPORT_REQUEST: "DATA_GET_TELEMETRY_EXPORT_REQUEST",
  GET_TELEMETRY_EXPORT_SUCCESS: "DATA_GET_TELEMETRY_EXPORT_SUCCESS",
  GET_TELEMETRY_EXPORT_FAILURE: "DATA_GET_TELEMETRY_EXPORT_FAILURE",

  // get status telemetry list export
  GET_TELEMETRY_EXPORT_STATUS_REQUEST: "DATA_GET_STATUS_TELEMETRY_EXPORT_REQUEST",
  GET_TELEMETRY_EXPORT_STATUS_SUCCESS: "DATA_GET_STATUS_TELEMETRY_EXPORT_SUCCESS",
  GET_TELEMETRY_EXPORT_STATUS_FAILURE: "DATA_GET_STATUS_TELEMETRY_EXPORT_FAILURE",

  // send status success telemetry list export
  SEND_TELEMETRY_EXPORT_SUCCESS_REQUEST: "DATA_SEND_SUCCESS_TELEMETRY_EXPORT_REQUEST",
  SEND_TELEMETRY_EXPORT_SUCCESS_SUCCESS: "DATA_SEND_SUCCESS_TELEMETRY_EXPORT_SUCCESS",
  SEND_TELEMETRY_EXPORT_SUCCESS_FAILURE: "DATA_SEND_SUCCESS_TELEMETRY_EXPORT_FAILURE",

  // get telemetry list export fropm db
  GET_TELEMETRY_DB_EXPORT_REQUEST: "DATA_GET_TELEMETRY_DB_EXPORT_REQUEST",
  GET_TELEMETRY_DB_EXPORT_SUCCESS: "DATA_GET_TELEMETRY_DB_EXPORT_SUCCESS",
  GET_TELEMETRY_DB_EXPORT_FAILURE: "DATA_GET_TELEMETRY_DB_EXPORT_FAILURE"
};
