import React from "react";
import PropTypes from "prop-types";

const TextField = (props) => {
  return (
    <div
      className={`floating-label-wrap ${
        props.className ? `floating-label-wrap${props.className}` : ""
        }`}
    >
      <input
        ref={props.refTextField}
        type={props.type ? props.type : "text"}
        className={`floating-label-field floating-label-field--s3 ${
          props.isDisable ? "floating-label-field--s3--inactive" : ""
          }`}
        id={props.id}
        name={props.name}
        placeholder={props.placeholder ? props.placeholder : props.text}
        onChange={props.handleInputValue}
        value={props.dataUser ? props.dataUser : props.value}
        disabled={props.isDisable ? props.isDisable : false}
      />
      {/* <label
        htmlFor={props.id}
        className={`floating-label ${
          props.isDisable ? "floating-label--disabled" : ""
          }`}
      >
        {props.text}
      </label> */}
      <label
        htmlFor={props.id}
        className={`floating-label`}
      >
        {props.text}
      </label>

        <div className="text--validate-error">{props.error}</div>
    </div>
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleInputValue: PropTypes.func,
  refTextField: PropTypes.object,
  isDisable: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string
};

export default TextField;
