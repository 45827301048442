/* eslint-disable complexity */
import { notificationConstants } from "../_constants/notification.constants";
import { isEmpty } from 'lodash';

export function notification(state = {}, action) {
  switch (action.type) {

    // get audit list
    case notificationConstants.GET_LOG_REQUEST:
      return {
        loading: true
      };
    case notificationConstants.GET_LOG_SUCCESS:
      return {
        activityList: action.response.data.data,
        loading: false
      };
    case notificationConstants.GET_LOG_FAILURE:
      return {
        error: action.error,
        loading: false
      };

    // get audit detail
    case notificationConstants.GET_LOG_DETAIL_REQUEST:
      return {
        // loading: true
      };
    case notificationConstants.GET_LOG_DETAIL_SUCCESS:
      return {
        activityDetail: action.response.data.data,
        // loading: false
      };
    case notificationConstants.GET_LOG_DETAIL_FAILURE:
      return {
        error: action.error
        // loading: false
      };
    // get audit list
    case notificationConstants.GET_LOG_EXPORT_REQUEST:
      return {
        loading: true
      };
    case notificationConstants.GET_LOG_EXPORT_SUCCESS:
      return {
        activityExportList: action.response.data.data,
        loading: false
      };
    case notificationConstants.GET_LOG_EXPORT_FAILURE:
      return {
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}
