import React, { Component } from 'react';
import { Button, Container, Form, Grid, Header, Image, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { accountActions } from '../../_actions/account.actions';
import { connect } from 'react-redux';

import InputForm from '../../components/Input/InputForm';
import { NavbarLogo } from '../../components/Menu/NavBarLogo';
import handleLoading from '../../components/Loader/Loader';

class ChangeORForgotPassPage extends Component {

  state = {
    disableBtn: true,
    labelErrDisplay: false,
    labelErrText: 'Your passwords not match',
    newPassValue: '',
    repeatPassValue: ''
  };

  fillFormComplete = () => {
    const { newPassValue, repeatPassValue } = this.state;

    if (newPassValue === repeatPassValue) {
      this.setState({
        labelErrDisplay: false
      });
    } else {
      this.setState({
        labelErrDisplay: true
      });
    }
  };

  validatorPass = (data, name) => {
    const currentPassword = localStorage.getItem('currentPassword');

    if (currentPassword) {
      this.setState({
        [name]: data.value && data.value.length ? data.value : ''
      }, () => {
        this.fillFormComplete();
      });
    } else {
      this.setState({ [name]: data.value }, () => {
        this.fillFormComplete();
      });
    }
  };

  saveBtnOnclick = () => {
    const { repeatPassValue, labelErrDisplay, newPassValue } = this.state;
    const currentPassword = localStorage.getItem('currentPassword');

    if (currentPassword && !labelErrDisplay) {
        const payload = {
          currentPassword,
          newPassword: repeatPassValue
        };

        this.props.changePass(payload);
    } else if(!labelErrDisplay) {
      const token = new URLSearchParams(this.props.location.search).get("token");
      const payload = {
        password: repeatPassValue
      };

      this.props.changePass(payload, "setPassword", token);
    }
  }

  render () {
    const { loading } = this.props;
    const { labelErrText, labelErrDisplay, newPassValue, repeatPassValue } = this.state;
    const currentPassword = localStorage.getItem('currentPassword');

    return (
      <React.Fragment>
        <NavbarLogo/>
        <Grid className={'grid-form'}>
          <Grid.Column width={1}/>
          <Grid.Column width={14}>
            <Segment className="segment-change-password">

              {handleLoading(loading)}

              <Grid textAlign="center">
                <Grid.Row verticalAlign="middle">
                  <Grid.Column computer={8} only="computer">
                    <Image src="./images/forgot-password.svg"/>
                  </Grid.Column>

                  <Grid.Column computer={8} tablet={16} mobile={16}>
                    <Header textAlign={'left'} className={'header__title'} as="h2">
                      SET PASSWORD
                    </Header>
                    {/* <Container
                      textAlign={'left'}
                      className={'container__desc container__desc--space-bot'}>{currentPassword ?
                      <p>Before you can get started we need you to make your account more secure by changing your
                        password.</p> : <p>Your new password must be different from previous used password. </p>
  }
                    </Container> */}
                    <Container
                      textAlign={'left'}
                      className={'container__desc container__desc--space-bot'}>
                      <p>Please input new password and confirm password.</p>
                    </Container>
                    <Form>
                      <InputForm icon={'lock'}
                        type={'password'}
                        placeholder={"New Password"}
                        handleChange={(event, data) => this.validatorPass(data, 'newPassValue')}
                      />

                      <InputForm
                      icon={'lock'}
                      type={'password'}
                      placeholder={"Confirm Password"}
                      handleChange={(event, data) => this.validatorPass(data, 'repeatPassValue')}
                      />

                      { currentPassword ? labelErrDisplay && <p align={'left'} className="font--error">{labelErrText}</p> : null}

                    </Form>
                    <br/>
                    <Button floated="right"
                      content={'SAVE'}
                      className={'btn--primary btn--width144px'}
                      // disabled={currentPassword ? labelErrDisplay : !newPassValue || !repeatPassValue}
                      disabled={(!newPassValue || !repeatPassValue) || labelErrDisplay}
                      onClick={this.saveBtnOnclick}/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

            </Segment>
          </Grid.Column>
          <Grid.Column width={1}/>
        </Grid>
      </React.Fragment>
    );
  }
}

ChangeORForgotPassPage.propTypes = {
  changePass: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  loading: state.account.loading
});

const mapDispatchToProps = (dispatch) => ({
  changePass: (payload, setPassword, token) => dispatch(accountActions.changePass(payload, setPassword, token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeORForgotPassPage);

