export const devicePageConstants = {
  SET_DEVICES_DATA: 'SET_DEVICES_DATA',
  SET_IS_DELETING: 'SET_IS_DELETING',
  SET_IS_EXPORTING: 'SET_IS_EXPORTING',
  SET_SIDEBAR_VISIBLE: 'SET_SIDEBAR_VISIBLE',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  ADD_SELECTED_ID: 'ADD_SELECTED_ID',
  DELETE_SELECTED_ID: 'DELETE_SELECTED_ID',
  ADD_SELECTED_EXPORT_ID: 'ADD_SELECTED_EXPORT_ID',
  DELETE_SELECTED_EXPORT_ID: 'DELETE_SELECTED_EXPORT_ID',
  CLEAR_SELECTED_ID: 'CLEAR_SELECTED_ID',

      // clear device
      CLEAR_DEVICE: "CLEAR_DEVICE"
};
