import React from "react";
import { Grid, Header, Input, Segment } from "semantic-ui-react";
import DeviceTypeTable from "./component/DeviceTypeTable";
import PaginationBuilder from "./component/PaginationBuilder";
import CreateAndUpdateDeviceTypeModal from "./component/CreateAndUpdateDeviceTypeModal";
import { connect } from "react-redux";
import { deviceTypeActions, directoryActions, authorizeActions } from '../../_actions/';

class DeviceTypeController extends React.Component {
  state = {
    tableHeader: ["Device type", "Telemetry key", "Last update", ""],
    search: '',
    activePage: 1,
    totalPages: 1,
    limit: 10,
    sizeOptions: [
        { key: '10', text: '10', value: 10 },
        { key: '20', text: '20', value: 20 },
        { key: '50', text: '50', value: 50 },
        { key: '100', text: '100', value: 100 }
    ],
    isAdmin: false
  }

  getDeviceTypeListAndDirectoryList = () => {
    const payload = {
      limit: this.state.limit,
      admin: 'device'
    };

    this.props.getDirectoryEligible(payload).then(() => {
    if (this.props.directoryEligibleStatus !== 403) {
      if (this.state.search !== '') {
        return this.props.getDeviceTypeList({ search: this.state.search, page: this.state.activePage, limit: this.state.limit }).then((total) => {
          this.setState({ totalPages: Math.ceil(total/this.state.limit) });
        });
      } else {
        return this.props.getDeviceTypeList({ page: this.state.activePage, limit: this.state.limit }).then((total) => {
          this.setState({ totalPages: Math.ceil(total/this.state.limit) });
        });
      }
    }

    return null;
  });
  }

  componentDidMount() {
    this.getDeviceTypeListAndDirectoryList();

    const payload = { action: '*', subject: '*', directoryPath: '/' };

    this.props.getEligible(payload).then((response) => {
      this.setState({ isAdmin: response.isEligible,
        tableHeader: ["Device type", "Telemetry key", "Last update", ""] });
    });
 }

  handleSubmit = (payload, type, id) => {
    if (type) {
      // patch device type
      this.props.patchDeviceType(id, payload)
      .then(() => { this.getDeviceTypeListAndDirectoryList();
        this.setState({
        deviceType: "",
        telemetryKeyList: ""
      });
    });
    } else {
      // post device type
    this.props.postDeviceType(payload)
    .then(() => { this.getDeviceTypeListAndDirectoryList();
      this.setState({
      deviceType: "",
      telemetryKeyList: ""
    });
  });
    }
  }

  handleDeleteItem = (id) => {
    const dirPath = this.props.optionDirectory.map((item) => item.value)[0];

    const payload = { directoryPath: dirPath };

    this.props.deleteDeviceType(id, payload).then(() => this.getDeviceTypeListAndDirectoryList());
  }

  handleSearch = (e) => {
    this.setState({ activePage: 1, totalPages: 1, search: e.target.value });
    // this.setState({ search: e.target.value });
    this.getDeviceTypeListAndDirectoryList();
  }

  handleOnPageChange = (activePage) => {
    this.setState({ activePage });
    this.getDeviceTypeListAndDirectoryList();
  };

  handleChangeSize = (e, {value}) => {
    this.setState({ limit: value });
    this.getDeviceTypeListAndDirectoryList();
  }

  render() {
    const { tableHeader, search, activePage, totalPages, sizeOptions, isAdmin } = this.state;
    const { deviceTypeList, deviceTypeInfo, optionDirectory, getDeviceTypeById,
            deleteDeviceTypeStatus, postDeviceTypeStatus, updateDeviceTypeStatus, loading, directoryEligibleStatus } = this.props;
    let deviceList = [];

    if (search === '') {
      deviceList = deviceTypeList ? deviceTypeList : [];
    } else {
      deviceList = deviceTypeList ? deviceTypeList : [];
    }

    return (
      <>
      <Grid className={'grid__in-sidebar'}>
        <Grid.Row>
          <Header id="layout__row2" className="Header-page">Device Type </Header>
        </Grid.Row>
      </Grid>

      <Segment className="segment-deviceType">
        <Grid centered>
        {/* <Grid.Column width={1}/> */}
        <Grid.Column width={15}>
          <Grid>

              <Grid.Row columns={'equal'} className={'row--header'}>
                <Grid.Column className={"column--header"} textAlign="left">
                  <Input
                    fluid
                    className={"input__search--withoutBorder"}
                    placeholder={"Search"}
                    icon={"search"}
                    iconPosition={"left"}
                    value={search}
                    onChange={this.handleSearch}
                  />
                </Grid.Column>

                {
                  isAdmin ?
                  <Grid.Column className={"column--header"} textAlign="right">
                    { directoryEligibleStatus === 403 ? null :
                    <CreateAndUpdateDeviceTypeModal
                    handleSubmit={this.handleSubmit}
                    optionDirectory={optionDirectory}
                    handleChangeDropdown={this.handleChangeDropdown}
                    deviceTypeInfo={deviceTypeInfo}
                    getDeviceTypeById={getDeviceTypeById}
                    postDeviceTypeStatus={postDeviceTypeStatus}
                    loading={loading}
                    isAdmin={isAdmin}
                    />
                    }
                  </Grid.Column> : null
                }
              </Grid.Row>

              <Grid.Row>
                <DeviceTypeTable
                tableHeader={tableHeader}
                deviceTypeList={deviceList}
                handleSubmit={this.handleSubmit}
                handleDeleteItem={this.handleDeleteItem}
                getDeviceTypeById={getDeviceTypeById}
                deviceTypeInfo={deviceTypeInfo}
                optionDirectory={optionDirectory}
                deleteDeviceTypeStatus={deleteDeviceTypeStatus}
                updateDeviceTypeStatus={updateDeviceTypeStatus}
                loading={loading}
                directoryEligibleStatus={directoryEligibleStatus}
                paginationComponent={
                  deviceList && deviceList.length > 0 ?
                  <PaginationBuilder activePage={activePage}
                                  totalPages={totalPages}
                                  handleOnPageChange={this.handleOnPageChange}/>
                    : null}
                colSpanFooter={"5"}
                sizeOptions={sizeOptions}
                handleChangeSize={this.handleChangeSize}
                isAdmin={isAdmin}
                />

              </Grid.Row>
          </Grid>
        </Grid.Column>
        {/* <Grid.Column width={1}/> */}
      </Grid>
    </Segment>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
  deviceTypeList: state.deviceType.deviceTypeList,
  postDeviceTypeStatus: state.deviceType.postDeviceTypeStatus,
  optionDirectory: state.directory.optionDirectory,
  deviceTypeInfo: state.deviceType.deviceTypeInfo,
  deleteDeviceTypeStatus: state.deviceType.deleteDeviceTypeStatus,
  updateDeviceTypeStatus: state.deviceType.updateDeviceTypeStatus,
  loading: state.deviceType.loading || state.deviceType.getting || state.deviceType.deleting || state.deviceType.updating || state.directory.loading,
  directoryEligibleStatus: state.directory.directoryEligibleStatus
});

const mapDispatchToProps = (dispatch) => ({
  getDeviceTypeList: (payload) => dispatch(deviceTypeActions.getDeviceTypeList(payload)),
  postDeviceType: (payload) => dispatch(deviceTypeActions.postDeviceType(payload)),
  deleteDeviceType: (id, payload) => dispatch(deviceTypeActions.deleteDeviceType(id, payload)),
  getDirectoryEligible: (payload) => dispatch(directoryActions.getDirectoryEligible(payload)),
  getDeviceTypeById: (id) => dispatch(deviceTypeActions.getDeviceTypeById(id)),
  patchDeviceType: (id, payload) => dispatch(deviceTypeActions.patchDeviceType(id, payload)),
  getEligible: (payload) => dispatch(authorizeActions.getEligible(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTypeController);
