import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

class SearchDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue ? props.initialValue : ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.initialValue) {
      this.setState({ value: nextProps.initialValue });
    }
  }

  handleChangeDropdown = (e, { value }) => {
    this.setState(
      {
        value
      },
      () => this.props.handleChangeDropdown(this.state.value)
    );
  };

  render() {
    const { value } = this.state;
    const { options, search, text, name, includeClassName, isDisable } = this.props;

    return (
      <Dropdown
        className={`dropdown__selection ${
          includeClassName ? includeClassName : ""
        }`}
        options={options}
        selection
        value={value}
        search={search}
        onChange={this.handleChangeDropdown}
        text={ value !== "" ? value : text }
        name={name}
        disabled={isDisable}
      />
    );
  }
}

SearchDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  handleChangeDropdown: PropTypes.func.isRequired
};

export default SearchDropdown;
