import { createSelector } from "reselect";
import { isEmpty } from "lodash";

const getDirectoryEligible = (state) => state.directory;

export const makeGetDirectoryToDropDown = () => createSelector(
  getDirectoryEligible,
  (directory) => {
    if (isEmpty(directory)) {
      return [];
    }

    return directory.data.data.map((value) => ({
      key: value._id,
      value: value.path,
      text: value.name
    }));
  }
);
