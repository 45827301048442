import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Input, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { VehicleTable } from "../../../components/Table/VehicleTable";
import handleLoading from "../../../components/Loader/Loader";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { InputSearchTable } from "../../../components/Input/InputSearchTable";
import IconCustom from "../../../assets/icons";
import ExportButton from "../../../components/Button/ExportButton";
import { deviceActions } from "../../../_actions";

class DeviceTab extends Component {
  constructor(props) {
    super(props);
    let header = ["Device Name", "Device Type", "Expire", "Error"];

    if (props.isUpdateDevice || props.isDeleteDevice) {
      header.push("");
    }

    this.state = {
      vehicles: [],
      vehiclesHeader: header,
      tab: "device",
      filterText: ""
    };
  }

  filterUpdate = (e) => {
    this.setState({
      filterText: e.target.value
    });
  };

  handleDateTime = (id, value) => {
    const payload = { "expiredOn": value };
    this.props.postDeviceAttribute(id, "shared", payload, "edit");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.postAttributeStatus === 200 || nextProps.delAttributeStatus === 204) {
      if(!this.props.isSidebarOpen) this.props.handleSearchChange(null, { value: "" })
    }
  }

  render() {
    const { deviceStatus, deviceList, getting, onOpenSidebar, isShowCheckbox, isShowExportCheckbox, checkedListAll, checkedListExportAll, handleSwitchCheckbox, handleCheckboxClick, handleSwitchExportCheckbox, handleExportCheckboxClick, handleDeleteItem, handleExportItem, handleSelectedRow, searchValue, handleSearchChange, isCreateDevice, isUpdateDevice, isDeleteDevice, isUpdateCustomDevice, isAdminRoot } = this.props;
    const { vehiclesHeader, tab, filterText } = this.state;

    return (
      <>
      <Grid>
      {deviceStatus === 403 || getting ? null :
        <Grid.Row>
          <Grid.Column width={14}>
            {/* <Input
                className="input__search--withoutBorder"
                icon="search"
                iconPosition="left"
                placeholder="Search"
                value={filterText}
                onChange={this.filterUpdate}
              /> */}
          </Grid.Column>

          <Grid.Column width={2}>
            <Button.Group floated="right">
              { isCreateDevice ?
                <Popup
                  trigger={
                    <span onClick={onOpenSidebar("device")}>
                      <IconCustom name={"add"} className={"svg-icon__add"} />
                    </span>
                  }
                  content="Create device"
                  mouseLeaveDelay={0}
                  basic
                />: null
              }

              {/* <span className="icon--mg--left">
                <IconCustom className={"svg-icon--white"} name={"filter"} />
              </span> */}

              {isShowCheckbox ? (
                <DeleteModal
                  triggerType="icon--trash"
                  id={checkedListAll}
                  name={deviceList.find(
                    (item) => item._id === checkedListAll[0]
                  )}
                  descType={"device"}
                  handleDeleteItem={handleDeleteItem}
                />
              ) : null}
              {/* {isShowExportCheckbox ? (
                <ExportButton
                  id={checkedListExportAll}
                  name={deviceList.find(
                    (item) => item._id === checkedListExportAll[0]
                  )}
                  handleExportItem={handleExportItem}
                />
              ) : null} */}
            </Button.Group>

          </Grid.Column>
        </Grid.Row>
      }
    </Grid>

    {getting ?
      handleLoading(getting, "tableLoading") :
     (
      <VehicleTable
        vehicles={deviceList}
        vehiclesHeader={vehiclesHeader}
        checkedListAll={checkedListAll}
        isShowCheckbox={isShowCheckbox}
        checkedListExportAll={checkedListExportAll}
        isShowExportCheckbox={isShowExportCheckbox}
        handleSwitchCheckbox={handleSwitchCheckbox}
        handleCheckboxClick={handleCheckboxClick}
        handleSwitchExportCheckbox={handleSwitchExportCheckbox}
        handleExportCheckboxClick={handleExportCheckboxClick}
        handleSelectedRow={handleSelectedRow}
        handleDateTime={this.handleDateTime}
        deviceStatus={deviceStatus}
        tabName={tab}
        renderEmptyContentComponent={this.props.renderEmptyContentComponent}
        searchValue={searchValue}
        filterText={filterText}
        isUpdateDevice={isUpdateDevice}
        isDeleteDevice={isDeleteDevice}
        isUpdateCustomDevice={isUpdateCustomDevice}
        isAdminRoot={isAdminRoot}
      />
    )}
    </>
    );
  }
}

DeviceTab.propTypes = {
  deviceList: PropTypes.arrayOf(PropTypes.object),
  getting: PropTypes.bool,

  onOpenSidebar: PropTypes.func.isRequired,
  isShowCheckbox: PropTypes.bool,
  checkedListAll: PropTypes.array.isRequired,
  handleSwitchCheckbox: PropTypes.func.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  isShowExportheckbox: PropTypes.bool,
  checkedListExportAll: PropTypes.array.isRequired,
  handleSwitchExportCheckbox: PropTypes.func.isRequired,
  handleExportCheckboxClick: PropTypes.func.isRequired,
  handleExportItem: PropTypes.func.isRequired,
  handleSelectedRow: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  deviceList: state.device.deviceList,
  getting: state.device.getting, // get list and get info of device
  deviceStatus: state.device.deviceStatus,
  postAttributeStatus: state.device.postAttributeStatus
});

const mapDispatchToProps = (dispatch) => ({
  postDeviceAttribute: (id, scope, payload, type) => dispatch(deviceActions.postDeviceAttribute(id, scope, payload, type))
});

export default connect(mapStateToProps, mapDispatchToProps, null)(DeviceTab);
