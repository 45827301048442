import { combineReducers } from "redux";
import { paginationReducerFor } from "./pagination.reducer";
import { devicePageConstants } from "../../_constants/pages/devicePage.constants";

const initialState = {
  devicesData: [],
  deletingSelectedId: [],
  exportingSelectedId: [],
  isDeleting: false,
  isExporting: false,
  visible: false,
  fetching: false
};

export function devicePage(state = initialState, action) {
  switch (action.type) {

    case devicePageConstants.CLEAR_DEVICE:
      return {};

    case devicePageConstants.SET_DEVICES_DATA:
      return {
        ...state,
        devicesData: action.devices.data
      };

    case devicePageConstants.ADD_SELECTED_ID:
      return {
        ...state,
        deletingSelectedId: [
          ...state.deletingSelectedId,
          action.device
        ]
      };

    case devicePageConstants.DELETE_SELECTED_ID:
      return {
        ...state,
        deletingSelectedId: state.deletingSelectedId.filter((value) => value !== action.device)
      };

    case devicePageConstants.ADD_SELECTED_EXPORT_ID:
      return {
        ...state,
        exportingSelectedId: [
          ...state.exportingSelectedId,
          action.device
        ]
      };

    case devicePageConstants.DELETE_SELECTED_EXPORT_ID:
      return {
        ...state,
        exportingSelectedId: state.exportingSelectedId.filter((value) => value !== action.device)
      };

    case devicePageConstants.CLEAR_SELECTED_ID:
      return {
        ...state,
        deletingSelectedId: []
      };

    case devicePageConstants.SET_IS_DELETING:
      return {
        ...state,
        isDeleting: !state.isDeleting
      };

    case devicePageConstants.SET_IS_EXPORTING:
    return {
      ...state,
      isExporting: !state.isExporting
    };

    case devicePageConstants.SET_SIDEBAR_VISIBLE:
      return {
        ...state,
        visible: !state.visible
      };

    case devicePageConstants.SET_IS_FETCHING:
      return {
        ...state,
        fetching: !state.fetching
      };

    default:
      return state;
  }
}

export const devicePageReducer = combineReducers({
  devicesData: devicePage,
  paginationData: paginationReducerFor('DEVICES_')
});
