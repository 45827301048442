export const userConstants = {
  CLEAR_USER: "AUTHORIZE_CLEAR_USER",
  INITIAL_STATE_USER: "AUTHORIZE_INITIAL_STATE_USER",

  // get user list of directory
  GET_USER_LIST_REQUEST: "AUTHORIZE_GET_USER_LIST_REQUEST",
  GET_USER_LIST_SUCCESS: "AUTHORIZE_GET_USER_LIST_SUCCESS",
  GET_USER_LIST_FAILURE: "AUTHORIZE_GET_USER_LIST_FAILURE",

  // delete user list of directory
  DEL_USER_FROM_DIR_REQUEST: "AUTHORIZE_DEL_USER_FROM_DIR_REQUEST",
  DEL_USER_FROM_DIR_SUCCESS: "AUTHORIZE_DEL_USER_FROM_DIR_SUCCESS",
  DEL_USER_FROM_DIR_FAILURE: "AUTHORIZE_DEL_USER_FROM_DIR_FAILURE",

  // get user's info
  GET_USERINFO_REQUEST: "GET_USERINFO_REQUEST",
  GET_USERINFO_SUCCESS: "GET_USERINFO_SUCCESS",
  GET_USERINFO_FAILURE: "GET_USERINFO_FAILURE",

  // create User
  POST_USER_REQUEST: "POST_USER_REQUEST",
  POST_USER_SUCCESS: "POST_USER_SUCCESS",
  POST_USER_FAILURE: "POST_USER_FAILURE",

  // assign role to user
  POST_ASSIGN_ROLE_REQUEST: "POST_ASSIGN_ROLE_REQUEST",
  POST_ASSIGN_ROLE_SUCCESS: "POST_ASSIGN_ROLE_SUCCESS",
  POST_ASSIGN_ROLE_FAILURE: "POST_ASSIGN_ROLE_FAILURE",

  // unassign role to user
  POST_UNASSIGN_ROLE_REQUEST: "POST_UNASSIGN_ROLE_REQUEST",
  POST_UNASSIGN_ROLE_SUCCESS: "POST_UNASSIGN_ROLE_SUCCESS",
  POST_UNASSIGN_ROLE_FAILURE: "POST_UNASSIGN_ROLE_FAILURE",

  // get user's detail
  GET_USER_DETAIL_REQUEST: "GET_USER_DETAIL_REQUEST",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILURE: "GET_USER_DETAIL_FAILURE",

  // get user's detail
  GET_USER_ROLE_REQUEST: "GET_USER_ROLE_REQUEST",
  GET_USER_ROLE_SUCCESS: "GET_USER_ROLE_SUCCESS",
  GET_USER_ROLE_FAILURE: "GET_USER_ROLE_FAILURE",

  // delete user's by userId
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE"
};
