import React, { Component } from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import { Dimmer, Header, List, Loader, Segment } from "semantic-ui-react";
import SidebarButton from "../../../../components/Button/SidebarButton";
import UserNotInRoleListCell from "../Component/UserNotInRoleListCell";
import { authorizeActions } from "../../../../_actions";
import InputSearch from "../../../../components/Input/InputSearch";
import { connect } from "react-redux";

class AddMemberInRoleSideBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      usersNotInRole: [],
      resultsNotInRole: [],
      selectedUserNotInRole: []
    };
  }

  handleSearchChange = (e, value) => {
    if (value) {
      const re = new RegExp(_.escapeRegExp(value), 'i');

      this.setState((prevState) => ({
        resultsNotInRole: prevState.usersNotInRole.filter((item) => re.test(item.firstname) || re.test(item.lastname)),
        selectedUserNotInRole: []
      }));
    } else {
      this.setState((prevState) => ({
        resultsNotInRole: prevState.usersNotInRole,
        selectedUserNotInRole: []
      }));
    }
  };

  handleUserSelected = (value) => {
    this.setState((prevState) => ({
      selectedUserNotInRole: selectingUser(prevState.selectedUserNotInRole, value)
    }));

    function selectingUser(selectedUser) {
      if (selectedUser && selectedUser.length) {
        if (selectedUser.includes(value)) {
          return selectedUser.filter((item) => item !== value);
        }

        return [...selectedUser, value];
      }

      return [value];
    }
  };

  includeRole = (item, role) => {
    return !item.roles.includes(role.name);
  };

  onClickAddRoleUser = () => {
    this.setState({
      isLoading: true
    }, () => {
      const payload = {
        userId: this.state.selectedUserNotInRole,
        roles: [
          this.props.role._id
        ],
        directoryPath: this.props.objDirectory.path
      };

      this.props.assignRole(payload).then(() => {
        // setTimeout(() => {
        //   this.setState((prevState, prevProps) => ({
        //     usersNotInRole: prevProps.users.filter((item) => this.includeRole(item, prevProps.role)),
        //     resultsNotInRole: prevProps.users.filter((item) => this.includeRole(item, prevProps.role)),
        //     selectedUserNotInRole: []
        //   }));
        //
        //   this.props.handleAnimationChangeClose('overlay');
        // }, 500);
      });

      setTimeout(() => {
        this.setState({
          isLoading: false
        });
      }, 500);
    });
  };

  handleUsersNotInRole = (users, role) => {
    if (users && users.length && role) {
      const usersNotInRole = users.filter((item) => this.includeRole(item, role));
      const resultsNotInRole = users.filter((item) => this.includeRole(item, role));

      this.setState((pervState) => ({
        usersNotInRole,
        resultsNotInRole,
        selectedUserNotInRole: [],
        isLoading: false
      }));
    } else {
      this.setState((prevState) => ({
        usersNotInRole: [],
        resultsNotInRole: [],
        selectedUserNotInRole: [],
        isLoading: false
      }));
    }
  };

  componentDidMount() {
    const { users: { data }, role } = this.props;

    this.setState({
      isLoading: true
    }, () => {
      setTimeout(() => {
        this.handleUsersNotInRole(data, role);
      }, 500);
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.objDirectory.path === this.props.objDirectory.path && prevProps.users !== this.props.users && this.props.users) {
      setTimeout(() => {
        this.handleUsersNotInRole(this.props.users.data, this.props.role);
      }, 500);

      // console.log("update when users change directory path");
    }
  };

  render() {
    const { resultsNotInRole, selectedUserNotInRole, isLoading } = this.state;

    return (
      <React.Fragment>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <Header textAlign={"center"} className={"header__title1"}>Add Member</Header>
        <div className={"div__header-sidebar"}>
          <InputSearch handleTextChange={this.handleSearchChange} />
        </div>

        <div className={"div__scrollbar div__scrollbar--add-sidebar"}>
          <Segment basic className="segment-nonePending segment-addPadding-left-right">
            <List divided verticalAlign={"middle"}>
              {
                resultsNotInRole && resultsNotInRole.length ? resultsNotInRole.map((item) => (
                  <UserNotInRoleListCell
                    fullName={item.user && `${item.user.firstname} ${item.user.lastname}`}
                    key={item._id}
                    valueBtn={item.userId}
                    handleUserSelected={this.handleUserSelected}
                    isActive={selectedUserNotInRole.indexOf(item.userId) > -1} />
                )) :
                  <Segment padded={"very"} textAlign={"center"} basic>
                    <Header className={"header--emptyArr"}>
                      There are no members added to this position.
                    </Header>
                  </Segment>
              }
            </List>
          </Segment>
        </div>

        <SidebarButton
          handleAnimationChange={this.props.handleAnimationChange}
          disabled={selectedUserNotInRole.length === 0}
          btnSecondary="CANCEL"
          btnActive="ADD"
          handleSubmit={this.onClickAddRoleUser}
          OnCloseSidebar={this.props.handleAnimationChangeClose('overlay')}
          isUpdateAccount={true}
        />
      </React.Fragment>
    );
  }
}

AddMemberInRoleSideBar.propTypes = {
  handleChange: PropTypes.func,
  handleCheckEmail: PropTypes.func,
  handleAnimationChange: PropTypes.func,
  handleAnimationChangeClose: PropTypes.func,
  objDirectory: PropTypes.object
};

const
  mapStateToProps = (state) => ({
    users: state.authorize.users,
    role: state.authorize.role,
    assign: state.authorize.assign,
    shouldUpdateRoleDetail: state.authorize.shouldUpdateRoleDetail
  });

const
  mapDispatchToProps = (dispatch) => ({
    assignRole: (payload) => dispatch(authorizeActions.assignRole(payload))
  });

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberInRoleSideBar);
