/* eslint-disable complexity */
import { deviceConstants } from "../_constants";
import { isEmpty } from 'lodash';

export function device(state = {}, action) {
  switch (action.type) {

    // clear device
    case deviceConstants.CLEAR_DEVICE:
      return {};

    // get device list
    case deviceConstants.GET_DEVICE_LIST_REQUEST:
      return {
        getting: true
      };
    case deviceConstants.GET_DEVICE_LIST_SUCCESS:
      return {
        deviceList: action.response.data.data.map((item) => ({
          _id: item._id,
          name: item.name,
          type: item.type,
          expiredOn: item.expiredOn,
          error: item.error,
          token: item._id
        }))
      };
    case deviceConstants.GET_DEVICE_LIST_FAILURE:
      return {
        error: action.error,
        deviceStatus: action.error.response.status
      };

    // get all device list
    case deviceConstants.GET_ALL_DEVICE_LIST_REQUEST:
      return {
        request: action.request
      };
    case deviceConstants.GET_ALL_DEVICE_LIST_SUCCESS:
      return {
        devicesData: action.response
      };
    case deviceConstants.GET_ALL_DEVICE_LIST_FAILURE:
      return {
        error: action.error,
        deviceErrorStatus: action.error.response.status
      };

    // post device
    case deviceConstants.POST_DEVICE_REQUEST:
      return {
        deviceList: state.deviceList,
        deviceType: state.deviceType,
        creating: true
      };
    case deviceConstants.POST_DEVICE_SUCCESS:
      return {
        deviceType: state.deviceType,
        deviceList: isEmpty(state.deviceList) ?
        [{
          _id: action.response.data._id,
          name: action.response.data.name,
          type: action.response.data.type,
          expiredOn: null,
          error: null,
          token: action.response.data._id
        }] : [
          {
            _id: action.response.data._id,
            name: action.response.data.name,
            type: action.response.data.type,
            expiredOn: null,
            error: null,
            token: action.response.data._id
          },
          ...state.deviceList
        ],
        postDeviceStatus: action.response.status
      };
    case deviceConstants.POST_DEVICE_FAILURE:
      return {
        deviceList: state.deviceList,
        deviceType: state.deviceType,
        error: action.error
      };

    // get device info
    case deviceConstants.GET_DEVICE_INFO_REQUEST:
      return {
        deviceList: state.deviceList,
        deviceType: state.deviceType,
        getting: true,
        activeDevice: action.id
      };
    case deviceConstants.GET_DEVICE_INFO_SUCCESS:
      return {
        deviceList: state.deviceList,
        deviceType: state.deviceType,
        deviceInfo: action.response.data,
        activeDevice: state.activeDevice
      };
    case deviceConstants.GET_DEVICE_INFO_FAILURE:
      return {
        deviceList: state.deviceList,
        deviceType: state.deviceType,
        error: action.error
      };

    // patch device
    case deviceConstants.PATCH_DEVICE_REQUEST:
      return {
        deviceList: state.deviceList,
        deviceType: state.deviceType,

        updating: true,
        id: action.id
      };
    case deviceConstants.PATCH_DEVICE_SUCCESS:
      return {
        deviceList: isEmpty(state.deviceList) ? null : state.deviceList.map((item) => {
          return item._id === state.id ?
            {
              _id: action.response.data._id,
              name: action.response.data.name,
              type: action.response.data.type,
              expiredOn: action.response.data.expiredOn,
              error: action.response.data.error,
              token: action.response.data._id
            } :
            item;
        }),
        deviceType: state.deviceType,
        patchDevice: action.response.data,
        patchDeviceStatus: action.response.status
      };
    case deviceConstants.PATCH_DEVICE_FAILURE:
      return {
        deviceList: state.deviceList,
        deviceType: state.deviceType,
        error: action.error
      };

    // del device
    case deviceConstants.DEL_DEVICE_REQUEST:
      return {
        deviceList: state.deviceList,
        deleting: true,
        payload: action.payload,
        delDeviceStatus: state.delDeviceStatus
      };
    case deviceConstants.DEL_DEVICE_SUCCESS:
      return {
        // value is null for just delete device only, no filter list of device for display
        // deviceList: isEmpty(state.deviceList) ? null : state.deviceList.filter(
        //   (item) => !state.payload.includes(item._id)
        // ),
        delDeviceStatus: action.response.status
      };
    case deviceConstants.DEL_DEVICE_FAILURE:
      return {
        error: action.error
      };

    // get deivce token
    case deviceConstants.GET_DEVICE_TOKEN_REQUEST:
      return {
        deviceList: state.deviceList,
        gettingToken: true
      };
    case deviceConstants.GET_DEVICE_TOKEN_SUCCESS:
      return {
        deviceList: state.deviceList,
        primaryToken: action.response.data.primaryToken,
        secondaryToken: action.response.data.secondaryToken
      };
    case deviceConstants.GET_DEVICE_TOKEN_FAILURE:
      return {
        deviceList: state.deviceList,
        error: action.error,
        deviceTokenError: action.error.response.status
      };

    // get device type
    case deviceConstants.GET_DEVICE_TYPE_REQUEST:
      return {
        activeDevice: state.activeDevice,
        deviceList: state.deviceList,
        attributeList: state.attributeList,
        gettingType: true
      };
    case deviceConstants.GET_DEVICE_TYPE_SUCCESS:
      return {
        activeDevice: state.activeDevice,
        deviceList: state.deviceList,
        attributeList: state.attributeList,
        response: action.response.data,
        deviceType: action.response.data.map((item) => ({
          key: item._id,
          value: item.type,
          text: item.type
        })),

        // deviceTelemetry: action.response.data.map((item) => item.telemetryKey)
        deviceTelemetry: action.response.data

      };
    case deviceConstants.GET_DEVICE_TYPE_FAILURE:
      return {
        deviceList: state.deviceList,
        error: action.error
      };

    // get keys's telemetry data from device
    case deviceConstants.GET_KEYS_TELEMETRY_REQUEST:
      return {
        deviceList: state.deviceList,
        index: action.index,
        gettingKeys: true
      };
    case deviceConstants.GET_KEYS_TELEMETRY_SUCCESS:
      return {
        deviceList: state.deviceList,
        datasetIndex: action.index,
        keysTelemetryList: action.response.data.map((key) => ({
          key,
          text: key,
          value: key
        })),
        telemetry: action.response.data
      };
    case deviceConstants.GET_KEYS_TELEMETRY_FAILURE:
      return {
        deviceList: state.deviceList,
        error: action.error
      };

    // get device's attribute
    case deviceConstants.GET_DEVICE_ATTRIBUTE_REQUEST:
      return {
        deviceList: state.deviceList,
        gettingAttribute: true
      };
    case deviceConstants.GET_DEVICE_ATTRIBUTE_SUCCESS:
      return {
        deviceList: state.deviceList,
        attributeList: action.response.data,
        deviceTelemetry: state.deviceTelemetry
      };
    case deviceConstants.GET_DEVICE_ATTRIBUTE_FAILURE:
      return {
        error: action.error
      };

    // post device's attribute
    case deviceConstants.POST_DEVICE_ATTRIBUTE_REQUEST:
      return {
        deviceList: state.deviceList,
        attributeList: state.attributeList,
        creating: true
      };
    case deviceConstants.POST_DEVICE_ATTRIBUTE_SUCCESS:
      return {
        deviceList: state.deviceList,
        attributeList: state.attributeList,
        postAttriuteRes: action.response.data,
        postAttributeStatus: action.response.status
      };
    case deviceConstants.POST_DEVICE_ATTRIBUTE_FAILURE:
      return {
        deviceList: state.deviceList,
        attributeList: state.attributeList,
        error: action.error
      };

    // del device's attribute
    case deviceConstants.DEL_DEVICE_ATTRIBUTE_REQUEST:
      return {
        deviceList: state.deviceList,
        attributeList: state.attributeList,
        deleting: true
      };
    case deviceConstants.DEL_DEVICE_ATTRIBUTE_SUCCESS:
      return {
        deviceList: state.deviceList,
        attributeList: state.attributeList,
        delAttriuteRes: action.response.data,
        delAttributeStatus: action.response.status
      };
    case deviceConstants.DEL_DEVICE_ATTRIBUTE_FAILURE:
      return {
        deviceList: state.deviceList,
        attributeList: state.attributeList,
        error: action.error
      };

    default:
      return state;
  }
}
