import React from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';

export const FilterUserSegment = (props) => {
  return (
    <Segment basic className="segment-nonePending">
      <Segment className="segment--bg-top-sidebar"></Segment>

      <Segment basic>
        <Header as="h2">Filter</Header>

        <Header textAlign="left" className="header--filter-sort">Sort by</Header>
        <div className="div--left">
          <Button content="Name" className="btn--filter"/>
          <Button content="Role" className="btn--filter"/>
          <Button content="Create date" className="btn--filter"/>
        </div>

        <hr className="hr--color-gray"/>

        <Header textAlign="left" className="header--filter-sort">View by role</Header>
        <div>
          <Button content="Admin" className="btn--filter"/>
          <Button content="User management" className="btn--filter"/>
          <Button content="Device management" className="btn--filter"/>
          <Button content="Asset management" className="btn--filter"/>
        </div>
        <div className="btn--fixBottom">
          <Button className="btn--inactive-gray"
            onClick={props.dimmed === props.visible ? props.handleAnimationChange('overlay', 'FilterUser') : null}>CLEAR</Button>
          <Button className="btn--active-green">SETTING</Button>
        </div>
      </Segment>
    </Segment>
  );
};
