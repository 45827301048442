import { createSelector } from "reselect";
import { isEmpty } from "lodash";

const getDevicesData = (state) => state.devicesData;

export const makeGetDevicesDataToTable = () => createSelector(
  [getDevicesData],
  (devices) => {
    if (isEmpty(devices)) {
      return [];
    }

    return devices.map((value) => ({
      id: value._id,
      name: value.name,
      type: value.type,
      label: value.label,
      directoryPath: value.directoryPath,
      directoryPathName: value.directoryPathName,
      createdAt: value.createdAt,
      updatedAt: value.updatedAt,
      expiredOn: value.expiredOn,
      error: value.error,
      isUpdate: value.isUpdate,
      isDelete: value.isDelete,
      isUpdateCustom: value.isUpdateCustom,
      isOnline: value.isOnline
    }));
  }
);
