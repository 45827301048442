import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dropdown,
  Form,
  FormField,
  FormGroup,
  Image,
  Segment,
  Header,
  Grid,
} from "semantic-ui-react";
import _, { last } from "lodash";

// component
import { connect } from "react-redux";
import {
  authorizeActions,
  userActions,
  accountPageActions,
  accountActions,
} from "../../../../../_actions/";
import IconCustom from "../../../../../assets/icons";
import TextField from "../../../../../components/TextField/TextField";
import FormGroupRole from "../../../../../components/FormGroup/FormGroupRole";
import styled from "styled-components";
import { RoleColor } from "../../../../../_helpers";
import SidebarButton from "../../../../../components/Button/SidebarButton";
import handleLoading from "../../../../../components/Loader/Loader";
import DefaultTextArea from "../../../../../components/TextArea/TextArea";
import SearchDropdown from "../../../../../components/Dropdown/SearchDropdown";
import Countries from "../../../../../assets/data/countries";

function gradientColor(props) {
  return (props.roleColors && props.roleColors.length > 1
  ? `linear-gradient(${props.roleColors.map(addColors)})`
  : props.roleColors.length === 1)
    ? `${props.roleColors[0]}`
    : "#589FA5";

  function addColors(item) {
    return `${item}`;
  }
}

const SegmentGradients = styled(Segment)`
  background: ${gradientColor} !important;
`;

const SegmentRole = styled(Segment)`
  background: ${gradientColor} !important;
`;

class UpdateUserDetailComponent extends Component {
  constructor(props) {
    super(props);

    const { userObj, options } = this.props;
      console.log("UpdateUserDetailComponent === >>> userObj ",userObj)
      const fullnameArray = userObj ? userObj.name.split(" ") : undefined;
    this.state = {
      name: userObj ? userObj.name : "",
      firstname: userObj ? fullnameArray[0] : "",
      lastname: userObj ? fullnameArray[1] : "",
      email: userObj ? userObj.email : "",
      telephoneNumber: userObj ? userObj.telephoneNumber : "",
      username: userObj ? userObj.username : "",
      password: userObj ? userObj.password : "",
      address1: userObj ? userObj.address1 : "",
      address2: userObj ? userObj.address2 : "",
      province: userObj ? userObj.province : "",
      country: userObj ? userObj.country : "",
      postal: userObj ? userObj.postal : "",
      description: userObj ? userObj.description : "",

      roleSelecting: {},
      roleSelected: [],
      options,
      countryOptions: Countries.COUNTRIES_LIST,

      disableRoleBtn: true,
      disableUpdateBtn: true,
      roleColors: [],
    };
  }

  inputEmailRef = createRef();

  componentDidMount() {
    const requestUserObj = this.props.userObj;

    Promise.resolve(this.props.getRoles({ directoryPath: this.props.objDirectory.path }, requestUserObj, true)).then(() => {
      this.checkEligible();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.roleSelected) {
      this.setState({
        roleSelected: nextProps.roleSelected,
      });
    }
  }

  checkEligible() {
    const directoryPath = this.props.objDirectory.path;
    const payload = { action: "*", subject: "*", directoryPath: directoryPath };

    Promise.resolve(this.props.getEligible(payload)).then((response) => {
      this.props.updateEligible(response.isEligible);
      if (!response.isEligible) {
        const arr = this.props.options?.filter((item) => item.text !== "admin");
        this.setState({ options: arr });
      } else {
        this.setState({ options: this.props.options });
      }
    });
  }

  onClickRole = () => {
    const payload = {
      key: this.state.roleSelecting.key,
    };
    Promise.resolve(this.props.decrementRole(payload)).then(() => {
      this.checkEligible();
    });

    this.setState((prevState) => ({
      roleColors:
        prevState.roleColors && prevState.roleColors.length
          ? [...prevState.roleColors, RoleColor(prevState.roleSelecting.value)]
          : [RoleColor(prevState.roleSelecting.value)],
      roleSelected: [prevState.roleSelecting, ...prevState.roleSelected],
      roleSelecting: {},
      disableRoleBtn: true,
      disableUpdateBtn: false,
    }));
  };

  onClickRemoveRole = (e, { value }) => {
    const roleSelected = this.state.roleSelected.find(
      (item) => item.key === value
    );
    const payload = { option: roleSelected };
    Promise.resolve(this.props.incrementRole(payload)).then(() => {
      this.checkEligible();
    });
    this.setState((prevState) => ({
      roleColors: prevState.roleColors.filter(
        (item) => item !== RoleColor(roleSelected.value)
      ),
      roleSelected: prevState.roleSelected.filter((item) => item.key !== value),
      disableUpdateBtn: false,
    }));
  };

  handleChangeRole = (e, data) => {
    const option = data.options.find((o) => o.value === data.value);

    this.setState({
      roleSelecting: option,
      disableRoleBtn: false,
    });
  };

  handleGetRolesId = (roles) => {
    const baseRoles = [
      {
        _id: "5e786223925a581eccd8e577",
        name: "admin",
      },
      {
        _id: "5e786223925a581eccd8e578",
        name: "admin_account",
      },
      {
        _id: "5e786223925a581eccd8e579",
        name: "admin_directory",
      },
      {
        _id: "5e786223925a581eccd8e579",
        name: "admin_dashboard",
      },
      {
        _id: "5e786223925a581eccd8e57b",
        name: "admin_authorize",
      },
      {
        _id: "5e786223925a581eccd8e57a",
        name: "admin_asset_and_device",
      },
      {
        _id: "5e786223925a581eccd8e57b",
        name: "user",
      },
      {
        _id: "6180b4409a6a9f7808004d3b",
        name: "Kubota Admin",
      },
      {
        _id: "6180b63d9a6a9f7808004d3d",
        name: "Service Engineer",
      },
      {
        _id: "6180b6d39a6a9f7808004d3e",
        name: "Sale Admin",
      },
      {
        _id: "6180b8c19a6a9f7808004d3f",
        name: "Customer",
      },
      {
        _id: "6180b9f39a6a9f7808004d40",
        name: "Read Only",
      },
      {
        _id: "6184b4969a6a9f7808004d60",
        name: "Region Admin",
      },
      {
        _id: "6527805a2f06675e4c247fd1",
        name: "Host",
      },
      {
        _id: "6527805a2f06675e4c247fd2",
        name: "Guest",
      }
    ];

    return baseRoles
      .filter((baseRole) => roles.includes(baseRole.name))
      .map((role) => role._id);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, disableUpdateBtn: false });
  };

  handleChangeFirstname = (e) => {
    this.setState({ firstname: e.target.value, disableUpdateBtn: false });
  };

  handleChangeLastname = (e) => {
    this.setState({ lastname: e.target.value, disableUpdateBtn: false });
  };

  handleOnChangeCountry = (value) => {
    this.setState({ country: value, disableUpdateBtn: false });
  };

  handleSubmit = () => {
    const { userObj } = this.props;
    const directoryPath = this.props.objDirectory.path;
    const {
      name,
      firstname,
      lastname,
      telephoneNumber,
      email,
      address1,
      address2,
      province,
      country,
      postal,
      description,
    } = this.state;

    const fullname = name.split(" ");
    const payload = {
      userId: userObj._id,
      requestBody: {
        email,
        //firstname: fullname[0],
        //lastname: fullname[1],
        firstname: firstname,
        lastname: lastname,
        telephoneNumber,
        address1,
        address2,
        province,
        country,
        postal,
        description,
      },
    };

    // const fields = ["email", "firstname", "lastname", "telephoneNumber", "address1", "address2", "province", "postal", "description"];

    // const deleteField = (field, value) => {
    //   if (_.isEmpty(value)) {
    //     delete payload.requestBody[field];
    //   }
    // }

    // fields.forEach((field)=>{
    //   deleteField(field, payload.requestBody[field]);
    // });

    const oldRoles = this.props.userObj.roles;
    const newRoles = this.state.roleSelected.map((role) => role.value);

    const decrementRole = oldRoles.filter(
      (oldRole) => !newRoles.includes(oldRole)
    );
    const incrementRole = newRoles.filter(
      (newRole) => !oldRoles.includes(newRole)
    );

    const decrementRolePayload = {
      userId: [userObj._id],
      roles: this.handleGetRolesId(decrementRole),
      directoryPath,
    };

    const incrementRolePayload = {
      userId: [userObj._id],
      roles: this.handleGetRolesId(incrementRole),
      directoryPath,
    };

    

    if (decrementRole.length === 0 && incrementRole.length === 0) {
      this.props
        .updateUserInfo(payload)
        .then(() => this.props.getUserListNoPage(directoryPath));
    } else if (decrementRole.length === 0) {
      this.props
        .postAssignRole(incrementRolePayload)
        .then(() => this.props.updateUserInfo(payload))
        .then(() => this.props.getUserListNoPage(directoryPath));
    } else if (incrementRole.length === 0) {
      this.props
        .postUnassignRole(decrementRolePayload)
        .then(() => this.props.updateUserInfo(payload))
        .then(() => this.props.getUserListNoPage(directoryPath));
    } else {
      this.props
        .postAssignRole(incrementRolePayload)
        .then(() => this.props.postUnassignRole(decrementRolePayload))
        .then(() => this.props.getUserListNoPage(directoryPath));
    }

    return null;
  };

  render() {
    const { roleSelected, countryOptions } = this.state;
    const { loading, handleAnimationChangeClose, isCreateAccount, isUpdateAccount, isUpdateCustomAccount, userObj, isAdminRoot } = this.props;

    const currentUser = JSON.parse(localStorage.getItem("user"))

    return (
      <React.Fragment>
        {handleLoading(loading)}
        <div>
          {/* <SegmentGradients
            className={"segment--profile"}
            basic
            inverted
            roleColors={this.state.roleColors} /> */}
          <div>
            <Header
              className={"header__title1 header__title1--sidebar"}
              textAlign={"center"}
            >
              { isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id) ? "Edit Account" : "View Account" }
            </Header>
          </div>

          <div className="div__scroll--createUser">
            {/* <SegmentRole
              className="image--profile"
              roleColors={this.state.roleColors}
              circular >
              <Image
                src="/images/addUser.svg"
                className="img--addNewUser"
                centered />
            </SegmentRole> */}

            <Form>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    {/* <TextField
                      text={"Full Name"}
                      id={"name"}
                      name="name"
                      value={this.state.name}
                      handleInputValue={this.handleChange}
                      isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                    /> */}

                    <TextField
                      text={"First Name"}
                      id={"firstname"}
                      name="firstname"
                      value={this.state.firstname}
                      handleInputValue={this.handleChangeFirstname}
                      isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                    />

                    <TextField
                      text={"Last Name"}
                      id={"lastname"}
                      name="lastname"
                      value={this.state.lastname}
                      handleInputValue={this.handleChangeLastname}
                      isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                    />

                    <TextField
                      text={"Phone Number"}
                      id={"phone-number"}
                      name="telephoneNumber"
                      handleInputValue={this.handleChange}
                      value={this.state.telephoneNumber}
                      isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                    />

                    <TextField
                      text={"Address1"}
                      id={"address1"}
                      name="address1"
                      handleInputValue={this.handleChange}
                      value={this.state.address1}
                      isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                    />

                    <TextField
                      text={"Address2"}
                      id={"address2"}
                      name="address2"
                      handleInputValue={this.handleChange}
                      value={this.state.address2}
                      isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                    />

                    <TextField
                      text={"Province"}
                      id={"province"}
                      name="province"
                      handleInputValue={this.handleChange}
                      value={this.state.province}
                      isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                    />

                    <SearchDropdown
                      includeClassName={
                        "dropdown__sidebar dropdown__sidebar--inline dropdown__sidebar--bottom"
                      }
                      options={countryOptions}
                      initialValue={this.state.country}
                      text={"Select country"}
                      name={"country"}
                      search={true}
                      handleChangeDropdown={this.handleOnChangeCountry}
                      isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                    />

                    <TextField
                      text={"Postal"}
                      id={"postal"}
                      name="postal"
                      handleInputValue={this.handleChange}
                      value={this.state.postal}
                      isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                    />
                    <br />

                    <div className={"div__header-sidebar--description"}>
                      <Header className={"header__title-sidebar"}>Description</Header>
                    </div>
                    <FormField>
                      <DefaultTextArea
                        name="description"
                        text={"Description"}
                        value={this.state.description}
                        handleChange={this.handleChange}
                        placeholder="Description"
                        isDisable={!(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
                      />
                    </FormField>
                  </Grid.Column>
                  <Grid.Column>
                    <TextField
                      text={"Username"}
                      id={"username"}
                      name={"username"}
                      handleInputValue={this.handleChange}
                      value={this.state.username}
                      isDisable={Boolean(this.state.username)}
                    />
                    <TextField
                      refTextField={this.inputEmailRef}
                      text={"Email"}
                      id={"email"}
                      name="email"
                      value={this.state.email}
                      handleInputValue={this.handleChange}
                      isDisable={Boolean(this.state.email)}
                    />
                    <br />
                    {roleSelected && roleSelected.length
                      ? roleSelected.map((item) => (
                          <FormGroupRole
                            key={item.key}
                            title={item.value}
                            value={item.key}
                            desc={item.desc}
                            onClickRemove={this.onClickRemoveRole}
                            isDisable={!isCreateAccount}
                          />
                        ))
                      : null}

                    { isCreateAccount ?
                      (this.state.options && this.state.options.length ?
                        <FormGroup inline>
                          <FormField
                            fluid
                            selection
                            className={"form-selection"}
                            width={13}
                            control={Dropdown}
                            options={this.state.options}
                            placeholder="Select Role"
                            name="roles"
                            onChange={this.handleChangeRole}
                            isDisable={!isCreateAccount}
                          />
                          <FormField className={"field--no-right"}>
                            <Button
                              basic
                              fluid
                              className={
                                "button__image button__image--sumbit-role"
                              }
                              onClick={this.onClickRole}
                              disabled={this.state.disableRoleBtn}
                            >
                              <IconCustom
                                name={"check"}
                                className={"svg-icon__check"}
                                fillColor={"ffffff"}
                                width={"40"}
                                height={"40"}
                                viewBox={"0 0 40 40"}
                                reactX={"6"}
                                reactY={"6"}
                              />
                            </Button>
                          </FormField>
                        </FormGroup>
                        : null)
                      : null
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </div>
        </div>

        <SidebarButton
          disabled={this.state.disableUpdateBtn}
          btnSecondary={isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id) ? "CANCEL" : "CLOSE"}
          btnActive="UPDATE"
          OnCloseSidebar={handleAnimationChangeClose()}
          handleSubmit={this.handleSubmit}
          width={5}
          isUpdateAccount={(isAdminRoot || (!isUpdateCustomAccount && isUpdateAccount) || (isUpdateCustomAccount && currentUser._id === userObj._id))}
        />
      </React.Fragment>
    );
  }
}

UpdateUserDetailComponent.propTypes = {
  loading: PropTypes.bool,
  roles: PropTypes.array,
  options: PropTypes.array,
  roleSelected: PropTypes.array,
  userObj: PropTypes.object,
  objDirectory: PropTypes.object,
  handleAnimationChangeClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading:
    state.user.creatingAssignRole ||
    state.user.creatingUnassignRole ||
    state.authorize.updatingUser,
  roles: state.authorize.roles,
  options: state.authorize.options,
  roleSelected: state.authorize.roleSelected,
});

const mapDispatchToProps = (dispatch) => ({
  postAssignRole: (payload) => dispatch(userActions.postAssignRole(payload)),
  postUnassignRole: (payload) =>
    dispatch(userActions.postUnassignRole(payload)),
  updateUserInfo: (payload) => dispatch(accountActions.updateUserInfo(payload)),
  getUserListNoPage: (directoryPath) =>
    dispatch(userActions.getUserListNoPage(directoryPath)),

  getRoles: (payload, requestUserObj, isUpdateComponent) =>
    dispatch(authorizeActions.getRoles(payload, requestUserObj, isUpdateComponent)),
  getAuthority: (payload) => dispatch(authorizeActions.getAuthority(payload)),
  incrementRole: (payload) => dispatch(authorizeActions.incrementRole(payload)),
  decrementRole: (payload) => dispatch(authorizeActions.decrementRole(payload)),
  getEligible: (payload) => dispatch(authorizeActions.getEligible(payload)),
  updateEligible: (eligible) =>
    dispatch(accountPageActions.updateEligible(eligible))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateUserDetailComponent);
