export const RoleLabel = (label) => {
    switch (label) {
        case "Region Admin":
            return "Service Admin";

        case "Service Engineer":
            return "Service Staff";
  
        default:
            return label;
    }
  };
  