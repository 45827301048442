/* eslint-disable max-lines */
import { authorizeConstants } from "../_constants/";
import { authHeader } from "../_helpers/";
import axios from "axios";
import { alertActions } from "./alert.actions";
import { alertFetchServiceError } from "../_helpers";
import { RoleLabel } from "../_helpers/";

export const authorizeActions = {
  getRoles,
  getRolesNoState,
  getRoleInfo,
  getAuthorityById,
  getAuthority,
  getAuthorityNext,
  delAuthorityUserFromDir,
  assignRole,
  unAssignRole,
  decrementRole,
  incrementRole,
  getEligible,
  getEligibleWithoutDirectoryPath,
  getUsersbySearch
};

const authorizePath = "/api/authorize";
const authorizeRelativePath = "/authority";

function getRoles(payload, requestUserObj, isUpdateComponent) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { headers: authHeader("withToken"), params: { limit: 65535, ...payload } };

    return axios
      .get(`${authorizePath}/role`, requestOptions)
      .then((response) => {
        const options = response.data.data.filter((item) => item.name !== 'admin_dashboard').map((item) => {
          return {
            key: item._id,
            text: RoleLabel(item.name),
            desc: item.description,
            value: item.name
          };
        });

        dispatch(success(response.data, options));
        return response;
      })
      .catch((error) => {
        alertFetchServiceError(dispatch, error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: authorizeConstants.GET_ROLES_REQUEST, requestUserObj };
  }

  function success(response, options) {
    return {
      type: authorizeConstants.GET_ROLES_SUCCESS,
      response,
      options,
      isUpdateComponent
    };
  }

  function failure(error) {
    return { type: authorizeConstants.GET_ROLES_FAILURE, error };
  }
}

function getRolesNoState(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { headers: authHeader("withToken"), params: { limit: 65535, ...payload } };

    return axios
      .get(`${authorizePath}/role`, requestOptions)
      .then((response) => {
        const options = response.data.data.map((item) => {
          return {
            key: item._id,
            text: RoleLabel(item.name),
            desc: item.description,
            value: item.name
          };
        });

        dispatch(success(options));
        return options;
      })
      .catch((error) => {
        alertFetchServiceError(dispatch, error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: authorizeConstants.GET_ROLES_NO_STATE_REQUEST };
  }

  function success(response, options) {
    return { type: authorizeConstants.GET_ROLES_NO_STATE_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.GET_ROLES_NO_STATE_FAILURE, error };
  }
}

function decrementRole(payload) {
  return (dispatch, getState) => {
    const { options } = getState().authorize;
    const newOptions = options.filter((item) => item.key !== payload.key);

    dispatch(decrement(newOptions));
  };

  function decrement(options) {
    return { type: authorizeConstants.DECREMENT_ROLE, options };
  }
}

function incrementRole(payload) {
  return (dispatch, getState) => {
    const { options } = getState().authorize;
    const newOptions = [...options, payload.option].sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    dispatch(increment(newOptions));
  };

  function increment(options) {
    return { type: authorizeConstants.INCREMENT_ROLE, options };
  }
}

function getRoleInfo(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { headers: authHeader("withToken") };
    const { id } = payload;

    return axios
      .get(`${authorizePath}/role/${id}`, requestOptions)
      .then((response) => {
        dispatch(success(response));
        return response.data;
      })
      .catch((error) => {
        alertFetchServiceError(dispatch, error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: authorizeConstants.GET_ROLE_INFO_REQUEST };
  }

  function success(response) {
    return { type: authorizeConstants.GET_ROLE_INFO_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.GET_ROLE_INFO_FAILURE, error };
  }
}

function getAuthorityById(payload) {
  return (dispatch) => {
    dispatch(request());

    const { userId } = payload;
    const requestOptions = { headers: authHeader("withToken") };

    return axios
      .get(`${authorizePath}/authority/user/${userId}`, requestOptions)
      .then((response) => dispatch(success(response)))
      .catch((error) => {
        alertFetchServiceError(dispatch, error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: authorizeConstants.GET_AUTHORITY_REQUEST };
  }

  function success(response) {
    return { type: authorizeConstants.GET_AUTHORITY_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.GET_AUTHORITY_FAILURE, error };
  }
}

// Get list of users by directory path
function getAuthority(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { params: payload };

    return axios
      .get(`${authorizePath}/authority`, requestOptions)
      .then((response) => {
        dispatch(success(response.data));
        return response.data;
      })
      .catch((error) => {
        // alertFetchServiceError(dispatch, error);
        dispatch(failure(error));

        const message = error.response.data.code === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: authorizeConstants.GET_AUTHORITY_LIST_REQUEST, payload };
  }

  function success(response) {
    return { type: authorizeConstants.GET_AUTHORITY_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.GET_AUTHORITY_LIST_FAILURE, error };
  }
}

// Get list of users by directory path
function getAuthorityNext(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { headers: authHeader("withToken") };

    return new Promise((resolve, reject) => {
      axios
        .get(`${authorizePath}/authority${payload.search}`, requestOptions)
        .then((response) => {
          dispatch(success(response.data));
          resolve();
        })
        .catch((error) => {
          alertFetchServiceError(dispatch, error);
          dispatch(failure(error));
          reject();
        });
    });
  };

  function request() {
    return { type: authorizeConstants.GET_AUTHORITY_LIST_REQUEST };
  }

  function success(response) {
    return { type: authorizeConstants.GET_AUTHORITY_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.GET_AUTHORITY_LIST_FAILURE, error };
  }
}

function delAuthorityUserFromDir(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { data: payload };

      return axios
        .delete(`${authorizePath}${authorizeRelativePath}/user`, requestOptions)
        .then((response) => {
          dispatch(success(response));
          dispatch(alertActions.success("Delete asset is successful!"));
        dispatch(alertActions.clear());
        })
        .catch((error) => {
          dispatch(failure(error));

          dispatch(
            alertActions.error(
              error.response.data.message ? error.response.data.message : "Something went wrong!",
              "Error!"
            )
          );
          dispatch(alertActions.clear());
        });
  };

  function request() {
    return { type: authorizeConstants.DEL_AUTHORITY_REQUEST, payload };
  }

  function success(response) {
      return { type: authorizeConstants.DEL_AUTHORITY_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.DEL_AUTHORITY_FAILURE, error };
  }
}

function assignRole(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { headers: authHeader("withToken") };

    return axios
      .post(`${authorizePath}/assign`, payload, requestOptions)
      .then((response) => {
        dispatch(success(response));
        dispatch(
          alertActions.success("add users from this role successfully!!")
        );
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        dispatch(
          alertActions.error(error.response.data.message, "Error!")
        );
        alertFetchServiceError(dispatch, error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: authorizeConstants.ASSIGN_ROLE_REQUEST };
  }

  function success(response) {
    return { type: authorizeConstants.ASSIGN_ROLE_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.ASSIGN_ROLE_FAILURE, error };
  }
}

function unAssignRole(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { headers: authHeader("withToken") };

    return axios
      .post(`${authorizePath}/unassign`, payload, requestOptions)
      .then((response) => {
        dispatch(success(response));
        dispatch(
          alertActions.success("remove users from this role successfully!!")
        );
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        alertFetchServiceError(dispatch, error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: authorizeConstants.UN_ASSIGN_ROLE_REQUEST };
  }

  function success(response) {
    return { type: authorizeConstants.UN_ASSIGN_ROLE_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.UN_ASSIGN_ROLE_FAILURE, error };
  }
}

function getEligible(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { params: payload };

    return new Promise((resolve, reject) => {
      axios
        .get(`${authorizePath}/eligible`, requestOptions)
        .then((response) => {
          dispatch(success(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(failure(error));
          reject();
        });
    });
  };

  function request() {
    return { type: authorizeConstants.GET_ELIGIBLE_REQUEST };
  }

  function success(response) {
    return { type: authorizeConstants.GET_ELIGIBLE_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.GET_ELIGIBLE_FAILURE, error };
  }
}

function getEligibleWithoutDirectoryPath(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { params: payload };

    return new Promise((resolve, reject) => {
      axios
        .get(`${authorizePath}/eligibleSomePath`, requestOptions)
        .then((response) => {
          dispatch(success(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(failure(error));
          reject();
        });
    });
  };

  function request() {
    return { type: authorizeConstants.GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_REQUEST };
  }

  function success(response) {
    return { type: authorizeConstants.GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.GET_ELIGIBLE_WITHOUT_DIRECTORY_PATH_FAILURE, error };
  }
}

function getUsersbySearch(payload) {
  return (dispatch) => {
    dispatch(request());

    const requestOptions = { params: payload };

    return axios
      .get(`${authorizePath}/authority/query`, requestOptions)
      .then((response) => {
        dispatch(success(response.data));
        return response.data;
      })
      .catch((error) => {
        // alertFetchServiceError(dispatch, error);
        dispatch(failure(error));

        const message = error.response.data.code === 403 ? null : error.response.data.message;

        dispatch(
          alertActions.error(
            error.response.data ? message : "Something went wrong!",
            "Error!"
          )
        );
        dispatch(alertActions.clear());
      });
  };

  function request() {
    return { type: authorizeConstants.GET_USER_SEARCH_LIST_REQUEST, payload };
  }

  function success(response) {
    return { type: authorizeConstants.GET_USER_SEARCH_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: authorizeConstants.GET_USER_SEARCH_LIST_FAILURE, error };
  }
}
