import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'semantic-ui-react';

export const DefaultTextArea = (props) => {
  return (
    // <div className={`normalfield__group ${props.padding ? props.padding : ""}`}>
    //   <TextArea
    //     className="normalfield__field"
    //     name={props.name}
    //     value={props.value}
    //     onChange={props.handleChange}
    //     placeholder={props.placeholder}
    //     disabled={props.isDisable? props.isDisable : false}
    //   />
    //   <label htmlFor={props.name} className="normalfield__label">{props.placeholder}</label>
    // </div>
    <div
      className={`floating-label-wrap ${
        props.className ? `floating-label-wrap${props.className}` : ""
        }`}
    >
      <TextArea
        type={props.type ? props.type : "text"}
        className={`floating-label-field floating-label-field--s3 ${
          props.isDisable ? "floating-label-field--s3--inactive" : ""
          }`}
        id={props.id}
        name={props.name}
        placeholder={props.placeholder ? props.placeholder : props.text}
        onChange={props.handleChange}
        value={props.dataUser ? props.dataUser : props.value}
        disabled={props.isDisable ? props.isDisable : false}
        rows={props.rows ? props.rows : 3}

      />
      <label
        htmlFor={props.id}
        className={`floating-label ${
          props.isDisable ? "floating-label--disabled" : ""
          }`}
      >
        {props.text}
      </label>

        <div className="text--validate-error">{props.error}</div>
    </div>

  );
};

DefaultTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default DefaultTextArea;
