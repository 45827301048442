import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { isNil } from 'lodash';

class MultipleSelectionDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: props.type ? props.type : "",
      option: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== undefined) {
      console.log(nextProps.options);
      this.setState({ option: nextProps.options });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.type !== this.props.type && !isNil(this.props.type)) {
      this.setState({
        searchQuery: this.props.type
      });
    }
  };

  handleChange = (e, { searchQuery, value }) => {
    this.setState(
      {
        searchQuery,
        value
      },
      () => this.props.handleChangeDropdown(this.state.value)
    );
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.setState(
      {
        searchQuery,
        value: ""
      },
      () => this.props.handleChangeDropdown(this.state.searchQuery)
    );
  };

  render() {
    const { searchQuery, value, option } = this.state;
    const { options, placeholder, disabled, classList } = this.props;
    return (
      <Dropdown
        className={`dropdown__selection font-body ${classList? classList : ""}`}
        options={option}
        search
        selection
        placeholder={`${placeholder && placeholder.length ? placeholder : 'Type*'}`}
        value={value}
        searchQuery={searchQuery}
        onChange={this.handleChange}
        onSearchChange={this.handleSearchChange}
        disabled={disabled}
      />
    );
  }
}

MultipleSelectionDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string,
  handleChangeDropdown: PropTypes.func.isRequired
};

export default MultipleSelectionDropdown;
