import { isEmpty } from 'lodash';

const asyncTimeout = (delay) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(delay), delay);
  })
    .then((d) => `Waited ${d} seconds`);
};

/**
 * @param {function} url - function for call or fetch api
 * @param {Object} payload - payload for param or body
 */
const asyncFetch = (url, payload) => {
  if (isEmpty(payload)) {
    return url();
  }

  return url(payload);
};

export const runTask = (spec) => {
  return spec.task === 'wait' ?
    asyncTimeout(spec.duration) :
    asyncFetch(spec.url, spec.payload);
};

// Example Async Sequence
// await asyncDelDevice.reduce(
//   (p, spec, index) => p.then(() => runTask(spec).then(() => {
//   })),
//   Promise.resolve(null)
// );
