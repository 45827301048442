import { accountConstants } from "../_constants";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {

    // login Page
    case accountConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        authenticating: true,
        payload: action.payload,
        rememberMe: action.rememberMe
      };
    case accountConstants.LOGIN_SUCCESS:
      return {
        user: action.resUserInfo,
        rememberMe: state.rememberMe
      };
    case accountConstants.LOGIN_FAILURE:
      return {
        error: action.err
      };

    // logout
    case accountConstants.LOGOUT_REQUEST:
      return {
        logout: true
      };
    case accountConstants.LOGOUT_SUCCESS:
      return { };
    case accountConstants.LOGOUT_FAILURE:
      return {
        error: action.err
      };

    default:
      return state;
  }
}
