/* eslint-disable complexity */
import { userConstants } from "../_constants";

export function user(state = {}, action) {
  switch (action.type) {

    // clear user
    case userConstants.CLEAR_USER:
      return {};

    // set initial user state
    case userConstants.INITIAL_STATE_USER:
      return {
        userList: state.userList
      };

    // get user list
    case userConstants.GET_USER_LIST_REQUEST:
      return {
        getting: true
      };
    case userConstants.GET_USER_LIST_SUCCESS:
      return {
        response: action.response,
        userList: action.response.data.data
          .filter((item) => item.user)
          .map((item) => ({
            _id: item.userId,
            name: `${item.user.firstname} ${item.user.lastname}`,
            email: item.user.email,
            telephoneNumber: item.user.telephoneNumber,
            roles: item.roles,
            username: item.user.username,
            password: item.user.password,
            address1: item.user.address1,
            address2: item.user.address2,
            province: item.user.province,
            country: item.user.country,
            postal: item.user.postal,
            description: item.user.description
          }))
      };
    case userConstants.GET_USER_LIST_FAILURE:
      return {
        error: action.error,
        getUserListStatus: action.error.response.status
      };

    // get user's info
    case userConstants.GET_USERINFO_REQUEST:
      return {
        gettingUserInfo: true,
        userList: state.userList
      };
    case userConstants.GET_USERINFO_SUCCESS:
      return {
        userDirectoryStatus: action.response.data.directory,
        userInfo: action.response.data.user,

        response: action.response,
        userList: state.userList
      };
    case userConstants.GET_USERINFO_FAILURE:
      return {
        error: action.error,
        userList: state.userList,
        errorStatus: action.error.response.data.code
      };

    // delete user from directory
    case userConstants.DEL_USER_FROM_DIR_REQUEST:
      return {
        userList: state.userList.map((userItem) => {
          return action.arrayId.includes(userItem._id) ?
            { ...userItem, deleting: true } :
            userItem;
          })
      };
    case userConstants.DEL_USER_FROM_DIR_SUCCESS:
      return {
        delUserResponse: action.delUserResponse,
        userList: action.compleateUserList.filter((userItem) => userItem.delStatus !== 204)
      };

    // case userConstants.DEL_USER_FROM_DIR_FAILURE:
    //   return {
    //     userList: state.userList,
    //     error: action.error
    //   };

    // create user
    case userConstants.POST_USER_REQUEST:
      return {
        creatingUser: true,
        userList: state.userList,
        user: action.payload
      };
    case userConstants.POST_USER_SUCCESS:
      return {
        response: action.response,
        userList: state.userList,

        // userList: [
        //   {
        //     _id: action.response.data._id,
        //     name: `${action.response.data.firstname} ${action.response.data.lastname}`,
        //     email: action.response.data.email,
        //     roles: state.user.roles
        //   },
        //   ...state.userList
        // ],
        postUserStatus: action.response.status
      };
    case userConstants.POST_USER_FAILURE:
      return {
        userList: state.userList,
        error: action.error
      };

    // post assign role
    case userConstants.POST_ASSIGN_ROLE_REQUEST:
      return {
        creatingAssignRole: true,
        userList: state.userList
      };
    case userConstants.POST_ASSIGN_ROLE_SUCCESS:
      return {
        response: action.response,
        postAssignRole: action.response.status,
        userList: state.userList
      };
    case userConstants.POST_ASSIGN_ROLE_FAILURE:
      return {
        userList: state.userList,
        error: action.error
      };

          // post unassign role
    case userConstants.POST_UNASSIGN_ROLE_REQUEST:
      return {
        creatingUnassignRole: true,
        userList: state.userList
      };
    case userConstants.POST_UNASSIGN_ROLE_SUCCESS:
      return {
        response: action.response,
        postUnassignRole: action.response.status,
        userList: state.userList
      };
    case userConstants.POST_UNASSIGN_ROLE_FAILURE:
      return {
        userList: state.userList,
        error: action.error
      };

    // get user detail
    case userConstants.GET_USER_DETAIL_REQUEST:
      return {
        userList: state.userList,
        loading: false,
        getting: true
      };
    case userConstants.GET_USER_DETAIL_SUCCESS:
      return {
        response: action.response,
        userList: state.userList
      };
    case userConstants.GET_USER_DETAIL_FAILURE:
      return {
        userList: state.userList,
        error: action.error
      };

    case userConstants.DELETE_USER_REQUEST:
      return {
        deleteUserRequest: action.request
      };
    case userConstants.DELETE_USER_SUCCESS:
      return {
        deleteUser: action.response,
        delUserStatus: action.status
      };
    case userConstants.DELETE_USER_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
