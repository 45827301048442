import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userActions, accountActions } from "../../../../_actions/";
import * as Regex from "../../../../../src/utils/Validator";
import _ from "lodash";

// component
import handleLoading from "../../../../components/Loader/Loader";
import { ResErrorComponent } from "./Component/ResErrorComponent";
import CreateUserComponent from "./Component/CreateUserComponent";
import CheckEmailComponent from "./Component/CheckEmailComponent";
class CreateUserController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      email: "",
      errorEmail: "",
      errorUser: "",
      fullName: "",
      firstname: "",
      lastname: "",
      errorFullName: "",
      telephoneNumber: "",
      errorTelephoneNumber: "",
      username: "",
      errorUsername: "",
      password: "",
      errorPassword: "",
      address1: "",
      address2: "",
      province: "",
      country: "",
      postal: "",
      description: "",
      isCreateComponent: false,
      user: "",
      firstType: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorComponent === 404 || nextProps.userDirectoryStatus === false || nextProps.createComponent === false) {
      this.setState({
        isCreateComponent: true
      });
    } else {
      this.setState({
        isCreateComponent: false
      });
    }

    if (nextProps.userInfo) {
      this.setState({
        _id: nextProps.userInfo._id,
        email: nextProps.userInfo.email,
        //fullName: `${nextProps.userInfo.firstname} ${nextProps.userInfo.lastname}`,
        firstname: nextProps.userInfo.firstname,
        lastname: nextProps.userInfo.lastname,
        telephoneNumber: nextProps.userInfo.telephoneNumber,
        username: nextProps.userInfo.username,
        password: nextProps.userInfo.password,
        address1: nextProps.userInfo.address1,
        address2: nextProps.userInfo.address2,
        province: nextProps.userInfo.province,
        country: nextProps.userInfo.country,
        postal: nextProps.userInfo.postal,
        description: nextProps.userInfo.description
      });
    }
  }

  validateInput = () => {
    let errorUser = "";
    let errorFullName = "";
    let errorFirstName = "";
    let errorLastName = "";
    let errorTelephoneNumber = "";
    let errorUsername = "";
    let errorPassword = "";
    let errorEmail = "";
    const { fullName, telephoneNumber, username, password, isCreateComponent, email, user,firstname,lastname } = this.state;

    if (isCreateComponent) {
      // if (!fullName.includes(' ')) {
      //   errorFullName = "Please enter your firstname lastname";
      // }

      if (firstname === "") {
        errorFirstName = "Please enter firstname ";
      }
      if (lastname === "") {
        errorLastName = "Please enter lastname";
      }
      if (!Regex.validateMobileWithoutCC(telephoneNumber)) {
        errorTelephoneNumber = "Please enter valid phone number";
      }
      if (username === "") {
        errorUsername = "Please enter username";
      }
      if (password === "") {
        errorPassword = "Please enter password";
      }
      if (email === "") {
        errorEmail = "Please enter email";
      }
    }
    else {
      // if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email) === false) {
      //   errorUser = "Please enter your email address";
      // }
      if (user === "") {
        errorUser = "Please enter username or email";
      }
    }

    if (errorUser || errorFirstName || errorLastName || errorTelephoneNumber || errorUsername || errorPassword || errorEmail) {
      this.setState({ errorUser, errorFullName, errorFirstName,errorLastName, errorTelephoneNumber, errorUsername, errorPassword, errorEmail });
      return false;
    }

    this.setState({
      errorUser: "",
      errorFullName: "",
      errorTelephoneNumber: "",
      errorUsername: "",
      errorPassword: "",
      errorEmail: "",
      errorFirstName: "",
      errorLastName: ""
    });

    return true;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnChangeCountry = (value) => {
    this.setState({ country: value });
  };

  handleSubmit = (roleSelected) => {
    const isValid = this.validateInput();
    const directoryPath = this.props.objDirectory.path;
    const {
      _id,
      email,
      fullName,
      firstname,
      lastname,
      telephoneNumber,
      isCreateComponent,
      username,
      password,
      address1,
      address2,
      province,
      country,
      postal,
      description,
      user,
      firstType
    } = this.state;
    const { errorComponent, createComponent, userDirectoryStatus } = this.props;
    if (isCreateComponent) {
      if (isValid) {
        if (errorComponent === 404) {
          const fullname = fullName.split(" ");
          const payload = {
            email,
            // firstname: fullname[0],
            // lastname: fullname[1],
            firstname:firstname,
            lastname:lastname,
            telephoneNumber,
            username,
            password,
            address1,
            address2,
            province,
            country,
            postal,
            description,
            roles: roleSelected.map((item) => item.key),
            directoryPath
          };

          // const fields = ["email", "firstname", "lastname", "username", "password", "telephoneNumber", "address1", "address2", "province", "postal", "description"];

          // const deleteField = (field, value) => {
          //   if (_.isEmpty(value)) {
          //     delete payload[field];
          //   }
          // }

          // fields.forEach((field)=>{
          //   deleteField(field, payload[field]);
          // });

          // create user
          this.props.postUser(payload, firstType).then(() => this.props.getUserListNoPage(directoryPath));
        } else if (createComponent === false || userDirectoryStatus === false) {
          const payload = {
            userId: [_id],
            roles: roleSelected.map((item) => item.key),
            directoryPath
          };

          // assign role
          this.props.postAssignRole(payload).then(() => this.props.getUserListNoPage(directoryPath));
        }
      }
    } else {
      if (isValid && user && directoryPath) {
        if(user.includes("@")) {
          this.setState({ email: user, firstType: "email" });
          this.props.getUserInfoFromEmail(user, directoryPath);
        } else{
          this.setState({ username: user, firstType: "username" });
          this.props.getUserInfoFromUsername(user, directoryPath);
        }
      }
    }
  };

  displayCheckEmailComponent = () => {
    // back btn in create user comp
    this.props.initialStateUser();
    this.setState({
      _id: "",
      email: "",
      //fullName: "",
      firstname: "",
      lastname: "",
      telephoneNumber: "",
      username: "",
      password: "",
      address1: "",
      address2: "",
      province: "",
      country: "",
      postal: "",
      description: ""
    });
  }

  renderComponentOfCreateUserSidebar = () => {
    const {
      email,
      fullName,
      telephoneNumber,
      username,
      password,
      address1,
      address2,
      province,
      country,
      postal,
      description,
      errorUser,
      errorFullName,
      errorFirstName,
      errorLastName,
      errorTelephoneNumber,
      errorUsername,
      errorPassword,
      errorEmail,
      user,
      firstname,
      lastname,
      firstType } = this.state;
    const { createComponent, userDirectoryStatus, errorComponent, handleAnimationChangeClose, objDirectory } = this.props;

    // account without this directory or account without workspace
    if (errorComponent === 404 || userDirectoryStatus === false || createComponent === false) {
      return (
        <CreateUserComponent
          email={email}
          fullName={fullName}
          firstname={firstname}
          lastname={lastname}
          telephoneNumber={telephoneNumber}
          username={username}
          password={password}
          address1={address1}
          address2={address2}
          province={province}
          country={country}
          postal={postal}
          description={description}
          errorFullName={errorFullName}
          errorFirstName={errorFirstName}
          errorLastName={errorLastName}
          errorTelephoneNumber={errorTelephoneNumber}
          errorUsername={errorUsername}
          errorPassword={errorPassword}
          errorEmail={errorEmail}
          firstType={firstType}

          handleChange={this.handleChange}
          handleOnChangeCountry={this.handleOnChangeCountry}
          handleSubmit={this.handleSubmit}

          // back btn in create user comp
          displayCheckEmailComponent={this.displayCheckEmailComponent}
          directoryPath={objDirectory.path}
          userDirectoryStatus={userDirectoryStatus}
        />
      );
    } else if (createComponent === true || userDirectoryStatus === true) {
      // this dir have an account
      return (
        <ResErrorComponent handleAnimationChangeClose={handleAnimationChangeClose} />
      );
    }

    return (
      <CheckEmailComponent
        user={user}
        errorUser={errorUser}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleAnimationChangeClose={handleAnimationChangeClose}
      />
    );
  }

  render() {
    const { loading, userDirectoryStatus } = this.props;

    console.log('createComponent', userDirectoryStatus);

    return (
      <React.Fragment>
        {handleLoading(loading)}

        {this.renderComponentOfCreateUserSidebar()}

      </React.Fragment>
    );
  }
}

CreateUserController.propTypes = {
  loading: PropTypes.bool,
  errorComponent: PropTypes.number,
  createComponent: PropTypes.bool,
  userInfo: PropTypes.object,
  handleAnimationChangeClose: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  getUserInfoFromEmail: (email, directoryPath) => dispatch(userActions.getUserInfoFromEmail(email, directoryPath)),
  getUserInfoFromUsername: (username, directoryPath) => dispatch(userActions.getUserInfoFromUsername(username, directoryPath)),
  initialStateUser: () => dispatch(userActions.initialStateUser()),
  postUser: (payload, firstType) => dispatch(userActions.postUser(payload, firstType)),
  postAssignRole: (payload) => dispatch(userActions.postAssignRole(payload)),
  getUserListNoPage: (directoryPath) => dispatch(userActions.getUserListNoPage(directoryPath))
});

const mapStateToProps = (state) => ({
  loading: state.user.gettingUserInfo || state.user.creatingUser || state.user.creatingAssignRole,
  errorComponent: state.user.errorStatus,
  createComponent: state.account.directoryStatus,
  userInfo: state.user.userInfo,
  userDirectoryStatus: state.user.userDirectoryStatus
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserController);
