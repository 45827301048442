/* eslint-disable complexity */
import { directoryConstants } from "../_constants";

const rootDirectory = JSON.parse(localStorage.getItem("rootDirectory"));
const initialState = rootDirectory ? { rootDirectory } : {};

export function directory(state = initialState, action) {
  switch (action.type) {

    // clear child directory
    case directoryConstants.CLEAR_CHILD_DIR:
      return {
        parentDirectory: state.parentDirectory,
        activeDirPath: state.activeDirPath,
        getParent: state.getParent
      };

    // get root/parent directory
    case directoryConstants.GET_DIRECTORY_REQUEST:
      return {
        loading: true,
        activeDirPath: action.activeDirPath,
        parentDirectory: state.parentDirectory,
        childDirectory: state.childDirectory,
        directoryEligibleStatus: state.directoryEligibleStatus
      };
    case directoryConstants.GET_DIRECTORY_SUCCESS:
      return {
        activeDirPath: state.activeDirPath ?
          [rootDirectory, ...state.activeDirPath] :
          [rootDirectory],
        parentDirectory: action.response.data.data,
        getParent: state.parentDirectory ? state.parentDirectory : action.response.data.data,
        childDirectory: state.childDirectory,
        childDir: action.response.data.data,
        rootDirectory,
        directoryEligibleStatus: state.directoryEligibleStatus
      };
    case directoryConstants.GET_DIRECTORY_FAILURE:
      return {
        error: action.error
      };

    // get child directory
    case directoryConstants.GET_CHILD_DIRECTORY_REQUEST:
      return {
        parentDirectory: state.parentDirectory,
        loading: true,
        id: action.id,
        activeDirPath: action.activeDirPath,
        getParent: state.getParent,
        directoryEligibleStatus: state.directoryEligibleStatus
      };
    case directoryConstants.GET_CHILD_DIRECTORY_SUCCESS:
      return {
        parentDirectory: state.parentDirectory,
        activeDirPath: state.activeDirPath ?
          [rootDirectory, ...state.activeDirPath] :
          [rootDirectory],
        childDirectory: action.response.data.data,
        getParent: state.getParent,
        directoryEligibleStatus: state.directoryEligibleStatus
      };
    case directoryConstants.GET_CHILD_DIRECTORY_FAILURE:
      return {
        error: action.error
      };

    // create directory
    case directoryConstants.POST_DIRECTORY_REQUEST:
      return {
        parentDirectory: state.parentDirectory,
        childDirectory: state.childDirectory,
        loading: true,
        role: action.role
      };
    case directoryConstants.POST_DIRECTORY_SUCCESS:
      return {
        parentDirectory:
          state.role === "parent" ?
            [...state.parentDirectory, action.response.data] :
            state.parentDirectory,
        childDirectory:
          state.role === "child" ?
            [...state.childDirectory, action.response.data] :
            [],
        postDirStatus: action.response.status
      };
    case directoryConstants.POST_DIRECTORY_FAILURE:
      return {
        parentDirectory: state.parentDirectory,
        error: action.error
      };

    // update directory
    case directoryConstants.PATCH_DIRECTORY_REQUEST:
      return {
        parentDirectory: state.parentDirectory,
        childDirectory: state.childDirectory,
        updating: true,
        id: action.id,
        payload: action.payload,
        role: action.role
      };
    case directoryConstants.PATCH_DIRECTORY_SUCCESS:
      return {
        parentDirectory:
          state.role === "parent" ?
            state.parentDirectory.map((item) => {
              return item._id === state.id ?
                  {
                      ...action.response.data
                    } :
                  item; }) :
            state.parentDirectory,
        childDirectory:
          state.role === "child" ?
            state.childDirectory.map((item) => {
              return item._id === state.id ?
                  {
                      ...action.response.data
                    } :
                  item; }) :
            state.childDirectory,
        patchDirStatus: action.response.status
      };
    case directoryConstants.PATCH_DIRECTORY_FAILURE:
      return {
        parentDirectory: state.parentDirectory,
        childDirectory: state.childDirectory,
        error: action.error
      };

    // delete directory
    case directoryConstants.DEL_DIRECTORY_REQUEST:
      return {
        parentDirectory: state.parentDirectory,
        childDirectory: state.childDirectory,
        deleting: true,
        id: action.id,
        role: action.role
      };
    case directoryConstants.DEL_DIRECTORY_SUCCESS:
      return {
        parentDirectory:
          state.role === "parent" ?
            state.parentDirectory.filter((item) => item._id !== state.id) :
            state.parentDirectory,
        childDirectory:
          state.role === "child" ?
            state.childDirectory.filter((item) => item._id !== state.id) :
            state.childDirectory,
        delDirectoryStatus: action.response.status
      };
    case directoryConstants.DEL_DIRECTORY_FAILURE:
      return {
        parentDirectory: state.parentDirectory,
        childDirectory: state.childDirectory,
        error: action.error
      };

    case directoryConstants.GET_DIRECTORY_LIST_REQUEST:
      return {
        loading: true
      };

    case directoryConstants.GET_DIRECTORY_LIST_SUCCESS:
      return {
        directory: action.response,

        // create dashboard modal
        optionDirectory: action.response.data.map((item) => ({
         key: item._id,
         value: item.path,
         text: item.name
        })),
        loading: false
      };

    case directoryConstants.GET_DIRECTORY_LIST_FAILURE:
      return {
        loading: false,
        error: action.error
      };

    case directoryConstants.GET_DIRECTORY_DROPDOWN_REQUEST:
      return {
        loading: true
      };

    case directoryConstants.GET_DIRECTORY_DROPDOWN_SUCCESS:
      return {
        directoryDropdown: action.response.map((item) => ({
          key: item._id,
          value: item.path,
          text: item.name
         })),
        loading: false
      };

    case directoryConstants.GET_DIRECTORY_DROPDOWN_FAILURE:
      return {
        loading: false,
        error: action.error
      };

      // get directory eligible
      case directoryConstants.GET_DIRECTORY_ELIGIBLE_REQUEST:
      return {
        loading: true,
        parentDirectory: state.parentDirectory,
        getParent: state.getParent,
        childDirectory: state.childDirectory,
        activeDirPath: state.activeDirPath
      };

    case directoryConstants.GET_DIRECTORY_ELIGIBLE_SUCCESS:
      return {
        parentDirectory: state.parentDirectory,
        getParent: state.getParent,
        childDirectory: state.childDirectory,
        activeDirPath: state.activeDirPath,

        directory: action.response,
        optionDirectory: action.response.data.data.map((item) => ({
         key: item._id,
         value: item.path,
         text: item.name
        })),
        loading: false
      };

    case directoryConstants.GET_DIRECTORY_ELIGIBLE_FAILURE:
      return {
        parentDirectory: state.parentDirectory,
        childDirectory: state.childDirectory,
        loading: false,
        err: action.error,
        directoryEligibleStatus: action.error.response.status
      };

    default:
      return state;
  }
}
