import React from "react";

// const SVG = () => (
//   <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
//     <rect width="31" height="31" rx="8" fill="#D35344"/>
//     <path d="M14.1875 20.4375C14.1875 20.0894 14.3258 19.7556 14.5719 19.5094C14.8181 19.2633 15.1519 19.125 15.5 19.125C15.8481 19.125 16.1819 19.2633 16.4281 19.5094C16.6742 19.7556 16.8125 20.0894 16.8125 20.4375C16.8125 20.7856 16.6742 21.1194 16.4281 21.3656C16.1819 21.6117 15.8481 21.75 15.5 21.75C15.1519 21.75 14.8181 21.6117 14.5719 21.3656C14.3258 21.1194 14.1875 20.7856 14.1875 20.4375Z" fill="white"/>
//     <path d="M14.639 11.9675C14.6755 11.7658 14.7817 11.5833 14.9391 11.4519C15.0965 11.3204 15.295 11.2484 15.5 11.2484C15.705 11.2484 15.9035 11.3204 16.0609 11.4519C16.2183 11.5833 16.3245 11.7658 16.361 11.9675L16.375 12.125V16.5L16.361 16.6575C16.3245 16.8593 16.2183 17.0418 16.0609 17.1732C15.9035 17.3046 15.705 17.3766 15.5 17.3766C15.295 17.3766 15.0965 17.3046 14.9391 17.1732C14.7817 17.0418 14.6755 16.8593 14.639 16.6575L14.625 16.5V12.125L14.639 11.9675Z" fill="white"/>
//     <path d="M24.25 16.5C24.25 18.8206 23.3281 21.0462 21.6872 22.6872C20.0462 24.3281 17.8206 25.25 15.5 25.25C13.1794 25.25 10.9538 24.3281 9.31282 22.6872C7.67187 21.0462 6.75 18.8206 6.75 16.5C6.75 14.1794 7.67187 11.9538 9.31282 10.3128C10.9538 8.67187 13.1794 7.75 15.5 7.75C17.8206 7.75 20.0462 8.67187 21.6872 10.3128C23.3281 11.9538 24.25 14.1794 24.25 16.5ZM22.5 16.5C22.5 14.6435 21.7625 12.863 20.4497 11.5503C19.137 10.2375 17.3565 9.5 15.5 9.5C13.6435 9.5 11.863 10.2375 10.5503 11.5503C9.2375 12.863 8.5 14.6435 8.5 16.5C8.5 18.3565 9.2375 20.137 10.5503 21.4497C11.863 22.7625 13.6435 23.5 15.5 23.5C17.3565 23.5 19.137 22.7625 20.4497 21.4497C21.7625 20.137 22.5 18.3565 22.5 16.5Z" fill="white"/>
//   </svg>
// );

const SVG = ({ fillColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M8.1875 13.4375C8.1875 13.0894 8.32578 12.7556 8.57192 12.5094C8.81806 12.2633 9.1519 12.125 9.5 12.125C9.8481 12.125 10.1819 12.2633 10.4281 12.5094C10.6742 12.7556 10.8125 13.0894 10.8125 13.4375C10.8125 13.7856 10.6742 14.1194 10.4281 14.3656C10.1819 14.6117 9.8481 14.75 9.5 14.75C9.1519 14.75 8.81806 14.6117 8.57192 14.3656C8.32578 14.1194 8.1875 13.7856 8.1875 13.4375Z" fill={fillColor} />
    <path d="M8.639 4.96764C8.67553 4.76589 8.78174 4.58338 8.9391 4.45196C9.09646 4.32053 9.29498 4.24854 9.5 4.24854C9.70502 4.24854 9.90354 4.32053 10.0609 4.45196C10.2183 4.58338 10.3245 4.76589 10.361 4.96764L10.375 5.12514V9.50014L10.361 9.65764C10.3245 9.85938 10.2183 10.0419 10.0609 10.1733C9.90354 10.3047 9.70502 10.3767 9.5 10.3767C9.29498 10.3767 9.09646 10.3047 8.9391 10.1733C8.78174 10.0419 8.67553 9.85938 8.639 9.65764L8.625 9.50014V5.12514L8.639 4.96764Z" fill={fillColor} />
    <path d="M18.25 9.5C18.25 11.8206 17.3281 14.0462 15.6872 15.6872C14.0462 17.3281 11.8206 18.25 9.5 18.25C7.17936 18.25 4.95376 17.3281 3.31282 15.6872C1.67187 14.0462 0.75 11.8206 0.75 9.5C0.75 7.17936 1.67187 4.95376 3.31282 3.31282C4.95376 1.67187 7.17936 0.75 9.5 0.75C11.8206 0.75 14.0462 1.67187 15.6872 3.31282C17.3281 4.95376 18.25 7.17936 18.25 9.5ZM16.5 9.5C16.5 7.64348 15.7625 5.86301 14.4497 4.55025C13.137 3.2375 11.3565 2.5 9.5 2.5C7.64348 2.5 5.86301 3.2375 4.55025 4.55025C3.2375 5.86301 2.5 7.64348 2.5 9.5C2.5 11.3565 3.2375 13.137 4.55025 14.4497C5.86301 15.7625 7.64348 16.5 9.5 16.5C11.3565 16.5 13.137 15.7625 14.4497 14.4497C15.7625 13.137 16.5 11.3565 16.5 9.5Z" fill={fillColor} />
  </svg>
);

export default SVG;