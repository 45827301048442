import React from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";

export const InputSearchTable = (props) => (
  <Input
    className="input__search--withoutBorder"
    icon="search"
    iconPosition="left"
    onChange={props.handleSearchChange}
    value={props.searchValue}
    placeholder="Search"
  />
);

InputSearchTable.propTypes = {
  handleSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  name: PropTypes.string
};

export default InputSearchTable;
