import React from "react";

const SVG = ({
  className = "",
  viewBox = "0 0 32 32",
  width = "32",
  height = "32",
  x = "10",
  y = "8"
}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" />
    <path
      d="M20.4444 8H9.77778C8.79111 8 8 8.8 8 9.77778V22.2222C8 23.2 8.79111 24 9.77778 24H22.2222C23.2 24 24 23.2 24 22.2222V11.5556L20.4444 8ZM16 22.2222C14.5244 22.2222 13.3333 21.0311 13.3333 19.5556C13.3333 18.08 14.5244 16.8889 16 16.8889C17.4756 16.8889 18.6667 18.08 18.6667 19.5556C18.6667 21.0311 17.4756 22.2222 16 22.2222ZM18.6667 13.3333H9.77778V9.77778H18.6667V13.3333Z"
    />
  </svg>
);

export default SVG;
