import { combineReducers } from "redux";
import { paginationReducerFor } from "./pagination.reducer";
import { activityPageConstants } from "../../_constants/pages/activityPage.constants";

const initialState = {
  activityData: [],
  fetching: false
};

export function activityPage(state = initialState, action) {
  switch (action.type) {

    case activityPageConstants.CLEAR_ACTIVITY:
      return {};

    case activityPageConstants.SET_ACTIVITY_DATA:
      return {
        ...state,
        activityData: action.activity.data
      };

    case activityPageConstants.SET_IS_FETCHING:
      return {
        ...state,
        fetching: !state.fetching
      };

    default:
      return state;
  }
}

export const activityPageReducer = combineReducers({
  activityData: activityPage,
  paginationData: paginationReducerFor('ACTIVITY_')
});
