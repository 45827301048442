export const notificationConstants = {
  // get LOG list
  GET_LOG_REQUEST: "NOTIFICATION_GET_LOG_REQUEST",
  GET_LOG_SUCCESS: "NOTIFICATION_GET_LOG_SUCCESS",
  GET_LOG_FAILURE: "NOTIFICATION_GET_LOG_FAILURE",

  // get LOG detail
  GET_LOG_DETAIL_REQUEST: "NOTIFICATION_GET_DETAIL_LOG_REQUEST",
  GET_LOG_DETAIL_SUCCESS: "NOTIFICATION_GET_DETAIL_LOG_SUCCESS",
  GET_LOG_DETAIL_FAILURE: "NOTIFICATION_GET_DETAIL_LOG_FAILURE",

  // get LOG export list
  GET_LOG_EXPORT_REQUEST: "NOTIFICATION_GET_LOG_EXPORT_REQUEST",
  GET_LOG_EXPORT_SUCCESS: "NOTIFICATION_GET_LOG_EXPORT_SUCCESS",
  GET_LOG_EXPORT_FAILURE: "NOTIFICATION_GET_LOG_EXPORT_FAILURE",
};
