import React, { Component } from "react";
import { Grid, Header, Image, Segment, Sidebar } from "semantic-ui-react";

import { connect } from "react-redux";
import { assetActions, authorizeActions, deviceActions, userActions } from "../../../_actions/";

import VerticalSidebar from "../../../components/Sidebar/VerticalSidebar";
import handleLoading from "../../../components/Loader/Loader";
import SwicthRenderTabPane from "./SwicthRenderTabPane";

class SidebarPushable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: "overlay",
      direction: "right",
      dimmed: true,
      visible: false,
      type: "",

      isShowCheckbox: false,
      checkedListAll: [],
      isShowExportCheckbox: false,
      checkedListExportAll: [],
      userObj: "",
      searchValue: "",
      sidebarWidth: "very wide"
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.objDirectory !== this.props.objDirectory || nextProps.delStatus === 204) {
      this.setState({ isShowCheckbox: false, checkedListAll: [] });
    }
    if (nextProps.postStatus === 201 || nextProps.patchStatus === 200) { this.setState({ visible: false, type: "" }); }

    if (nextProps.directoryStatus === null) { this.setState({ visible: false, type: "" }); }

    if (nextProps.directoryIsClicked) { this.setState({ visible: false, type: "" }); }
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ searchValue: value },
      () => {
        switch (this.props.activeTabIndex) {
          case 0:
            this.props.getUserListNoPage(this.props.objDirectory.path, this.state.searchValue);
            break;
          case 1:
            break;
          case 2:
            this.props.getDeviceListNoPage(this.props.objDirectory.path, this.state.searchValue);
            break;
          case 3:
            this.props.getAssetList(this.props.objDirectory.path, this.state.searchValue);
            break;
          default:
            break;
        }
      });
  };

  handleSwitchCheckbox = (id) => {
    this.setState((prevState) => ({ isShowCheckbox: !prevState.isShowCheckbox }),
      () => {
        if (id) { this.setState((prevState) => ({ checkedListAll: [...prevState.checkedListAll, id] })); }
      });
  };

  handleCheckboxClick = (e) => {
    const { id, checked } = e.target;

    if (checked) {
      this.setState((prevState) => ({ checkedListAll: [...prevState.checkedListAll, id] }));
    } else {
      this.setState((prevState) => ({ checkedListAll: prevState.checkedListAll.filter((item) => item !== id) }),
        () => { if (this.state.checkedListAll.length === 0) { this.handleSwitchCheckbox(); } });
    }
  };

  handleSwitchExportCheckbox = (id) => {
    const idOri = id?.replace("export","")
    this.setState((prevState) => ({ isShowExportCheckbox: !prevState.isShowExportCheckbox }),
      () => {
        if (idOri) { this.setState((prevState) => ({ checkedListExportAll: [...prevState.checkedListExportAll, idOri] })); }
      });
  };

  handleExportCheckboxClick = (e) => {
    const { id, checked } = e.target;
    const idOri = id?.replace("export","")
    if (checked) {
      this.setState((prevState) => ({ checkedListExportAll: [...prevState.checkedListExportAll, idOri] }));
    } else {
      this.setState((prevState) => ({ checkedListExportAll: prevState.checkedListExportAll.filter((item) => item !== idOri) }),
        () => { if (this.state.checkedListExportAll.length === 0) { this.handleSwitchExportCheckbox(); } });
    }
  };

  handleSelectedRow = (obj) => {
    switch (this.props.activeTabIndex) {
      case 0:
        this.setState({ userObj: obj, visible: true, type: "userInfo" });
        break;
      case 1:
        break;
      case 2:
        this.props.getDeviceInfo(obj._id).then(() => {
          this.setState({ visible: true, type: "device" });
        });
        break;
      case 3:
        this.props.getAssetInfo(obj._id).then(() => {
          this.setState({ visible: true, type: "asset" });
        });
        break;
      default:
        break;
    }
  };

  handleDeleteItem = (arrayId) => {
    let payload;

    switch (this.props.activeTabIndex) {
      case 0: {
        payload = {
          directoryPath: this.props.objDirectory.path,
          users: arrayId
        };

        this.props.delAuthorityUserFromDir(payload)

          .then(() => this.props.getUserListNoPage(this.props.objDirectory.path));

        break;
      }

      case 1:
        break;
      case 2:
        payload = { _id: arrayId };
        const deleteDevice = arrayId.map((value) => this.props.delDevice(value, true));

        Promise.all(deleteDevice).then(()=>{
          this.props.getDeviceListNoPage(this.props.objDirectory.path, this.state.searchValue);
        });
        break;
      case 3:
        payload = { id: arrayId };
        this.props.delAsset(payload);
        break;
      default:
        break;
    }
  };

  handleExportItem = (arrayId) => {
    let payload;

    payload = { _id: arrayId };
    console.log(payload)
    // const exportDevice = arrayId.map((value) => this.props.delDevice(value, true));
    // Promise.all(exportDevice)
  };

  handleAnimationChange = (type) => () => {
    this.setState({ visible: true, type });
  };

  handleAnimationChangeClose = () => () => {
    const { activeTabIndex } = this.props;
    this.setState({ visible: false, type: "" }, () => {
      switch (activeTabIndex) {
        case 0:
          this.props.initialStateUser();
          break;
        case 1:
          break;
        case 2:
          break;
        case 3:
          this.props.initialStateAsset();
          break;
        default:
          break;
      }
    });
  };

  renderEmptyContentComponent = (image, header, loading, color) => {
    return (
      <>
        {loading ? <Grid centered className="grid__col12--directory">
        {handleLoading(loading)}
        </Grid> :

        (<Segment basic className="segment--center" textAlign="center">
          <Image src={`./images/${image}`} centered/>
          <Header className="font--title2" color={color}>
            {header}
          </Header>
        </Segment>)}
      </>
    );
  }

  renderSidebarContent = (parentDirectory, activeDirectory, isRootDirectory, getUserListStatus) => {
    const { animation, direction, visible, type, isShowCheckbox, checkedListAll, isShowExportCheckbox, checkedListExportAll, searchValue, userObj, sidebarWidth } = this.state;
    const { objDirectory, handleTabChange, loading, activeTabIndex, directoryEligibleStatus, isAdminRoot,
            isCreateAccount, isUpdateAccount, isUpdateCustomAccount, isDeleteAccount, isAssignAccount, isUnassignAccount, isCreateDevice, isUpdateDevice, isDeleteDevice, isUpdateCustomDevice } = this.props;
    if (parentDirectory && parentDirectory.length > 0) {
      // if (getUserListStatus) {
      //   return (
      //     this.renderEmptyContentComponent('not-permission.jpg', 'You do not have permission to access', null, 'grey')
      //   );
      // }
      if (activeDirectory || isRootDirectory) {
        return (
          <Grid centered className="grid__col12--directory">
          <Grid.Column width={16}>
            <Sidebar.Pushable
              as={Segment}
              className="directory__segment--content" >
              <VerticalSidebar
                userObj={userObj}
                objDirectory={objDirectory}
                animation={animation}
                direction={direction}
                visible={visible}
                type={type}
                handleAnimationChangeClose={this.handleAnimationChangeClose}
                sidebarWidth={activeTabIndex !== 1 ? sidebarWidth : "thin"}
                isCreateAccount={isCreateAccount}
                isUpdateAccount={isUpdateAccount}
                isUpdateCustomAccount={isUpdateCustomAccount}
                isUpdateDevice={isUpdateDevice}
                isUpdateCustomDevice={isUpdateCustomDevice}
                isAdminRoot={isAdminRoot}
              />

              <Sidebar.Pusher dimmed={visible}>
                <Grid centered>
                  {/* <Grid.Column width={1} /> */}
                  <Grid.Column width={15}>
                      <SwicthRenderTabPane
                        activeTabIndex={activeTabIndex}
                        handleTabChange={handleTabChange}

                        // open create sidebar
                        handleAnimationChange={this.handleAnimationChange}
                        isSidebarOpen={visible}

                        // directoryPath
                        objDirectory={objDirectory}

                        // muliple checkbox
                        isShowCheckbox={isShowCheckbox}
                        checkedListAll={checkedListAll}
                        isShowExportCheckbox={isShowExportCheckbox}
                        checkedListExportAll={checkedListExportAll}
                        handleSwitchCheckbox={this.handleSwitchCheckbox}
                        handleCheckboxClick={this.handleCheckboxClick}
                        handleSwitchExportCheckbox={this.handleSwitchExportCheckbox}
                        handleExportCheckboxClick={this.handleExportCheckboxClick}

                        // del and select rpw for open sidebar detail of asset
                        handleDeleteItem={this.handleDeleteItem}
                        handleSelectedRow={this.handleSelectedRow}

                        // export
                        handleExportItem={this.handleExportItem}

                        // search
                        searchValue={searchValue}
                        handleSearchChange={this.handleSearchChange}

                        getUserListStatus={getUserListStatus}
                        renderEmptyContentComponent={this.renderEmptyContentComponent}

                        isCreateAccount={isCreateAccount}
                        isUpdateAccount={isUpdateAccount}
                        isUpdateCustomAccount={isUpdateCustomAccount}
                        isDeleteAccount={isDeleteAccount}
                        isAssignAccount={isAssignAccount}
                        isUnassignAccount={isUnassignAccount}
                        isCreateDevice={isCreateDevice}
                        isUpdateDevice={isUpdateDevice}
                        isDeleteDevice={isDeleteDevice}
                        isUpdateCustomDevice={isUpdateCustomDevice}
                        isAdminRoot={isAdminRoot}
                      />

                  </Grid.Column>
                  {/* <Grid.Column width={1} /> */}
                </Grid>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Grid.Column>
        </Grid>
        );
      }

      return this.renderEmptyContentComponent('img-inactive-directory.svg', 'Please select directory...', loading);

    } else if(directoryEligibleStatus === 403) {
      return this.renderEmptyContentComponent('not-permission.png', 'You do not have permission to access', null, 'grey');
    }

    return this.renderEmptyContentComponent('img-empty-directory.svg', 'Please create directory...', loading);
  }

  render() {
    const { activeDirectory, parentDirectory, getUserListStatus } = this.props;
    const isRootDirectory = Boolean(this.props.activeDirPath && this.props.activeDirPath.length === 1);

    return (
      <React.Fragment>
        {this.renderSidebarContent(parentDirectory, activeDirectory, isRootDirectory, getUserListStatus)}
      </React.Fragment>
    );
  }
}

SidebarPushable.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  // get item list
  getDeviceListNoPage: (directoryPath, value) => dispatch(deviceActions.getDeviceListNoPage(directoryPath, value)),
  getAssetList: (directoryPath, value) => dispatch(assetActions.getAssetList(directoryPath, value)),

  // del item
  // delUserfromDirectory: (arrayId, userList) => dispatch(userActions.delUserfromDirectory(arrayId, userList)),
  delAuthorityUserFromDir: (payload) => dispatch(authorizeActions.delAuthorityUserFromDir(payload)),

  delDevice: (payload, isDeletingOneByOne) => dispatch(deviceActions.delDevice(payload, isDeletingOneByOne)),
  delAsset: (payload) => dispatch(assetActions.delAsset(payload)),

  // get item's information
  getDeviceInfo: (id) => dispatch(deviceActions.getDeviceInfo(id)),
  getAssetInfo: (id) => dispatch(assetActions.getAssetInfo(id)),

  // get user list
  getUserListNoPage: (directoryPath) => dispatch(userActions.getUserListNoPage(directoryPath)),

  // set initial state of user or asset
  initialStateAsset: () => dispatch(assetActions.initialStateAsset()),
  initialStateUser: () => dispatch(userActions.initialStateUser())
});

const mapStateToProps = (state) => ({
  postStatus:
    state.device.postDeviceStatus || state.asset.postAssetStatus || state.user.postUserStatus,
  patchStatus: state.device.patchDeviceStatus || state.asset.patchAssetStatus ||
    state.user.postAssignRole || state.user.postUnassignRole || state.authorize.patchUserStatus,
  delStatus:

    // state.user.delUserStatus ||
    state.device.delDeviceStatus ||
    state.asset.delAssetStatus || state.authorize.deleteAuthority,
  activeDirPath: state.directory.activeDirPath,
  activeDirectory: state.directory.childDirectory,
  parentDirectory: state.directory.parentDirectory,
  loading: state.directory.loading,
  directoryStatus: state.user.directoryStatus,
  userList: state.user.userList,
  getUserListStatus: state.user.getUserListStatus,
  directoryEligibleStatus: state.directory.directoryEligibleStatus
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarPushable);
