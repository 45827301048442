import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Input, Popup } from "semantic-ui-react";

import { connect } from "react-redux";

// component
import { VehicleTable } from "../../../components/Table/VehicleTable";
import handleLoading from "../../../components/Loader/Loader";
import DeleteModal from "../../../components/Modal/DeleteModal";
import IconCustom from "../../../assets/icons";

class Users extends Component {
  constructor(props) {
    super(props);

    let header = ["Name", "Email", "Role"];

    if (props.isUpdateAccount || props.isDeleteAccount) {
      header.push("");;
    }

    this.state = {
      vehiclesHeader: header,
      filterText: "",
      tab: 'user'
    };
  }

  filterUpdate = (e) => {
    this.setState({
      filterText: e.target.value
    });
  };

  render() {
    const {
      userList,
      loading,
      onOpenSidebar,
      isShowCheckbox,
      checkedListAll,
      handleSwitchCheckbox,
      handleCheckboxClick,
      handleDeleteItem,
      handleSelectedRow,
      isCreateAccount,
      isUpdateAccount,
      isUpdateCustomAccount,
      isDeleteAccount,
      isAdminRoot
    } = this.props;
    const { vehiclesHeader, filterText, tab } = this.state;

    return (
      <React.Fragment>
        {/* {loading || this.props.getUserListStatus ? this.props.renderEmptyContentComponent('not-permission.jpg', 'You do not have permission to access', null, 'grey') :

  } */}

        {loading ?
          handleLoading(loading, "tableLoading") :
          this.props.getUserListStatus ? this.props.renderEmptyContentComponent('not-permission.jpg', 'You do not have permission to access', null, 'grey') :
          (<><Grid>
            <Grid.Row>
              <Grid.Column width={14}>
              </Grid.Column>
              <Grid.Column width={2}>
                <Button.Group floated="right">
                  <Popup
                    trigger={isCreateAccount ? (
                      <span onClick={onOpenSidebar("user")}>
                        <IconCustom name={"add"} className={"svg-icon__add"} />
                      </span>) : null
                    }
                    content="Add user"
                    mouseLeaveDelay={0}
                    basic
                  />
                  {isShowCheckbox ? (
                    <DeleteModal
                      triggerType="icon--trash"
                      id={checkedListAll}
                      name={userList.find(
                        (item) => item._id === checkedListAll[0]
                      )}
                      descType={"userdirectory"}
                      handleDeleteItem={handleDeleteItem}

                      // for user table, close del modal when click submit bottom
                      closeModal={true}
                    />
                  ) : null}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <VehicleTable
            user={true} // condition to show table user
            filterText={this.state.filterText}
            vehicles={userList}
            vehiclesHeader={vehiclesHeader}
            checkedListAll={checkedListAll}
            isShowCheckbox={isShowCheckbox}
            handleSwitchCheckbox={handleSwitchCheckbox}
            handleCheckboxClick={handleCheckboxClick}
            handleSelectedRow={handleSelectedRow}
            tabName={tab}
            isCreateAccount={isCreateAccount}
            isUpdateAccount={isUpdateAccount}
            isUpdateCustomAccount={isUpdateCustomAccount}
            isDeleteAccount={isDeleteAccount}
            isAdminRoot={isAdminRoot}
          /></>)
        }
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  userList: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  isShowCheckbox: PropTypes.bool,
  checkedListAll: PropTypes.array,
  handleSwitchCheckbox: PropTypes.func,
  handleCheckboxClick: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  handleSelectedRow: PropTypes.func
};

const mapStateToProps = (state) => ({
  userList: state.user.userList,
  loading: state.user.getting
});

export default connect(
  mapStateToProps,
  null
)(Users);
