import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, Grid, Header, Icon, Image, Label, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import IconCustom from "../../../assets/icons";
import { RoleColor,RoleLabel } from "../../../_helpers";
import InputSearch from "../../../components/Input/InputSearch";
import _ from "lodash";
import handleLoading from "../../../components/Loader/Loader";
import axios from 'axios';
import { authorizeActions } from "../../../_actions/authorize.actions";

class RoleAndPermission extends Component {
  state = {
    resultRoles: [],
    users: []
  };

  onClickFilter = (event, data) => {
    // console.log("on click");
  };

  handleSearchChange = (e, value) => {
    if (value) {
      const re = new RegExp(_.escapeRegExp(value), "i");

      this.setState((prevState, prevProps) => ({
        resultRoles: prevProps.roles.filter((item) => re.test(item.name))
      }));
    } else {
      this.setState((prevState, prevProps) => ({
        resultRoles: prevProps.roles
      }));
    }
  };
  componentDidMount() {
    this.handleGetAllData()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.roles !== this.props.roles &&
      this.props.roles &&
      this.props.roles.length
    ) {
      this.setState((state, props) => ({
        resultRoles: props.roles
      }));
    }
  };

  handleGetAllData() {
    const payload = { directoryPath: this.props.directoryPath };

    axios.all([this.props.getAuthority(payload)])
      .then(axios.spread((usersList) => {
        if (usersList) {
          this.setState({
            users: usersList.data
          })
        }
      }));
  }

  getUserInRole = (users, roleName) => {
    const userList = users.filter((item) => item.roles.includes(roleName))
    return userList.length;
  };

  renderEmptyContentComponent = (image, header, loading, color) => {
    return (
      <>
        {loading ? <Grid centered className="grid__col12--directory">
        {handleLoading(loading)}
        </Grid> :

        (<Segment basic className="segment--center" textAlign="center">
          <Image src={`./images/${image}`} centered/>
          <Header className="font--title2" color={color}>
            {header}
          </Header>
        </Segment>)}
      </>
    );
  }

  renderComponentOfRoleAndPermission = () => {
    const { resultRoles, users } = this.state;
    const { roles, error } = this.props;


    if (roles && roles.length) {
      roles.forEach(element => {
        element["usersNum"] = users.length > 0 ? this.getUserInRole(users, element.name) : 0
      });
      return (
        <Grid stackable verticalAlign={"middle"} centered className="grid__form--space">
          <Grid.Row className={"grid-tab"} columns={"equal"}>
            <Grid.Column>
              {resultRoles && resultRoles.length ? (
                <Card.Group>
                  {resultRoles.map((role, index) => (
                    <Card
                      className={"card__role"}
                      link
                      key={index}
                      onClick={this.props.onClickGotoDetail}
                      value={role._id}
                    >
                      <Card.Content className={"card-role-permit"}>
                        <Image floated={"left"}>
                          <IconCustom className={"svg-icon__user"}
                            name={"user"}
                            fillColor={RoleColor(role.name)}
                          />
                        </Image>

                        <Card.Header className={"card-role-permit__header"}>
                          <span>{RoleLabel(role.name)}</span> {role.usersNum > 0 && <Label size={"tiny"}>{role.usersNum}</Label>}
                        </Card.Header>

                        <Card.Description className={"card-role-permit__desc"}>
                          {role.description}
                        </Card.Description>

                      </Card.Content>
                    </Card>
                  ))}
                </Card.Group>
              ) : (
                  <Segment padded={"very"} textAlign={"center"} basic>
                    <Header className="header--emptyArr">
                      There are no roles in this directory.
                  </Header>
                  </Segment>
                )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else if (error && error.status === 403) {
      return this.renderEmptyContentComponent('not-permission.jpg', 'You do not have permission to access', null, 'grey');
    } else if (error && error.data) {
      return (
        <Segment>
          <Header icon>
            <Icon color={"red"} name={"times circle outline"} />
          Something went wrong, Please try again.
        </Header>
          <Button primary onClick={this.props.handleGetRoles}>
            Try Again
        </Button>
        </Segment>
      );
    }

    return handleLoading(true, "tableLoading");
  }

  render() {
    // const { resultRoles } = this.state;
    // const { roles, error } = this.props;

    return (
      <React.Fragment>
        {
          this.renderComponentOfRoleAndPermission()
        }
        {/* {roles && roles.length ? (
          <Grid stackable verticalAlign={"middle"} centered>
            <GridRow columns={"equal"} className={"grid-tab"}>
              <GridColumn textAlign={"left"}>
                <InputSearch handleTextChange={this.handleSearchChange}/>
              </GridColumn>
              <GridColumn>
                <Button
                  className={"button__image"}
                  floated={"right"}
                  basic
                  onClick={this.onClickFilter}>
                  <IconCustom className={"svg-icon--white"} name={"filter"}/>
                </Button>
              </GridColumn>
            </GridRow>
            <GridRow className={"grid-tab"} columns={"equal"}>
              <GridColumn>
                {resultRoles && resultRoles.length ? (
                  <CardGroup itemsPerRow={4}>
                    {resultRoles.map((role, index) => (
                      <Card
                        className={"card__role"}
                        link
                        key={index}
                        onClick={this.props.onClickGotoDetail}
                        value={role._id}>
                        <CardContent className={"card-role-permit"}>
                          <Image floated={"left"}>
                            <IconCustom className={"svg-icon__user"}
                                        name={"user"}
                                        fillColor={RoleColor(role.name)}/>
                          </Image>
                          <CardHeader className={"card-role-permit__header"}>
                            {role.name.toUpperCase()}
                          </CardHeader>
                          <CardDescription
                            className={"card-role-permit__desc"}
                          >
                            {role.description}
                          </CardDescription>
                        </CardContent>
                      </Card>
                    ))}
                  </CardGroup>
                ) : (
                  <Segment padded={"very"} textAlign={"center"} basic>
                    <Header className="header--emptyArr">
                      There are no roles in this directory.
                    </Header>
                  </Segment>
                )}
              </GridColumn>
            </GridRow>
          </Grid>
        ) : error && error.data ? (
          <Segment placeholder>
            <Header icon>
              <Icon color={"red"} name={"times circle outline"}/>
              Something went wrong, Please try again.
            </Header>
            <Button primary onClick={this.props.handleGetRoles}>
              Try Again
            </Button>
          </Segment>
        ) :
          handleLoading(true, "tableLoading")
        } */}
      </React.Fragment>
    );
  }
}

RoleAndPermission.propTypes = {
  onClickGotoDetail: PropTypes.func.isRequired,
  onOpenSidebar: PropTypes.func,
  handleGetRoles: PropTypes.func,
  roles: PropTypes.array,
  error: PropTypes.object
};

const mapStateToProps = (state) => ({
  error: state.authorize.error
});

const mapDispatchToProps = (dispatch) => ({
  getAuthority: (payload) => dispatch(authorizeActions.getAuthority(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleAndPermission);
