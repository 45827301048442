export const RoleColor = (type) => {
  switch (type) {
    case "admin":
      return "#FF3636";

    case "admin_account":
      return "#EFF032";

    case "admin_directory":
      return "#5571BD";

    case "admin_asset_and_device":
      return "#219421";

    case "admin_authorize":
      return "#FFA836";

    case "user":
      return "#D13BC6";

    case "admin_dashboard":
      return "#8F8F00";

    case "Kubota Admin":
      return "#DB7762";

    case "Region Admin":
      return "#E9A93F";

    case "Service Admin":
      return "#E9A93F";

    case "Service Engineer":
      return "#538BBE";

    case "Service Staff":
      return "#538BBE";

    case "Sale Admin":
      return "#D6BE46";

    case "Customer":
      return "#5FC1C9";

    case "Read Only":
      return "#94BE54";

    default:
      return "grey";
  }
};
