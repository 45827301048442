import { assetConstants } from "../_constants";

export function asset(state = {}, action) {
  switch (action.type) {

    // clear state
    case assetConstants.CLEAR_ASSET:
      return {};

    // initial state
    case assetConstants.INITIAL_STATE_ASSET:
      return {
        assetList: state.assetList
      };

    // get asset
    case assetConstants.GET_ASSET_LIST_REQUEST:
      return {
        getting: true
      };
    case assetConstants.GET_ASSET_LIST_SUCCESS:
      return {
        assetList: action.response.data.data.map((item) => ({
          _id: item._id,
          name: item.name,
          parent: "parent",
          lastValue: "lastValue"
        }))
      };
    case assetConstants.GET_ASSET_LIST_FAILURE:
      return {
        error: action.error
      };

    // post asset
    case assetConstants.POST_ASSET_REQUEST:
      return {
        assetList: state.assetList,
        creating: true
      };
    case assetConstants.POST_ASSET_SUCCESS:
      return {
        assetList: [
          {
            _id: action.response.data._id,
            name: action.response.data.name,
            parent: "parent",
            lastValue: "lastValue"
          },
          ...state.assetList
        ],
        postAssetStatus: action.response.status
      };
    case assetConstants.POST_ASSET_FAILURE:
      return {
        assetList: state.assetList,
        error: action.error
      };

    // get asset's infomation
    case assetConstants.GET_ASSET_INFO_REQUEST:
      return {
        assetList: state.assetList,
        getting: true
      };
    case assetConstants.GET_ASSET_INFO_SUCCESS:
      return {
        assetList: state.assetList,
        assetInfo: action.response.data
      };
    case assetConstants.GET_ASSET_INFO_FAILURE:
      return {
        assetList: state.assetList,
        error: action.error
      };

    // patch asset
    case assetConstants.PATCH_ASSET_REQUEST:
      return {
        assetList: state.assetList,
        updating: true,

        id: action.id
      };
    case assetConstants.PATCH_ASSET_SUCCESS:
      return {
        assetList: state.assetList.map((item) => {
          return item._id === state.id ? {
                _id: action.response.data._id,
                name: action.response.data.name,
                parent: "parent",
                lastValue: "lastValue"
              } :
            item; }),
        patchAssetStatus: action.response.status
      };
    case assetConstants.PATCH_ASSET_FAILURE:
      return {
        assetList: state.assetList,
        error: action.error
      };

    // del asset
    case assetConstants.DEL_ASSET_REQUEST:
      return {
        assetList: state.assetList,
        deleting: true,
        payload: action.payload.id
      };
    case assetConstants.DEL_ASSET_SUCCESS:
      return {
        assetList: state.assetList.filter(
          (item) => !state.payload.includes(item._id)
        ),
        delAssetStatus: action.response.status
      };
    case assetConstants.DEL_ASSET_FAILURE:
      return {
        assetList: state.assetList,
        error: action.error
      };

    default:
      return state;
  }
}
