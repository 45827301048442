/* eslint-disable complexity */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Breadcrumb, Header, Icon, Image, Menu } from "semantic-ui-react";
import { InputSearchTable } from "../../components/Input/InputSearchTable";

class DirectoryPath extends Component {
  renderName(name) {
    return name.length > 35 ? `${name.substring(0, 35)}...` : name
  }

  render() {
    const { error, directoryPath, handleBackDirectory, getRootDirectory, getParentDirectory, searchValue, handleSearchChange, getChildDirectory, activeIndex } = this.props;
    const rootDirectory = JSON.parse(localStorage.getItem("rootDirectory"));

    return (
      <React.Fragment>
        {/* ------------------------------- path -------------------------------*/}
        {error && error.response.status === 403 ? null :
        <>
        <Menu id="layout__row3" borderless className="menu--noneborder menu-path">
          {/* { directoryPath.length > 0 ?
          <Menu.Item className="paddingleft--none">
            <Icon
              name="arrow circle left"
              className={'icon--font32 color--lightgray--active icon--hover icon--cursor'}
              onClick={handleBackDirectory}
            />
          </Menu.Item> : null
          } */}

          <Menu.Item className="removePaddingLeft-Right"><Image src="./images/department.svg"/></Menu.Item>

          <Menu.Item>
            <Breadcrumb>
              <Image src="./images/arrow.svg" inline className="img--padding" />
              <Breadcrumb.Section link onClick={getRootDirectory}>
                <Header className="font--title2 color--gray weight--normal text-link">{rootDirectory ? rootDirectory.name : ""}</Header>
              </Breadcrumb.Section>
              {directoryPath.map((dir, index) => (
                <React.Fragment key={index}>
                  <Image src="./images/arrow.svg" inline className="img--padding" />

                  {directoryPath.length === index + 1 ? (
                    <Breadcrumb.Section active>
                      <Header className="font--title2 color--gray weight--normal">{this.renderName(dir.name)}</Header>
                    </Breadcrumb.Section>
                  ) : (
                      <Breadcrumb.Section link onClick={(e) => getChildDirectory(dir, searchValue, activeIndex[activeIndex.length - 1])}>
                        <Header className="font--title2 color--gray weight--normal cursor--pointer text-link">{this.renderName(dir.name)}</Header>
                      </Breadcrumb.Section>
                    )}
                </React.Fragment>
              ))}
            </Breadcrumb>
          </Menu.Item>
        </Menu>
        {/* <Menu id="layout__row3" borderless className="menu--noneborder menu-path">
          <Menu.Item position="right">
          <InputSearchTable searchValue={searchValue} handleSearchChange={handleSearchChange} />
          </Menu.Item>
        </Menu> */}
        </>
        }
        </React.Fragment>
      );
    }
  }

DirectoryPath.propTypes = {
  parentDirectory: PropTypes.arrayOf(PropTypes.object),
  childDirectory: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  status: PropTypes.bool
};

export default DirectoryPath;
