export const paginationConstants = {
  SUFFIX_SET_PAGINATION: 'SET_PAGINATION',
  SUFFIX_SET_AMOUNT_DISPLAY: 'SUFFIX_SET_AMOUNT_DISPLAY',
  SUFFIX_SET_ACTIVE_PAGE: 'SUFFIX_SET_ACTIVE_PAGE',

  // Account Page
  ACCOUNTS_SET_PAGINATION: 'ACCOUNTS_SET_PAGINATION',
  ACCOUNTS_SET_AMOUNT_DISPLAY: 'ACCOUNTS_SET_AMOUNT_DISPLAY',
  ACCOUNTS_SET_ACTIVE_PAGE: 'ACCOUNTS_SET_ACTIVE_PAGE',

  // Device Page
  DEVICES_SET_PAGINATION: 'DEVICES_SET_PAGINATION',
  DEVICES_SET_AMOUNT_DISPLAY: 'DEVICES_SET_AMOUNT_DISPLAY',
  DEVICES_SET_ACTIVE_PAGE: 'DEVICES_SET_ACTIVE_PAGE',

  // Activity Page
  ACTIVITY_SET_PAGINATION: 'ACTIVITY_SET_PAGINATION',
  ACTIVITY_SET_AMOUNT_DISPLAY: 'ACTIVITY_SET_AMOUNT_DISPLAY',
  ACTIVITY_SET_ACTIVE_PAGE: 'ACTIVITY_SET_ACTIVE_PAGE'
};
