import React from "react";
import PropTypes from 'prop-types';
import { Dropdown, Grid, Header, Label, Segment } from "semantic-ui-react";

import DeleteModal from "../../../components/Modal/DeleteModal";

export const RelationRow = (props) => {
  return (
    <Grid.Row onClick={() => props.handleShowDetail(props.relation._id)}>
      <Grid.Column>
        <Segment padded className="segment--relation">
          <Label attached="top left">
            {props.relation.directionDetail.entityType}
          </Label>
          <Header className="font--body weight--bold">
            {props.relation.directionDetail.name}
          </Header>
          <span className="font-caption1 color--empty">
            {props.relation.direction.toUpperCase()}
          </span>

          <Dropdown icon="ellipsis horizontal" className="dropdown__menu--etc">
            <Dropdown.Menu>
              {/* dropdown-item delete */}
              <DeleteModal
                triggerType="dropdown__item--delete"
                name={props.relation.directionDetail.name}
                id={props.relation._id}
                descType={"related"}
                handleDeleteItem={props.handleDeleteItem}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

RelationRow.propTypes = {
  relation: PropTypes.object.isRequired,
  handleShowDetail: PropTypes.func.isRequired
};
