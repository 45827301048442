import React from "react";
import PropTypes from 'prop-types';
import { Pagination } from "semantic-ui-react";

const NormalPagination = ({
  className,
  activePage,
  itemsPerPage,
  totalItems,
  handlePaginationChange
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i = i + 1) {
    pageNumbers.push(i);
  }

  return (
    <Pagination
      className={className}
      defaultActivePage={activePage}
      boundaryRange={1}
      siblingRange={0}
      totalPages={pageNumbers.length}

      // ellipsisItem={undefined}
      firstItem={null}
      lastItem={null}

      secondary

      // prevItem={undefined}
      // nextItem={undefined}
      onPageChange={handlePaginationChange}
    />
  );
};

NormalPagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  handlePaginationChange: PropTypes.func.isRequired
};

export default NormalPagination;
