import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Sidebar } from "semantic-ui-react";

import { connect } from "react-redux";

// import { userActions } from "../../_actions/";

import { FilterUserSegment } from "../../pages/directory/User/Sidebar/FilterUserSegment";
import CreateUserController from "../../pages/directory/User/Sidebar/CreateUserController";

import UpdateUserDetailComponent from "../../pages/directory/User/Sidebar/Component/UpdateUserDetailComponent";
import DeviceSidebar from "../../pages/directory/Device/Sidebar/DeviceSidebar";
import AssetSidebar from "../../pages/directory/Asset/Sidebar/AssetSidebar";
import AddMemberInRoleSideBar from "../../pages/directory/RoleAndPermission/Sidebar/AddMemberInRoleSideBar";
import AccountDetailSideBar from "../../pages/Account/Sidebar/AccountDetailSideBar";

class VerticalSidebar extends Component {
  typeNotHide = ["user", "userInfo", "device", "AccountDetailSideBar"];

  renderSwitch = (type) => {
    const { visible, dimmed, handleAnimationChange, userObj, isCreateAccount, isUpdateAccount, isUpdateCustomAccount, isUpdateDevice, isUpdateCustomDevice, isAdminRoot } = this.props;

    switch (type) {
      case "user":
        return (
          <CreateUserController
            objDirectory={this.props.objDirectory}
            handleAnimationChangeClose={this.props.handleAnimationChangeClose}
            userObj={userObj}
          />
        );

      case "userInfo":
        return (
          <UpdateUserDetailComponent
            userObj={userObj}
            objDirectory={this.props.objDirectory}
            handleAnimationChangeClose={this.props.handleAnimationChangeClose}
            isCreateAccount={isCreateAccount}
            isUpdateAccount={isUpdateAccount}
            isUpdateCustomAccount={isUpdateCustomAccount}
            isAdminRoot={isAdminRoot}
          />
        );

      case "device":
        return (
          <DeviceSidebar
            objDirectory={this.props.objDirectory}
            handleAnimationChangeClose={this.props.handleAnimationChangeClose}
            isUpdateDevice={isUpdateDevice}
            isUpdateCustomDevice={isUpdateCustomDevice}
            isAdminRoot={isAdminRoot}
          />
        );

      case "asset":
        return (
          <AssetSidebar
            objDirectory={this.props.objDirectory}
            handleAnimationChangeClose={this.props.handleAnimationChangeClose}
          />
        );

      case "FilterUser":
        return (
          <FilterUserSegment
            visible={visible}
            dimmed={dimmed}
            handleAnimationChange={handleAnimationChange}
          />
        );

      case "AddMemberInRole":
        return (
          <AddMemberInRoleSideBar
            handleAnimationChange={handleAnimationChange}
            handleChange={this.handleChange}
            handleCheckEmail={this.handleCheckEmail}
            OnCloseSidebar={this.OnCloseSidebar}
            handleAnimationChangeClose={this.props.handleAnimationChangeClose}
            objDirectory={this.props.objDirectory}
          />
        );

      case "AccountDetailSideBar":
        return (
          <AccountDetailSideBar
            handleAnimationChangeClose={this.props.handleAnimationChangeClose}
            handleAnimationChange={handleAnimationChange}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const { type, animation, direction, visible, sidebarWidth } = this.props;
    return (
      <Sidebar
        as={Menu}
        animation={animation}
        direction={direction}
        icon="labeled"
        vertical
        onHide={ !this.typeNotHide.includes(type) ? this.props.handleAnimationChangeClose() : null }
        visible={visible}
        className="sidebar--width308 form__sidebar"
        width={sidebarWidth ? sidebarWidth : "thin"}
      >
        {this.renderSwitch(type)}
      </Sidebar>
    );
  }
}

VerticalSidebar.propTypes = {
  type: PropTypes.string.isRequired,
  animation: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  dimmed: PropTypes.bool.isRequired,
  handleAnimationChange: PropTypes.func.isRequired,
  userObj: PropTypes.object
};

const mapStateToProps = (state) => ({
  userList: state.user.userList,
  loading: state.user.getting,
  userDetail: state.user.userDetail
});

export default connect(
  mapStateToProps,
  null
)(VerticalSidebar);
