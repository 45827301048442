import { accountPageConstants } from "../../_constants/pages/accountPage.constants";
import { combineReducers } from "redux";
import { paginationReducerFor } from "./pagination.reducer";

const initialState = {
  amountDisplay: 10,
  activePage: 1,
  totalPages: 1,
  resultDisplayTableData: [],
  displayTableData: [],
  selectedUserToDelete: [],
  previousURL: null,
  nextURL: null,
  isUpdating: false,
  loading: false,
  eligible: false
};

export function accountPage(state = initialState, action) {
  switch (action.type) {
    case accountPageConstants.UPDATING_TABLE:
      return {
        ...state,
        isUpdating: true
      };
    case accountPageConstants.UPDATE_TABLE_COMPLETED:
      return {
        ...state,
        isUpdating: !state.isUpdating
      };
    case accountPageConstants.LOADING_TABLE:
      return {
        ...state,
        loading: !state.loading
      };
    case accountPageConstants.SET_ELIGIBLE:
      return {
        ...state,
        eligible: action.eligible
      };
    default:
      return state;
  }
}

export const accountPageReducer = combineReducers({
  accountsData: accountPage,
  paginationData: paginationReducerFor('ACCOUNTS_')
});
