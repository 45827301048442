import React, { Component } from "react";
import { Button, Divider, Form, Grid, Header, Image, Modal, Segment, Icon, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { deviceActions } from "../../_actions/";
import IconCustom from "../../assets/icons/";
import handleLoading from "../Loader/Loader";

class GetTokenModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      copyPrimaryTokenSuccess: false,
      copySecondaryTokenSuccess: false
    };

    this.handleOpenModal = () => {
      this.props.getDeviceToken(this.props.id).then(() => {
        if (this.props.deviceTokenError === 404) {
          this.setState({ modalOpen: false });
        } else {
          this.setState({ modalOpen: true });
        }
      });
    };

    this.handleCloseModal = () => {
      this.setState({
        modalOpen: false,
        copyPrimaryTokenSuccess: false,
        copySecondaryTokenSuccess: false
      });
    };
  }

  copyPrimaryTokenToClipboard = () => {
    this.setState({ copyPrimaryTokenSuccess: true });
  };

  copySecondaryTokenToClipboard = () => {
    this.setState({ copySecondaryTokenSuccess: true });
  };

  render() {
    const { modalOpen, copyPrimaryTokenSuccess, copySecondaryTokenSuccess } = this.state;
    const { primaryToken, secondaryToken, gettingToken } = this.props;

    return (
      <Modal
        className="modal--width80p"
        trigger={
          <Popup
            trigger={
              <Button
                icon
                className="button__icon btn_icon--third mg-right"
                onClick={this.handleOpenModal}
              >
                <Icon name="key" />
              </Button>
            }
            content="Generate connection string"
            mouseLeaveDelay={0}
            basic
          />
        }
        open={modalOpen}
        onClose={this.handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
      >
        {handleLoading(gettingToken)}

        <Modal.Content>
          <Grid columns="equal">
            <Grid.Column width={7} textAlign="center">
              <Image className="center--vertical" src="./images/img-get-token.png" />
            </Grid.Column>


            <Grid.Column width={8}>
              <Segment className="segment--center height75p" basic>
                <Header className="font--title1">DEVICE CONNECTION STRING</Header>

                <Form>
                    <Form.Input
                      fluid
                      readOnly
                      label="Primary"
                      action={{
                        icon: copyPrimaryTokenSuccess ? "checkmark" : "copy outline",
                        onClick: () => {
                          navigator.clipboard.writeText(primaryToken);
                          this.copyPrimaryTokenToClipboard();
                        }
                      }}
                      labelPosition="right"
                      value={primaryToken}
                    />

                    <Divider hidden/>

                    <Form.Input
                      fluid
                      readOnly
                      label="Secondary"
                      action={{
                        icon: copySecondaryTokenSuccess ? "checkmark" : "copy outline",
                        onClick: () => {
                          navigator.clipboard.writeText(secondaryToken);
                          this.copySecondaryTokenToClipboard();
                        }
                      }}
                      labelPosition="right"
                      value={secondaryToken}
                    />
                </Form>
              </Segment>

              <Segment basic textAlign="center">
                <Grid columns="equal">
                  <Grid.Column>
                    <Button
                      className="btn--primary btn--width144px"
                      onClick={this.handleCloseModal}
                    >
                      CLOSE
                    </Button>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDeviceToken: (id) => dispatch(deviceActions.getDeviceToken(id))
});

const mapStateToProps = (state) => ({
  gettingToken: state.device.gettingToken,
  primaryToken: state.device.primaryToken,
  secondaryToken: state.device.secondaryToken,
  deviceTokenError: state.device.deviceTokenError
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetTokenModal);
