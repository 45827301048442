export const activityConstants = {
  // get audit list
  GET_AUDIT_REQUEST: "ACTIVITY_GET_AUDIT_REQUEST",
  GET_AUDIT_SUCCESS: "ACTIVITY_GET_AUDIT_SUCCESS",
  GET_AUDIT_FAILURE: "ACTIVITY_GET_AUDIT_FAILURE",

  // get audit detail
  GET_AUDIT_DETAIL_REQUEST: "ACTIVITY_GET_DETAIL_AUDIT_REQUEST",
  GET_AUDIT_DETAIL_SUCCESS: "ACTIVITY_GET_DETAIL_AUDIT_SUCCESS",
  GET_AUDIT_DETAIL_FAILURE: "ACTIVITY_GET_DETAIL_AUDIT_FAILURE",

  // get audit export list
  GET_AUDIT_EXPORT_REQUEST: "ACTIVITY_GET_AUDIT_EXPORT_REQUEST",
  GET_AUDIT_EXPORT_SUCCESS: "ACTIVITY_GET_AUDIT_EXPORT_SUCCESS",
  GET_AUDIT_EXPORT_FAILURE: "ACTIVITY_GET_AUDIT_EXPORT_FAILURE",
};
