import React, { Component } from "react";
import { Button, Dropdown, Grid, Header, Image, Modal, Segment, Icon } from "semantic-ui-react";

import { connect } from "react-redux";

import handleLoading from "../Loader/Loader";
import PropTypes from "prop-types";
import IconCustom from "../../assets/icons";

class WarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };

    this.handleOpenModal = () => {
      this.setState({ modalOpen: true });
    };

    this.handleCloseModal = () => {
      this.setState({ modalOpen: false });
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.delStatus === 204) {
      this.setState({
        modalOpen: false
      });
    }
  }

  // handleDeleteItem = (id, role) => {
  //   const { closeModal } = this.props;

  //   if (closeModal) {
  //     this.setState({
  //       modalOpen: false
  //     }, () => this.props.handleDeleteItem(id, role));
  //   } else {
  //     this.props.handleDeleteItem(id, role);
  //   }
  // }

  handleTriggerType = (triggerType) => {
    console.log("handleTriggerType")
    console.log(triggerType)
    switch (triggerType) {
      case "tiny":
        return (
          <Button
            icon
            size="mini"
            onClick={this.handleOpenModal}
          >
            <Icon name='trash alternate' />
          </Button>
        );

      default:
        return (
          <Button
            basic
            className={"button__image button__image--inactive"}
            onClick={this.handleOpenModal}
          >
            <IconCustom className={"svg-icon__bin"} name={"bin"} />
          </Button>
        );
    }

  };

  render() {
    const { modalOpen } = this.state;
    const {
      item,
      triggerType
    } = this.props;

    return (
      <Modal
        className="modal--width80p--withoutpadding"
        trigger={this.handleTriggerType(triggerType)}
        open={modalOpen}
        onClose={this.handleCloseModal}
        closeOnEscape={true}
        closeOnDimmerClick={false}
      >

        <Modal.Content>
          <Grid columns="equal">
            <Grid.Column />
            <Grid.Column width={8}>
              <Image src="./images/img_del_modal.svg" />
            </Grid.Column>

            <Grid.Column width={4}>
              <Segment
                textAlign="center"
                className="segment--center height75p"
                basic
              >
                <Header className="font--title1">Are you sure?</Header>
                <Header className="font--title2 color--gray weight--normal without-margin">
                  Remove {item.title} role will remove admin permissions from this account.
                </Header>
              </Segment>

              <Segment basic textAlign="center">
                <Grid columns="equal">
                  <Grid.Column>
                    <Button
                      className="btn--secondary btn--width100p"
                      onClick={() => {this.props.handleDeleteItem(null,this.props.item); this.handleCloseModal()}}
                    >
                      REMOVE
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      className="btn--primary btn--width100p"
                      onClick={this.handleCloseModal}
                    >
                      CANCEL
                    </Button>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column />
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

WarningModal.propTypes = {
  triggerType: PropTypes.bool
};

const mapStateToProps = (state) => ({
});

export default connect(
  mapStateToProps,
  null
)(WarningModal);
