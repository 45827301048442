import React from "react";
import PropTypes from "prop-types";

export const InputModal = (props) => {
  return (
    <div className={`normalfield__group ${props.padding ? props.padding : ""}`}>
      <input
        className={
          props.textAlign === "center" ?
            "normalfield__field align--center" :
            "normalfield__field"
        }
        name={props.name}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        disabled={props.disabled}
      />
      <label htmlFor={props.name} className="normalfield__label">
        {props.placeholder}
        {props.isRequired ? "*" : ""}
      </label>

      <div className="text--validate-error">{props.error}</div>
    </div>
  );
};

InputModal.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default InputModal;
