import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import HomePage  from "./../pages/home/HomePage";
import DirectoryPage from "./../pages/directory/DirectoryPage";
import Loadable from "react-loadable";
import DeviceTypeController from './../pages/DeviceType/DeviceTypeController';

// import ChangeORForgotPassPage from '../pages/changeORforgotPass/ChangeORForgotPassPage';
// import ReportPage from "../pages/Report/ReportPage";

const LoadableAccountPage = Loadable({
  loader: () => import("../pages/Account/AccountViewController"),
  loading: () => null
});

const LoadableDevicePage = Loadable({
  loader: () => import("../pages/Device/DeviceViewController"),
  loading: () => null
});

const LoadableActivityPage = Loadable({
  loader: () => import("../pages/ActivityLog/ActivityLogViewController"),
  loading: () => null
});

const LoadableNotificationLogPage = Loadable({
  loader: () => import("../pages/NotificationLog/NotificationLogViewController"),
  loading: () => null
}); 

export default function Routing() {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />

      <Route exact path={"/home"} component={HomePage} />
      <Route exact path={"/directory"} component={DirectoryPage} />
      <Route exact path={"/account"} component={LoadableAccountPage} />
      <Route exact path={"/device"} component={LoadableDevicePage} />
      <Route exact path={"/deviceType"} component={DeviceTypeController} />
      <Route exact path={"/activityLog"} component={LoadableActivityPage} />
      <Route exact path={"/notificationLog"} component={LoadableNotificationLogPage} />

      {/* <Route exact path={"/report"} component={ReportPage} /> */}
    </Switch>
  );
}
