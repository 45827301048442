import React, { Component } from "react";
import { Button, Form, Grid, Header, Image, Radio, Segment } from "semantic-ui-react";
import Carousel from "nuka-carousel";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { accountActions } from "../../_actions/account.actions";

import InputForm from "../../components/Input/InputForm";
import { NavbarLogo } from "../../components/Menu/NavBarLogo";
import handleLoading from "../../components/Loader/Loader";

import { history } from "../../_helpers/";

export class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: Boolean(localStorage.getItem('email')) || Boolean(localStorage.getItem('username')),
      email: localStorage.getItem('email') || localStorage.getItem('username') ? localStorage.getItem('email') ?? localStorage.getItem('username') : "",
      password: "",
      errorEmail: "",
      isAuthenticated: false
    };
  }

  validate = () => {
    let errorEmail = "";
    const { email } = this.state;

    // if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email) === false) {
    //   errorEmail = "Please enter your email address.";
    // }

    if (email === "") {
      errorEmail = "Please enter your email address or username.";
    }

    if (errorEmail) {
      this.setState({ errorEmail });
      return false;
    }

      this.setState({
        errorEmail: ""
      });

    return true;
  };

  handleSubmit = () => {
    const isValid = this.validate();
    const payload = {
      email: this.state.email,
      password: this.state.password
    };

    if (isValid) {
      // call login function in actions file by props
      this.props.login(payload, this.state.checked).then((res) => {
        console.log("loginPage.jsx =====>>>> ",res);



        if(res.telephoneNumber){
          localStorage.setItem('telephoneNumber', res.telephoneNumber);
          //localStorage.setItem('email', this.state.email);
          localStorage.setItem('remember', this.state.checked);
          history.push({    // no need
            pathname: "/verifyOTP",
            state: { telephoneNumber: res.telephoneNumber }
          });
        }

        if(res.accessToken){
          if (localStorage.getItem('remember') === true) {
            if(this.state.email.includes("@")) {
              localStorage.setItem('email', this.state.email);
              if(localStorage.getItem('username')) localStorage.removeItem('username');
            }
            else {
              localStorage.setItem('username', this.state.email);
              if(localStorage.getItem('email')) localStorage.removeItem('email');
            }
          }else {
            if(localStorage.getItem('email')) localStorage.removeItem('email');
            if(localStorage.getItem('username')) localStorage.removeItem('username');
          }
        }
        
        // if (this.state.checked === true) {
        //   if(this.state.email.includes("@")) {
        //     localStorage.setItem('email', this.state.email);
        //     if(localStorage.getItem('username')) localStorage.removeItem('username');
        //   }
        //   else {
        //     localStorage.setItem('username', this.state.email);
        //     if(localStorage.getItem('email')) localStorage.removeItem('email');
        //   }
        // }else {
        //   if(localStorage.getItem('email')) localStorage.removeItem('email');
        //   if(localStorage.getItem('username')) localStorage.removeItem('username');
        // }
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  toggle = () => {
    this.setState((prevState) => ({ checked: !prevState.checked }));
  };

  handleForgotPassword = () => {
    history.push("/sendEmail");
    //history.push("/verifyOTP");
  }

  render() {
    const { authenticating } = this.props;

    return (
      <React.Fragment>
        <NavbarLogo />

        <Grid className="login-grid">
          <Grid.Row centered>
            <Grid.Column computer={7} textAlign="center" only="computer">
              <Header as="h1">Welcome</Header>
              <label className="description-label">
                Paragraphs are the building blocks of papers. Many students
                define paragraphs in terms of length: a paragraph is a group of
                at least five sentences, a paragraph is half a page long, etc.
              </label>
              <Carousel
                wrapAround
                dragging={false}
                className="silder-img-loginpage"
                width={387}
              >
                <Image src="./images/login-silde.svg" />
                <Image src="./images/login-silde.svg" />
                <Image src="./images/login-silde.svg" />
              </Carousel>
            </Grid.Column>

            <Grid.Column width={1} only="computer"/>
            <Grid.Column computer={6} tablet={10} mobile={16} className="col-login-form">
              <Segment className="login-form-segment">
                {handleLoading(authenticating)}
                <Segment basic>
                  <Header as="h2" textAlign="center">
                    ADMIN LOGIN
                  </Header>
                </Segment>
                <Form>

                  <InputForm
                    icon="user"
                    name="email"
                    type="text"
                    value={this.state.email}
                    handleChange={this.handleChange}
                    placeholder="Email/Username"
                  />
                  <InputForm
                    icon="lock"
                    name="password"
                    type="password"
                    value={this.state.password}
                    handleChange={this.handleChange}
                    placeholder="Password"
                  />
                  <p className="font--error">{this.state.errorEmail}</p>

                  <span>
                    <Radio
                      className="radio--square"
                      label="Remember me"
                      checked={this.state.checked}
                      onClick={this.toggle}
                    />

                    <label className="forgotPassword-label" onClick={this.handleForgotPassword}>
                      Forgot password
                    </label>

                  </span>

                  <br />
                  <br />
                  <br />

                  <Button
                    fluid
                    type="submit"
                    className="btn--primary"
                    onClick={this.handleSubmit}
                  >
                    LOGIN
                  </Button>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  authenticating: state.authentication.authenticating
});

const mapDispatchToProps = (dispatch) => ({
  login: (payload, rememberMe) => dispatch(accountActions.login(payload, rememberMe))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
