import React from "react";

const SVG = ({
  className = "",
  viewBox = "0 0 32 32",
  width = "32",
  height = "32",
  reactX = "4",
  reactY = "4",
  active = false,
  activeClassName = "",
  fillColor = "transparent"
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""} ${active ? activeClassName : ""}`}
  >
    <rect
      x="0.5"
      y="0.5"
      width={width - 1}
      height={height - 1}
      rx="8"
      fill={fillColor}
    />
    <svg
      id={"icon-check"}
      fill={"none"}
      stroke={"none"}
      viewBox="0 0 24 24"
      width={width * 0.7}
      height={height * 0.7}
      x={reactX}
      y={reactY}
    >
      <path d="M 19.28125 5.28125 L 9 15.5625 L 4.71875 11.28125 L 3.28125 12.71875 L 8.28125 17.71875 L 9 18.40625 L 9.71875 17.71875 L 20.71875 6.71875 Z" />
    </svg>
  </svg>
);

export default SVG;
