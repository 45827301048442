import React from "react";

const SVG = ({
               className = "",
               viewBox = "0 0 32 32",
               width = "32",
               height = "32",
               x = "8",
               y = "8"
             }) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg"
       className={`svg-icon ${className || ""}`}>
    <rect x="0.5" y="0.5" width={width - 1} height={height - 1} rx="8"/>
    <svg id={"remove-user"} height={height / 2} width={width / 2} x={`${x && x.length ? x : ((width / 2) / 2) + 0.5}`}
         y={`${y && y.length ? y : ((height / 2) / 2) - 0.5}`}
         viewBox="0 0 640 512" fill={"none"}>
      <path
        d="M589.6 240l45.6-45.6c6.3-6.3 6.3-16.5 0-22.8l-22.8-22.8c-6.3-6.3-16.5-6.3-22.8 0L544 194.4l-45.6-45.6c-6.3-6.3-16.5-6.3-22.8 0l-22.8 22.8c-6.3 6.3-6.3 16.5 0 22.8l45.6 45.6-45.6 45.6c-6.3 6.3-6.3 16.5 0 22.8l22.8 22.8c6.3 6.3 16.5 6.3 22.8 0l45.6-45.6 45.6 45.6c6.3 6.3 16.5 6.3 22.8 0l22.8-22.8c6.3-6.3 6.3-16.5 0-22.8L589.6 240zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/>
    </svg>
  </svg>);

export default SVG;
